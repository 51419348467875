import { STATUS_BEFORE_START } from "../../constants/commondata"

export const verifyStatusShowUnassignAllBtn = (books = []) => {
  if(!(books?.length > 0)) return false
  if(books.every(bk => !STATUS_BEFORE_START.includes(bk.status))) return false

  if(books?.length === 1) {
    return STATUS_BEFORE_START.includes(books[0].status)
  }

  return true
}

export const getPlateNumberFromBook = ({ booking, isMobile }) => {
  const plateNumber =
    booking?.vehicle?.plateNumber || booking?.drvInfo?.plateNumber || '';
  if (isMobile) {
    let txtFullInfoVhc = plateNumber;
    if (booking?.vehicle?.plateNumber) {
      txtFullInfoVhc = `${booking?.vehicle?.plateNumber}
      ${
        booking?.vehicle?.vehicleModelName
          ? ` ${'\u2022'} ${booking?.vehicle?.vehicleModelName}`
          : ''
      }
      ${
        booking?.vehicle?.vhcColor
          ? ` ${'\u2022'} ${booking?.vehicle?.vhcColor}`
          : ''
      }
    `;
    }
    return txtFullInfoVhc;
  }

  return plateNumber;
};