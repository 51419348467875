import React, { useEffect, useRef, useState } from 'react';
import Drawer from '../../../../components/drawer';
import { Button, FormControl, FormGroup } from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import styles from '../styles/formCarType.module.scss';
import NewIconUpload from '../../../../assets/images/icons/newIconUpload.svg';
import DeleteIcon from '../../../../assets/images/icons/delete.svg';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import RadioButton from '../../../../components/radioButton/radio';
import { MultiSelectCommonFunc } from '../../../../utils/commonFunctions';
import _ from 'lodash';
const FormCarType = (props) => {
  const {
    showModalCarType,
    dataEdit,
    permissions,
    commonData,
    settingActions,
    fleetId,
    notification,
    updateDataList,
    closeModal,
    s3Host,
  } = props;
  const [dialogData, setDialogData] = useState({
    vehicleType: '',
    appDisplayName: '',
    shortDesc: '',
    maxPassengers: 0,
    requireMaxPassengers: false,
    requireMaxLuggages: false,
    maxLuggages: 0,
    iconSlider: '',
    iconOnMap: '',
    image: '',
    bookLater: true,
    bookNow: true,
    destinationRequired: false,
    extraDestination: false,
    actualFare: true,
    zoneId: [],
    zoneName: [],
    dispatch: [],
  });

  const [errorValid, setErrorValid] = useState({});
  const [zoneList] = useState(
    _.filter(
      commonData.mapZone,
      (z) => z.display && z.isActive && z.currency
    ) || []
  );
  const [carTypeList] = useState(commonData.carType || []);
  const handleResetForm = () => {
    setDialogData({
      vehicleType: '',
      appDisplayName: '',
      shortDesc: '',
      maxPassengers: 0,
      requireMaxPassengers: false,
      requireMaxLuggages: false,
      maxLuggages: 0,
      iconSlider: '',
      iconOnMap: '',
      image: '',
      bookLater: true,
      bookNow: true,
      destinationRequired: false,
      extraDestination: false,
      actualFare: true,
      zoneId: [],
      zoneName: [],
      dispatch: [],
    });
    setErrorValid({});
    closeModal();
  };

  const renderImage = (val) => {
    if (typeof val === 'string') {
      return `${s3Host}${val}`;
    } else {
      return URL.createObjectURL(val);
    }
  };

  const handleChangeMultiple = (e, key) => {
    let value = MultiSelectCommonFunc.getSelectValues(e);
    let dialogDataDefault = { ...dialogData } || {};
    dialogDataDefault[key] = value;
    if (key == 'zoneId') {
      dialogDataDefault['zoneName'] = zoneList
        ?.filter((item) => value.includes(item._id))
        ?.map((item) => item.zoneName);
    }
    setDialogData(dialogDataDefault);
  };

  const handleChangeValue = (key, value) => {
    let dialogDataDefault = { ...dialogData } || {};
    dialogDataDefault[key] = value;
    setDialogData(dialogDataDefault);
  };

  const handleChangeImages = (key, e) => {
    const files = [...e.target.files];
    let newImages = {};

    files.forEach((file) => {
      newImages = file;
    });

    let dialogDataDefault = { ...dialogData } || {};
    dialogDataDefault[key] = newImages;
    setDialogData(dialogDataDefault);
  };

  const deleteImages = (key) => {
    let dialogDataDefault = { ...dialogData } || {};
    dialogDataDefault[key] = null;
    setDialogData(dialogDataDefault);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const validateData = (val) => {
    let errorVal = {
      vehicleType: '',
      iconSlider: '',
      iconOnMap: '',
      image: '',
      appDisplayName: '',
      maxPassengers: '',
      maxLuggages: '',
    };
    if (!val.iconSlider) {
      errorVal.iconSlider = 'carTypeSetting.validateSelectImage';
    }
    if (!val.iconOnMap) {
      errorVal.iconOnMap = 'carTypeSetting.validateSelectImage';
    }
    if (!val.image) {
      errorVal.image = 'carTypeSetting.validateSelectImage';
    }
    if (!val.vehicleType) {
      errorVal.vehicleType = 'carTypeSetting.validateName';
    }
    if (!val.appDisplayName) {
      errorVal.appDisplayName = 'carTypeSetting.validateAppDisplayName';
    }
    if (val.requireMaxLuggages && !val.maxLuggages) {
      errorVal.maxLuggages = 'carTypeSetting.validateRequire';
    }
    if (val.requireMaxPassengers && !val.maxPassengers) {
      errorVal.maxPassengers = 'carTypeSetting.validateRequire';
    }
    setErrorValid(errorVal);
  };

  const handleSaveData = async () => {
    try {
      const updateData = { ...dialogData };
      updateData.fleetId = fleetId;
      validateData(updateData);

      if (
        !updateData.iconSlider ||
        !updateData.iconOnMap ||
        !updateData.image ||
        !updateData.vehicleType ||
        !updateData.appDisplayName ||
        (updateData.requireMaxLuggages && !updateData.maxLuggages) ||
        (updateData.requireMaxPassengers && !updateData.maxPassengers)
      ) {
        return;
      }

      if (updateData.iconSlider && typeof updateData.iconSlider !== 'string') {
        const iconSliderBase64 = await convertToBase64(updateData.iconSlider);
        updateData.iconSlider = iconSliderBase64;
      }

      if (updateData.iconOnMap && typeof updateData.iconOnMap !== 'string') {
        const iconOnMapBase64 = await convertToBase64(updateData.iconOnMap);
        updateData.iconOnMap = iconOnMapBase64;
      }

      if (updateData.image && typeof updateData.image !== 'string') {
        const imageBase64 = await convertToBase64(updateData.image);
        updateData.image = imageBase64;
      }

      const result = await settingActions.updateVehicleType(updateData);
      if (!result.ok) {
        if (result.error.errorCode === 4008) {
          notification('error', result.error.message);
        } else {
          notification('error', result.error?.message || I18n.t('carTypeSetting.failApi'));
        }
      } else {
        notification('success', I18n.t('carTypeSetting.updateSuccess'));
        updateDataList();
        handleResetForm();
        setTimeout(function () {
          window.location.reload();
        }, 1 * 1000);
      }
    } catch (error) {
      notification('error', I18n.t('carTypeSetting.failApi'));
    }
  };

  useEffect(() => {
    if (showModalCarType && dataEdit) {
      setDialogData(JSON.parse(JSON.stringify(dataEdit)));
    }
  }, [showModalCarType]);

  return (
    <>
      <Drawer
        isOpen={showModalCarType}
        onClose={handleResetForm}
        title={
          <Translate
            value={
              !dataEdit
                ? 'carTypeSetting.newService'
                : 'carTypeSetting.editService'
            }
          />
        }
        footer={
          <>
            {!permissions || permissions.actions ? (
              !dataEdit ? (
                <Button className="btn-save" onClick={handleSaveData}>
                  <Translate value="carTypeSetting.Save" />
                </Button>
              ) : (
                <Button className="btn-save" onClick={handleSaveData}>
                  <Translate value="carTypeSetting.Update" />
                </Button>
              )
            ) : null}
            <Button className="btn-cancel" onClick={handleResetForm}>
              <Translate value="carTypeSetting.Cancel" />
            </Button>
          </>
        }
      >
        <div className={styles['title-content']}>
          <Translate value="carTypeSetting.serviceInfo" />
        </div>

        <FormGroup className={styles['group-item']}>
          <div className={styles['text-content']}>
            <Translate value="carTypeSetting.name" />
            <span className={styles['require']}> *</span>
          </div>
          <FormControl
            type="text"
            className="form-custom"
            onChange={(e) => {
              handleChangeValue('vehicleType', e.target.value);
            }}
            value={dialogData?.vehicleType || ''}
            placeholder={I18n.t('carTypeSetting.namePH')}
            maxLength={100}
            required={true}
          />
          {errorValid.vehicleType && (
            <div className={styles['validate-errorValid']}>
              <Translate value={errorValid.vehicleType} />
            </div>
          )}
        </FormGroup>

        <FormGroup className={styles['group-item']}>
          <div className={styles['text-content']}>
            <Translate value="carTypeSetting.appDisplayName" />
            <span className={styles['require']}> *</span>
          </div>
          <FormControl
            type="text"
            className="form-custom"
            onChange={(e) => {
              handleChangeValue('appDisplayName', e.target.value);
            }}
            value={dialogData?.appDisplayName || ''}
            placeholder={I18n.t('carTypeSetting.appDisplayName')}
            maxLength={100}
            required={true}
            disabled={false}
          />
          {errorValid.appDisplayName && (
            <div className={styles['validate-errorValid']}>
              <Translate value={errorValid.appDisplayName} />
            </div>
          )}
        </FormGroup>
        <FormGroup className={styles['group-item']}>
          <div className={styles['text-content']}>
            <Translate value="carTypeSetting.shortDescription" />
          </div>
          <FormControl
            type="text"
            className="form-custom"
            onChange={(e) => {
              handleChangeValue('shortDesc', e.target.value);
            }}
            value={dialogData?.shortDesc || ''}
            placeholder={I18n.t('carTypeSetting.shortDescription')}
            maxLength={500}
            required={true}
            disabled={false}
          />
        </FormGroup>
        <div className={styles['flex-col-2']}>
          <FormGroup className={styles['group-item']}>
            <div className={styles['text-content']}>
              <Translate value="carTypeSetting.maxPassengers" />
            </div>
            <FormControl
              type="number"
              min={0}
              className="form-custom"
              onChange={(e) => {
                handleChangeValue(
                  'maxPassengers',
                  parseInt(e.target.value || 0)
                );
              }}
              value={dialogData?.maxPassengers || 0}
              placeholder={I18n.t('carTypeSetting.enterNumber')}
              maxLength={25}
              required={true}
              disabled={false}
            />
            {errorValid.maxPassengers && (
              <div className={styles['validate-errorValid']}>
                <Translate value={errorValid.maxPassengers} />
              </div>
            )}
            <CcCheckbox
              className={styles['remove-margin']}
              checked={dialogData?.requireMaxPassengers}
              onChange={(e) =>
                handleChangeValue('requireMaxPassengers', e.target.checked)
              }
              text={
                <span className={styles['text-content']}>
                  <Translate value="carTypeSetting.Required" />
                </span>
              }
            />
          </FormGroup>
          <FormGroup className={styles['group-item']}>
            <div className={styles['text-content']}>
              <Translate value="carTypeSetting.maxLuggage" />
            </div>
            <FormControl
              type="number"
              min={0}
              className="form-custom"
              onChange={(e) => {
                handleChangeValue('maxLuggages', parseInt(e.target.value || 0));
              }}
              value={dialogData?.maxLuggages || 0}
              placeholder={I18n.t('carTypeSetting.enterNumber')}
              maxLength={25}
              required={true}
              disabled={false}
            />
            {errorValid.maxLuggages && (
              <div className={styles['validate-errorValid']}>
                <Translate value={errorValid.maxLuggages} />
              </div>
            )}
            <CcCheckbox
              className={styles['remove-margin']}
              checked={dialogData?.requireMaxLuggages}
              onChange={(e) =>
                handleChangeValue('requireMaxLuggages', e.target.checked)
              }
              text={
                <span className={styles['text-content']}>
                  <Translate value="carTypeSetting.Required" />
                </span>
              }
            />
          </FormGroup>
        </div>

        <div className={styles['title-content']}>
          <Translate value="carTypeSetting.vehiclePhoto" />
        </div>

        <div className={styles['flex-col-2']}>
          <FormGroup className={styles['group-item']}>
            <div className={styles['text-content']}>
              <Translate value="carTypeSetting.iconShowList" />
              <span className={styles['require']}> *</span>
            </div>
            <div className={styles['input_images']}>
              {dialogData?.iconSlider ? (
                <div className={styles['file_upload_render']}>
                  <img
                    className={styles['image-render']}
                    src={renderImage(dialogData?.iconSlider)}
                    alt="iconSlider"
                  />
                  <div
                    className={styles['delete-icon']}
                    onClick={() => deleteImages('iconSlider')}
                  >
                    <img alt="deleteIcon" src={DeleteIcon} />
                  </div>
                </div>
              ) : (
                <div className={styles['file_upload']}>
                  <div className={styles['upload-group-image']}>
                    <img alt="NewIconUpload" src={NewIconUpload} />
                  </div>
                  <input
                    type="file"
                    className={styles['inputfile']}
                    accept="image/*"
                    onChange={(e) => handleChangeImages('iconSlider', e)}
                  />
                </div>
              )}
            </div>
            <div className={styles['text-content-small']}>
              <span>
                <Translate value="carTypeSetting.recommendedSize" /> 170x74
              </span>
            </div>
            {errorValid.iconSlider && (
              <div className={styles['validate-errorValid']}>
                <Translate value={errorValid.iconSlider} />
              </div>
            )}
          </FormGroup>
          <FormGroup className={styles['group-item']}>
            <div className={styles['text-content']}>
              <Translate value="carTypeSetting.imageShowWeb" />
              <span className={styles['require']}> *</span>
            </div>
            <div className={styles['input_images']}>
              {dialogData?.image ? (
                <div className={styles['file_upload_render']}>
                  <img
                    className={styles['image-render']}
                    src={renderImage(dialogData?.image)}
                    alt="image"
                  />
                  <div
                    className={styles['delete-icon']}
                    onClick={() => deleteImages('image')}
                  >
                    <img alt="deleteIcon" src={DeleteIcon} />
                  </div>
                </div>
              ) : (
                <div className={styles['file_upload']}>
                  <div className={styles['upload-group-image']}>
                    <img alt="NewIconUpload" src={NewIconUpload} />
                  </div>
                  <input
                    type="file"
                    className={styles['inputfile']}
                    accept="image/*"
                    onChange={(e) => handleChangeImages('image', e)}
                  />
                </div>
              )}
            </div>
            <div className={styles['text-content-small']}>
              <span>
                <Translate value="carTypeSetting.recommendedSize" /> 400x200
              </span>
            </div>
            {errorValid.image && (
              <div className={styles['validate-errorValid']}>
                <Translate value={errorValid.image} />
              </div>
            )}
          </FormGroup>
        </div>

        <FormGroup className={styles['group-item']}>
          <div className={styles['text-content']}>
            <Translate value="carTypeSetting.carPinOnMap" />
            <span className={styles['require']}> *</span>
          </div>
          <div className={styles['input_images']}>
            {dialogData?.iconOnMap ? (
              <div
                className={`${styles['file_upload_render']} ${styles['size-small']}`}
              >
                <img
                  className={styles['image-render-small']}
                  src={renderImage(dialogData?.iconOnMap)}
                  alt="iconOnMap"
                />
                <div
                  className={styles['delete-icon']}
                  onClick={() => deleteImages('iconOnMap')}
                >
                  <img alt="deleteIcon" src={DeleteIcon} />
                </div>
              </div>
            ) : (
              <div
                className={`${styles['file_upload']} ${styles['size-small']}`}
              >
                <div className={styles['upload-group-image']}>
                  <img alt="NewIconUpload" src={NewIconUpload} />
                  <div className={styles['delete-icon']}>
                    <img alt="deleteIcon" src={DeleteIcon} />
                  </div>
                </div>
                <input
                  type="file"
                  className={styles['inputfile']}
                  accept="image/*"
                  onChange={(e) => handleChangeImages('iconOnMap', e)}
                />
              </div>
            )}
          </div>
          <div className={styles['text-content-small']}>
            <span>
              <Translate value="carTypeSetting.recommendedSize" /> 44x84
            </span>
          </div>
          {errorValid.iconOnMap && (
            <div className={styles['validate-errorValid']}>
              <Translate value={errorValid.iconOnMap} />
            </div>
          )}
        </FormGroup>

        <div className={styles['title-content']}>
          <Translate value="carTypeSetting.serviceSetting" />
        </div>

        <div className={styles['text-content']}>
          <Translate value="carTypeSetting.serviceType" />
        </div>

        <div className={styles['flex-col-2']}>
          <FormGroup className={styles['group-item']}>
            <CcCheckbox
              className={styles['remove-margin']}
              checked={dialogData?.bookLater}
              onChange={(e) => handleChangeValue('bookLater', e.target.checked)}
              text={
                <span className={styles['text-content']}>
                  <Translate value="carTypeSetting.reservation" />
                </span>
              }
            />
          </FormGroup>
          <FormGroup className={styles['group-item']}>
            <CcCheckbox
              className={styles['remove-margin']}
              checked={dialogData?.bookNow}
              onChange={(e) => handleChangeValue('bookNow', e.target.checked)}
              text={
                <span className={styles['text-content']}>
                  <Translate value="carTypeSetting.hideDestinationOnDemand" />
                </span>
              }
            />
          </FormGroup>
        </div>

        <div className={styles['text-content']}>
          <Translate value="carTypeSetting.destinationSetting" />
        </div>

        <div className={styles['flex-col-2']}>
          <FormGroup className={styles['group-item']}>
            <CcCheckbox
              className={styles['remove-margin']}
              checked={dialogData?.destinationRequired}
              onChange={(e) =>
                handleChangeValue('destinationRequired', e.target.checked)
              }
              text={
                <span className={styles['text-content']}>
                  <Translate value="carTypeSetting.requireDestination" />
                </span>
              }
            />
          </FormGroup>
          <FormGroup className={styles['group-item']}>
            <CcCheckbox
              className={styles['remove-margin']}
              checked={dialogData?.extraDestination}
              onChange={(e) =>
                handleChangeValue('extraDestination', e.target.checked)
              }
              text={
                <span className={styles['text-content']}>
                  <Translate value="carTypeSetting.extraDestination" />
                </span>
              }
            />
          </FormGroup>
        </div>

        <div className={styles['text-content']}>
          <Translate value="carTypeSetting.calculatorFareMode" />
        </div>

        <div className={styles['flex-col-2']}>
          <FormGroup className={styles['group-item']}>
            <RadioButton
              text={I18n.t('carTypeSetting.meteredFare')}
              value={true}
              onChange={(e) => handleChangeValue('actualFare', true)}
              checked={dialogData?.actualFare === true}
              name="calculatorFare"
            />
          </FormGroup>
          <FormGroup className={styles['group-item']}>
            <RadioButton
              text={I18n.t('carTypeSetting.quotedFare')}
              value={false}
              name="calculatorFare"
              onChange={(e) => handleChangeValue('actualFare', false)}
              checked={dialogData?.actualFare === false}
            />
          </FormGroup>
        </div>

        <div className={styles['title-content']}>
          <Translate value="carTypeSetting.availableZones" />
        </div>

        <FormGroup
          controlId="formControlsSelectMultiple"
          className={`${styles['group-item']} ${styles['select-multiple']}`}
        >
          <FormControl
            className={`form-custom select-zone ${styles['form-select-multiple']}`}
            as="select"
            multiple
            onChange={(e) => handleChangeMultiple(e, 'zoneId')}
            value={dialogData.zoneId || []}
            // disabled={disable}
          >
            {zoneList?.map((z) => {
              return (
                <option
                  style={{ padding: '4px 16px', marginBottom: '4px' }}
                  key={z._id}
                  value={z._id}
                >
                  {z.zoneName}
                </option>
              );
            })}
          </FormControl>
        </FormGroup>

        <div className={styles['title-content']}>
          <Translate value="carTypeSetting.dispatchAssociation" />
        </div>

        <FormGroup
          controlId="formControlsSelectMultiple"
          className={`${styles['group-item']} ${styles['select-multiple']}`}
        >
          <FormControl
            className={`form-custom select-zone ${styles['form-select-multiple']}`}
            as="select"
            multiple
            onChange={(e) => handleChangeMultiple(e, 'dispatch')}
            value={dialogData.dispatch || []}
            // disabled={disable}
          >
            {carTypeList?.map((z) => {
              return (
                <option
                  style={{ padding: '4px 16px', marginBottom: '4px' }}
                  key={z._id}
                  value={z.vehicleType}
                >
                  {z.vehicleType}
                </option>
              );
            })}
          </FormControl>
        </FormGroup>
      </Drawer>
    </>
  );
};

export default FormCarType;
