export const tl = {
  "name": "Tagalog",
  "flagCode": "ph",
  "locale": "tl-tl",
  "Sidebar": {
    "Setting": "Mga setting",
    "Map": "Mapa",
    "Cue": "Pila",
    "ImportBookings": "Mag-import ng Transport Booking",
    "ImportDeliveryBookings": "Mag-import ng Mga Delivery Booking",
    "Trip": "Trip",
    "New_booking": "Bagong Booking",
    "City": "lungsod",
    "Chauffeur": "Transportasyon",
    "Delivery": "Paghahatid",
    "Shuttle": "Shuttle",
    "Intercity": "Intercity",
    "Customer": "Mga customer",
    "Driver": "Mga driver",
    "REPORTS": "Mga ulat",
    "Corporate": "Corporate",
    "mDispatcher": "Kasosyo",
    "Reservation": "Kalendaryo",
    "withdrawalRequest": "Mga kahilingan sa withdrawal",
    "Driver_Settlement": "Pag-aayos ng Driver",
    "Dashboard": "Analytics",
    "SmartData": "Smart data",
    "Smart_Data": "Smart data",
    "Campaign_Builder": "Tagabuo ng kampanya",
    "Company_operator": "Operator ng kumpanya",
    "Queuing_area": "Lugar ng pila",
    "Withdrawal_Requests": "Mga Kahilingan sa Pag-withdraw",
    "pickUp": "Pulutin/ Sunduin",
    "dropOff": "Ilaglag/ Naihatid",
    "SignUpNotifications": "Mga Notification sa Pag-sign Up",
    "Payout": "Payout",
    "Payout_label": "Payout",
    "payout": {
      "Pay_To_Driver": "Magbayad sa mga Driver",
      "Payout_History_For_Driver": "Kasaysayan ng Payout Driver",
      "Pay_To_Merchant": "Magbayad sa mga Merchant",
      "Payout_History_For_Merchant": "Kasaysayan ng Merchant ng Payout"
    },
    "number_of_records_to_export": "Bilang ng mga tala na ie-export",
    "export": "I-export",
    "Airline": "Airline",
    "driverPayout": {
      "Pay_to_driver": "Magbayad sa mga Driver",
      "Payout_history": "Kasaysayan ng Pagbabayad"
    },
    "Reports": {
      "Driver_Referral_history": "Tinutukoy ng driver ang customer",
      "Driver_refers_Pax": "Mga driver",
      "Driver_refers_Pax_Customers": "Mga customer",
      "Driver_refers_Pax_Bookings": "Mga booking",
      "Driver_refers_customer_Drivers": "Tinutukoy ng driver ang customer (Mga Driver)",
      "Driver_refers_customer_Customers": "Tinutukoy ng driver ang customer (Mga Customer)",
      "Driver_refers_customer_Bookings": "Tinutukoy ng driver ang customer (Mga Booking)",
      "Passenger_refers_Passengers": "Tinutukoy ng customer ang customer",
      "Driver_refers_drivers_Referrer": "Tinutukoy ng driver ang mga driver (Referrer)",
      "Deletion_request": "Kahilingan sa pagtanggal",
      "Driver_refers_drivers_Referee": "Tinutukoy ng driver ang mga driver (Referee)",
      "Driver_refers_Driver": "Driver ay tumutukoy sa driver",
      "Driver_refers_driver_Referrer": "Referrer",
      "Driver_refers_driver_Referee": "Referee",
      "Referral_history": "Kasaysayan ng referral",
      "Booking_details": "Mga detalye ng booking",
      "Financial": "Pananalapi",
      "Financial_driver": "Driver",
      "Financial_fleet": "Armada",
      "Financial_company": "kumpanya",
      "Financial_Driver": "Pinansyal (Driver)",
      "Financial_Fleet": "Pinansyal (Fleet)",
      "Financial_Company": "Pinansyal (Kumpanya)",
      "Financial_fleet_profit": "Fleet na kita",
      "Financial_Company_Tooltip": "Ulat ng netong kita ng mga driver, na nakagrupo ayon sa kumpanya",
      "Financial_Driver_ToolTip": "Net na ulat ng kita ng bawat driver",
      "Financial_Fleet_Profit_ToolTip": "Kita ng fleet",
      "Driver_activity": "Aktibidad ng driver",
      "Revenue_Summary": "Buod ng kita",
      "Revenue_fleet": "Armada",
      "Revenue_driver": "Driver",
      "Revenue_monthly": "Buwan-buwan",
      "Revenue_Summary_Fleet": "Buod ng Kita (Fleet)",
      "Revenue_Summary_Driver": "Buod ng Kita (Driver)",
      "Revenue_Summary_Monthly": "Buod ng Kita (Buwanang)",
      "Total_adjustment": "Kabuuang pagsasaayos",
      "Promotion": "Promosyon",
      "Incident_Cancellation": "Insidente at Pagkansela",
      "Booking_logs": "Mga log ng booking",
      "Rating": "Marka",
      "Thumbs_rating": "Marka",
      "Stars_rating": "Marka",
      "Operator_logs": "Mga log ng operator",
      "Daily": "Araw-araw",
      "Daily_Driver": "Araw-araw na Driver",
      "Daily_Car": "Araw-araw na Sasakyan",
      "Daily_Driver_Car": "Pang-araw-araw na Sasakyan ng Driver",
      "mDispatcher": "Kasosyo",
      "Corporate": "Lịch trình của công ty",
      "Driver_login_status": "Katayuan ng pag-login ng driver",
      "Car_activity": "Aktibidad ng sasakyan",
      "Partner": "Kasosyo",
      "Prepaid_top_up": "Corporate prepaid",
      "Affiliation": "Pagkakaugnay",
      "Affiliation_penalty_compensation": "Parusa/Kabayaran",
      "Affiliation_payout_history": "Kasaysayan ng paninirahan",
      "Affiliation_bookings": "Mga booking",
      "Booking_summary": "Buod ng booking",
      "Cash_Wallet": "Cash wallet",
      "Credit_Wallet": "Credit wallet",
      "Driver_wallet": "wallet ng driver",
      "Driver_Deposit": "Deposito ng driver",
      "Driver_cash_wallet": "Cash Wallet",
      "Driver_Deposit_old": "Deposito ng driver",
      "Document_expiry": "Pag-expire ng dokumento",
      "Settlement": "Settlement",
      "Unapproved_Driver": "Hindi naaprubahang driver",
      "Approved_Driver": "Inaprubahang driver",
      "Pay_to_driver": "Magbayad sa driver",
      "Settlement_history": "Kasaysayan ng paninirahan",
      "Withdrawal_History": "Pag-alis ng driver",
      "creditWalletTooltip": "Nagdedeposito ang driver sa credit wallet",
      "Incomplete_payment": "Hindi kumpletong bayad",
      "Driver_withdrawal": "Pag-withdraw ng Driver",
      "Incident_&_Cancellation": "Insidente at Pagkansela",
      "Passenger_wallet": "Wallet ng pasahero",
      "Accept_Cancel_rate": "Pagganap ng driver",
      "Acceptance/Cancellation_rate": "Pagganap ng driver",
      "driverDepositTooltip": "Nagdedeposito ang driver sa credit wallet",
      "Customer_quest": "Paghanap",
      "First_will_win": "Unang mananalo",
      "Largest_will_win": "Pinakamalaki ang mananalo",
      "redeemed": "Code #",
      "Code_#": "Code #",
      "Quests": "Paghanap",
      "Merchant_Report": "Mangangalakal",
      "Merchant_Financial_Report": "Pananalapi",
      "Merchant_Cash_Report": "Cash wallet",
      "Merchant_Credit_Report": "Credit wallet",
      "Deletion_Request": "Kahilingan sa pagtanggal",
      "Driver_Rating": "Driver ng rate ng customer",
      "Company_settlement": "Settlement ng kumpanya",
      "Passenger_Rating": "Customer rate ng driver",
      "Export": "I-export ang Kasaysayan",
      "Third_Party_Booking": "Pag-book ng 3rd party",
      "BookingDotCom": "Booking.com",
      "Third_Party_Booking_Bookingcom": "Pag-book ng Third Party (Booking.com)"
    },
    "Settings": {
      "Fleet_info": "Impormasyon ng fleet",
      "Stripe_connect": "Stripe Connect",
      "stripe_title_connect": "I-activate ang iyong Stripe Connect account upang awtomatikong matanggap ang iyong payout.",
      "Payment_info": "Impormasyon sa pagsingil",
      "Permission": "Pahintulot",
      "Driver_refer_driver": "Sumangguni sa driver ang driver",
      "Show_referal_history": "Ipakita/itago ang history ng referral sa driver app",
      "companyTooltip": "Supplier ng kumpanya",
      "User": "Gumagamit",
      "General": "Heneral",
      "Regular": "Regular",
      "Sharing": "Pagbabahagi ng Kalye",
      "Flat": "patag",
      "Hourly": "Oras-oras - Araw-araw",
      "Company": "kumpanya",
      "Dispatch": "Pagpapadala",
      "Voice_SMS": "Boses at SMS",
      "Rate": "Rate",
      "Car": "kotse",
      "Car_type": "Uri ng kotse",
      "Car_mgmt": "Car mgmt",
      "Car_Make": "Gumawa at Modelo",
      "Stripe_Connect": "Stripe connect",
      "Voice_&_SMS": "Boses at SMS",
      "Street-sharing_rate": "Rate ng pagbabahagi ng kalye",
      "Partner_type": "Uri ng kasosyo",
      "Shift_template": "Shift template",
      "Shift_settings": "Mga setting ng shift",
      "Operation": "Operasyon",
      "Driver_Note": "Tala ng driver",
      "Promotion_code": "Code ng promosyon",
      "Point_config": "Point config",
      "Code_#": "Code #",
      "Voucher_code": "Code #",
      "Campaign": "Kampanya",
      "Queuing_area": "Lugar ng pila",
      "Referral_code": "Referral code",
      "City": "lungsod",
      "category": "Category",
      "3rd_party_location": "lokasyon ng 3rd party",
      "Zone": "Sona",
      "App_banner": "Banner ng app",
      "App_banner_pegasus": "Banner ng app (Pegasus)",
      "Flight_integration": "Pagsasama ng Flight",
      "SMS_integration": "Pagsasama ng SMS",
      "Driver_refers_customer": "Tinutukoy ng driver ang customer",
      "Pax_refers_Pax": "Tinutukoy ng customer ang customer",
      "Customer_refers_customer": "Tinutukoy ng customer ang customer",
      "Dynamic_Surcharge": "Dynamic na Surcharge",
      "Dynamic_Fare": "Dynamic na Pamasahe",
      "FlatZone": "Flat rate",
      "IntercityZone": "Intercity",
      "Intercity_Zone": "Intercity Zone",
      "Intercity_Rate": "Intercity Rate",
      "Car_Color": "Kulay ng kotse",
      "third_party_integration": "integrasyon ng 3rd party",
      "bookingCom": "Booking.com",
      "Third_Party_Integration_Booking": "Pagsasama ng 3rd party (Booking.com)",
      "Third_Party_Integration_Karhoo": "Pagsasama ng 3rd party (Karhoo.com)",
      "Third_Party_Integration_Mozio": "Pagsasama ng 3rd party (Mozio.com)",
      "invoiceBranding": "Pagba-brand ng Invoice",
      "titleInvoice": "I-customize ang impormasyon ng header at footer ng iyong kumpanya.",
      "titleheader": "Pamagat ng Header",
      "descriptionHeader": "Paglalarawan ng Header",
      "placehodelHeader": "Halimbawa, magdagdag ng mga detalye ng kumpanya tulad ng email, numero ng telepono, address, numero ng buwis, atbp.",
      "titleFooter": "Pamagat ng Footer",
      "placehodelFooter": "Halimbawa, magdagdag ng impormasyon sa bank wire, o anumang patakaran atbp",
      "descriptionFooter": "Paglalarawan ng Footer",
      "titleBookingCom": "Mga Kredensyal ng Booking.com API",
      "noteBookingCom": "Makipag-ugnayan sa technical team ng booking.com para bigyan ka ng kliyente, at sikretong key para kumonekta sa kanilang system.",
      "clientKey": "Susi ng Kliyente",
      "placeHolderClientKey": "Ipasok ang client key",
      "secretKey": "Lihim na Susi",
      "placeHolderSecretKey": "Ipasok ang client key",
      "titleBookingComWebhook": "Configuration ng Webhook",
      "noteBookingComWebhook": "Ibigay ang mga parameter sa ibaba para i-configure ng booking.com. Ang mga kredensyal na ito ay ginagamit upang makipag-usap sa pagitan ng parehong mga platform. Para sa anumang mga isyu mangyaring makipag-ugnayan sa iyong tech provider.",
      "webhook": "URL ng Fleet Webhook",
      "clientId": "Fleet Client Key",
      "clientSecret": "Fleet Secret Key",
      "Email_config": "Email config",
      "Driver_fields": "Mga field ng driver",
      "Driver_document": "Dokumento ng driver",
      "Driver_App": "Driver App",
      "Invoice_Setting": "Invoice",
      "driver_app": "Driver App",
      "Jobs_tab": "Tab ng Trabaho",
      "API_Management": "Pamamahala ng API"
    }
  },
  "APIKeySetting": {
    "APIKey": "Mga API Key",
    "APIKey_title": "Huwag ibahagi ang iyong API key sa iba, o ilantad ito sa browser o iba pang client-side code. Upang maprotektahan ang seguridad ng iyong account, maaari ding awtomatikong i-disable ng Good Journey ang anumang API key na nakita naming nag-leak sa publiko.",
    "newKeyBtn": "Gumawa ng bagong key",
    "updateApplication": "I-update ang Application",
    "RegisterApp": "Magrehistro ng bagong aplikasyon",
    "RegisterBtn": "Magrehistro ng Aplikasyon",
    "appDescription": "Paglalarawan ng App",
    "appName": "Pangalan ng App",
    "clientKey": "Client Key",
    "secretKey": "Lihim na Susi",
    "hostedEndPoint": "Mga naka-host na endpoint",
    "maxEndpoints": "Maaari kang magkaroon ng maximum na 5 endpoint",
    "addEndpoint": "Magdagdag ng Endpoint",
    "updateEndpoint": "I-update ang Endpoint",
    "ManageWebhooks": "Pamahalaan ang Webhooks",
    "CreateWebhook": "Lumikha ng mga webhook",
    "subscriptionID": "Subscription ID",
    "webhookURL": "Webhook URL",
    "signingSecret": "Lihim na pumipirma",
    "eventsToSend": "Mga kaganapang ipapadala",
    "listeningFor": "Nakikinig Para sa",
    "assignedDriver": "Nakatalagang Driver",
    "bookingStatus": "Katayuan ng Pag-book",
    "driverLocation": "Lokasyon ng Driver",
    "titleSigningSecret": "Ang sikretong ito ay ibinigay ng iyong kapareha. Kung sa anumang kadahilanan ay nakompromiso ang lihim, maaari mong i-update ang lihim, o tanggalin ang web hook.",
    "notUsingHTTPS": "Hindi gumagamit ng HTTPS ang URL ng webhook.",
    "invalidURL": "Di-wasto ang URL ng webhook."
  },
  "messages": {
    "credits": {
      "406": "Nabigo ang pagkilos",
      "407": "Nabigo ang pagkilos",
      "416": "Nagamit na ang kupon",
      "417": "Ang iyong account ay hindi aktibo. Mangyaring makipag-ugnayan sa aming administrator",
      "418": "Nagkaroon ng mga error sa pagbabayad",
      "419": "Di-wasto ang promo code!",
      "420": "Inilapat ang promo code na ito para sa isa pang biyahe. Mangyaring gumamit ng isa pa.",
      "421": "Hindi wasto ang numero ng card.",
      "422": "Ang petsa ng pag-expire ay hindi wasto.",
      "429": "Hindi wasto ang zip code.",
      "430": "Hindi tugma ang AVS Postal Code",
      "431": "Hindi na-verify ang AVS Postal Code",
      "432": "Hindi wasto ang CVV.",
      "433": "Hindi tugma ang CVV",
      "434": "Hindi na-verify ang CVV",
      "435": "Ang tagapagbigay ng CVV ay hindi nakikilahok",
      "436": "Ang inyong card ay tinanggihan",
      "437": "Nabigo ang pag-verify ng credit card. Pakisuri ang iyong impormasyon at subukang muli.",
      "439": "Hindi naproseso ang CVV",
      "441": "Walang data ng CVV mula sa nagbigay",
      "443": "Walang data mula sa issuer/banknet switch",
      "445": "Hindi maproseso ng AVS System",
      "446": "Ang nag-isyu na bangko ay hindi sumusuporta sa AVS",
      "447": "Hindi suportado ang AVS para sa iyong negosyo",
      "448": "Na-block ang card na ito dahil sa paulit-ulit na hindi matagumpay na mga pagtatangka sa pagbabayad. Mangyaring baguhin ang iyong paraan ng pagbabayad o subukang muli sa ibang pagkakataon",
      "452": "Tinanggihan ang iyong card. Mangyaring magpasok ng isa pang credit card na nakarehistro sa parehong lugar ng iyong numero ng telepono",
      "453": "Hindi sinusuportahan ang payment.gateway na ito",
      "454": "Hindi tugma ang AVS Address",
      "455": "AVS Parehong postal code at address ay hindi magkatugma",
      "458": "Authentication failed",
      "459": "Ang mga bank card ay hindi supprt",
      "461": "Ang card na ito ay hindi dapat",
      "462": "Hindi wasto ang pangalan ng may hawak ng card",
      "463": "Ang verification code ay hindi wasto",
      "464": "Ang Bangko ay hindi nagsusupprt sa pagbuo at pagpapadala ng verification code",
      "470": "Pakilagay ang email address ng manlalakbay bago gumawa ng booking gamit ang credit card",
      "474": "Pakilagay ang pangalan ng manlalakbay bago gumawa ng booking gamit ang credit card",
      "475": "Kinakailangan ang numero ng telepono",
      "911": "Hindi namin ma-authenticate ang iyong paraan ng pagbabayad. Mangyaring pumili ng ibang paraan ng pagbabayad at subukang muli.",
      "2000": "Huwag Igalang",
      "2001": "Hindi Sapat na Pondo. Walang sapat na pondo ang account para masakop ang halaga ng transaksyon.",
      "524": "Hindi Sapat na Pondo. Walang sapat na pondo ang account para masakop ang halaga ng transaksyon.",
      "2002": "Lumagpas na sa limitasyon",
      "2003": "Lumampas sa Limitasyon ng Aktibidad ng Cardholder",
      "2004": "Nag-expire na Card",
      "2005": "Di-wastong Numero ng Credit Card",
      "2006": "Di-wastong Petsa ng Pag-expire",
      "2007": "Walang account",
      "2008": "Error sa Haba ng Card Account",
      "2009": "Walang Ganitong Nag-isyu",
      "2010": "Tinanggihan ng Tagabigay ng Card ang CVV",
      "2011": "Kinakailangan ang Pahintulot sa Boses. Hinihiling ng bangko ng cardholder na tumawag ang merchant upang makakuha ng espesyal na authorization code upang makumpleto ang transaksyong ito",
      "2012": "Tinanggihan ang Processor - Posibleng Nawala ang Card",
      "2013": "Tinanggihan ang Processor - Posibleng Nanakaw na Card",
      "2014": "Tinanggihan ang Processor - Pinaghihinalaang Panloloko",
      "2015": "Hindi Pinapayagan ang Transaksyon",
      "2016": "Duplicate na Transaksyon",
      "2017": "Huminto sa Pagsingil ang Cardholder",
      "2018": "Inihinto ng May-ari ng Card ang Lahat ng Pagsingil",
      "2019": "Di-wastong Transaksyon",
      "2020": "Paglabag",
      "2021": "Paglabag sa Seguridad",
      "2022": "Tinanggihan - Available ang Updated Cardholder",
      "2023": "Hindi Sinusuportahan ng Processor ang Feature na Ito",
      "2024": "Hindi Pinagana ang Uri ng Card",
      "2025": "Error sa Pag-set up - Merchant",
      "2026": "Di-wastong Merchant ID",
      "2027": "Error sa Pag-set up - Halaga",
      "2028": "Error sa Pag-set up - Hierarchy",
      "2029": "Error sa Pag-set up - Card",
      "2030": "Error sa Pag-set Up - Terminal",
      "2031": "Error sa Pag-encrypt",
      "2032": "Hindi Pinahihintulutan ang Surcharge",
      "2033": "Hindi Pabagu-bagong Data",
      "2034": "Walang Nagawa",
      "2035": "Bahagyang Pag-apruba Para sa Halaga Sa Bersyon ng Pangkat III",
      "2036": "Hindi mahanap ang pahintulot upang baligtarin",
      "2037": "Nabaliktad na",
      "2038": "Tinanggihan ang Processor. Ang bangko ng customer ay ayaw tanggapin ang transaksyon, kailangang makipag-ugnayan sa kanilang bangko para sa higit pang mga detalye.",
      "2039": "Di-wastong Authorization Code",
      "2040": "Di-wastong Tindahan",
      "2041": "Tinanggihan - Tumawag Para sa Pag-apruba",
      "2042": "Di-wastong Client ID",
      "2043": "Error - Huwag Muling Subukan, Tawagan ang Tagabigay",
      "2044": "Tinanggihan - Tawagan ang Tagabigay",
      "2045": "Di-wastong Numero ng Merchant",
      "2046": "Tinanggihan. Ang bangko ng customer ay ayaw tanggapin ang transaksyon, kailangang makipag-ugnayan sa kanilang bangko para sa higit pang mga detalye.",
      "2047": "Tawagan ang Tagapagbigay. Pick Up Card. Ang error na ito ay nagpapahiwatig na ang card ay naiulat na nawala o ninakaw ng cardholder.",
      "2048": "Di-wastong Halaga",
      "2049": "Di-wastong SKU Number",
      "2050": "Di-wastong Credit Plan",
      "2051": "Hindi tumutugma ang Numero ng Credit Card sa paraan ng pagbabayad",
      "2052": "Di-wastong Level III na Pagbili",
      "2053": "Iniulat ang card bilang nawala o nanakaw",
      "2054": "Ang halaga ng pagbaligtad ay hindi tumutugma sa halaga ng pahintulot",
      "2055": "Di-wastong Numero ng Dibisyon ng Transaksyon",
      "2056": "Ang halaga ng transaksyon ay lumampas sa limitasyon ng dibisyon ng transaksyon",
      "2057": "Naglagay ng paghihigpit ang Issuer o Cardholder sa card",
      "2058": "Merchant hindi MasterCard SecureCode pinagana.",
      "2060": "Nabigo ang Pag-verify ng Address at Card Security Code",
      "2061": "Di-wastong Data ng Transaksyon",
      "2062": "Di-wastong Halaga ng Buwis",
      "2064": "Di-wastong Code ng Pera",
      "2078": "Di-wastong Data ng Secure na Pagbabayad",
      "2080": "Di-wastong mga kredensyal ng user",
      "3000": "Hindi Available ang Processor Network - Subukang Muli",
      "4001": "Tinanggihan ang Settlement",
      "4006": "Ang halaga ng pagkuha ay lumampas sa pinapayagang limitasyon",
      "81703": "Ang uri ng credit card ay hindi tinatanggap ng merchant account na ito.",
      "81706": "Kinakailangan ang CVV",
      "81707": "Ang CVV ay dapat na 3 o 4 na digit.",
      "81709": "Kinakailangan ang petsa ng pag-expire.",
      "81710": "Ang petsa ng pag-expire ay hindi wasto.",
      "81714": "Kinakailangan ang numero ng credit card.",
      "81715": "Di-wasto ang numero ng credit card.",
      "81716": "Ang numero ng credit card ay dapat na 12-19 digit.",
      "81717": "Ang numero ng credit card ay hindi tinatanggap na numero ng pagsubok.",
      "81718": "Hindi maa-update ang numero ng credit card sa isang hindi sinusuportahang uri ng card kapag nauugnay ito sa mga subscription.",
      "81723": "Masyadong mahaba ang pangalan ng cardholder. Maximum na 175 character.",
      "81809": "Ang Postal code ay maaaring maglaman ng hindi hihigit sa 9 na letra o numero na mga character",
      "81813": "Ang Postal code ay maaari lamang maglaman ng mga titik, numero, puwang, at gitling",
      "91722": "Kinakailangan ang token ng paraan ng pagbabayad.",
      "91723": "Ang pag-update ng Umiiral na Token ay hindi wasto",
      "91730": "Hindi sinusuportahan ang pag-verify sa merchant account na ito.",
      "91734": "Ang uri ng credit card ay hindi tinatanggap ng merchant account na ito.",
      "91738": "Ang tinukoy na paraan ng pagbabayad ay hindi isang credit card.",
      "91745": "Mga di-wastong param para sa pag-update ng credit card.",
      "91826": "Hindi wasto ang postal code. Ang Postal code ay dapat na isang string ng 5 o 9 na digit, opsyonal na pinaghihiwalay ng isang gitling o isang puwang.",
      "-1": "Na-deactivate ang customer account na ito. Mangyaring makipag-ugnayan sa aming administrator."
    },
    "reBookingMsg": {
      "303": "Ang oras na iyong pinili ay dapat na mas huli kaysa sa kasalukuyang oras!",
      "304": "Hindi nakita ang booking na ito",
      "500": "Ang ilang impormasyon sa pag-book ay hindi wasto o nawawala. Pakisuri at subukang muli!"
    },
    "promoMsg": {
      "304": "Ang promo code ay hindi wasto",
      "306": "Di-wastong code ng promosyon. Sa ibang zone daw ang promo.",
      "413": "Paumanhin, hindi available ang promo code na ito sa ngayon",
      "414": "Ang promo code ay hindi wasto",
      "415": "The promo code is not valid",
      "416": "Nagamit na ang promo code na ito.",
      "419": "Paumanhin, hindi available ang promo code na ito sa ngayon",
      "420": "Paumanhin, naabot mo na ang iyong limitadong paggamit ng promo",
      "421": "Paumanhin, naabot na ng promo code na ito ang inilabas nitong limitasyon sa paggamit",
      "422": "Ang promo code na ito ay hindi magagamit sa ilalim ng iyong account",
      "423": "Paumanhin, naabot mo na ang iyong limitadong badyet sa promo",
      "424": "Di-wastong code ng promosyon. Sa mga nirefer na customer lang daw ang promo",
      "425": "Naabot na ng promo code na ito ang limitasyon sa paggamit nito bawat araw.",
      "426": "Naabot mo na ang iyong limitadong paggamit ng promo bawat araw.",
      "427": "Hindi mailapat ang code ng promosyon. Hindi kumpleto ang profile ng customer na ito.",
      "428": "Naubos na ng customer na ito ang buwanang code ng promosyon.",
      "430": "Naubos mo na ang iyong taunang mga code ng promosyon.",
      "431": "Hindi magagamit ang code ng promosyon para sa paraan ng pagbabayad na ito.",
      "432": "Ang halaga ng pamasahe na ito ay hindi karapat-dapat para sa code na pang-promosyon.",
      "433": "Ang code na pang-promosyon ay hindi karapat-dapat na gamitin sa napiling oras.",
      "434": "Paumanhin, naabot mo na ang iyong limitadong badyet sa promo.",
      "phoneInvalid": "Mangyaring ipasok ang numero ng telepono ng pasahero bago ilapat ang promo code"
    },
    "updateBookingMsg": {
      "303": "Nabigong i-update ang booking. Pakisubukang muli!",
      "304": "Hindi nakita ang booking na ito",
      "pickUpTime": "Ang oras na iyong pinili ay dapat na mas huli kaysa sa kasalukuyang oras!",
      "pickup": "Walang available na serbisyo sa pag-book sa lugar na ito",
      "destination": "Ang ilang impormasyon sa pag-book ay hindi wasto o nawawala. Pakisuri at subukang muli!",
      "dispatchType": "Ang ilang impormasyon sa pag-book ay hindi wasto o nawawala. Pakisuri at subukang muli!"
    },
    "sessionExpired": {
      "403": "Na-log out ka dahil naka-log in ang iyong account mula sa ibang device o nag-expire na ang session mo"
    },
    "not_found": {
      "404": "The page you requested could not be found"
    },
    "booking": {
      "303": "Ang oras na iyong pinili ay dapat na mas huli kaysa sa kasalukuyang oras!",
      "304": "Hindi nakita ang booking na ito",
      "305": "Kinansela o nakumpleto ng Booking.com ang booking; mangyaring tanggihan ang trabaho upang alisin ito sa pila.",
      "306": "Walang itinalagang rate sa napiling uri ng kotse. Upang maglapat ng rate para sa anumang uri ng kotse, mangyaring pumunta sa Mga Setting > Kotse > Uri ng Kotse.",
      "407": "I-type ang rate na sinusuportahan ko ang dagdag na lokasyon",
      "500": "Ang ilang impormasyon sa pag-book ay hindi wasto o nawawala. Pakisuri at subukang muli!",
      "upgroup_success": "Matagumpay na naalis sa pangkat ang booking",
      "Please_Select_driver": "Mangyaring pumili ng driver",
      "assign_driver_success": "Matagumpay na naitalaga ang driver",
      assign_driver_vehicle_success: 'Driver/Vehicle has been assigned successfully',
      "Group_success": "Matagumpay na na-grupo ang booking",
      "remove_booking_false": "Nabigong alisin ang booking",
      "remove_booking_success": "Matagumpay na naalis ang booking",
      "GROUP_NOT_FOUND": "Hindi nakita ang Group Id",
      "NO_SHARING_BOOKINGS": "Maaari ka lang magpangkat ng mga booking kung pinapayagan ng booking ng customer ang ride-sharing.",
      "DIFFERENT_CAR_TYPES": "Ang booking na sinusubukan mong pangkat ay may ibang uri ng kotse",
      "LIMIT_EXCEEDED_ON_SEATS_OR_LUGGAGE": "Nalampasan ang mga upuan o bagahe. Paki-double check ang kapasidad ng sasakyan.",
      "INVALID_CAR_TYPE": "Hindi maitalaga ang driver, tingnan ang upuan ng sasakyan ng driver at kapasidad ng bagahe.",
      "VALIDATION_ERROR": "Error sa pagpapatunay",
      "INTERNAL": "Internal server error",
      "fare_gt_zero": "Ang pamasahe ay dapat na higit sa 0!",
      "edit_total_fail": "Nabigo ang pag-edit ng kabuuang halaga!",
      "complete_fail": "Hindi makukumpleto ang booking!",
      "cancle_fail": "Nagkaproblema noong kinansela ang booking na ito. Pakisubukang muli!",
      "incident_fail": "Nabigo ang insidente",
      "phone_invalid": "Mangyaring magpasok ng wastong numero ng telepono!",
      "add_psg_fail": "Hindi makapagdagdag ng bagong pasahero",
      "REMOVE_RECIPIENT_DELIVERED": "Hindi maalis ang tatanggap ay naihatid na",
      "REMOVE_LAST_ACTIVE_RECIPIENT": "Hindi maalis ang huling aktibong tatanggap",
      "ORDER_HAS_BEEN_FINISHED": "Ang order ay tapos na",
      "end_booking_leave": "Ang pag-alis sa page na ito ay magtatapos sa iyong booking.",
      "update_booking_fail": "Nabigong i-update ang booking",
      "area_service_unavailable": "Walang available na serbisyo sa pag-book sa lugar na ito",
      "pickupSameDestination": "Ang pick-up address ay dapat na iba sa destinasyon.",
      "time_invalid": "Ang oras na iyong pinili ay dapat na mas huli kaysa sa kasalukuyang oras!",
      "psg_inactivate": "Na-deactivate ang customer account na ito. Mangyaring makipag-ugnayan sa aming administrator",
      "data_incorect": "Nagkaproblema. Subukang muli mamaya.",
      "preAuthorize": "Pakisuri ang iyong account dahil ang halagang {0} ay kinakailangan upang magarantiya ang iyong booking",
      "booking_limited": "Paumanhin, naabot mo na ang maximum na bilang ng mga booking kada minuto/araw. Subukang muli mamaya.",
      "minimumBookAhead": "Ang oras ng pagkuha ng reserbasyon ay dapat na hindi bababa sa {0} (mga) oras {1} (na) minuto mula sa kasalukuyang oras",
      "exDoDiffDo": "Ang karagdagang destinasyon ay dapat na iba sa destinasyon.",
      "recurringTimeRange": "Petsa na wala sa saklaw. Makakagawa ka lang ng mga booking {0} (na) buwan nang maaga. Mangyaring makipag-ugnayan sa admin para sa anumang mga isyu.",
      "recipientsLimit": "Ang maximum na bilang ng mga tatanggap ay {0}.",
      "maximumBookAhead": "Ang oras ng pagkuha ng reserbasyon ay hindi maaaring lumampas sa {0} (na) araw mula sa kasalukuyang oras",
      "Please_select_or_add_a_card": "Mangyaring pumili o magdagdag ng card!",
      "Unable_to_process_by_fare_zero": "Hindi namin maproseso ang iyong kahilingan, mangyaring baguhin ang uri ng kotse o uri ng booking at subukang muli. Kung kailangan mo ng anumang tulong, mangyaring makipag-ugnayan sa aming team ng suporta",
      "Can_not_connect_to_server": "Hindi makakonekta sa server!",
      "Check_connection_or_contact_system_support": "Pakisuri ang iyong koneksyon o makipag-ugnayan sa suporta ng system para sa tulong!",
      "Create_new_booking_success": "Matagumpay na nalikha ang isang bagong booking!",
      "Create_new_recurring_booking_success": "Matagumpay na nalikha ang bagong (mga) booking",
      "Update_booking_success": "Matagumpay na na-update ang booking na ito!",
      "Booking_drop_of_success": "Tagumpay ang pag-drop off ng booking",
      "Cancel_booking_success": "Matagumpay na nakansela ang booking na ito!",
      "Booking_detail_not_found": "Hindi mahanap ang detalye ng booking!",
      "Check_promo_error": "Suriin ang error sa promoCode!",
      "Invalid_format": "Di-wastong format",
      "Flight_not_found": "Hindi nahanap ang flight!",
      "Add_new_credit_error": "Magdagdag ng bagong credit error!",
      "Socket_server_disconnected": "Nadiskonekta ang socket server!",
      "Please_reload_page_or_check_your_connection": "Paumanhin. Hindi maproseso ang iyong kahilingan. Pakisubukang muli.",
      "request_timeout": "Paumanhin. Timeout ng iyong kahilingan. Pakisubukang muli.",
      "Check_customer_error": "Suriin ang error ng customer!",
      "Please_select_car_type": "Mangyaring pumili ng uri ng kotse",
      "DropOff_country": "Paumanhin, ang iyong hiniling na destinasyon ay wala sa aming lugar ng serbisyo!",
      "Different_Country": "Ang mga lokasyon ng pickup at drop-off ay dapat nasa 2 magkaibang bansa.",
      "Please_enter_destination_for_ridesharing_booking": "Mangyaring ilagay ang destinasyon para sa ride-sharing booking",
      "Extra_detination_must_be_difference_from_destination": "Ang karagdagang destinasyon ay dapat na naiiba sa kasalukuyang destinasyon",
      "Pickup_address_must_be_different_from_destination": "Dapat na iba ang pick-up address sa destinasyon",
      "Best_price": "Pinakamahusay na presyo",
      "You_may_be_charged_extra_fee_if_your_trip_excceds": "Maaari kang singilin ng dagdag na bayad kung lumampas ang iyong biyahe",
      "You_may_be_charged_extra_fee_if_your_trip_excceds_with_params": "Maaari kang singilin ng karagdagang bayad kung lumampas ang iyong biyahe sa {0} {1}, {2} {3}",
      "Reject_booking_success": "Matagumpay na tinanggihan ang booking na ito!",
      "Reject_fail": "Nagkaproblema noong tinanggihan ang booking na ito. Pakisubukang muli!",
      "Please_select_payment_method": "Mangyaring pumili ng paraan ng pagbabayad",
      "Driver_signed_out_or_deactivated": "Kaka-sign out o na-deactivate ang driver na ito",
      "reservation_booking_overlaps": "Paumanhin, nag-o-overlap ang reservation na ito sa isa pang tinanggap ng Driver.",
      "driver_is_not_available": "Paumanhin, hindi available ang driver na ito sa ngayon.",
      "seats_luggage_limit": "Hindi maitalaga ang booking sa driver. Lampas sa limitasyon ang upuan o bagahe.",
      "full_capacity": "Ang booking na ito ay hindi maaaring italaga sa driver dahil naabot na ng biyahe ang maximum capacity nito.",
      "marked_failed_ok": "Ang order ay minarkahan bilang nabigo.",
      "warning_markFail_lastRecipient": "Ito ang huling tatanggap ng booking na ito. Kung magpapatuloy ka sa pagmamarka sa order na ito bilang nabigo, kakailanganin mong kumpletuhin ang booking na ito.",
      "BOOKINGS_LIMIT_EXCEEDED": "Maaari ka lamang magtalaga ng maximum na 5 mga order sa bawat pangkat",
      "bookingCanceled": "Kinansela o nakumpleto ng Booking.com ang booking; mangyaring tanggihan ang trabaho upang alisin ito sa pila."
    },
    "item_no_found": "Walang nakitang item",
    "commonMessages": {
      "greater_than_0": "Mangyaring magpasok ng halagang mas mataas sa 0.",
      "Required_field": "Kinakailangan ang field na ito",
      "bankPhone_is_required": "Di-wasto ang numero ng telepono",
      "bankPhone": "Numero ng telepono",
      "distance_from_equa_or_smaller": "Ang distansya na 'mula' ay dapat na mas maliit kaysa o katumbas ng distansya 'sa'",
      "must_be_number": "Mangyaring magpasok ng wastong numero",
      "equal_or_greater_than_0": "Mangyaring magpasok ng halagang katumbas o higit sa 0.",
      "must_be_integer": "Pakilagay ang integer number",
      "invalid_email_format": "Please enter valid email.",
      "greater_or_equa": "Mangyaring magpasok ng halagang mas malaki sa o katumbas ng {0}.",
      "value_must_between": "Mangyaring magpasok ng halaga sa pagitan ng {0} at {1}.",
      "large_10x_markup_price": "Ang na-edit na pamasahe ay hindi dapat lumampas sa 10x na tinantyang pamasahe.",
      "greater_than": "Mangyaring magpasok ng halagang higit sa {0}.",
      "less_than": "Mangyaring magpasok ng halagang mas mababa sa {0}.",
      "less_or_equal": "Mangyaring magpasok ng halagang mas mababa sa o katumbas ng {0}.",
      "limit_amount": "Mangyaring magpasok ng isang halaga na mas mababa sa o katumbas ng halaga ng limitasyon",
      "driver_earnings_warning": "Mga offline na pagbabayad: Ang kita ng driver ay hindi dapat lumampas sa nakolektang pamasahe.",
      "Integer_from_range": "Pakipasok ang numero ng integer mula sa {0} - {1}",
      "integer_greater_than_0": "Mangyaring maglagay ng integer na numero na mas malaki sa 0. (Mas mababa sa 100 na may porsyento at ang decimal separator ay 1 character)",
      "Error_message": "Error!",
      "callcenter_newbooking_message": "Bagong kahilingan sa pag-book gamit ang telepono <span class='bold'>{0}</span>",
      "callcenter_existed_booking_message": "Isang aktibong booking <span class='bold'>#{0}</span> ang umiiral sa telepono <span class='bold'>{1}</span>",
      "callcenter_close_newbooking_form_warning": "Mangyaring isara/kumpletuhin ang iyong kasalukuyang aklat bago makatanggap ng bagong kahilingan ng customer",
      "Emergency_SOS_Title": "Emergency SOS",
      "Please_enter_a_future_date": "Mangyaring maglagay ng petsa sa hinaharap",
      "Card_holder_is_required": "Kinakailangan ang card holder",
      "Card_number_is_required": "Kinakailangan ang numero ng card",
      "Expired_date_is_required": "Kinakailangan ang petsa ng pag-expire",
      "Credit_code_is_required": "Pakipasok ang credit code (huling pagpapatunay)",
      "Expired_date_is_invalid": "Ang petsa ng pag-expire ay hindi wasto. Pakilagay ang expiration date sa format na mm/yy",
      "cvv_is_required": "CVV is required",
      "City_is_required": "Kinakailangan ang lungsod",
      "State_is_required": "Kinakailangan ang estado",
      "Address_is_required": "Kinakailangan ang address",
      "Zipcode_is_required": "Kinakailangan ang zip code",
      "Country_is_required": "Kinakailangan ang bansa",
      "Over_query_limit": "Hindi makapag-load ang Google API dahil sa pansamantalang error. Subukang muli mamaya.",
      "outOfInputQueueOrder": "Ang order # ay hindi maaaring mas malaki kaysa sa kabuuang bilang ng mga driver sa pila.",
      "Same_pickup_destination": "Ang pick-up address ay dapat na iba sa destination address.",
      "Max_length": "Ang maximum na haba ay {0} (na) character.",
      "choose_at_least_one": "Mangyaring pumili ng kahit isang opsyon.",
      "choose_at_least_one_intercity_schedule": "Mangyaring magdagdag ng iskedyul o payagan ang customer na humiling ng kanilang gustong oras.",
      "startDate_greater_than_endDate": "Ang petsa ng pagsisimula ay mas malaki kaysa sa petsa ng pagtatapos"
    },
    "trip": {
      "cancel_trip_success": "Matagumpay na nakansela ang biyaheng ito!",
      "data_incorect": "Walang driver na nakatalaga. Pumili ng driver mula sa dropdown list. <br/> Kung walang available na mga driver, piliin ang Auto Dispatch upang italaga sa ibang pagkakataon.",
      "INTERNAL": "Error sa panloob na server.",
      "NOT_FOUND": "Hindi nakita ang biyahe o di-wastong katayuan.",
      "SAME_DRIVER": "Ang assignee ay dapat na iba sa kasalukuyang driver.",
      "OVERLAP": "Paumanhin, ang biyaheng ito ay nag-o-overlap sa isa pa na tinatanggap ng driver na ito.",
      "CANNOT_ASSIGN": "Ang driver na ito ay hindi wasto (ibig sabihin, ang driver ay hindi natagpuan, hindi online, o hindi naka-log in)."
    },
    "car_mgmt": {
      "Search_car_color": "Maghanap ng kulay ng kotse",
      "Searching": "Naghahanap"
    },
    "driver_note": {
      "Search_driver_note": "Maghanap ng tala ng driver",
      "Searching": "Naghahanap"
    }
  },
  "errors": {
    "303": "hindi nahanap ang user",
    "453": "Hindi suportado ang gateway",
    "553": "Paumanhin! Ang customer ay may kasalukuyang nagbu-book, pakisubukang muli sa ibang pagkakataon.",
    "3001": "Umiiral ang numero ng telepono",
    "3002": "Di-wastong petsa ng kapanganakan",
    "3003": "Ang petsa ng pag-expire ay hindi wasto",
    "3004": "Hindi nahanap ang driver",
    "3005": "Nawawalang impormasyon sa settlement ng driver (address ng bangko, lungsod, estado, zipcode)",
    "3006": "Nawawalang impormasyon ng bank account (nameOfAccount, typeOfAccount, routingNumber, accountNumber, ssn)",
    "3007": "Hindi mo maaaring i-edit ang driver na ito, na may trabaho.",
    "3008": "Ang driver na ito ay kasalukuyang nagla-log in. Mangyaring hilingin sa kanya na mag-log out upang i-edit ang kanyang impormasyon.",
    "3009": "Ang driver na ito ay tumanggap ng isa o higit pang reserbasyon. Mangyaring italaga ang (mga) booking sa ibang mga driver bago i-update/palitan ang impormasyon ng driver.",
    "3010": "Nabigo ang pagpapadala ng koneksyon.",
    "3011": "Ang driver na ito ay dapat na naka-setup para sa operasyon bago ma-activate.",
    "3012": "Ang driver na ito ay dapat na naka-setup para sa operasyon bago ma-activate.",
    "3013": "Ang item lamang sa hindi aktibong katayuan ang maaaring tanggalin.",
    "3014": "Nawawala ang mga kinakailangang field. Mangyaring suriin.",
    "3015": "Di-wastong taon ng kapanganakan. Mangyaring suriin.",
    "3016": "Di-wastong zip code. Mangyaring suriin.",
    "3017": "Di-wastong estado/lalawigan. Mangyaring suriin.",
    "3018": "Di-wastong pangalan ng account. Mangyaring suriin.",
    "3019": "Di-wastong numero ng pagruruta. Mangyaring suriin.",
    "3020": "Kinakailangan ang kaarawan. Mangyaring suriin.",
    "3021": "Di-wastong ssn number. Mangyaring suriin.",
    "3022": "Ang bank account ng driver ay hindi makakatanggap ng mga pondo.",
    "3023": "Hindi mapapatakbo ang mga naka-log in na driver. Subukang muli mamaya.",
    "3026": "Wala na ang driver.",
    "3027": "Hindi naka-set up ang bank account ng driver. Mangyaring pumunta sa Drivers module upang i-set up ang kanyang bank account.",
    "3028": "Nabigong bayaran ang (mga) napiling driver. Pakisubukang muli.",
    "3029": "Nabigong i-clear ang data ng kita ng driver. Pakisubukang muli.",
    "3032": "Umiiral na ang username na ito sa aming system. Mangyaring magpasok ng isa pa.",
    "3033": "Di-wastong BSB. Ang numero ay dapat na 6 na digit sa format na xxxxxx.",
    "3039": "Kinakailangan ang Group ID.",
    "3040": "Ang numero ng teleponong ito ay ginagamit na ng isang driver ng %{brandName} (ibig sabihin, hindi maaaring ma-duplicate ang numero ng telepono). Mangyaring magpasok ng isa pa",
    "3041": "Umiiral ang username.",
    "3044": "Nadoble ang National ID.",
    "4001": "Ang iyong plate number ay umiral na sa system. Mangyaring maglagay ng isa pa.",
    "4002": "Di-wastong petsa ng pag-expire",
    "4003": "Dapat i-deactivate ang sasakyan bago tanggalin",
    "4004": "Hindi nahanap ang sasakyan",
    "4005": "Hindi na aktibo ang sasakyang ito",
    "4006": "Ang sasakyang ito ay ginagamit at hindi maaaring tanggalin o i-deactivate",
    "4007": "Kinakailangan ang plate number",
    "5001": "Umiiral ang kumpanya",
    "5004": "Hindi natagpuan ang kumpanya",
    "6001": "Umiral ang modelo ng sasakyan",
    "6004": "Hindi nakita ang modelo ng sasakyan",
    "6005": "Ginagamit ang modelo ng sasakyan",
    "7001": "Ang pangalan ng paggawa ng iyong sasakyan ay umiral na sa system. Mangyaring maglagay ng isa pa.",
    "7004": "Hindi nahanap ang paggawa ng sasakyan",
    "8001": "Umiral ang uri ng sasakyan",
    "8004": "Hindi nakita ang uri ng sasakyan",
    "9001": "Nagkaroon ng patag na ruta",
    "9004": "Hindi nahanap ang patag na ruta",
    "10001": "Umiral ang zone",
    "10004": "Hindi nahanap ang zone",
    "11001": "Umiral ang zone",
    "11002": "Ang lugar na ito ay magkakapatong sa isa pa. Mangyaring iguhit itong muli.",
    "11003": "Ang zone ay dapat na isang polygon object",
    "11004": "Hindi nahanap ang zone",
    "11005": "Ginagamit ang sona",
    "12001": "Umiral ang inbox",
    "12002": "Mangyaring i-configure ang SMS Twilio",
    "12004": "Hindi nakita ang inbox",
    "12005": "Walang tatanggap na tumutugma sa iyong mga filter. Pakisuri ang listahan at subukang muli.",
    "12006": "Walang customer sa file.",
    "13001": "Ang promotion code na ito ay umiiral na sa system. Mangyaring maglagay ng isa pa",
    "13002": "Maling petsa",
    "13003": "Pribado",
    "13004": "Hindi nakita ang code ng promosyon",
    "13005": "Bagong Customer lang",
    "13006": "Lampas sa Limitasyon ng Dami",
    "13007": "Lampas sa Limitasyon ng Badyet",
    "13008": "Lampas sa Kabuuang Limitasyon sa Paggamit",
    "13011": "Na-deactivate o tinanggal ang kampanyang ito sa promosyon",
    "13012": "Di-wasto ang hanay ng petsa",
    "13013": "Di-wastong code ng promosyon. Sa ibang zone daw ang promo",
    "13014": "Na-deactivate ang zone na iyong pinili.",
    "14004": "Hindi nakita ang booking",
    "15002": "Na-deactivate ang iyong fleet. Mangyaring makipag-ugnayan sa aming administrator",
    "15004": "Hindi nahanap ang fleet",
    "16001": "Ang iyong pangalan ng flat rate ay umiral na sa system. Mangyaring maglagay ng isa pa",
    "16004": "Hindi nakita ang pamasahe",
    "17001": "Ang pangalan ay umiral",
    "17004": "Hindi nakita ang rate ng package",
    "18001": "Ang pangalan ay umiral",
    "18002": "Ginagamit ang pamasahe",
    "18004": "Hindi nakita ang pamasahe",
    "19001": "Ang iyong pangalan ng package ay umiral na sa system. Mangyaring maglagay ng isa pa.",
    "19002": "Ginagamit ang pamasahe",
    "19004": "Hindi nakita ang pamasahe",
    "20001": "Umiiral na ang username na ito sa aming system. Mangyaring magpasok ng isa pa.",
    "20002": "Ang iyong account ay hindi aktibo. Mangyaring makipag-ugnayan sa aming administrator",
    "20003": "Ang iyong inilagay na username o password ay mali. Pakisubukang muli",
    "20004": "Ang username ay hindi nakarehistro sa system.",
    "20005": "Ang link na ito ay nag-expire",
    "20006": "Hindi tugma ang kasalukuyang password. Pakisubukang muli.",
    "20007": "Umiiral na ang user #",
    "21001": "Ang iyong pangalan ng pahintulot ay umiral na sa system. Mangyaring maglagay ng isa pa.",
    "21002": "Hindi ma-deactivate ang pahintulot na ito dahil kasalukuyang nakatalaga ito sa (mga) user.",
    "21003": "Aktibo ang tungkulin",
    "21004": "Hindi nakita ang tungkulin",
    "22001": "Umiiral ang customer",
    "22004": "Hindi nahanap ang customer",
    "22005": "Di-wastong Format ng File.",
    "24001": "Di-wasto ang numero ng telepono",
    "25001": "Ang pangalan na ito ay umiiral na sa system. Mangyaring maglagay ng isa pa.",
    "25002": "Di-wastong hanay ng oras",
    "25004": "Hindi nahanap ang shift",
    "26001": "Ang pangalan na ito ay umiiral na sa system. Mangyaring maglagay ng isa pa.",
    "26002": "Ang shift na ito ay kasalukuyang ginagamit sa pagpapatakbo, mangyaring alisin ito sa pagkakatalaga bago tanggalin",
    "26004": "Hindi nahanap ang template ng shift",
    "27001": "Ang kampanya ay umiiral",
    "27002": "Ang kampanya ay isinaaktibo",
    "27004": "Hindi natagpuan ang kampanya",
    "28001": "Umiral ang lungsod",
    "28002": "Ang lungsod ay isinaaktibo",
    "28003": "Ginagamit ang lungsod",
    "28004": "Hindi natagpuan ang lungsod",
    "29002": "Ang numero ng telepono ay umiiral",
    "30001": "Hindi nakita ang lugar ng pila",
    "30002": "Lugar ng pila sa paggamit",
    "30003": "Umiiral na sa system ang pangalan ng iyong queuing area. Mangyaring maglagay ng isa pa",
    "30004": "Ang lugar ng pila ay hindi dapat mag-overlap sa isa't isa",
    "30005": "Di-wasto ang mga coordinate ng lugar ng pila",
    "30006": "Di-wasto ang mga pick-up area",
    "31001": "Ang iyong company Id ay umiral na sa system. Mangyaring maglagay ng isa pa.",
    "31002": "Umiiral na ang username na ito sa aming system. Mangyaring magpasok ng isa pa.",
    "31003": "Hindi natagpuan ang kumpanya.",
    "31004": "Hindi nahanap ang Corporate User.",
    "31005": "Tanging mga corporate na hindi aktibo ang maaaring tanggalin.",
    "31006": "Ang teleponong ito ay umiiral na sa Mga Gumagamit ng Corp. Mangyaring maglagay ng isa pa.",
    "31007": "Hindi nahanap ang corporate traveler.",
    "31008": "Hindi nahanap ang card.",
    "31009": "This phone has been existing on the system. Please input another one.",
    "31010": "Na-deactivate ang kumpanyang ito. Mangyaring makipag-ugnayan sa administrator para sa higit pang impormasyon",
    "31011": "Hindi maaaring i-activate ang manlalakbay na ito dahil kabilang ito sa isang hindi aktibong corporate account",
    "31013": "Mayroon nang negosyong may ganitong pangalan",
    "32001": "Ang iyong pangalan ng serbisyo ay umiral na sa system. Mangyaring maglagay ng isa pa.",
    "32004": "Ang serbisyo ay hindi mahanap",
    "33002": "Ang mga hanay ng oras ay hindi dapat mag-overlap sa bawat isa",
    "34001": "Ang code ay umiiral",
    "34013": "Di-wasto ang hanay ng petsa",
    "36001": "Ang pangalan ay umiral",
    "71001": "Umiral ang dynamic na surcharge",
    "71002": "Ang lugar na ito ay magkakapatong sa isa pa. Mangyaring iguhit itong muli.",
    "71003": "Ang dynamic na surcharge ay dapat na isang polygon object",
    "71004": "Hindi nakita ang dynamic na surcharge",
    "72001": "Umiral ang dynamic na pamasahe",
    "72002": "Ang lugar na ito ay magkakapatong sa isa pa. Mangyaring iguhit itong muli.",
    "72003": "Ang dynamic na pamasahe ay dapat na isang polygon object",
    "72004": "Hindi nakita ang dynamic na pamasahe",
    "74001": "Umiral ang zone",
    "74002": "Ang lugar na ito ay magkakapatong sa isa pa. Mangyaring iguhit itong muli.",
    "74003": "Zone must be a polygon object",
    "74004": "Zone fare not found",
    "74005": "Hindi nakita ang pamasahe sa zone",
    "80001": "Umiral ang banner ng app.",
    "80002": "Hindi nahanap ang banner ng app.",
    "80003": "Ang item lamang sa hindi aktibong katayuan ang maaaring tanggalin.",
    "81001": "Walang laman ang pag-upload ng file.",
    "81002": "Limitado ang laki ng pag-upload ng file.",
    "82002": "Nagkaproblema habang nagse-set up ng flightaware. Subukang muli mamaya.",
    "90001": "Umiral ang intercity zone",
    "90002": "Ang lugar na ito ay magkakapatong sa isa pa. Mangyaring iguhit itong muli.",
    "90003": "Ang intercity zone ay dapat na isang polygon object",
    "90004": "Hindi nahanap ang intercity zone",
    "90005": "Ginagamit ang intercity zone",
    "91001": "Ang intercity rate ay umiiral",
    "91002": "Ginagamit ang intercity rate",
    "91003": "Hindi nahanap ang intercity rate",
    "92001": "Ang pangalan ng ruta ay umiiral",
    "92002": "Hindi nahanap ang ruta",
    "92003": "Umiiral na ang rutang ito sa ibang ruta, pakisuri at subukang muli",
    "92004": "May aktibong biyahe sa rutang ito.",
    "93001": "Ang driver note ay umiral na sa system. Mangyaring magpasok ng isa pa.",
    "93002": "Maaaring tanggalin ang tala ng driver na hindi aktibo.",
    "93003": "Ginagamit ang tala ng driver.",
    "93004": "Hindi nakita ang tala ng driver.",
    "94001": "Ang pangalan ng kulay ng iyong sasakyan ay umiral na sa system. Mangyaring maglagay ng isa pa.",
    "94002": "Hindi nakita ang kulay ng kotse",
    "400000": "Hindi wasto ang numero ng telepono!",
    400002: 'A validation error has occurred.',
    "400003": "Tinanggihan ang pahintulot",
    "400005": "Ang nilalaman ng file ay hindi wasto",
    "500000": "Error sa panloob na sistema.",
    "500001": "Mangyaring i-configure ang SMS Twilio",
    "500002": "Humiling ng timeout. Subukang muli mamaya",
    "600001": "Hindi nahanap ang lokasyon",
    "600002": "Ang mga coordinate ng Longitude at Latitude na ito ay umiral sa system. Mangyaring magdagdag ng isa pa",
    "610001": "Nagkaroon ng kategorya",
    "610002": "Hindi nahanap ang kategorya",
    "610003": "Umiiral ang kategorya sa lokasyon ng party",
    "700001": "Ginagamit ang uri",
    "700002": "Hindi nahanap ang partner",
    "700003": "Ang pangalan ng uri ng iyong kasosyo ay umiral na sa system. Mangyaring maglagay ng isa pa.",
    "800000": "Ang tindahang ito ay itinalaga sa isa pang merchant account. Mangyaring pumili ng isa pa.",
    "800001": "Ang bank account ng merchant ay hindi makakatanggap ng mga pondo.",
    "800002": "Ang bank account ng merchant ay hindi makakatanggap ng mga pondo.",
    "undefined": "Nagkaproblema sa aming server, pakisubukang muli.",
    "completeBooking": {
      "113": "Hindi namin ma-authenticate ang iyong paraan ng pagbabayad. Mangyaring pumili ng ibang paraan ng pagbabayad at subukang muli.",
      "521": "Walang sapat na pondo ang wallet.",
      "553": "Sorry! Customer has a booking in-progress, please try again later.",
      450: 'The balance is insufficient to pay. Please select another method',
      451: 'The balance is insufficient to pay. Please select another method',
    },
    "upload_file_fail": "Di-wastong file, pakisuri at subukang muli.",
    "upload_photo_fail": "Di-wastong file, pakisuri at subukang muli.",
    "missing_some_field": "Di-wastong field, pakisuri at subukang muli."
  },
  "statusDisplay": {
    "completedWithoutService": "Nakumpleto nang walang driver",
    "confirmed": "Kumpirmadong reserbasyon",
    "pending": "Nakabinbin",
    "queue": "Nagpapadala",
    "offered": "Nagpapadala",
    "booked": "Papunta na ang Driver",
    "engaged": "P.O.B",
    "droppedOff": "Nahulog/ Naihatid",
    "pre": "Tinanggap ang order",
    "recurrent": "Recurrent",
    "vacant": "",
    "vacantTime": "",
    "arrived": "Dumating at naghihintay",
    "completed": "Completed",
    "canceled": "Kinansela",
    "noShow": "Walang Palabas",
    "action": "Naghihintay ng proseso",
    "incident": "Insidente",
    "pickupTimeExceeded": "Time out",
    "canceledByCC": "Kinansela ni CC",
    "canceledByPassenger": "Kinansela ng pasahero",
    "canceledByDriver": "Kinansela ng driver",
    "canceledByPartner": "Kinansela ng partner",
    "canceledBymDispatcher": "Kinansela ng partner",
    "canceledByCorporateAdmin": "Kinansela ng Corporate Admin",
    "canceledByAPI": "Kinansela ng API",
    "canceledByWebBooking": "Kinansela sa pamamagitan ng web booking",
    "canceledByTimeout": "Kinansela ng timeout",
    "accepted": "Accepted",
    "rejected": "Tinanggihan",
    "canceledByCorpAd": "Kinansela ng Corporate Admin",
    "started": "Nagsimula",
    "intransit": "In transit",
    "empty": "Walang laman",
    "notLive": "Bahagyang - hindi live",
    "live": "Bahagyang - live",
    "full": "Puno",
    "delivering": "Naghahatid",
    "delivery_booked": "Driver sa daan patungo sa nagpadala",
    "delivery_arrived": "Dumating at naghihintay ng nagpadala",
    "shopForYou_booked": "Driver sa paraan upang mangolekta ng pera",
    "shopForYou_arrived": "Dumating at naghihintay upang mangolekta ng pera",
    "collecting": "Driver sa paraan upang mangolekta ng mga kalakal",
    "confirmed_delivery": "Nakumpirma",
    "food_booked": "Driver papunta sa merchant",
    "food_collecting": "Dumating at naghihintay upang mangolekta ng item",
    "mart_collecting": "Dumating at naghihintay upang mangolekta ng item",
    "otwMerchant": "Driver papunta sa merchant",
    "arrivedAndWaitingToCollectItem": "Arrived & waiting to collect item",
    "canceledByAirlineAdmin": "Kinansela ng Airline Admin",
    "partialCompleted": "Bahagyang Nakumpleto",
    "failed": "Nabigo",
    "allocated": "Inilaan"
  },
  "tripTypes": {
    "scheduled": "Mga naka-iskedyul na biyahe",
    "requested": "Humiling ng mga biyahe",
    "preferred": "Mas gusto"
  },
  "login": {
    "Username": "User name",
    "Password": "Password",
    "Remember_me": "Remember me",
    "Forgot_your_password": "Nakalimutan ang iyong password",
    "Login": "Mag log in"
  },
  "invoice": {
    "createBtn": "Gumawa ng invoice",
    "searchPlInvoice": "Maghanap ng kliyente, contact, numero ng invoice",
    "searchPl": "Maghanap ng bookId, pangalan ng customer",
    "createInvoice": "Lumikha ng Invoice",
    "invoicePeriod": "Panahon ng Invoice",
    "invoicePeriodNote": "Maaari kang pumili ng buwan-buwan na hanay ng petsa.",
    "viewEdit": "Tingnan/I-edit",
    "billingContact": "Contact sa pagsingil",
    "memo": "Memo",
    "memoMessage": "Mag-iwan ng mensahe sa customer",
    "dueDate": "Takdang petsa",
    "updateInvoice": "I-update ang Invoice",
    "chargeCustomer": "Singilin ang Customer",
    "invoiceNumber": "Numero ng Invoice",
    "noItem": "Hindi. Mga item",
    "issuedDate": "Petsa ng Paglabas",
    "refId": "Ref #",
    "pickupTime": "Oras ng pagkuha",
    "pickup": "Lokasyon ng Pick Up",
    "destination": "I-drop Off ang Lokasyon/ Lokasyon ng pagbababaan",
    "customerName": "Pangalan ng Customer",
    "totalAmount": "Amount Due",
    "amountDue": "Halagang dapat bayaran",
    "sendInvoice": "Magpadala ng Invoice",
    "copyInvoice": "Kopyahin ang Invoice",
    "downloadPDF": "I-download bilang PDF",
    "paymentLink": "Link sa pagbabayad",
    "deleteInvoice": "Tanggalin ang Invoice",
    "onlySave": "I-save ang Invoice",
    "saveSend": "I-save at Ipadala ang Invoice",
    "sendEmail": "Magpadala ng Email",
    "addRecipients": "Magdagdag ng Mga Tatanggap",
    "editInvoice": "I-edit ang Invoice",
    "viewInvoice": "Tingnan ang Invoice",
    "pending": "Nakabinbin",
    "paid": "Binayaran",
    "check": "Suriin",
    "wire": "Kawad",
    "digitalWallet": "Digital Wallet",
    "cash": "Cash",
    "completedTime": "Nakumpletong Oras",
    "Status": "Katayuan",
    "bookingStatus": "Katayuan ng Pag-book",
    "chargeBtn": "Singilin {0}",
    "pastDue": "Lampas na sa takdang panahon",
    "payCompleted": "Matagumpay na nabayaran ang invoice na ito!",
    "Delete": "Tanggalin ang invoice?",
    "Delete_content": "Permanenteng aalisin ng pagkilos na ito ang invoice na ito. Kung kinakailangan, maaari kang lumikha ng bago para sa (mga) order na ito.",
    "CopyLink": "Kopyahin ang Link"
  },
  "cue": {
    "creationTime": "Oras ng paglikha",
    "Pax": "Pax #",
    "Luggage": "Mga bagahe",
    "completedWithoutService": "Nakumpleto nang walang driver",
    "shuttle": "Shuttle",
    "farmIn": "Farm In",
    "chargeCode": "Code ng Pagsingil",
    "clientCaseMatter": "Paglalarawan ng Biyahe",
    "operatorNote": "Mga Panloob na Tala",
    "farmOut": "Ilipat sa iba",
    "FlightUpdate": "Update sa Flight",
    "networkType": "Uri ng network",
    "InHouse": "In-house",
    "carHailing": "Sasakyan Hailing",
    "uploadBookings": "Mag-upload ng Mga Booking",
    "rideSharing": "Shared (Transport)",
    "Rearrange": "Muling ayusin",
    "GroupId": "Group ID #",
    "Active": "Aktibo",
    "Finished": "Tapos na",
    "Home_affiliate": "Tahanan / Kaakibat",
    "Booking_type": "Uri ng booking",
    "From": "Mula sa",
    "balanceWallet": "{0}: {1} ang natitira",
    "partialWallet": "Bahagyang pagbabayad gamit ang Wallet",
    "To": "Upang/ Patungo",
    "Status": "Katayuan",
    "Migration_Status": "Katayuan ng Migration",
    "Migrated": "nag-migrate",
    "Select_Status": "Piliin ang Katayuan",
    "Operator": "Operator",
    "Car_types": "Mga uri ng sasakyan",
    "Booking_from": "Pagbu-book mula sa",
    "All": "Lahat",
    "Pending": "Nakabinbin",
    "Dispatching": "Nagpapadala",
    "Waiting_for_process": "Naghihintay ng proseso",
    "Confirmed_reservation": "Kumpirmadong reserbasyon",
    "Driver_on_the_way": "Papunta na ang Driver",
    "Arrived_and_waiting": "Dumating at naghihintay",
    "Passenger_on_board": "Pasahero na sakay",
    "Dropped_off": "Nahulog/ Naihatid",
    "combobox_select": "Select...",
    "Search_here": "Maghanap dito",
    "Bookingid": "# ng booking",
    "Pickup_Drop_Off": "Pickup/ Drop Off",
    "Pickup_Drop_Off_Crew": "Oras ng Pickup/Drop Off",
    "Pickup_Drop_Off_Local_Time": "Oras ng Operator",
    "Pickup_Location_Destination": "Lokasyon/Patutunguhan ng Pickup",
    "Passenger": "pasahero",
    "Intercity_Route": "Intercity na ruta",
    "Airport_Pickup": "Pagsundo sa paliparan",
    "Airport_Pickup_Crew": "Paliparan",
    "Flight_Status": "Katayuan",
    "Payment": "Pagbabayad",
    "Vehicle": "Sasakyan",
    "Car_type": "Uri ng Sasakyan",
    "Driver": "Driver",
    "Estimated_Fare": "Tinatayang Pamasahe",
    "Notes": "Mga Tala",
    "Note": "Tandaan",
    "Type": "Uri",
    "Home": "Bahay",
    "Affiliate": "Kaakibat",
    "Now": "Ngayon (Transport)",
    "Reservation": "Pagpapareserba (Transportasyon)",
    "Shuttle": "Shuttle",
    "recurring": "Umuulit (Transportasyon)",
    "No_show": "Walang Palabas",
    "Canceled_by_CC": "Kinansela ng CC",
    "Canceled_by_passenger": "Kinansela ng pasahero",
    "Canceled_by_partner": "Kinansela ng partner",
    "Canceled_by_mDispatcher": "Kinansela ng partner",
    "Canceled_by_Corporate_Admin": "Kinansela ng Corporate Admin",
    "Canceled_by_API": "Kinansela ng API",
    "canceled_By_Web_Booking": "Kinansela sa pamamagitan ng web booking",
    "canceled_By_Time_Out": "Kinansela ng timeout",
    "Canceled": "Kinansela",
    "Completed": "Nakumpleto",
    "Incident": "Insidente",
    "Payment_Method": "Paraan ng Pagbayad",
    "No_Phone": "Walang Telepono",
    "VIP": "VIP",
    "Non_VIP": "Hindi VIP",
    "Passenger_type": "Uri ng pasahero",
    "Error_load_booking": "Hindi ma-load ang booking, Pakisubukang muli o i-refresh ang page!",
    "Edit_total_success": "Kabuuang matagumpay na na-edit!",
    "Edit_total_fail": "Ang kabuuang pamasahe ay dapat na katumbas o mas malaki sa {0}",
    "Time_out": "Time out",
    "Ride_Service": "Serbisyo sa pagsakay",
    "Regulation": "Regulasyon",
    "Ride_Sharing": "Pagbabahaginan ng pagsakay",
    "Unmatching": "Hindi tugma",
    "Matching": "Pagtutugma",
    "all": "Lahat",
    "matching": "Pagtutugma",
    "unmatching": "Hindi tugma",
    "regulation": "Regulasyon",
    "rideSharing": "Pagbabahaginan ng pagsakay",
    "Support_service": "Serbisyong pang-supporta",
    "SOS": "SOS",
    "Non_SOS": "Hindi SOS",
    "Corporate": "Corporate",
    "Individual": "Indibidwal",
    "On_curb": "Sa gilid ng bangketa",
    "Meet_Greet": "Pagkikita at Pagbati",
    "Close": "Isara",
    "auto_refrest_cue": "Auto-refresh ({0} segundo)",
    "Ride_type": "Uri ng pagsakay",
    "Edit_total_note": "Tandaan: maa-update ang subtotal, tech fee, booking fee, buwis at tip kapag binago ang kabuuang halaga",
    "Booking": "Pagbu-book",
    "Edit_fare": "I-edit ang pamasahe",
    "Adjust_price": "Ayusin ang Presyo",
    "Total": "Kabuuan",
    "Reason": "Dahilan",
    "Please_enter_reason": "Pakilagay ang dahilan.",
    "Save": "I-save",
    "Cancel": "Kanselahin",
    "dmc": "Booking board",
    "corp": "Booking board (CTM)",
    "Zone": "Pickup zone",
    "mDispatcher": "Kasosyo",
    "streetSharing": "pagbabahagi ng kalye",
    "Driver_No_Job_found": "Naghihintay ng mga takdang-aralin...",
    "expandFilter": "Ipakita ang filter",
    "collapseFilter": "Itago ang filter",
    "clearSelectedItems": "I-clear ang Napiling Mga Item",
    "Accepted": "Tinanggap",
    "Yes": "Oo",
    "No": "Hindi",
    "Order_accepted": "Tinanggap ang order",
    "Confirmed": "Confirmed",
    "spotTime": "Spot Time",
    "ETA": "ETA",
    "spareTime": "Bakanteng oras",
    "expectedTime": "Inaasahang oras ng pagkuha",
    "Driver_on_the_way_to_sender": "Driver sa daan patungo sa nagpadala",
    "Driver_on_the_way_to_recipient": "Driver papunta sa tatanggap",
    "Driver_on_the_way_to_collect_cash": "Driver sa paraan upang mangolekta ng pera",
    "Driver_on_the_way_to_collect_goods": "Driver sa paraan upang mangolekta ng mga kalakal",
    "Arrived_waiting_for_sender": "Dumating at naghihintay ng nagpadala",
    "Arrived_waiting_for_recipient": "Dumating at naghihintay ng tatanggap",
    "Arrived_waiting_to_collect_cash": "Dumating at naghihintay upang mangolekta ng pera",
    "Arrived_waiting_to_deliver_goods": "Dumating at naghihintay na maghatid ng mga kalakal",
    "Delivered": "Naghahatid",
    "delivery": "Paghahatid",
    "Service": "Serbisyo",
    "intercity": "Intercity",
    "Transport": "Transportasyon",
    "About_Sender": "Tungkol sa Sender",
    "About_Merchant": "Tungkol sa Merchant",
    "About_Recipient": "Tungkol sa Tatanggap",
    "photos": "Mga larawan",
    "listOfItems": "Listahan ng item",
    "cashOnPickUp": "COP",
    "Package_information": "Impormasyon sa package",
    "otwMerchant": "Driver papunta sa merchant",
    "arrivedAndWaitingToCollectItem": "Dumating at naghihintay upang mangolekta ng item",
    "Save_Filter": "I-save ang Filter",
    "add_favorite_filter": "Magdagdag ng filter",
    "favorite_filter_name": "Pangalan",
    "list_favorite_filter": "Pamahalaan ang mga filter",
    "favorite_filter_actions": "Aksyon",
    "clear_all_filters": "I-clear ang lahat ng mga filter",
    "Delete_Filter": "Tanggalin ang Filter",
    "CONFIRM_DELETE_FILTER_TEMPLATE": "Sigurado ka bang gusto mong tanggalin ang filter na ito?",
    "editFare": {
      "creditTransactionFee": "Mga bayarin sa credit card",
      "preferredAdjustment": "Ginustong pagsasaayos",
      "editFareFee": "I-edit ang pamasahe at bayarin",
      "markupPrice": "Presyo ng markup",
      "quotedPrice": "Sinipi na presyo",
      "basicFare": "Pangunahing pamasahe",
      "preferredEarning": "Ginustong kita",
      "difference": "Pagkakaiba",
      "rushHourFee": "Patong na singil",
      "serviceFee": "Magdagdag ng serbisyo",
      "otherFees": "Iba pang bayad",
      "subTotal": "Sub total",
      "airportFee": "Bayad sa paliparan",
      "meetDriverFee": "Kilalanin ang driver",
      "deliveryFee": "Bayad sa paghahatid",
      "tollFee": "Toll fee",
      parkingFee: 'Parking fee',
        gasFee: 'Gas fee',
      "techFee": "bayad sa tech",
      "tip": "Tip",
      "bookingFee": "Bayad sa pagpapareserba",
      "tax": "Buwis",
      "promoAmount": "Promo amt",
      "etaFare": "Kabuuan",
      "fleetService": "Serbisyo ng armada",
      "Note_serviceFee": "(upang mag-edit ng karagdagang serbisyo, bisitahin ang panlabas na listahan)",
      Note_serviceFee: '(to edit additional service, visit outer list)',
      startingFee: 'Starting',
      moveFeeInJourney: 'Moving',
      waitFeeInJourney: 'Waiting (in-journey)'
    },
    "partialCompleted": "Bahagyang Nakumpleto",
    "failed": "Nabigo",
    "Recurring": "Umuulit #",
    "bookingCom": "Booking.com",
    "syncingCompleted": "Nakumpleto ang pag-sync",
    "allocated": "Inilaan",
    "roaming": "Roaming",
    "moreActions": "Higit pang mga Aksyon",
    "batchDelivery": "Batch Delivery",
    pickupTime: 'Pick-up time',
    AssignSupplier: 'Assign Supplier',
    sharedTransfer: 'Shared Transfer (%{id})',
    batchDeliveryGroup: 'Batch Delivery (%{id})',
    foundersFund: 'Founders Fund',
    zeroCommission: 'Zero Commission',
    unassignDriver: 'Unassign Driver',
    notUnassignDriver: 'Cannot unassign driver, Please try again',
    notAssignDriver: 'Cannot assign driver, Please try again',
    selectDriver: 'Select Driver',
    unassignVehicle: 'Unassign Vehicle',
    unassignAll: 'Unassign All',
    notUnassignAll: 'Cannot unassign all, Please try again',
    unassignAllSuccess: 'The vehicle and driver have been successfully detached.',
    unassign_all_partial: 'Partial Success: Batch unassignment failed for the following trips (Booking IDs) due to ongoing actions: {0}.',
    notUnassignVehicle: 'Cannot unassign vehicle, Please try again',
    notAssignVehicle: 'Cannot assign vehicle, Please try again',
    selectVehicle: 'Select Vehicle',
    confirmUnassign: 'Are you sure you want to unassign?'
  },
  "trip": {
    "Driver_vehicle": "sasakyan ng driver",
    "tripId": "# ng biyahe",
    "tripTypes": "Mga uri ng biyahe",
    "Start_Time": "Oras ng simula",
    "Seats_Status": "Katayuan ng mga upuan",
    "No_Of_Booking": "Bilang ng mga booking",
    "Vehicle": "sasakyan ng driver",
    "Estimated_Fare": "pamasahe",
    "capacityStatus": "Katayuan ng upuan",
    "Trip_Types": "Mga uri ng biyahe",
    "scheduled": "Mga naka-iskedyul na biyahe",
    "requested": "Humiling ng mga biyahe",
    "preferred": "Mas gusto",
    "All": "Lahat",
    "Confirmed": "Confirmed",
    "Started": "Nagsimula",
    "In_Transit": "Sa Transit",
    "Empty": "Walang laman",
    "Partial_not_live": "Bahagyang - hindi live",
    "Partial_live": "Bahagyang - live",
    "Full": "Puno",
    "Error_load_trip": "Hindi ma-load ang biyahe, Pakisubukang muli o i-refresh ang pahina!"
  },
  "tripDetails": {
    "Trip_ID": "paglalakbay sa pagitan ng lungsod",
    "DRIVER_JOB": "Impormasyon ng driver",
    "BOOKINGS": "Mga booking",
    "updateTrip": "I-update ang Biyahe",
    "cancelTrip": "Kanselahin ang Biyahe",
    "note_cancel": "Mangyaring italaga ang lahat ng booking sa ibang driver bago kanselahin ang biyaheng ito.",
    "TRIP_INFORMATION": "Impormasyon sa Biyahe",
    "route": "Ruta",
    "startTime": "Oras ng simula",
    "distance": "Distansya",
    "duration": "Tagal",
    "fare": "pamasahe",
    "cartype": "Intercity cartype",
    "seatStatus": "Katayuan ng upuan",
    "started": "Nagsimula",
    "intransit": "In transit",
    "empty": "Walang laman",
    "notLive": "Bahagyang - hindi live",
    "live": "Bahagyang - live",
    "full": "Puno",
    "noOfBooking": "Bilang ng booking",
    "luggage": "Mga bagahe",
    "nameDriver": "Pangalan",
    "vehicleType": "Sasakyan",
    "assignDriver": "Magtalaga ng driver",
    "pickUpAddress": "Pulutin",
    "destinationAddress": "Ilaglag/ Ihatid",
    "customerName": "Pangalan",
    "customerPhone": "Telepono",
    "customerNumber": "Mga upuan",
    "customerLuggageNumber": "Mga bagahe",
    "additionalService": "Karagdagang serbisyo",
    "paymentBooking": "Pagbabayad",
    "note": "Tandaan",
    "CONFIRM_UPDATE": "kumpirmahin ang pag-update",
    "CONFIRM_UPDATE_MESSAGE": "Sigurado ka bang gusto mong i-update ang biyaheng ito?",
    "Yes": "Oo",
    "No": "Hindi",
    "Update_info": "Oo, I-update ang impormasyon"
  },
  "newbooking": {
    "rideSharing_multi_point": "1 pick-up at 1 drop off point lang ang pinapayagan para sa ride sharing booking.",
    "Pick_up_time": "Oras ng pagkuha",
    "Drop_off_time": "Oras ng pagbaba",
    "All_Suppliers": "Lahat ng mga supplier",
    "Recipient": "tatanggap",
    "All": "Lahat",
    "New_Booking": "Bagong Booking",
    "GoogleMapRoute": "Google Map",
    "BOOKING_INFORMATION": "Impormasyon sa Pag-book",
    "TIP_PROMO": "Tip - Promo",
    "Pick_up": "Pulutin",
    "instructions": "Mga tagubilin",
    "instructions_placeholder": "Ilista ang iyong mga tagubilin dito...",
    "Enter_a_location": "Maglagay ng lokasyon",
    "Enter_a_location_delivery": "Maglagay ng lokasyon",
    "Enter_3rd_party_location": "Ilagay ang lokasyon ng 3rd party",
    "Enter_a_sender": "Magpasok ng isang Nagpadala",
    "Enter_a_recipient": "Maglagay ng Recipient",
    "Vehicle_Selection": "Pagpili ng Sasakyan",
    "Destination": "Patutunguhan",
    "Pickup_time": "Oras ng pagkuha",
    "Note": "Tandaan",
    "note_for_driver": "Paalala para sa driver",
    "operator_note": "Ipasok ang panloob na mga tala para sa mga operator",
    "TRAVELER_INFORMATION": "Impormasyon ng Manlalakbay",
    "Traveler_type": "Uri ng manlalakbay",
    "Individual": "Indibidwal",
    "Corporation": "Korporasyon",
    "Phone_number": "Numero ng telepono",
    "First_name": "Pangalan",
    "Last_name": "Huling pangalan",
    "Email_address": "Email address",
    "Corp_id": "Corp id",
    "Manager_name": "Pangalan ng manager",
    "Manager_email": "Email ng manager",
    "Cost_centre": "Sentro ng gastos",
    "Department": "Kagawaran",
    "Corp_division": "Dibisyon ng korporasyon",
    "Tip": "Tip",
    "Promo_code": "Promo code",
    "Apply": "Mag-apply",
    "Promo": "Promo:",
    "DISPATCH": "Pagpapadala",
    "Auto_dispatch": "Auto dispatch",
    "Assign_driver": "Magtalaga ng driver",
    "Driver": "Driver",
    "PAYMENT_METHOD": "Payment Method",
    "OPERATOR_NOTE": "Mga Panloob na Tala",
    "TRIP_ESTIMATE": "Pagtatantya ng Biyahe",
    "Choose_a_stored_card": "Pumili ng nakaimbak na card",
    "Choose_a_payment_method": "Pumili ng paraan ng pagbabayad",
    "Use_new_card": "Gumamit ng bagong card",
    "Card_holder": "May hawak ng card",
    "Card_number": "Numero ng card",
    "Expired_date": "Petsa ng pag-expire(mm/yy)",
    "ccv": "CVV",
    "Distance": "Distansya",
    "Duration": "Tagal",
    "Fare": "pamasahe",
    "Route": "Ruta",
    "Create": "Lumikha",
    "Cancel": "Kanselahin",
    "Meet_driver": "Kilalanin ang driver",
    "Airline": "Airline",
    "Flight": "Flight #",
    "FLY_INFO": "Impormasyon sa Paglipad",
    "Ride_Sharing": "Pagbabahagi ng Sakay",
    "Round_trip": "Papunta at pabalik",
    "Car_type": "Uri ng kotse",
    "Address": "Address",
    "City": "lungsod",
    "State": "Estado",
    "Zip_code": "Zip code",
    "Remove": "Alisin",
    "Client_case_matter": "Bagay sa kaso ng kliyente / paglalarawan ng biyahe",
    "Charge_code": "Code ng singil / Code ng biyahe",
    "CANCEL_BOOKING": "Isara ang Pag-book Kumpirmahin",
    "CANCEL_BOOKING_CONFIRM": "Gusto mo bang umalis sa form na ito? Mawawala ang lahat ng data ng booking.",
    "Search_here": "Maghanap dito",
    "No_support_location": "Sa kasamaang palad, ang serbisyo ay hindi magagamit sa lugar na ito",
    "Closest_driver_message": "Ang pinakamalapit na driver ay humigit-kumulang {0} ang layo",
    "No_cars_available": "Walang available na sasakyan sa loob ng radius ng pagpapadala.",
    "processed_by_affiliate": "Ang iyong mga kahilingan ay ipoproseso ng aming mga kasosyong kaakibat",
    "FlightExample": "Flight #, hal. AA1097",
    "Yes": "Oo",
    "No": "Hindi",
    "dispatch_3rd": "Pagpapadala sa pamamagitan ng 3rd party",
    "Company": "kumpanya",
    "Card_required": "Mangyaring pumili o magdagdag ng card!",
    "Tip_value": "N/A",
    "Calendar": "Kalendaryo",
    "Extra_Destination": "Karagdagang destinasyon",
    "Driver_credit_limited_balance_warning": "Ang balanse ng driver na ito ay mas mababa sa o katumbas ng limitadong halaga",
    "Meet_Greet_option": "Pagkikita at Pagbati",
    "On_Curb_option": "Sa gilid ng bangketa",
    "Extra_Services": "Karagdagang serbisyo",
    "Addl_Services": "Karagdagang serbisyo",
    "Save": "I-save",
    "Regular": "Regular",
    "Please_fill_in_all_the_required_fields": "Paki-sagutan ang mga kailangan.",
    "Please_select_weekdays": "Mangyaring piliin ang umuulit sa mga araw na gusto mong mangyari ang booking na ito",
    "Flat_rate": "Flat rate",
    "All_company": "Lahat ng kumpanya",
    "Best_price": "Pinakamahusay na presyo",
    "PromoTitle": "Promo",
    "Show_more": "Show more",
    "Warning_pending_payment": "Ang customer na ito ay may hindi kumpletong pagbabayad mula sa mga nakaraang booking: %{fare}",
    "Warning_outstanding_balance": "Ang natitirang balanse ay ilalapat sa pagtatapos ng biyahe.",
    "Not_available": "Hindi magagamit",
    "Warning_assigned_rate": "Mangyaring i-set up at italaga ang rate para sa napiling uri ng kotse upang magpatuloy.",
    "PaxLuggageNumber": "Numero ng pasahero/bagahe",
    "paxNumber": "pasahero",
    "luggageNumber": "Mga bagahe",
    "Date": "Petsa",
    "Single": "Walang asawa",
    "Recurring": "Paulit-ulit",
    "Date_range": "Na-customize na mga araw",
    "WeekDays": "Ulitin sa mga araw",
    "Create_Route": "Lumikha ng Ruta",
    "Pickup_Point": "Pulutin/ Sunduin",
    "Drag_To_Render": "I-drag at i-drop upang muling ayusin",
    "DropOff_Point": "Mga Babaan/ Mga Bagsakan",
    "Sender": "Nagpadala",
    "Recipients": "Mga tatanggap",
    "Sender_Infomation": "Impormasyon ng Nagpadala",
    "Recipient_Infomation": "Impormasyon ng Recipient",
    "Recipient_Name": "Pangalan ng makakatanggap *",
    "Recipient_Phone": "Numero ng telepono *",
    "Room_floor_block": "Kwarto, sahig, at bloke (opsyonal)",
    "Details": "Mga Detalye",
    "Package_Content": "Nilalaman ng Package *",
    "Order_ID": "Order # (hal. shopify order #)",
    "Instructions_for_driver": "Mga tagubilin para sa driver",
    "Cost_of_Goods": "Cash on Delivery (COD)",
    "Enter_amount": "Enter amount",
    "Additional_Information": "Additional Information",
    "Insurance": "Insurance",
    "Special_Care": "Espesyal na pag-aalaga",
    "Delivery_Fee": "Bayarin sa Paghahatid",
    "Item_fees": "Mga bayarin sa item",
    "Tech_Fee": "Bayad sa Teknolohiya",
    "Tax": "Buwis",
    "DriverEarning": "Kinita ng Driver",
    "DriverPayOut": "Payout ng Driver",
    "rejectModal": "Tanggihan ang booking",
    "driverNotes": "Mga Tala sa Pagmamaneho",
    "rejectModalBody": "Sigurado ka bang gusto mong tanggihan ang booking na ito?",
    "rejectButton": "Tanggihan ang booking",
    "acceptModal": "Tanggapin ang booking",
    "acceptModalBody": "Sigurado ka bang gusto mong Tanggapin ang booking na ito?",
    "acceptButton": "Tanggapin ang booking",
    "denyModal": "Tanggihan ang booking",
    "denyModalBody": "Sigurado ka bang gusto mong tanggihan ang booking na ito?",
    "denyButton": "Tanggihan ang booking",
    "Accept_booking_success": "Matagumpay na natanggap ang booking na ito!",
    "Deny_booking_success": "Matagumpay na tinanggihan ang booking na ito!",
    "adjustFare": "Ayusin ang pamasahe",
    "totalFare": "Kabuuang Pamasahe",
    "Profit": "Kita",
    "confirmReject": "Kumpirmahin ang Pagtanggi",
    "confirmCancelMSG": "Sigurado ka bang gusto mong tanggihan ang booking na ito? Maaari kang magkaroon ng multa mula sa iyong provider.",
    "reasonCancel3rd": "Dahilan ng pagtanggi",
    "welcome_psg": "Maligayang pagdating pasahero",
    "Search_driver_hydra": "Susubukan naming hanapin ka ng driver sa lalong madaling panahon.",
    "affiliate_note": "Paalala para sa kaakibat",
    "affiliate_note_placeholder": "Maglagay ng mga kaakibat na tala para sa mga operator",
    "FarmOut": "Ilipat sa iba",
    "AmountDue": "Halagang dapat bayaran",
    viewRate: 'View Rate'
  },
  "bookingdetail": {
    "groupOder": "Pagkakasunod-sunod ng pangkat",
    "ExistingGroup": "Umiiral na Grupo",
    "NewGroup": "Bagong grupo",
    "Search_trip": "Search trip #",
    "All": "Lahat",
    "creditTransactionFee": "Mga bayarin sa credit card",
    "partial_payment": "Paunang bayad",
    "Write_off_debt": "Isulat ang utang",
    "Booking_detail": "Pagbu-book",
    "Order_detail": "Umorder",
    "Completed_Booking": "Nakumpleto ang Pag-book",
    "Late_Cancel": "Late cancel",
    "Cancel_Driver": "Kanselahin ng driver",
    "No_Show": "Walang Palabas/ Hindi Dumating",
    "Without_driver": "Kumpleto nang walang driver",
    "Complete_driver": "Kumpleto sa driver",
    "BOOKING_INFORMATION": "Impormasyon sa pag-book",
    "TIP_PROMO": "Tip - Promo",
    "Rearrange_Column": "Ayusin muli ang Column",
    "Pick_up": "Pulutin/ Sunduin",
    "Enter_a_location": "Maglagay ng lokasyon",
    "Destination": "Patutunguhan",
    "Pickup_time": "Oras ng pagkuha/ Pagsundo",
    "Notes": "Mga Tala",
    "TRAVELER_INFORMATION": "Impormasyon ng manlalakbay",
    "Traveler_type": "Uri ng manlalakbay",
    "Individual": "Indibidwal",
    "Corporation": "Korporasyon",
    "Phone_number": "Numero ng telepono",
    "First_name": "Pangalan",
    "Last_name": "Huling pangalan",
    "Email_address": "Email address",
    "Corp_id": "Corp id",
    "Manager_name": "Pangalan ng manager",
    "Manager_email": "Email ng manager",
    "Cost_centre": "Sentro ng gastos",
    "Department": "Kagawaran",
    "Corp_division": "Dibisyon ng korporasyon",
    "Tip": "Tip",
    "Promo_code": "Promo code",
    "Apply": "Mag-apply",
    "Promo": "Promo",
    "DISPATCH": "Pagpapadala",
    "Auto_dispatch": "Auto dispatch",
    "Assign_driver": "Magtalaga ng driver",
    "Driver": "Driver",
    "PAYMENT_METHOD": "Payment method",
    "Payment_methods": "Mga paraan ng pagbabayad",
    "pending_payment": "Nakabinbing Pagbabayad",
    "TRIP_ESTIMATE": "Pagtatantya ng biyahe",
    "Choose_a_stored_card": "Pumili ng nakaimbak na card",
    "Choose_a_payment_method": "Pumili ng paraan ng pagbabayad",
    "Use_new_card": "Gumamit ng bagong card",
    "Card_holder": "May hawak ng card",
    "Card_number": "Numero ng card",
    "Expired_date": "Petsa ng pag-expire(mm/yy)",
    "ccv": "CVV",
    "Distance": "Distansya",
    "Duration": "Tagal",
    "Fare": "pamasahe",
    "Route": "Ruta",
    "Meet_driver": "Kilalanin ang driver",
    "Airline": "Airline",
    "Flight": "Flight #",
    "FLY_INFO": "Impormasyon ng flight",
    "Ride_Sharing": "Pagbabahagi ng Sakay",
    "Round_trip": "Papunta at pabalik",
    "Car_type": "Uri ng kotse",
    "Address": "Address",
    "City": "lungsod",
    "State": "Estado",
    "Zip_code": "Zip code",
    "Remove": "Alisin",
    "New_Booking": "Bagong Booking",
    "New_Order": "Bagong Order",
    "ViewTrackLink": "Tingnan ang Link ng Track",
    "ViewLog": "Tingnan ang mga dispatch log",
    "Update_Booking": "I-update ang Booking",
    "Cancel_Booking": "Kanselahin ang Booking",
    "Complete_with_payment": "Kumpleto sa Pagbabayad",
    "Incident": "Insidente",
    "CONFIRM_CANCEL": "kumpirmahin kanselahin",
    "CONFIRM_CANCEL_MESSAGE": "Sigurado ka bang gusto mong kanselahin ang booking na ito?",
    "CONFIRM_UPDATE": "kumpirmahin ang pag-update",
    "CONFIRM_UPDATE_MESSAGE": "Sigurado ka bang gusto mong i-update ang booking na ito?",
    "CONFIRM_RESET_EDIT_FARE_MESSAGE": "Na-reset ang iyong na-edit na pamasahe, mangyaring suriin bago magpatuloy.",
    "Close": "Isara",
    "Update_info": "Oo, I-update ang impormasyon",
    "Update_info_dispatch": "Oo, I-update ang impormasyon at pagpapadala",
    "Update_info_tooltip": "Kung itatalaga mo ang booking na ito sa isang bagong driver, mangyaring piliin ang \"Oo, I-update ang impormasyon at ipadala\" na button.",
    "BOOKING": "Pagbu-book",
    "DROPPED_OFF": "Nahulog/ Naihatid",
    "Payment_Type": "Uri ng Pagbabayad",
    "Total": "Kabuuan",
    "Next": "Susunod",
    "Pay_here": "Magbayad dito",
    "Complete_booking": "Kumpleto na ang booking",
    "Complete_booking_tooltip": "Paki-click ang \"I-update ang booking\" upang i-save ang iyong mga pagbabago bago magpatuloy.",
    "Cancel": "Kanselahin",
    "Back": "Bumalik",
    "Tax": "Buwis",
    "Booking_fees": "Mga bayarin sa pag-book",
    "Tech_fees": "Mga bayad sa tech",
    "Subtotal": "Subtotal",
    "Other_fees": "Iba pang bayad",
    Waiting_fee: 'Waiting fee',
    "Toll_fees": "Mga toll fee",
    "Heavy_traffic": "Matinding trapiko",
    "Rush_hour": "Patong na singil",
    "Basic_fare": "Pangunahing pamasahe",
    "Ok": "OK",
    "Input_reason": "Input na dahilan",
    "Client_case_matter": "Bagay sa kaso ng kliyente / paglalarawan ng biyahe",
    "Charge_code": "Code ng singil / Code ng biyahe",
    "Enter_3rd_party_location": "Ilagay ang lokasyon ng 3rd party",
    "Company": "kumpanya",
    "Check_promo_error": "Suriin ang error sa promoCode!",
    "Add_new_credit_error": "Magdagdag ng bagong credit error!",
    "Can_not_get_fare_setting": "Hindi makakuha ng setting ng pamasahe!",
    "Can_not_get_affilate_fare_setting": "Hindi makakuha ng mga setting ng pamasahe ng Affiliate!",
    "Can_not_check_commisson": "hindi masuri ang commisson!",
    "No_flight_info": "Walang impormasyon sa paglipad!",
    "Socket_server_disconnected": "Nadiskonekta ang socket server!",
    "Please_reload_page_or_check_your_connection": "Paumanhin. Hindi maproseso ang iyong kahilingan. Pakisubukang muli.",
    "View_Logs": "Tingnan ang mga log",
    "Bookingid": "# ng booking",
    "Calendar": "Kalendaryo",
    "Extra_Destination": "Karagdagang destinasyon",
    "Meet_Greet_option": "Pagkikita at Pagbati",
    "On_Curb_option": "Sa gilid ng bangketa",
    "Credit_Card": "Credit card",
    "Charge": "singilin",
    "No_charge": "Walang bayad",
    "QR_Code_method_not_allow_error_message": "Mangyaring pumili ng ibang uri ng pagbabayad dahil ang QRCode ay hindi magagamit na magbayad sa dashboard!",
    "change_payment_method_note": "Kung gusto mong baguhin ang paraan ng pagbabayad, mangyaring bumalik sa booking form.",
    "Airport_Fee": "Bayad sa Paliparan",
    "Addi_service": "Mga Add'l Services",
    "Additional_Services": "Karagdagang serbisyo",
    "Minimum": "pinakamababa",
    "Search_here": "Maghanap dito",
    "No_Driver_Yet": "Wala pang driver na nakatalaga",
    "Personal_Card": "Personal na card",
    "Reject_booking": "Tanggihan ang Booking",
    "Confirm_reject": "Kumpirmahin ang pagtanggi",
    "Confirm_reject_message": "Sigurado ka bang gusto mong tanggihan ang booking na ito?",
    "Confirm_reject_booking_with_charge": "Sisingilin ka ng {0}% ng naka-quote na pamasahe batay sa aming patakaran sa pagkansela. Sigurado ka bang gusto mong tanggihan ang booking na ito?",
    "Yes": "Oo",
    "No": "Hindi",
    "shortTrip": "Maikling Biyahe",
    "Add_new_card": "Magdagdag ng bagong card",
    "Require_customer_info": "Mangyaring ipasok ang impormasyon ng customer upang magpatuloy.",
    "confirm_force_overlap": "Kumpirmasyon",
    "confirm_force_overlap_message": "Nag-o-overlap ang booking na ito sa mga tinatanggap na booking ng driver. Sigurado ka bang gusto mong italaga ito sa driver na ito?",
    "select": "Pumili",
    "PICKUP_TIME_RANGE": "Tinatayang pickup sa {0}",
    "food": "Pagkain",
    "mart": "Mart",
    "Order_summary": "Buod ng Order",
    "addOnPrice": "Inayos ang presyo",
    "applicableFee": "Kabuuan (naaangkop)",
    "itemValue": "Halaga ng item",
    "promotion_note_paymentMethod": "Ang order na ito ay inilalapat ang promotion code na may limitadong paraan ng pagbabayad: %{method}.",
    "promotion_note_minimum_fare": "Ang order na ito ay inilalapat ang code na pang-promosyon na may limitadong minimum na pamasahe: %{fare}.",
    "promotion_note_schedules": "Ang order na ito ay inilalapat na promotion code na may limitadong oras ng pagkuha.",
    "edited_fare_note": "Ito ay mga tala sa pagsasaayos ng pamasahe para sa sanggunian lamang. Mangyaring bisitahin ang mga ulat upang tingnan ang huling pamasahe at bayad.",
    "view_good_receipt": "Tingnan ang resibo ng mga kalakal",
    "confirmRemoveGroupTransport": "Sigurado ka bang gusto mong alisin ang kasalukuyang booking mula sa manifest na ito?",
    "confirmRemoveGroupDelivery": "Sigurado ka bang gusto mong alisin ang kasalukuyang booking sa grupong ito?",
    "confirmUngroupTransport": "Sigurado ka bang gusto mong i-ungroup ang lahat ng booking mula sa manifest na ito?",
    "confirmUngroupDelivery": "Sigurado ka bang gusto mong i-ungroup ang lahat ng booking mula sa grupong ito?",
    "view_delivery_receipt": "Tingnan ang larawan ng paghahatid",
    "select_time": "Pumili ng oras",
    "corporateName": "Pangalan ng Kumpanya",
    "sendMessage": "Magpadala ng Mensahe",
    "AmountDueBefore": "Halaga ng Dapat Nauna",
    "AmountDueAfter": "Halaga na Babayaran Pagkatapos",
    "chargeCustomer": "Singilin ang Customer",
    "pending": "Hindi bayad",
    "partial": "Bahagyang",
    "paid": "Binayaran",
    "ViewPaymentLogs": "Tingnan ang mga log ng pagbabayad",
    "pmActivity": "Aktibidad sa Pagbabayad",
    "paidStatus": "Bayad na katayuan",
    "paymentLog": "Log ng Pagbabayad",
    "OPERATOR_NOTE": "Mga Panloob na Tala",
    "InputReason": "Input na dahilan",
    "viewOrder": "Tingnan ang Order",
    "markFailed": "Markahan bilang nabigo"
  },
  "map": {
    "Enter_a_location": "Maglagay ng lokasyon",
    "All_company": "Lahat ng kumpanya",
    "All_cars": "Lahat ng sasakyan",
    "Show_all_cars": "Ipakita ang lahat ng mga kotse",
    "Hide_all_cars": "Itago ang lahat ng sasakyan",
    "Vacant": "Available",
    "Dispatching": "Nagpapadala",
    "Driver_on_the_way": "Papunta na ang Driver",
    "Arrived_and_waiting": "Dumating at naghihintay",
    "P_O_B": "P.O.B",
    "Dropped_off": "Nahulog/ Naihatid",
    "Waiting_for_process": "Naghihintay ng proseso",
    "Unavailable": "Hindi magagamit",
    "Total_Drivers": "Kabuuang mga Driver",
    "Estimate": "Tantyahin",
    "Destination": "Patutunguhan",
    "Pickup": "Pulutin",
    "Start_point": "Panimulang punto",
    "Passenger": "pasahero",
    "Driver_name": "Pangalan ng driver",
    "Search_car_here": "maghanap ng pangalan ng kotse/driver",
    "Error_load_booking": "Hindi ma-load ang booking, Pakisubukang muli o i-refresh ang page!",
    "Show_all": "Ipakita lahat",
    "driversLiveMap": "Mapa ng mga Driver",
    "heatMap": "Mapa ng Demand",
    "Trip": "Trip",
    "traffic": "Trapiko",
    "bicyclingLayers": "Pagbibisikleta",
    "layer": "Layer",
    "trafficBicycling": "Trapiko, Pagbibisikleta",
    "All_airline": "Lahat ng airline"
  },
  "customer": {
    "Customers": "Mga customer",
    "Points": "Mga puntos",
    "Migration_Status": "Katayuan ng Migration",
    "ADD_CUSTOMER": "Magdagdag ng customer",
    "EDIT_CUSTOMER": "I-edit ang customer",
    "Add": "Idagdag",
    "Activate": "I-activate",
    "Deactivate": "I-deactivate",
    "Delete": "Tanggalin",
    "Import": "Angkat",
    "Download_template": "I-download ang template",
    "View_Message": "Mensahe",
    "Send_Message": "Magpadala ng Mensahe",
    "Export_to_excel": "I-export sa excel",
    "Filter": "Salain",
    "Apply": "Mag-apply",
    "Clear": "Maaliwalas",
    "Name": "Pangalan",
    "Phone_Number": "Numero ng telepono",
    "Email": "Email",
    "Vip": "Vip",
    "companies": "kumpanya",
    "File": "file",
    "Number_successful_imports": "Bilang ng matagumpay na pag-import:",
    "Number_skipped": "Bilang ng nilaktawan:",
    "Number_duplicate_phone_numbers": "Bilang ng mga duplicate na numero ng telepono:",
    "Number_wrong_phone_numbers": "Bilang ng mga maling numero ng telepono:",
    "Corporate_Name": "Korporasyon",
    "Registered_From": "Nakarehistro Mula sa",
    "Registration_Date": "Petsa ng Pagpaparehistro",
    "Registration_date_from": "Petsa ng pagpaparehistro mula sa",
    "Registration_date_to": "Petsa ng pagpaparehistro sa",
    "IOS_Last_Login_Version": "IOS Last-Login Version",
    "Android_Last_Login_Version": "Bersyon ng Huling Pag-login ng Android",
    "Last_Active_Date": "Huling Aktibo na Petsa",
    "Verification": "Pagpapatunay",
    "Support_ID": "ID ng suporta",
    "Days_Since_Last_Active": "Mga Araw Mula Noong Huling Aktibo",
    "Completed_Bookings": "Mga Nakumpletong Booking",
    "Outstanding_amount": "Outstanding amount",
    "Status": "Katayuan",
    "Actions": "Mga aksyon",
    "Action": "Aksyon",
    "Inactive": "Hindi aktibo",
    "Active": "Aktibo",
    "Edit": "I-edit",
    "View": "Tingnan",
    "ALERT_SELECT_ACTIVATE": "Mangyaring piliin ang customer na ia-activate.",
    "ALERT_SELECT_DEACTIVATE": "Mangyaring piliin ang customer na ide-deactivate.",
    "ALERT_SELECT_DELETE": "Mangyaring piliin ang customer na tatanggalin.",
    "CONFIRM_DELETE": "Sigurado ka bang gusto mong tanggalin ang account na ito? Maaaring mag-signup ang customer ng bagong profile na may walang laman na data pagkatapos alisin ang account.",
    "CONFIRM_DELETE_MULTI": "Sigurado ka bang gusto mong tanggalin ang mga customer na ito? Tanging ang mga customer na hindi aktibo at hindi rehistradong user sa pamamagitan ng app ang maaaring tanggalin.",
    "CONFIRM_DEACTIVATE_MULTI": "Gusto mo bang i-deactivate ang mga customer na ito?",
    "CONFIRM_ACTIVATE_MULTI": "Gusto mo bang i-activate ang mga customer na ito?",
    "CONFIRM_EXPORT_TO_EXCEL": "Sigurado ka bang gusto mong i-export ang lahat ng data bilang isang .xlsx file?",
    "Add_card_top_up_success": "Matagumpay na na-top up ang prepaid card",
    "Add_card_success": "Matagumpay na naidagdag ang card!",
    "Add_customer_success": "Matagumpay na nalikha ang bagong customer",
    "Update_customer_success": "Matagumpay na na-update ang customer",
    "Deleted_customer_success": "Matagumpay na natanggal ang customer",
    "Create_customer_fail": "Nabigong lumikha ng customer",
    "Update_customer_fail": "Nabigong i-update ang customer",
    "ERROR_EXISTED_PHONE": "Ang teleponong ito ay umiiral na sa system. Mangyaring maglagay ng isa pa",
    "ERROR_INPUT_FIRSTNAME": "Mangyaring ipasok ang unang pangalan.",
    "ERROR_INPUT_PHONE": "Mangyaring ipasok ang numero ng telepono.",
    "ERROR_INPUT_VALIDPHONE": "Mangyaring magpasok ng wastong numero ng telepono.",
    "ERROR_INPUT_VALIDEMAIL": "Mangyaring magpasok ng wastong email address.",
    "CONFIRM_DELETE_CARD": "Sigurado ka bang gusto mong tanggalin ang mga card na ito?",
    "First_name": "Pangalan",
    "Last_name": "Huling pangalan",
    "Phone_number": "Numero ng telepono",
    "Email_address": "Email address",
    "Notes": "Mga Tala",
    "Account": "Account",
    "Card_management": "Pamamahala ng card",
    "Home": "Bahay",
    "Affiliate": "Kaakibat",
    "SHOW_PERSONAL_CARD": "Ipakita ang personal na card",
    "ADD_PERSONAL_CARD": "Magdagdag ng personal na card",
    "SHOW_mDISPATCHER_CARD": "Ipakita ang card ng kasosyo",
    "ADD_mDISPATCHER_CARD": "Magdagdag ng partner card",
    "Personal_card": "Personal na card",
    "mDispatcher_card": "Card ng kasosyo",
    "Card_holder": "May hawak ng card",
    "Card_number": "Numero ng card",
    "Credit_type": "Uri ng kredito",
    "Credit_code": "Credit code",
    "Sms_verification": "SMS verification",
    "Sms_verification_placeholder": "ipasok ang verification code",
    "Expired_date": "Expired date",
    "CVV": "CVV",
    "Address": "Address",
    "Country": "Bansa",
    "City": "lungsod",
    "State": "Estado",
    "Zip_code": "Zip code",
    "postal_code": "Postal code",
    "Required_fields": "Mga kailangang punan",
    "Cancel": "Kanselahin",
    "Save": "I-save",
    "ERROR_INPUT_CARD_HOLDER": "Paki-input ang may hawak ng card",
    "ERROR_INPUT_CARD_NUMBER": "Mangyaring ipasok ang numero ng card",
    "ERROR_INPUT_EXPIRED_DATE": "Mangyaring ipasok ang petsa ng pag-expire",
    "ERROR_VAILD_EXPIRED_DATE": "Ang petsa ng pag-expire ay hindi wasto",
    "ERROR_INPUT_CVV": "Mangyaring ipasok ang CVV",
    "ERROR_INPUT_ADDRESS": "Mangyaring ipasok ang address",
    "ERROR_INPUT_CITY": "Mangyaring ipasok ang lungsod",
    "ERROR_INPUT_ZIPCODE": "Mangyaring ipasok ang zip code",
    "ERROR_INPUT_COUNTRY": "Mangyaring pumili ng bansa",
    "Info_edit_error": "Error!",
    "Gender": "Kasarian",
    "Select_Gender": "Pumili",
    "Select_Idtype": "Pumili ng uri ng id",
    "Male": "Lalaki",
    "Female": "Babae",
    "Other": "Iba pa",
    "Date_of_birth": "Araw ng kapanganakan",
    "Street_address": "Address ng kalye",
    "Select_Zone": "Piliin ang Zone",
    "ERROR_GET_GATEWAYS": "ERROR!!! Kumuha ng mga Gateway",
    "Require_email": "Mangyaring ipasok ang email bago magdagdag ng bagong card",
    "Require_lastName": "Pakilagay ang apelyido bago magdagdag ng bagong card",
    "Require_phone_number": "Mangyaring ipasok ang numero ng telepono ng manlalakbay bago mag-book gamit ang credit card",
    "IMPORT_CUSTOMER_SUCCESS": "matagumpay na mag-import ng mga customer",
    "No_card": "Walang nakitang card",
    "No_Support": "Kasalukuyang hindi sinusuportahan sa lugar na ito ang pagdaragdag ng bagong card",
    "Card_Management": "Pamamahala ng Card",
    "Emergency_contacts": "Mga contact sa emergency",
    "DETAIL_CUSTOMER": "Mga Detalye ng Customer",
    "Select_Country": "Select country",
    "Customer_ID": "ID# / Pambansang IC#",
    "Credit_balance": "Balanse ng credit",
    "required_email_payment": "Kinakailangan ang email ng customer. Mangyaring i-update ang profile upang magpatuloy.",
    "Yes": "Oo",
    "No": "Hindi",
    "WriteOffDebt": "Isulat ang utang",
    "selectPayment": "Mangyaring pumili ng paraan ng pagbabayad upang isara ang lahat ng booking na may natitirang bayad",
    "writeOffDebtSuccess": "Matagumpay na matanggal ang utang",
    "writeOffDebtNotes": "Tandaan: Ang write-off na aksyon na ito ay dapat lamang kumpirmahin pagkatapos mabayaran nang buo ang lahat ng natitirang halaga.",
    "totalCustomer": "Kabuuang mga customer",
    "Personal_Card": "Personal na card",
    "INFO_ADD_BALANCE_SUCCESS": "Matagumpay na na-update ang balanse ng pasahero",
    "INFO_UPDATE_POINT_SUCCESS": "Matagumpay na na-update ang punto ng pasahero",
    "currency": "Pera",
    "balance": "Balanse",
    "changeBalance": "Baguhin ang balanse",
    "change": "Baguhin",
    "WALLET_DEPOSIT": "Wallet",
    "AccessDenied": "Wala kang pahintulot na i-access ang modyul na ito!",
    "referral_code": "Referral code"
  },
  "importBookings": {
    "no": "Bilang",
    "status": "Katayuan",
    "pickupDate": "Petsa ng Pagkuha",
    "pickup": "Address ng Pickup",
    "dropoff": "Address ng Paghahatidan",
    "passenger": "pasahero",
    "phone": "Numero ng telepono",
    "seats": "Mga upuan",
    "luggages": "Mga bagahe",
    "rideSharing": "Sumakay-share",
    "flightNumber": "Flight No.",
    "notes": "Mga Tala",
    "externalId": 'External ID',
    "operatorNote": "Panloob na mga tala",
    "clientCaseMatter": "Paglalarawan ng biyahe",
    "chargeCode": "Trip code",
    "paymentType": "Pagbabayad",
    "vehicleTypeRequest": "Uri ng kotse",
    "importBookings": "Mag-import ng mga booking",
    "corporation": "Korporasyon",
    "clickOrDropCsvFile": "I-click o drop.csv file",
    "importingFileForReview": "Ini-import ang file para sa pagsusuri...",
    "import": "Angkat",
    "submit": "Ipasa",
    "downloadTemplate": "I-download ang template",
    "learnMore": "Matuto pa",
    "total": "Kabuuan",
    "valid": "Wasto",
    "invalid": "Di-wasto",
    "failed": "Nabigo",
    "processing": "Pinoproseso",
    "success": "Tagumpay",
    "confirm": "Kumpirmahin",
    "confirmMessage": "lilikha ng mga order. Sigurado ka bang gusto mong magpatuloy?",
    "create": "Lumikha",
    "cancel": "Kanselahin",
    "close": "Isara",
    "leavingConfirmMessage": "Ang iyong gawa ay hindi nai-save! Sigurado ka bang gusto mong umalis?",
    "resultMessage": "Matagumpay na nagawa ang (mga) booking ng ${success}, nabigo ang ${failed}.",
    "limitExceededBookings": "Pinakamataas na laki ng pag-import ng file: 100 booking",
    "delivery": {
      "no": "#",
      "pickupDate": "Petsa ng pagkuha",
      "senderName": "Pangalan ng nagpadala",
      "senderPhone": "Telepono ng nagpadala",
      "senderMail": "Email ng nagpadala",
      "senderAddress": "Address ng nagpadala",
      "recipients": "Mga tatanggap",
      "senderDistrictOptional": "Distrito",
      "senderLocationDetails": "Mga detalye ng lokasyon",
      "recipientName": "Pangalan ng makakatanggap",
      "recipientPhone": "Telepono ng tatanggap",
      "recipientAddress": "Address ng tatanggap",
      "recipientDistrictOptional": "Distrito",
      "recipientLocationDetailsOptional": "Mga Detalye ng Lokasyon",
      "packageContent": "Nilalaman ng package",
      "orderNumber": "Order #",
      "instructions": "Mga tagubilin para sa Driver",
      "cod": "Halaga ng Item ng COD",
      "rideShare": "Sumakay-share",
      "paymentType": "Uri ng Pagbabayad",
      "carType": "Uri ng kotse",
      "recipientLocationDetails": "Mga detalye ng lokasyon",
      "rideSharing": "Batch delivery",
      "operatorNote": "Panloob na mga tala"
    }
  },
  "merchant": {
    "INFO_ADD_BALANCE_SUCCESS": "Matagumpay na na-update ang balanse ng merchant",
    "ADD_MERCHANT_USER": "Magdagdag ng merchant account",
    "EDIT_MERCHANT_USER": "I-edit ang merchant account",
    "MERCHANT_USER_DETAILS": "Mga detalye ng merchant account",
    "CASH_WALLET": "Cash wallet",
    "CREDIT_WALLET": "Credit wallet",
    "ADD_MERCHANT": "Magdagdag ng mangangalakal",
    "ACCOUNT_INFO": "Impormasyon ng Account",
    "BILLING_ADDRESS": "Impormasyon sa Bangko",
    "user": {
      "fullName": "Gumagamit ng mangangalakal",
      "Phone_Number": "Numero ng telepono",
      "Email": "Email",
      "storeName": "Mangangalakal",
      "status": "Katayuan",
      "actions": "Aksyon",
      "First_name": "Pangalan",
      "Last_name": "Huling pangalan",
      "Select_store": "Pumili ng merchant",
      "accountHolder": "Pangalan ng may hawak ng account",
      "First_M_Last": "Una M. Huli",
      "accountNumber": "Account number",
      "ibanNumber": "Numero ng IBAN",
      "bankName": "Pangalan ng bangko",
      "IDtype": "Uri ng ID",
      "IDnumber": "ID# / Pambansang IC#",
      "routingNumber": "Ruta/Transit/Numero ng sangay",
      "checkNumber": "Siguraduhing tama ang numero; tingnan ang bilang",
      "ssn": "SSN/NI",
      "sortCode": "Pagbukud-bukurin ang code",
      "transitNumber": "Numero ng transit",
      "institutionNumber": "Numero ng Institusyon",
      "birthDay": "Birthday",
      "address": "Address",
      "city": "lungsod",
      "state": "Estado",
      "postalCode": "Zipcode",
      "beneficiaryIDIC": "ID# / IC# ng benepisyaryo",
      "relationshipOtherName": "Relasyon sa iba?",
      "verificationDocumentFront": "Dokumento sa pagpapatunay (harap)",
      "verificationDocumentBack": "Dokumento sa pagpapatunay (likod)",
      "Verification_document_tooltip": "Mga katanggap-tanggap na form para sa pagkakakilanlan:\n  - Pasaporte\n  - Lisensiya sa pagmamaneho\n  - Resident permit ID\n  - Citizen Card\n  - ID card",
      "additionalDocumentFront": "Karagdagang dokumento (harap)",
      "additionalDocumentBack": "Karagdagang dokumento (likod)",
      "Additional_document_tooltip": "Mga katanggap-tanggap na paraan ng pag-verify ng address:\n  - Lisensiya sa pagmamaneho\n  - Utility bill\n  - Liham na ibinigay ng pamahalaan\n  - Pahayag mula sa isang institusyong pinansyal\n  - Entry ng Electoral Register",
      "please_select": "Please select",
      "commission": "Komisyon",
      "ERROR_INPUT_VALIDPHONE": "Mangyaring magpasok ng wastong numero ng telepono.",
      "ERROR_EXISTED_PHONE": "Ang teleponong ito ay umiiral na sa system. Mangyaring maglagay ng isa pa",
      "Activate": "I-activate",
      "Deactivate": "I-deactivate",
      "ALERT_SELECT_ACTIVATE": "Mangyaring pumili ng user na ia-activate.",
      "ALERT_SELECT_DEACTIVATE": "Mangyaring pumili ng user na ide-deactivate.",
      "CONFIRM_ACTIVATE_MULTI": "Gusto mo bang i-activate ang mga user na ito?",
      "CONFIRM_DEACTIVATE_MULTI": "Gusto mo bang i-deactivate ang mga user na ito?",
      "RESET_PASSWORD": "Gusto mo bang i-reset ang password?",
      "CONFIRM_RESET_PASSWORD": "Gusto mo bang i-reset ang password?",
      "REMOVE_BANK_INFO": "Kumpirmahin ang pag-alis ng impormasyon sa bangko",
      "CONFIRM_REMOVE_BANK_INFO": "Gusto mo bang tanggalin ang impormasyon ng bangko?",
      "note_account_password_default": "Tandaan: Para sa bagong Merchant account, ang default na password ay \"12345678\"",
      "Cash_Balance": "Balanse ng pera",
      "Credit_Balance": "Balanse ng credit"
    },
    "Update_merchant_user_success": "Matagumpay na na-update ang gumagamit ng merchant",
    "Update_merchant_user_fail": "Nabigong i-update ang user ng merchant",
    "Create_merchant_user_success": "Matagumpay na nalikha ang bagong user ng merchant",
    "Create_merchant_user_fail": "Nabigong lumikha ng user ng merchant",
    "Update_merchant_user_status_success": "Matagumpay na na-update ang gumagamit ng merchant",
    "Update_merchant_user_status_fail": "Nabigong i-update ang user ng merchant",
    "Reset_password_merchant_user_success": "Matagumpay na i-reset ang password",
    "Reset_password_merchant_user_fail": "Nabigo ang pag-reset ng password",
    "Deleted_merchant_bank_info_success": "Matagumpay na alisin ang impormasyon ng bangko",
    "Deleted_merchant_bank_info_fail": "Nabigo ang pag-alis ng impormasyon sa bangko"
  },
  "table_header": {
    "Items_per_page": "Mga item bawat pahina",
    "Page": "Pahina",
    "Search_here": "Maghanap dito",
    "Shown_Active": "%{start} - %{end} of %{total} na ipinakita",
    "Active": "%{active} Aktibo",
    "OF": "ng"
  },
  "driver": {
    Driver: 'Driver',
    Add: 'Add',
    addDriver: 'Add driver',
    Detail: 'Detail',
    detailDriver: 'Driver Details',
    addNewVehicle: 'Add Vehicle',
    serviceType: 'Service Type',
    transport: 'Transport',
    delivery: 'Delivery',
    stripeConnect: 'Stripe Connect',
    stripeConnectDes: 'Add a stripe connect account for easier payouts',
    bankAccount: 'Banking Info',
    bankAccountDes: 'Drivers bank info to handle payouts',
    supplierComission: 'Supplier Commission',
    supplierComissionDes: 'Set commissions the supplier earns for every complete order.',
    fleetCommission: 'Fleet/Supplier commission',
    fleetCommissionDes: 'Set commissions the fleet or supplier earns for every complete order. If the driver is a supplier’s driver, the commission will be deducted from the supplier’s earning.',
    operationZones: 'Operation Zones',
    operationZonesDes: 'Zones that suppliers can receive jobs from:',
    notes: 'Notes',
    notesDes: 'Internal notes about this driver:',
    searchNotes: 'Add notes for driver',
    marketplace: 'Marketplace',
    marketplaceDes: 'Empower drivers to accept jobs they want to do.',
    otherSettings: 'Other Settings',
    topDriver: 'TOP Driver',
    topDriverDes: 'Prioritize top performers with the "Top Driver" tag, ensuring they get more rides.',
    walletTransferBetweenDriver: 'Wallet Transfers Between Drivers',
    walletTransferBetweenDriverDes: 'Enable drivers to transfer funds directly to each other’s wallets, facilitating easy distribution of credits by team leaders or ambassadors.',
    rideSharing: 'Ride-sharing',
    rideSharingDes: 'Enable drivers to perform ride-sharing jobs. Contact your account manager for this advance feature.',
    individual_vehicle: 'Individual vehicle',
    company_vehicle: 'Company vehicle',
    ContactInfo: 'Contact Info',
    name: 'Name',
    expiryDate: 'Expiry Date',
    action: 'Action',
    editDocument: 'Edit Document',
    documentToUpload: 'Document to upload',
    addFiles: 'Click or drag file to this area to upload.',
    fileSize: 'Maximum file size 10 MB',
    fileSizeDes: 'The file extension must be .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip',
    removeOldFile: 'Please remove the old file before uploading a new one.',
    maxLengthFiles: 'Please upload max length 1 file',
    maxSizeFiles: 'Please upload file size less than 10MBs',
    typeFiles: 'Please upload file type .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip',
    maxAvatarSizeFiles: 'Please upload avatar size less than 8MBs',
    
    "IFSCCode": "IFSC Code",
    "Drivers": "Mga driver",
    "Add": "Idagdag",
    "default": "Default",
    "customize": "Customized",
    "Activate": "I-activate",
    "Stripe_connect": "STRIPE CONNECT",
    "status_stripe": "Katayuan: {0}",
    "stripeConnectStatus": "Stripe connect",
    "stripeStatus": "Stripe status ng account",
    "Deactivate": "I-deactivate",
    "Delete": "Tanggalin",
    "acceptInDay": "Maaaring tanggapin ng driver ang maximum na reservation sa isang araw",
    "acceptATime": "Kabuuang bilang ng mga reservation na maaaring tanggapin ng isang driver sa isang pagkakataon",
    "Commission": "Itakda ang komisyon ng fleet",
    "Title_Commission": "Itakda ang komisyon ng fleet para sa lahat ng mga driver",
    "Confirm_Set_Commission": "Sigurado ka bang gusto mong itakda ang halaga ng komisyon na ito para sa lahat ng driver?",
    "Commission_Successfully": "Matagumpay na na-update ang komisyon ng driver",
    "Apply": "Mag-apply",
    "Type": "Uri",
    "Currency": "Pera",
    "View_Message": "Mensahe",
    "Send_Message": "Magpadala ng Mensahe",
    "Driver_Name": "Pangalan ng driver",
    "Driver_ID": "ID# / Pambansang IC#",
    "Phone_Number": "Numero ng telepono",
    "Driver_Type": "Uri ng Driver",
    "TOP": "TOP",
    "Company": "kumpanya",
    "City": "lungsod",
    "Driver_License_Number": "Numero ng Lisensya sa Pagmamaneho",
    "License_Plate": "Biển số xe",
    "Car_Type": "Uri ng kotse",
    "Zone": "Sona",
    "Registered_From": "Nakarehistro Mula sa",
    "Registration_Date": "Petsa ng Pagpaparehistro",
    "IOS_Last_Login_Version": "IOS Last-Login Version",
    "Android_Last_Login_Version": "Bersyon ng Huling Pag-login ng Android",
    "Last_Active_Date": "Huling Aktibo na Petsa",
    "Days_Since_Last_Active": "Mga Araw Mula Noong Huling Aktibo",
    "Completed_bookings": "Nakumpleto ang mga booking",
    "Status": "Katayuan",
    "Actions": "Mga aksyon",
    "Action": "Aksyon",
    "Inactive": "Hindi aktibo",
    "Active": "Aktibo",
    "Edit": "I-edit",
    "View": "Tingnan",
    "ALERT_SELECT_ACTIVATE": "Mangyaring piliin ang driver upang i-activate.",
    "ALERT_SELECT_DEACTIVATE": "Mangyaring piliin ang driver upang i-deactivate.",
    "ALERT_SELECT_DELETE": "Mangyaring piliin ang driver na tatanggalin.",
    "CONFIRM_DELETE": "Gusto mo bang tanggalin ang driver na ito?",
    "CONFIRM_DEACTIVATE_MULTI": "Sigurado ka bang gusto mong i-deactivate ang (mga) napiling driver? Idi-disable din ang kanilang (mga) konektadong hardware meter. Tanging ang (mga) driver na walang kasalukuyang biyahe o reserbasyon ang maaaring ma-deactivate.",
    "CONFIRM_ACTIVATE_MULTI": "Gusto mo bang i-activate ang mga driver na ito? Tanging ang (mga) driver na ganap na naka-setup para sa operasyon ang maaaring i-activate.",
    "CONFIRM_DELETE_MULTI": "Gusto mo bang tanggalin ang mga driver na ito? Tanging driver lamang sa hindi aktibong katayuan ang maaaring tanggalin.",
    "CONFIRM_REMOVE_BANKING_INFOR": "Sigurado ka bang gusto mong alisin ang impormasyon sa pagbabangko?",
    "REMOVE_BANKING_INFOR": "Alisin ang impormasyon sa pagbabangko",
    "ADD_DRIVER": "ADD DRIVER",
    "Avatar": "Avatar",
    "Individual_Driver": "Transportasyon - Sasakyan ng kumpanya",
    "Owner_Operator": "Transportasyon - Indibidwal na sasakyan",
    "deliveryCompany": "Paghahatid - Sasakyan ng kumpanya",
    "deliveryIndividual": "Paghahatid - Indibidwal na sasakyan",
    "CONTACT_INFO": "CONTACT INFO",
    "Home_address": "Address ng bahay",
    "Username": "Username",
    "First_name": "Pangalan",
    "Last_name": "Huling pangalan",
    "Phone_number": "Numero ng telepono",
    "Email_address": "Email address",
    "Select_city": "Pumili ng lungsod",
    "State": "Estado",
    "Zip_code": "Zip code",
    "Date_of_birth": "Araw ng kapanganakan",
    "placeholderDate": "mm/dd/yyyy",
    "City_driver_want_to_drive": "Gustong magmaneho ng city driver",
    "Driver_license_number": "Numero ng lisensya sa pagmamaneho",
    "Driver_license_state": "Estado/Probinsya ng lisensya sa pagmamaneho",
    "Driver_license_expiry": "Pag-expire ng lisensya sa pagmamaneho",
    "FOR_INSPECTOR": "PARA SA INSPECTOR",
    "Document": "Dokumento",
    "Document_Id": "ID ng Dokumento",
    "Link": "Link",
    "Notes": "Mga Tala",
    "UPLOAD_DOCUMENT": "MAG-UPLOAD NG DOKUMENTO",
    "Click_here_to_download": "I-click upang i-download",
    "Click_here_to_view": "I-click upang tingnan",
    "EXTENSION_REQUIREMENT": "Ang extension ng file ay dapat na .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip at mas mababa sa 5Mb.",
    "Invalid_file": "Ang nilalaman ng file ay hindi wasto",
    "File_size_larger_than_5Mb": "Ang laki ng file ay dapat na mas malaki kaysa sa 5Mb",
    "BANK_ACCOUNT": "BANK ACCOUNT",
    "Name_of_bank": "Pangalan ng bangko",
    "Name_of_account": "Pangalan ng may hawak ng account",
    "First_M_Last": "Una M. Huli",
    "RTBnumber": "Ruta/Transit/Numero ng sangay",
    "bankName": "Pangalan ng bangko",
    "Select_a_bank": "Pumili ng bangko",
    "Account_number": "Account number",
    "IBAN_number": "Numero ng IBAN",
    "Check_number": "Check number",
    "Swift_code_number": "Swift code number",
    "SSNNI": "SSN/ NI",
    "sortCode": "Pagbukud-bukurin ang code",
    "Verification_document": "Dokumento sa pagpapatunay (harap)",
    "Additional_document": "Karagdagang dokumento (harap)",
    "Verification_document_back": "Dokumento sa pagpapatunay (likod)",
    "Additional_document_back": "Karagdagang dokumento (likod)",
    "others": "Iba",
    "signedW9Form": "Nilagdaan W-9 Form",
    "driverLicense": "Lisensiya sa pagmamaneho",
    "Verification_document_tooltip": "Mga katanggap-tanggap na form para sa pagkakakilanlan:\n  - Pasaporte\n  - Lisensiya sa pagmamaneho\n  - Resident permit ID\n  - Citizen Card\n  - ID card",
    "Additional_document_tootip": "Mga katanggap-tanggap na paraan ng pag-verify ng address:\n  - Lisensiya sa pagmamaneho\n  - Utility bill\n  - Liham na ibinigay ng pamahalaan\n  - Pahayag mula sa isang institusyong pinansyal\n  - Entry ng Electoral Register",
    "isBankAccountOwner": "Is this your account?",
    "yes": "Oo",
    "no": "Hindi",
    "beneficiary_name": "Beneficiary Name",
    "beneficiary_idic": "ID/IC ng benepisyaryo",
    "relationship": "Relasyon",
    "select_relationship": "Pumili ng status ng relasyon",
    "spouse": "asawa",
    "daughterOrSon": "Anak na babae anak na lalaki",
    "brotherOrSister": "Kuya/Ate",
    "relative": "Kamag-anak",
    "friends": "Mga kaibigan",
    "other": "Iba pa",
    "relationshipOtherName": "Relasyon sa iba?",
    "OPERATION": "OPERASYON",
    "CAR_INSURANCE": "CAR INSURANCE",
    "Policy_number": "Numero ng patakaran",
    "Effective_date": "Effective date",
    "Expired_date": "Expired date",
    "Select_company": "Pumili ng kumpanya",
    "All_company": "Lahat ng kumpanya",
    "All_Car_Type": "Lahat ng uri ng kotse",
    "Car": "kotse",
    "Existing_car": "Umiiral na sasakyan",
    "New_car": "Bagong sasakyan",
    "License_plate": "Biển số xe",
    "Select_license_plate": "Chọn Biển số xe",
    "Select_Car_Type": "Piliin ang Uri ng Kotse",
    "vehicleOwner": "Pangalan ng may-ari ng sasakyan",
    "Make": "Gawin",
    "Select_Car_Make": "Piliin ang Car Make",
    "Model": "Modelo",
    "Select_Car_Model": "Piliin ang Modelo ng Sasakyan",
    "Year": "taon",
    "Select_Year": "Piliin ang Taon",
    "Color": "Kulay",
    "Passengers": "pasahero",
    "Luggage": "Passengers",
    "License_expiry": "Pag-expire ng lisensya",
    "Shift": "Paglipat",
    "Select_Shift_Template": "Piliin ang Shift Template",
    "Fleet_commission": "Komisyon ng fleet (ulat)",
    "Company_commission": "Komisyon ng kumpanya (ulat)",
    "transport": "Transportasyon",
    "rideHailing": "Street Hailing",
    "intercity": "Intercity",
    "food": "Pagkain",
    "mart": "Mart",
    "streetSharing": "Pagbabahagi ng Kalye",
    "Shuttle": "Shuttle",
    "parcel": "Parcel",
    "driverInsurance": "Insurance sa Pagmamaneho",
    "Percentage": "Porsiyento",
    "Amount": "Halaga",
    "transaction": "Transaksyon",
    "Terminal_ID": "Terminal ID",
    "Auth_key": "Susi ng pagpapatotoo",
    "CREDIT_DEPOSIT": "Credit wallet",
    "Credit_Balance": "Balanse ng Credit",
    "Balance": "Balanse",
    "Change_balance": "Baguhin ang balanse",
    "Select_balance": "Pumili ng balanse",
    "Adjust_amount": "Ayusin ang halaga",
    "Adjust_value": "Ayusin ang halaga",
    "New_balance": "Bagong balanse",
    "New_point": "Bagong punto",
    "Enter_reason": "Ipasok ang dahilan",
    "ERROR_INPUT_BALANCE": "Mangyaring pumili ng balanse.",
    "ERROR_INPUT_AMOUNT": "Mangyaring ilagay ang halagang ito.",
    "ERROR_INPUT_REASON": "Pakilagay ang dahilan.",
    "ERROR_INPUT_VALID_AMOUNT": "Mangyaring magpasok ng halagang mas malaki sa o katumbas ng 0.",
    "INFO_ADD_BALANCE_SUCCESS": "Matagumpay na na-update ang balanse ng driver",
    "INFO_ADD_CASH_BALANCE_SUCCESS": "Matagumpay na na-update ang balanse ng cash ng driver",
    "Force_meter": "Force meter",
    "Force_meter_tooltip": "Dapat na nakakonekta ang driver app sa metro bago gumana. Inilapat para sa mga metro ng Pulsar at Centrodyne",
    "ERROR_EXISTED_PHONE": "Ang teleponong ito ay umiiral na sa system. Mangyaring maglagay ng isa pa",
    "TOP_driver": "TOP driver",
    "Ride_sharing": "Pagbabahagi ng Sakay",
    "Note_password": "Tandaan: Para sa bagong driver account, ang default na password ay \"12345678\"",
    "Required_fields": "Mga kailangang punan",
    "Cancel": "Kanselahin",
    "Save": "I-save",
    "SaveAndInProgress": "I-save at Isinasagawa",
    "SaveAndDeactivate": "I-save at I-deactivate",
    "ERROR_REQUIRE_": "Please input",
    "ERROR_INPUT_AVATAR": "Mangyaring mag-upload ng avatar.",
    "ERROR_INPUT_DRIVER_TYPE": "Mangyaring piliin ang uri ng driver.",
    "ERROR_INPUT_DOCUMENT": "Please upload",
    "ERROR_INPUT_USERNAME": "Mangyaring ipasok ang username.",
    "ERROR_INPUT_VALID_USERNAME": "Ang username ay maaari lamang maglaman ng mas mababang mga titik at numero at dapat ay nasa pagitan ng 3 at 20 character ang haba",
    "ERROR_INPUT_PHONE": "Mangyaring tumukoy ng wastong numero ng telepono.",
    "ERROR_INPUT_VALID_EMAIL": "Please enter valid email.",
    "ERROR_INPUT_EMAIL": "Mangyaring mag-input ng email.",
    "ERROR_INPUT_FIRSTNAME": "Mangyaring ipasok ang unang pangalan.",
    "ERROR_INPUT_LASTNAME": "Mangyaring ipasok ang apelyido.",
    "ERROR_INPUT_ADDRESS": "Mangyaring ipasok ang address ng tahanan.",
    "ERROR_INPUT_CITY": "Mangyaring ipasok ang lungsod.",
    "ERROR_INPUT_STATE": "Mangyaring ilagay ang estado.",
    "ERROR_INPUT_ZIPCODE": "Mangyaring ipasok ang zipcode.",
    "ERROR_INPUT_BIRTHDAY": "Mangyaring ipasok ang kaarawan.",
    "ERROR_INPUT_NAME_OF_BANK": "Mangyaring ipasok ang pangalan ng bangko.",
    "ERROR_INPUT_NAME_OF_ACCOUNT": "Paki-input ang pangalan ng may-ari ng account.",
    "ERROR_INPUT_ROUTING_NUMBER": "Paki-input ang routing number.",
    "ERROR_INPUT_BANK_NAME": "Mangyaring pumili ng isang bangko.",
    "ERROR_INPUT_ACCOUNT_NUMBER": "Mangyaring maglagay ng account number",
    "ERROR_INPUT_CHECK_NUMBER": "Mangyaring ilagay ang check number.",
    "ERROR_INPUT_SSN": "Mangyaring ipasok ang SSN.",
    "ERROR_INPUT_SORTCODE": "Mangyaring ilagay ang sort code.",
    "ERROR_INPUT_COMPANY": "Mangyaring pumili ng kumpanyang isaaktibo.",
    "ERROR_INPUT_LICENSE_PLATE": "Mangyaring pumili ng plaka ng lisensya.",
    "ERROR_INPUT_ZONE": "Mangyaring piliin ang zone upang isaaktibo.",
    "ERROR_INPUT_SHIFT": "Mangyaring piliin ang shift para isaaktibo.",
    "ERROR_ACCOUNT_NUMBER_ONLY_NUMBERS": "Dapat itong maglaman lamang ng mga numero",
    "ERROR_SSN_ONLY_NUMBERS": "Dapat itong maglaman lamang ng mga numero",
    "ERROR_SSN_LENGTH": "Dapat na hindi bababa sa 4 na character ang SSN",
    "ERROR_COMMISSION_NUMBERS": "Mangyaring magpasok ng wastong numero",
    "INFO_DEACTIVAED_SUCCESS": "Matagumpay na na-deactivate ang driver.",
    "INFO_ACTIVAED_SUCCESS": "Matagumpay na na-activate ang driver.",
    "INFO_DEACTIVAED_SUCCESS_BUT_HARDWARE": "Ang driver ay na-deactivate ngunit ang integrated hardware meter ay hindi maaaring patayin para sa ilang mga hindi inaasahang dahilan.",
    "INFO_MULTIDEACTIVAED_SUCCESS": "Ang {0} napiling mga driver ay matagumpay na na-deactivate.",
    "INFO_MULTIDEACTIVAED_HARDWARE_SUCCESS": "Ang {0} napiling mga driver ay matagumpay na na-deactivate. {1} (mga) konektadong hardware meter ay hindi pinagana.",
    "INFO_MULTIDEACTIVAED_SUCCESS_BUT_HARDWARE": "Ang {0} napiling mga driver ay matagumpay na na-deactivate. {1} (mga) konektadong hardware meter ay hindi pinagana. Ang {2} ay hindi dahil sa ilang hindi inaasahang dahilan.",
    "INFO_MULTIACTIVAED_SUCCESS": "Ang {0} napiling mga driver ay matagumpay na na-activate.",
    "DRIVER_UPDATE_SUCCESS": "Matagumpay na na-update ang driver.",
    "DRIVER_CREATE_SUCCESS": "Ang impormasyon ng driver ay matagumpay na naidagdag",
    "Expiry_Date": "Petsa ng Pag-expire",
    "Case_number": "Numero ng kaso",
    "Export_to_csv": "I-export sa CSV",
    "CONFIRM_EXPORT_TO_CSV": "Sigurado ka bang gusto mong i-export ang lahat ng data sa ilalim ng .csv na format?",
    "Ride_sharing_tooltip": "Ang pagbabahagi ng pagsakay ay isang advanced na tampok, mangyaring makipag-ugnay sa aming Koponan ng suporta upang makakuha ng higit pang impormasyon",
    "In_Review": "Sa Pagsusuri",
    "Reset_password_success": "Matagumpay na i-reset ang password",
    "Topup_note": "(Pinapayagan na i-top up ang balanse ng iyong driver pagkatapos i-activate ang driver)",
    "cash_balance": "Balanse ng pera",
    "cash_wallet": "Cash wallet",
    "total_balance": "Kabuuang balanse",
    "total_cash_balance": "Kabuuang balanse ng cash",
    "total_credit_balance": "Kabuuang balanse ng kredito",
    "banking_status": "Katayuan sa pagbabangko",
    "bankingStatus": {
      "verified": "Na-verify",
      "not_verified": "Hindi napatunayan"
    },
    "INVALID_FILE_SIZE": "Ang laki ng file ay dapat na mas mababa sa 8Mb.",
    "totalDrivers": "Kabuuang Driver",
    "profileStatus": "Katayuan ng profile",
    "profile_completed": "Nakumpleto ang profile",
    "profile_incomplete": "Hindi kumpleto ang profile",
    "profile_inProgress": "Kasalukuyang ginagawa ang profile",
    "in_review_completed": "Sinusuri (nakumpleto ang profile)",
    "in_review_incomplete": "Sinusuri (hindi kumpleto ang profile)",
    "in_review_inProgress": "Nasa pagsusuri (profile in progress)",
    "CASH_WALLET_DEPOSIT": "Cash wallet",
    "payoutSelect": "Payout (isang inbox at email ang ipapadala sa driver pagkatapos ng payout)",
    "Confirm_update_cash_balance": "Baguhin ang Balanse ng Cash",
    "Confirm_update_cash_balance_msg": "Sigurado ka bang gusto mong baguhin ang balanse ng driver?",
    "cashBalance": "Balanse ng pera",
    "cashBalance_unavailable": "0.00",
    "cashBalance_active": "Aktibo",
    "cashBalance_negative": "Negatibo",
    "creditBalance": "Balanse ng credit",
    "ERROR_INPUT_DOCUMETID": "Mangyaring ipasok ang Document Id.",
    "ERROR_INPUT_EXPIRYDATE": "Pakilagay ang Petsa ng Pag-expire.",
    "driverType": "Uri ng Driver",
    "tooltip_in_review_completed": "Ipadala ang inbox sa mga driver na 'In review' na kumukumpleto sa kanilang profile.",
    "tooltip_in_review_incomplete": "Ipadala ang inbox sa mga driver na 'In review' na hindi pa nakumpleto ang kanilang profile.",
    "tooltip_in_review_inProgress": "Magpadala ng inbox sa mga driver na 'In review' na kumukumpleto ng kanilang profile ngunit nangangailangan ng karagdagang pag-verify.",
    "tooltip_active": "Ipadala ang inbox sa mga driver na 'Aktibo'.",
    "tooltip_inactive": "Magpadala ng inbox sa mga driver na muling na-activate ang account.",
    "active": "I-activate",
    "inactive": "I-deactivate",
    "inReviewInProgress": "Sa Pagsusuri",
    "Market_place": "Marketplace",
    "Market_place_tooltip": "Bigyan ng kapangyarihan ang mga driver na pumili ng on-demand o mga pagpapareserba na tatanggapin",
    "selectDriverType": "Piliin ang uri ng driver",
    "PlsSelectDriverType": "Mangyaring piliin ang uri ng driver",
    "ERROR_INPUT_DocumentId": "Mangyaring ipasok ang Document ID.",
    Warning_change_company: 'Warning: Reassigning this driver to another company will unlink all associated vehicles.',
  },
  "corporate": {
    "User_Name": "User Name",
    "Edit_Balance": "I-edit ang Balanse",
    "Corporates": "Mga kumpanya",
    "Supplier": "Supplier",
    "Add": "Idagdag",
    "Balance": "Balanse",
    "Activate": "I-activate",
    "Deactivate": "I-deactivate",
    "Delete": "Tanggalin",
    "Company_Name": "Company Name",
    "Admin_Name": "Pangalan ng Admin",
    "Username": "Username",
    "Phone_Number": "Numero ng telepono",
    "importBooking": "May kakayahang mag-import ng mga booking",
    "Email": "Email",
    "Registered_From": "Nakarehistro Mula sa",
    "Registration_Date": "Petsa ng Pagpaparehistro",
    "Status": "Katayuan",
    "Actions": "Mga aksyon",
    "Action": "Aksyon",
    "Inactive": "Hindi aktibo",
    "Active": "Aktibo",
    "In_Review": "Sa Pagsusuri",
    "Edit": "I-edit",
    "View": "Tingnan",
    "ADD_CORPORATE_CARD": "Magdagdag ng corporate card",
    "selectCountry": "Select country",
    "CONFIRM_DELETE_CARD": "Sigurado ka bang gusto mong tanggalin ang mga card na ito?",
    "ALERT_SELECT_ACTIVATE": "Mangyaring pumili ng corporate na isaaktibo.",
    "ALERT_SELECT_DEACTIVATE": "Mangyaring pumili ng korporasyon upang i-deactivate.",
    "ALERT_SELECT_DELETE": "Mangyaring pumili ng kumpanyang tatanggalin.",
    "CONFIRM_DELETE": "Gusto mo bang tanggalin ang kumpanyang ito?",
    "CONFIRM_DELETE_USER": "Gusto mo bang tanggalin ang corp user na ito?",
    "CONFIRM_DEACTIVATE_MULTI": "Gusto mo bang i-deactivate ang mga korporasyong ito?",
    "CONFIRM_ACTIVATE_MULTI": "Gusto mo bang i-activate ang mga corporate na ito?",
    "CONFIRM_DELETE_MULTI": "Gusto mo bang tanggalin ang mga korporasyong ito?\n  ** Tanging mga corporate na hindi aktibo ang maaaring tanggalin.",
    "ERROR_INPUT_PHONE": "Mangyaring tumukoy ng wastong numero ng telepono.",
    "ERROR_INPUT_VALIDPHONE": "Mangyaring magpasok ng wastong numero ng telepono.",
    "ERROR_EXISTED_PHONE": "Ang teleponong ito ay umiiral na sa system. Mangyaring maglagay ng isa pa",
    "Account": "Account",
    "Corp_traveler": "Mga Gumagamit ng Corp",
    "Payment_methods": "Mga paraan ng pagbabayad",
    "Corp_operators": "Mga operator ng korporasyon",
    "Pricing": "Pagpepresyo",
    "COMPANY_INFORMATION": "Impormasyon ng kumpanya",
    "Company_name": "Company name",
    "Company_ID": "Company ID (para sa pag-signup ng Corp Users)",
    "Company_address": "Address ng kumpanya",
    "Invoice_email": "Email ng invoice",
    "Confirmation_email": "Email ng kumpirmasyon",
    "colorCodeBooking": "Code ng kulay",
    "ADMIN_ACCOUNT": "Admin account",
    "First_name": "Pangalan",
    "Last_name": "Huling pangalan",
    "Commission": "Komisyon",
    "commissionBooking": "Mag-apply ng komisyon para sa mga booking mula sa app/pwa",
    "Required_fields": "Mga kailangang punan",
    "Save": "I-save",
    "Cancel": "Kanselahin",
    "Reset_password": "Reset password",
    "Employee_Name": "Pangalan",
    "Created_Date": "Petsa ng Paggawa",
    "Add_corporate_card": "Magdagdag ng corporate card",
    "Corp_Users": "Mga Gumagamit ng Corp",
    "Pricing_Default": "Default (parehong plano sa pagpepresyo gaya ng karaniwang pax)",
    "Pricing_Discount": "Diskwento ayon sa porsyento (inilapat lamang para sa Mga Gumagamit ng Corp)",
    "Markup_By_Percent": "Mark-up ayon sa porsyento (inilapat para sa Mga User ng Corp lamang)",
    "Pricing_Add_different_rates": "Magdagdag ng iba't ibang mga rate",
    "ERROR_INPUT_COMPANYNAME": "Pakilagay ang pangalan ng kumpanya.",
    "ERROR_INPUT_USERNAME": "Mangyaring ipasok ang username.",
    "ERROR_INPUT_FIRSTNAME": "Mangyaring ipasok ang unang pangalan.",
    "ERROR_INPUT_EMAIL": "Mangyaring mag-input ng email.",
    "ERROR_INPUT_VALID_EMAIL": "Mangyaring magpasok ng wastong email address.",
    "ERROR_INPUT_VALID_NUMBER": "Mangyaring magpasok ng wastong numero",
    "ERROR_INPUT_VALID_RANGE_AMOUNT": "Mangyaring magpasok ng halaga sa pagitan ng {0} at {1}.",
    "INFO_ADD_CORPORATE_SUCCESS": "Matagumpay na nalikha ang account na {0}. Pakisuri ang email {1} ​​para sa mga detalye ng kredensyal sa pag-log in.",
    "INFO_UPDATE_CORPORATE_SUCCESS": "Matagumpay na na-update ang impormasyon.",
    "INFO_UPDATE_PRICING_SUCCESS": "Matagumpay na na-update ang pagpepresyo",
    "INFO_RESET_PASSWORD_SUCCESS": "Matagumpay na na-reset ang account na {0}. Pakisuri ang email {1} ​​para sa mga detalye ng kredensyal sa pag-log in.",
    "COMFIRM_RESET_PASSWORD": "Gusto mo bang i-reset ang password?",
    "Traveler_signature": "Lagda ng manlalakbay",
    "Parcel_Delivery": "Paganahin ang paghahatid ng parsela",
    "Tracking_log": "Log ng pagsubaybay",
    "Rating": "Marka",
    "Email_address": "Email address",
    "Assistant_email": "Marka",
    "Corp_PO": "Corp PO",
    "Cost_centre": "Sentro ng gastos",
    "Corp_division": "Dibisyon ng korporasyon",
    "Corp_id": "Corp id",
    "Department": "Kagawaran",
    "Manager_email": "Email ng manager",
    "Manager_name": "Pangalan ng manager",
    "Corporate_card": "Corporate card",
    "Direct_invoicing": "Direktang pag-invoice",
    "Corporate_Prepaid": "Corporate Prepaid",
    "Cash": "Cash",
    "Car_Type_ID": "ID ng Uri ng Kotse",
    "App_Display_Name": "Display Name ng App",
    "Normal_Fare": "Regular",
    "Flat_Fare": "Pantay na Rate",
    "Hourly_Daily_Rate": "Oras-oras/ Araw-araw",
    "Delivery_Rate": "Paghahatid",
    "Assign_rate": "Magtalaga ng rate",
    "Zone": "Sona",
    "Rate": "Rate",
    "Zone_Rate": "Sona - Rate",
    "Please_select_rate": "Mangyaring pumili ng rate",
    "Paid_by": "Binayaran ni",
    "Enter_new_credit_card": "Maglagay ng bagong credit card",
    "TOP_UP": "TOP UP",
    "Top_up": "Top up",
    "Topup_amount": "Dami ng topup",
    "Currency": "Pera",
    "ERROR_INPUT_AMOUNT": "Mangyaring maglagay ng wastong halaga.",
    "VAT_number": "Numero ng VAT",
    "Gender": "Kasarian",
    "Select_Gender": "Pumili",
    "Male": "Lalaki",
    "Female": "Babae",
    "Date_of_birth": "Araw ng kapanganakan",
    "Street_address": "Address ng kalye",
    "City": "lungsod",
    "State": "Estado",
    "Zip_code": "Zip code",
    "Country": "Bansa",
    "adding_new_card": "Nagdaragdag ka ng bagong card para magamit ang serbisyo sa %{zoneName} area",
    "No_Support": "Kasalukuyang hindi sinusuportahan sa lugar na ito ang pagdaragdag ng bagong card",
    "Job_title": "Titulo sa trabaho",
    "required_phone_number_payment": "Kinakailangan ang numero ng telepono ng kumpanya. Mangyaring i-update ang profile upang magpatuloy.",
    "Yes": "Oo",
    "No": "Hindi",
    "AddCardBeforeTopup": "Walang nakitang credit card. Mangyaring magdagdag ng bagong card upang magpatuloy.",
    "flightNumber": "Numero ng flight",
    "Company_Type": "Uri ng kumpanya",
    "Standard": "Karaniwang korporasyon",
    "Airline": "Airline",
    "Branding": "Pagba-brand",
    "bgImage": "Larawan sa background (1600x900)",
    "logoImage": "Imahe ng Logo",
    "aliasTitle": "Parameter ng alias (URL).",
    "CarTypes": "Mga Uri ng Sasakyan",
    "importChauffeurBooking": "May kakayahang mag-import ng mga booking sa transportasyon",
    isCorporateVip: 'Corporate VIP',
    isCorporateVipToolTip: 'Enable this setting to automatically assign VIP status to all corporate travelers upon creation, you may turn it off for each individual user.',
    qrTitle: 'QR Web booking link',
    qrNote: 'Your personalized partner booking link is here! Display it prominently in bars, hotel receptions, guest rooms, and on your website—anywhere your customers might need a ride or delivery. Consider designing standees with your unique QR code, allowing customers a quick and easy scan to book their journey!'
  },
  "airline": {
    "Airlines": "Mga airline",
    "Add": "Idagdag",
    "Activate": "I-activate",
    "Deactivate": "I-deactivate",
    "Delete": "Tanggalin",
    "Company_Name": "pangalan ng Kumpanya",
    "Admin_Name": "Pangalan ng Admin",
    "Username": "Username",
    "Phone_Number": "Numero ng telepono",
    "Email": "Email",
    "Registered_From": "Nakarehistro Mula sa",
    "Registration_Date": "Petsa ng Pagpaparehistro",
    "Status": "Katayuan",
    "Actions": "Mga aksyon",
    "Action": "Aksyon",
    "Inactive": "Hindi aktibo",
    "Active": "Aktibo",
    "In_Review": "Sa Pagsusuri",
    "Edit": "I-edit",
    "View": "Tingnan",
    "ADD_CORPORATE_CARD": "Magdagdag ng Airline card",
    "selectCountry": "Select country",
    "CONFIRM_DELETE_CARD": "Sigurado ka bang gusto mong tanggalin ang mga card na ito?",
    "ALERT_SELECT_ACTIVATE": "Mangyaring piliin ang Airline para i-activate.",
    "ALERT_SELECT_DEACTIVATE": "Mangyaring piliin ang Airline upang i-deactivate.",
    "ALERT_SELECT_DELETE": "Mangyaring piliin ang Airline na tatanggalin.",
    "CONFIRM_DELETE": "Gusto mo bang tanggalin ang Airline na ito?",
    "CONFIRM_DELETE_USER": "Gusto mo bang tanggalin ang user ng Airline na ito?",
    "CONFIRM_DEACTIVATE_MULTI": "Sigurado ka bang gusto mong i-deactivate ang kumpanyang ito?",
    "CONFIRM_ACTIVATE_MULTI": "Sigurado ka bang gusto mong gawing aktibo ang kumpanyang ito?",
    "CONFIRM_DELETE_MULTI": "Sigurado ka bang gusto mong tanggalin ang kumpanyang ito?\n  ** Tanging kumpanya na hindi aktibo ang maaaring tanggalin.",
    "ERROR_INPUT_PHONE": "Mangyaring tumukoy ng wastong numero ng telepono.",
    "ERROR_INPUT_VALIDPHONE": "Mangyaring magpasok ng wastong numero ng telepono.",
    "ERROR_EXISTED_PHONE": "Ang teleponong ito ay umiiral na sa system. Mangyaring maglagay ng isa pa",
    "Airline": "Airline",
    "Crew": "Crew",
    "Payment_methods": "Mga paraan ng pagbabayad",
    "Airline_operators": "Mga operator ng airline",
    "Pricing": "Pagpepresyo",
    "COMPANY_INFORMATION": "Impormasyon ng kumpanya",
    "Company_name": "Pangalan ng Kumpanya",
    "Company_ID": "ID ng kumpanya",
    "Company_address": "Address ng kumpanya",
    "Invoice_email": "Email ng invoice",
    "Confirmation_email": "Email ng kumpirmasyon",
    "colorCodeBooking": "Code ng kulay",
    "ADMIN_ACCOUNT": "Admin account",
    "First_name": "Pangalan",
    "Last_name": "Huling pangalan",
    "Commission": "Komisyon",
    "Required_fields": "Mga kailangang punan",
    "Required_fields_crews": "Tandaan: Para sa bagong Crew account, ang default na password ay \"12345678\"",
    "Save": "I-save",
    "Cancel": "Kanselahin",
    "Reset_password": "Reset password",
    "Crew_Name": "Crew",
    "Created_Date": "Petsa ng Paggawa",
    "Add_corporate_card": "Magdagdag ng airline card",
    "Corp_Users": "Mga operator",
    "Pricing_Default": "Default",
    "Pricing_Discount": "Diskwento ayon sa porsyento",
    "Markup_By_Percent": "Mark-up ayon sa porsyento",
    "Pricing_Add_different_rates": "Magdagdag ng iba't ibang mga rate",
    "ERROR_INPUT_COMPANYNAME": "Pakilagay ang pangalan ng kumpanya.",
    "ERROR_INPUT_USERNAME": "Mangyaring ipasok ang username.",
    "ERROR_INPUT_FIRSTNAME": "Mangyaring ipasok ang unang pangalan.",
    "ERROR_INPUT_EMAIL": "Mangyaring mag-input ng email.",
    "ERROR_INPUT_VALID_EMAIL": "Mangyaring magpasok ng wastong email address.",
    "ERROR_INPUT_VALID_NUMBER": "Mangyaring magpasok ng wastong numero",
    "ERROR_INPUT_VALID_RANGE_AMOUNT": "Mangyaring magpasok ng halaga sa pagitan ng {0} at {1}.",
    "INFO_ADD_CORPORATE_SUCCESS": "Matagumpay na nalikha ang account na {0}.",
    "INFO_UPDATE_CORPORATE_SUCCESS": "Matagumpay na na-update ang impormasyon.",
    "INFO_UPDATE_PRICING_SUCCESS": "Matagumpay na na-update ang pagpepresyo",
    "INFO_RESET_PASSWORD_SUCCESS": "Matagumpay na na-reset ang account na {0}.",
    "COMFIRM_RESET_PASSWORD": "Gusto mo bang i-reset ang password?",
    "Traveler_signature": "Lagda ng manlalakbay",
    "Tracking_log": "Log ng pagsubaybay",
    "Rating": "Marka",
    "Email_address": "Email address",
    "Assistant_email": "Email ng assistant",
    "Corp_PO": "Corp PO",
    "Cost_centre": "Sentro ng gastos",
    "Corp_division": "Dibisyon ng korporasyon",
    "Corp_id": "Corp id",
    "Department": "Kagawaran",
    "Manager_email": "Email ng manager",
    "Manager_name": "Pangalan ng manager",
    "Corporate_card": "Airline card",
    "Direct_invoicing": "Direktang pag-invoice",
    "Corporate_Prepaid": "Airline Prepaid",
    "Cash": "Cash",
    "Car_Type_ID": "ID ng Uri ng Kotse",
    "App_Display_Name": "Display Name ng App",
    "Normal_Fare": "Regular",
    "Flat_Fare": "Pantay na Rate",
    "Hourly_Daily_Rate": "Oras-oras/ Araw-araw",
    "Delivery_Rate": "Paghahatid",
    "Assign_rate": "Magtalaga ng rate",
    "Zone": "Sona",
    "Rate": "Rate",
    "Zone_Rate": "Sona - Rate",
    "Please_select_rate": "Mangyaring pumili ng rate",
    "Paid_by": "Binayaran ni",
    "Enter_new_credit_card": "Maglagay ng bagong credit card",
    "TOP_UP": "TOP UP",
    "Top_up": "Top up",
    "Topup_amount": "Dami ng topup",
    "Currency": "Pera",
    "ERROR_INPUT_AMOUNT": "Mangyaring maglagay ng wastong halaga.",
    "VAT_number": "Numero ng VAT",
    "Gender": "Kasarian",
    "Select_Gender": "Pumili",
    "Male": "Lalaki",
    "Female": "Babae",
    "Date_of_birth": "Araw ng kapanganakan",
    "Street_address": "Address ng kalye",
    "City": "lungsod",
    "State": "Estado",
    "Zip_code": "Zip code",
    "Country": "Bansa",
    "adding_new_card": "Nagdaragdag ka ng bagong card para magamit ang serbisyo sa %{zoneName} area",
    "No_Support": "Kasalukuyang hindi sinusuportahan sa lugar na ito ang pagdaragdag ng bagong card",
    "Job_title": "Titulo sa trabaho",
    "required_phone_number_payment": "Kinakailangan ang numero ng telepono ng kumpanya. Mangyaring i-update ang profile upang magpatuloy.",
    "Yes": "Oo",
    "No": "Hindi",
    "AddCardBeforeTopup": "Walang nakitang credit card. Mangyaring magdagdag ng bagong card upang magpatuloy."
  },
  "message": {
    "Add": "Idagdag",
    "Delete": "Tanggalin",
    "Items_per_page": "Mga item bawat pahina",
    "Page": "Pahina",
    "Search_here": "Maghanap dito",
    "Shown_Active": "{0} - {1} ng {2} ang ipinakita",
    "Date": "Petsa",
    "Subject": "Paksa",
    "To": "Upang",
    "Type": "Uri",
    "Result": "Resulta",
    "Actions": "Mga aksyon",
    "New_message": "Bagong mensahe",
    "MESSAGE_DETAILS": "Mga detalye ng mensahe",
    "All": "Lahat",
    "Company": "kumpanya",
    "Active": "Aktibo",
    "In_Active": "Hindi aktibo",
    "In_Review": "Sa Pagsusuri",
    "Send_to": "Ipadala sa",
    "All_drivers": "Lahat ng driver",
    "All_customers": "Lahat ng customer",
    "All_merchants": "Lahat ng mangangalakal",
    "Customized_driver": "Customized na driver",
    "Customized_customer": "Customized na customer",
    "Customized_merchant": "Customized na mangangalakal",
    "Receiver_list": "Listahan ng tatanggap",
    "Content": "Nilalaman",
    "Required_fields": "Mga kailangang punan",
    "Search_driver_name_driver_phone_number": "Hanapin ang pangalan ng driver, numero ng telepono ng driver",
    "Search_category_name": "Pangalan ng kategorya ng paghahanap",
    "Search_customer_name_customer_phone_number": "Hanapin ang pangalan ng customer, numero ng telepono ng customer",
    "Search_merchant_name_merchant_phone_number": "Hanapin ang pangalan ng customer, numero ng telepono ng customer",
    "Search_car_type": "Maghanap ng uri ng kotse",
    "Searching": "Naghahanap...",
    "No_results": "Walang resulta",
    "Send": "Send",
    "Cancel": "Kanselahin",
    "SMS_failed_Inbox_succeeded": "Nabigo ang SMS, nagtagumpay ang Inbox",
    "Failed": "Nabigo",
    "Succeeded": "Nagtagumpay",
    "CONFIRM_DELETE": "Gusto mo bang tanggalin ang mensaheng ito?",
    "CONFIRM_DELETE_MULTI": "Gusto mo bang tanggalin ang mga mensaheng ito?",
    "ALERT_SELECT_DELETE": "Mangyaring piliin ang mensaheng tatanggalin.",
    "ERROR_INPUT_RECEIVERLIST": "Mangyaring pumili ng hindi bababa sa isang driver upang magpadala ng mensahe",
    "ERROR_INPUT_CUSTOMER_RECEIVERLIST": "Mangyaring pumili ng hindi bababa sa isang customer na magpapadala ng mensahe",
    "ERROR_INPUT_SUBJECT": "Mangyaring ipasok ang paksa",
    "ERROR_INPUT_CONTENT": "Mangyaring mag-input ng nilalaman",
    "ERROR_INPUT_LEAST_ONE_SUBJECT_CONTENT": "Mangyaring mag-set up ng hindi bababa sa isang wika upang magpatuloy",
    "ERROR_INPUT_SUBJECT_MULTI_LANGUAGE": "Mangyaring ipasok ang paksa para sa {0} wika",
    "ERROR_INPUT_CONTENT_MULTI_LANGUAGE": "Mangyaring ipasok ang paksa para sa {0} wika",
    "ERROR_INPUT_URL_MULTI_LANGUAGE": "Mangyaring ipasok ang URL para sa {0} wika",
    "ERROR_INPUT_TITLE_MULTI_LANGUAGE": "Mangyaring ipasok ang pamagat para sa {0} wika",
    "ERROR_INPUT_BODY_MULTI_LANGUAGE": "Paki-input ang katawan para sa {0} wika",
    "import_fail_success_driver": "Matagumpay na na-import ang %{success} (mga) driver, nabigo ang %{fail}.",
    "import_fail_success_passenger": "Matagumpay na na-import ang %{success} (mga) customer, nabigo ang %{fail}.",
    "import_fail_success_merchant": "Matagumpay na na-import ang %{success} (mga) merchant, nabigo ang %{fail}.",
    "CONFIRM_SEND_SUCCESS": "Ang iyong mensahe ay naipadala na. Maaaring tumagal ito ng ilang minuto. Mangyaring maghintay habang pinoproseso ng system ang iyong kahilingan.",
    "Load_message_and_sms_fail": "Naganap ang mga error habang naglo-load ng mga detalye ng mensahe at SMS",
    "Update_message_and_sms_fail": "Naganap ang mga error habang ina-update ang mga detalye ng mensahe at SMS",
    "Update_message_and_sms_success": "Matagumpay na na-update ang mga detalye ng mensahe at SMS",
    "Driver_List": "Listahan ng Driver",
    "Car_Type_List": "Listahan ng uri ng kotse",
    "Search_vehicle_type_name": "Pangalan ng Uri ng Sasakyan sa Paghahanap",
    "ALERT_PROCESSING_DELETE": "Pinoproseso ang iyong kahilingan. Maaaring tumagal ito ng ilang segundo",
    "Customer": "Customer",
    "Driver": "Driver",
    "StatusOfCustomer": "Katayuan ng customer",
    "StatusOfDriver": "Katayuan ng driver",
    "StatusOfMerchant": "Katayuan ng mangangalakal",
    "SendTo": "Ipadala sa",
    "DownloadReceiverList": "I-download ang listahan ng tatanggap",
    "warningInputChangeOver": "Mayroong relatibong pagbabago na higit sa 20% sa pagitan ng 2 kamakailang input. Mangyaring isaalang-alang!",
    "NoteDownloadReveverList": "Pagkalipas ng 1 buwan, idi-disable ang pag-download ng listahan ng mga receiver.",
    "Load_voip_fail": "Naganap ang mga error habang nilo-load ang mga detalye ng VOIP.",
    "Update_voip_success": "Matagumpay na na-update ang mga detalye ng VOIP.",
    "Update_voip_fail": "Naganap ang mga error habang ina-update ang mga detalye ng VOIP.",
    "expiryDate": "Ipadala sa bagong user hanggang",
    "expiryDate_placeholder": "Petsa ng Pag-expire (mm/dd/yy)",
    "NA": "N/A",
    "PleaseSelectStatusOfDriver": "Mangyaring piliin ang katayuan ng driver",
    "versionApp": "Bersyon ng App",
    "platform": "Platform",
    "contentType": "Uri ng nilalaman",
    "url": "URL",
    "title": "Pamagat",
    "contentId": "ContentId",
    "body": "Katawan",
    "image_notification": "Imahe",
    "platformItem": {
      "all": "Lahat",
      "iOS": "iOS",
      "android": "Android"
    },
    "comparisonVersionApp": {
      "is": "Ay",
      "is_not": "Ay hindi",
      "is_one_of": "Ay isa sa",
      "is_not_one_of": "Ay hindi isa sa",
      "greater_or_equal": "Mas malaki o katumbas",
      "less_or_equal": "Mas kaunti o katumbas"
    },
    "contentTypeItem": {
      "html": "HTML",
      "link": "Link"
    },
    "Please_input": "Kinakailangan ang field na ito"
  },
  "fleetInfo": {
    "Fleet_name": "Pangalan ng fleet",
    "Phone_number": "Numero ng telepono",
    "Email_address": "Email address",
    "Country": "Bansa",
    "Time_zone": "Time zone",
    "Address": "Address",
    "Website": "Website",
    "Currency": "Pera",
    "Distance_unit": "Unit ng distansya",
    "Load_fleet_info_fail": "Naganap ang mga error habang nilo-load ang impormasyon ng fleet"
  },
  "generalSetting": {
    "showFare": {
      "title": "Ipakita ang pamasahe sa driver",
      "totalFare": "Kabuuang pamasahe",
      "estimateGross": "Tantyahin ang kabuuang kita"
    },
    "carHailing": "Street Hailing",
    "regular": "Regular",
    "cancelTimeout": "Awtomatikong kanselahin ang mga timeout na booking",
    "allowStripePaymentLink": "Payagan ang pagbuo ng link ng pagbabayad ng Stripe",
    "flat": "patag",
    "techFee": "Tech fee bawat transaksyon",
    "customizeLogo": "I-customize ang logo ng dashboard",
    "logo": "Logo",
    "customerApp": "App ng Customer",
    "hourly": "Oras-oras",
    "shuttle": "Shuttle",
    "parcel": "Parcel",
    "freeCancelTime": "Mga libreng oras ng pagkansela bawat araw",
    "AM": "AM",
    "PM": "PM",
    "RUSH_HOUR": "Patong na singil",
    "Name": "Pangalan",
    "Date": "Petsa",
    "Mon": "Mon",
    "Tue": "Tue",
    "Wed": "Wed",
    "Thu": "Thu",
    "Fri": "Fri",
    "Sat": "Sat",
    "Sun": "Sun",
    "Time_range": "Saklaw ng oras",
    "From": "Mula sa",
    "To": "Upang",
    "Rush_hour_time_calculation": "Pagkalkula ng oras ng surcharge",
    "Rush_hour_surcharge": "Patong na singil",
    "Required_fields": "Mga kailangang punan",
    "Save": "I-save",
    "Cancel": "Kanselahin",
    "MDISPATCHER_CANCELLATION": "Pagkansela ng Pax / Partner / Web booking",
    "Book_now": "Mag-book na",
    "MDISPATCHER_CANCELLATION_TOOLTIP": "Pagkansela ng Pax / Partner / Web booking",
    "Book_now_toolTip": "Nalalapat ang bayad sa pagkansela kung ang mga on-demand na booking ay nakansela sa loob",
    "Book_later_toolTip": "Nalalapat ang bayad sa pagkansela kung ang isang reserbasyon ay nakansela bago ang oras ng pagkuha",
    "Drv_Book_now_toolTip": "Nalalapat ang bayad sa pagkansela kung ang mga on-demand na booking ay nakansela sa loob ng itinakdang oras, mula sa oras ng pagtanggap",
    "Drv_Book_later_toolTip": "Applicable ang cancellation fee kung makansela ang mga advance booking sa loob ng nakatakdang oras, bago ang inaasahang oras ng pick-up",
    "Free_cancel": "Libreng tagal ng pagkansela",
    "Hide_cancel_btn": "Itago ang button na kanselahin pagkatapos ng mga nakatakdang oras",
    "Driver_cancellation": "Pagkansela ng driver",
    "Time_day": "Mga oras bawat araw",
    "Duration": "Tagal",
    "Times": "Mga oras",
    "Book_later": "Mag-book mamaya",
    "Intercity": "Intercity",
    "Intercity_tooltip": "Applicable ang cancellation fee kung ang isang intercity booking ay nakansela sa loob ng itinakdang oras, bago ang oras ng pickup.",
    "MDISPATCHER_NO_SHOW": "Walang Palabas",
    "AIRPORT_FEE": "Bayad sa paliparan",
    "From_airport": "Mula sa airport",
    "To_airport": "Sa airport",
    "MEET_DRIVER": "Kilalanin ang driver",
    "On_curb": "Sa gilid ng bangketa",
    "Meet_Greet": "Pagkikita at Pagbati",
    "Add": "Idagdag",
    "Edit": "I-edit",
    "DELETE_SURCHARGE": "Tanggalin ang surcharge",
    "DELETE_SERVICE": "Tanggalin ang serbisyo",
    "CHANGE_APPLY_SERVICE_FEES": "Baguhin ang paglalapat ng mga bayarin sa serbisyo",
    "DELETE_CONFIRM": "Sigurado ka bang gusto mong tanggalin ang item na ito?",
    "DELETE_SURCHARGE_CONFIRM": "Sigurado ka bang gusto mong tanggalin ang napiling surcharge?",
    "DELETE_ADDITIONAL_SERVICES_CONFIRM": "Sigurado ka bang gusto mong tanggalin ang napiling serbisyo?",
    "CHANGE_APPLY_SERVICE_FEES_CONFIRM_CUSTOM": "Sigurado ka bang gusto mong mag-apply ng iba't ibang mga bayarin para sa mga seperated zone?",
    "CHANGE_APPLY_SERVICE_FEES_CONFIRM_ALL": "Sigurado ka bang gusto mong ilapat ang parehong mga bayarin para sa lahat ng mga zone?",
    "EDIT_SERVICE_FEES": "I-edit ang mga bayarin sa serbisyo",
    "EDIT_SERVICE_FEES_CONFIRM": "Sigurado ka bang gusto mong umalis nang hindi nagse-save ng mga setting para sa %{currentZone}?",
    "HEAVY_TRAFFIC": "Matinding trapiko",
    "Heavy_traffic_surcharge": "Matinding traffic surcharge",
    "TOLL_FEE": "Toll fee",
    "OTHER_FEES": "Iba pang bayad",
    "Can_edit": "Marunong mag edit",
    "Can_add_note": "May kakayahang magdagdag ng tala",
    "Other_fees_amount": "Iba pang halaga ng bayad",
    "TAX": "Buwis",
    "Tax_surcharge": "dagdag na singil sa buwis",
    "TIP": "Tip",
    "Default_tip": "Default na tip",
    "RESERVATION_REMINDER": "Paalala sa pagpapareserba",
    "Driver_app_SMS_alert_before": "Driver app - SMS at alerto dati",
    "Pax_app_alert_before": "Pax app - alerto dati",
    "Confirm_driver_info_via_email_before": "Magpadala ng email / sms booking confirmation sa customer bago",
    "Confirm_driver_info_via_email_before_hint": "Magpapadala ang system ng kumpirmasyon sa booking sa customer batay sa iyong itinakdang oras, bago ang oras ng pagkuha.",
    "Show_request_tab_on_driver_app": "Ipakita ang tab na 'Kahilingan' sa driver app",
    "Show_preferred_tab_on_driver_app": "Ipakita ang tab na 'Preferred' sa driver app",
    "Sound_notification": "Tunog na notification",
    "Advance_information": "Paunang impormasyon ng mga gumagamit ng kumpanya",
    "Load_general_fail": "Naganap ang mga error habang naglo-load ng pangkalahatang setting",
    "Update_general_success": "Matagumpay na na-update ang pangkalahatang setting",
    "Update_general_fail": "Matagumpay na na-update ang pangkalahatang setting",
    "Time_over_lap": "Ang mga hanay ng oras ay hindi dapat mag-overlap sa bawat isa",
    "Allow_passenger_to_register_as_corp_traveler": "Pahintulutan ang pasahero na magparehistro bilang mga gumagamit ng corp",
    "Not_Allow_Make_Booking_Without_Assign_Fare": "Huwag ipakita ang uri ng kotse sa PWA at App kung walang itinalagang pamasahe (v4.6.60 at mas mataas)",
    "Not_Allow_show_car_type_on_cc": "Huwag ipakita ang uri ng sasakyan sa CC kung walang itinalagang pamasahe",
    "Disable_Direct_Invoicing_Receipt_Email": "Huwag paganahin ang email ng resibo ng direktang pag-invoice",
    "Additional_services": "Karagdagang serbisyo",
    "Edit_additional_services": "I-edit ang Mga Karagdagang Serbisyo",
    "Service_Fee_By_Currencies": "Kabayaran sa serbisyo",
    "Service_type": "Uri ng Serbisyo",
    "Optional": "Opsyonal",
    "Compulsory": "Compulsory",
    "Vehicle_type": "Uri ng Sasakyan",
    "Adding_additional_service_success": "Matagumpay na nalikha ang bagong serbisyo",
    "Update_additional_service_success": "Matagumpay na na-update ang serbisyo",
    "Delete_additional_service_success": "Matagumpay na natanggal ang serbisyo",
    "Adding_additional_service_fail": "Nabigong magdagdag ng Serbisyo",
    "Update_additional_service_fail": "Nabigong i-update ang serbisyo",
    "Other_settings": "Iba pang mga setting",
    "Operator_assign_error": "Nagkaroon ng mga error sa pagtatalaga ng operator",
    "Create_surcharge_success": "Matagumpay na nalikha ang bagong surcharge",
    "Update_surcharge_success": "Matagumpay na na-update ang surcharge",
    "Delete_surcharge_success": "Matagumpay na natanggal ang surcharge",
    "Create_surcharge_fail": "Nabigong muling ibigay ang surcharge",
    "Update_surcharge_fail": "Nabigong i-update ang surcharge",
    "Delete_surcharge_fail": "Nabigong tanggalin ang surcharge",
    "Get_surcharge_detail_fail": "Nabigong makuha ang detalye ng surcharge",
    "Repeat": "Ulitin",
    "DayOfWeek": "Araw sa isang linggo",
    "SingleDay": "Isang araw",
    "Yearly": "Taon-taon",
    "Backup_phone_number": "Numero ng backup",
    "Backup_phone_number_hint": "Direkta sa numerong ito kapag ang driver at pasahero ay wala sa mga operation zone",
    "Apply_countdown_clock": "Ilapat ang countdown na orasan",
    "No_show_duration_tooltip": "I-set up ang oras para ipakita ang No Show button pagkatapos i-tap ng driver ang Arrived button sa Driver App",
    "Limit_radius": "Limitahan ang radius",
    "Limit_radius_tooltip": "Maaaring mag-ulat ang driver ng booking bilang no-show sa loob ng radius na ito",
    "Limit_radius_arrive_pickup": "Maaaring dumating ang driver ng limit radius at mag-pick up para sa transport booking",
    "Booking_validation": "Mga pagpapatunay ng booking",
    "Invalid_period_of_time": "Di-wastong yugto ng panahon",
    "hide_Provider_Filter_From_Corporate_Board": "Itago ang filter ng provider mula sa corporate board",
    "customer_email_is_optional": "Ang email ng customer ay opsyonal sa pampasaherong app",
    "disable_hourly_rate": "I-disable ang oras-oras na rate kapag available ang parehong oras-oras at flat rate",
    "customer_gender_is_compulsory": "Ang kasarian ng customer ay sapilitan sa pampasaherong app",
    "SHOW_MAP_STATUS": "Ipakita ang katayuan ng mapa",
    "Pay_to": "Magbayad sa",
    "EMERGENCY_SOS": "Emergency S.O.S",
    "customer_address_is_compulsory": "Ang address ng customer ay sapilitan sa pampasaherong app",
    "customer_id_is_compulsory": "Ang Customer's ID# / National IC# ay sapilitan sa pampasaherong app",
    "allowPassengerToUploadDocument": "Payagan ang pasahero na mag-upload ng ID# / National IC# na dokumento",
    "compulsoryNationalIcDocumentInPaxApp": "Ang Customer's ID# / National IC# na dokumento ay sapilitan sa Pax app",
    "doNotAllowAddDriverWhenNationalIdDuplicated": "Huwag payagan ang operator na magdagdag ng driver kapag nadoble ang national ID",
    "forceCustomerToUseLiveLocation": "Pilitin ang customer na gumamit ng Live na Lokasyon",
    "disableMarketPlace": "Huwag paganahin ang Market Place para sa mga bagong driver",
    "dontShowSecondStageImmediately": "Itago ang stage 2 signup",
    "compulsoryCarInsurance": "Ang seguro sa kotse ng driver ay sapilitan",
    "marketPlace": "Lugar ng Pamilihan",
    "Enable": "Paganahin",
    "Disable": "Huwag paganahin",
    "showTotalFareWithoutPromotionOnDriverApp": "Ipakita ang kabuuang pamasahe nang walang promosyon sa Driver app",
    "showTotalFareWithoutPromotionOnDriverApp_hint": "- Transportasyon: ilapat ang setting na ito bago magbayad ang driver. <br/> - Paghahatid at Intercity: ilapat ang setting na ito bago magsimulang magtrabaho ang driver.",
    "hideFareOnPaxAppForAllCorporateBookings": "Itago ang pamasahe sa Pax app para sa lahat ng corporate booking",
    "hidePaxNameAvatarOnDispatchScreen": "Itago ang pangalan at avatar ng customer sa screen ng pagpapadala",
    "showFullCustomerNameOnDriverApp": "Ipakita ang buong pangalan ng customer sa Driver app",
    "hideDriverLogoutBtn": "Itago ang Driver logout button sa Driver app",
    "settings": "Mga setting",
    "Default_value": "Default na halaga",
    "limitTopupAmount": {
      "valueByCurrencies": "Dami ng babala",
      "minimumByCurrencies": "Minimum na halaga ng top up",
      "maximumByCurrencies": "Pinakamataas na halaga ng top up",
      "title": "Minimum at Maximum na halaga ng Top up"
    },
    "driverApp": "Driver App",
    "passengerApp": "App ng Pasahero",
    "Default_Topup_Values": "Default na Mga Halaga ng Top up",
    "service_fees": "Mga Bayarin sa Serbisyo",
    "commission_fleet": "Default na Fleet Commission",
    "apply_all": "Ang parehong para sa lahat ng mga zone ng operasyon",
    "apply_custom_zone": "Naiiba sa pagitan ng lahat ng mga zone ng operasyon",
    "apply": "Mag-apply",
    "tollFeeDriverCanInput": "Limitahan ang halaga na mai-input ng driver",
    "unlimited": "Walang limitasyon",
    "limited": "Limitado",
    "sameFee": "Parehong bayad",
    "customizedAirportfee": "Pasadyang bayad sa paliparan",
    "noteAirportFeeModal": "Tandaan: Ang bayad sa paliparan ay inilalapat lamang para sa mga paliparan na nasa listahan.",
    "intercity_schedule_trip_time_range": "Upang matiyak ang magkakasunod na pagkakasunud-sunod ng oras ng pag-book, pakitiyak na ang hanay ng oras ng mga ginustong/hiling na mga biyahe ay mas malaki kaysa sa mga iskedyul ng mga biyahe",
    "airportFee": {
      "tooltipApply": "Ang default na bayad sa paliparan ay ilalapat para sa bawat biyahe mula / papunta sa paliparan bilang default. Kung ang bayad sa paliparan ay na-customize, ang bagong bayad ay ilalapat para sa lugar na iyon.",
      "titleSearchAiportZone": "Mangyaring hanapin ang iyong airport at idagdag ang iyong bayad",
      "airport": "Paliparan",
      "currency": "Pera",
      "fromAirportFee": "Mula sa bayad sa paliparan",
      "toAirportFee": "Sa bayad sa airport",
      "action": "Mga aksyon"
    },
    "Yes": "Oo",
    "No": "Hindi",
    "tipPaymentMethod": "Available ang paraan ng pagbabayad para sa customer na magbigay ng tip sa kanyang driver pagkatapos ng order",
    "tipDefaultValues": "Mga default na halaga para sa tip pagkatapos ng biyahe",
    "creadit": "Credit card (Personal card / Corporate card)",
    "wallet": "Wallet",
    "tnGeWallet": "TnG eWallet",
    "vippseWallet": "Vipps",
    "DurationRegularBooking": "Tagal (para sa mga regular na booking)",
    "googlePay": "Google Pay",
    "applePay": "Apple Pay",
    "DurationRegularBookingToolTip": "Ipapakita ang \"No show\" na button pagkatapos na i-tap ng driver ang \"Arrived\" na button sa Driver app.",
    "noShowCountDown": {
      "customizedNoShow": "Pag-customize para sa mga partikular na lokasyon (hal. airport...)",
      "customizedNoShowTitle": "Ilapat ang tagal ng hindi pagsipot na count down para sa mga partikular na lokasyon (hal. airport...)"
    },
    "HEAT_MAP": "Mapa ng init",
    "heatMap": {
      "defaultMode": "Default na mode",
      "radius": "Radius",
      "intensity": "Intensity",
      "opacity": "Opacity",
      "requestedBookings": "Hiniling na mga booking",
      "activePassengers": "Mga aktibong pasahero",
      "completedBookings": "Nakumpleto ang mga booking",
      "timeoutBookings": "Mag-time out sa mga booking",
      "tooltipRadius": "Ang radius ng impluwensya para sa bawat punto ng data, sa mga pixel.",
      "tooltipIntensity": "Ang maximum na intensity ng heatmap.",
      "tooltipOpacity": "Ang opacity ng heatmap, na ipinahayag bilang isang numero sa pagitan ng 0 at 1."
    },
    "schedule_trip": "Mag-iskedyul ng paglalakbay",
    "driver_starts_trip_before_set_time": "Nagsisimula ang biyahe ng driver bago ang itinakdang oras",
    "request_trip": "Humiling ng biyahe",
    "preferred_trip": "Ginustong paglalakbay",
    "time_range": "Saklaw ng oras para sa kahilingan at gustong biyahe",
    "working_time": "Oras ng trabaho",
    "hour": "oras",
    "hours": "oras",
    "minutes": "minuto",
    "indicate_pickup_time_in_advance_to_customer": "Ipahiwatig ang oras ng pagkuha nang maaga sa customer.",
    "customers_can_select_start_time_and_request_intercity_trip": "Maaaring pumili ang mga customer ng oras ng pagsisimula at humiling ng intercity trip. Ang mga driver ay maaari ring lumikha ng kanilang ginustong biyahe gamit ang kanilang sariling iskedyul. Halimbawa: ang isang hanay ng oras na 1 oras ay magbibigay-daan sa mga customer at driver na gumawa ng intercity trip na magsisimula sa 8:00 am - 9:00 am, 9:00 am - 10:00 am, o 10:00 am - 11:00 am .",
    "this_setting_limits_customers_to_create_request_trip_within_working_hours_only": "Nililimitahan ng setting na ito ang mga customer na gumawa ng kahilingang biyahe sa loob ng mga oras ng trabaho lamang.",
    "face_mask_verify": "Pag-verify ng face mask",
    "faceMask_allZones": "Lahat ng zone",
    "faceMask_customized": "Customized na zone",
    "turn_on_verify": "I-on ang pag-verify",
    "force_to_wear_face_mask": "Piliting magsuot ng face mask",
    "time_range_to_verify": "Huwag i-verify muli sa loob",
    "customizedFaceMaskZone": "Customized na pag-verify ng face mask",
    "zoneOperation": "Operation Zone",
    "FaceMaskCustomized": "Customized",
    "time": "oras",
    "day": "araw",
    "days": "Mga Araw",
    "allow_driver_to_skip_verification_after": "Payagan ang driver na laktawan ang pag-verify pagkatapos",
    "driver_need_to_verify": "Kapag kailangang i-verify ng driver",
    "open_app": "Buksan ang app",
    "start_reservation": "Simulan ang pagpapareserba",
    "choose_at_least_one": "Dapat kang pumili ng hindi bababa sa isang aksyon upang magpatuloy.",
    "face_mask_customized_fail": "Dapat kang pumili ng hindi bababa sa isang aksyon upang magpatuloy ({0}).",
    "vehicleAgeLimit": "Limitasyon sa edad ng sasakyan",
    "vehicleAgeLimit_years": "taon",
    "founderFund_firstBooking": "Founder's Fund: unang booking lang",
    "distance_diversity": "Pagkakaiba-iba ng distansya",
    "Add_Surcharge_Distance": "Idagdag",
    "from": "Mula sa",
    "to": "Upang",
    "surchargeValue": "Patong na singil",
    "actions": "Aksyon",
    "please_enter_distance_range_valid": "Di-wasto ang distansyang %{name}!",
    "please_enter_distance_to": "Pakilagay ang distansya \"To\"",
    "please_enter_surchargeValue": "Pakilagay ang distansyang \"Surcharge\" para sa hanay ng distansya %{name}",
    "please_enter_surchargeValue_is_number": "Pakipasok ang wastong numerong \"Surcharge\" para sa hanay ng distansya %{name}",
    "Update_service_fee_success": "Matagumpay na na-update ang mga bayarin sa serbisyo",
    "Update_service_fee_fail": "Naganap ang mga error habang ina-update ang mga bayarin sa serbisyo",
    "Update_fleet_commission_success": "Matagumpay na na-update ang default na fleet commision",
    "Update_fleet_commission_fail": "Naganap ang mga error habang ina-update ang default na komisyon ng fleet",
    "RESERVATION_POLICY": "Patakaran sa pagpapareserba",
    "default_expected_pickup_time": "Default na inaasahang oras ng pagkuha",
    "default_expected_pickup_time_hint": "Ang default na halaga na inaasahan mong darating ang isang driver mula sa oras ng pagkuha.",
    "same_original_pickup_time": "Pareho sa orihinal na oras ng pagkuha",
    "quarter_before": "15 min bago",
    "half_before": "30 min bago",
    "quarter_after": "15 min pagkatapos",
    "half_after": "30 min pagkatapos",
    "custom": "Custom",
    "min_before": "min bago",
    "min_after": "min pagkatapos",
    "TransactionFee": "Bayad sa transaksyon",
    "creditCardPercent": "Credit card/ prepaid",
    "directBilling": "Direktang pagsingil",
    "externalCard": "External Card"
  },
  "social": {
    "pax_app": "app ng pasahero",
    "driver_app": "Driver app",
    "default": "Default",
    "customized": "Customized",
    "html": "HTML",
    "about": "Tungkol sa",
    "email": "Suporta sa email",
    "enablePhone": "Numero ng telepono",
    "phone": "",
    "policy": "I-link ang Patakaran sa Privacy",
    "termOfUse": "I-link ang Mga Tuntunin ng Paggamit",
    "site": "Website",
    "logoDriverIOS": "Logo - IOS",
    "logoDriverAndroid": "Logo - Android",
    "htmlUrl": "HTML file",
    "Update_fail": "Naganap ang mga error habang nag-a-update ng social",
    "Update_success": "Matagumpay na na-update ang social"
  },
  "user": {
    "Show_per_page": "Mga item bawat pahina",
    "Page": "Pahina",
    "Search_here": "Maghanap dito",
    "show_pages": "{0} - {1} ng {2} ang ipinakita",
    "Username": "Username",
    "Name": "Pangalan",
    "Phone_number": "Numero ng telepono",
    "Email": "Email",
    "Permission": "Pahintulot",
    "Status": "Katayuan",
    "Actions": "Mga aksyon",
    "Active": "Aktibo",
    "Inactive": "Hindi aktibo",
    "Edit": "I-edit",
    "Deactivate": "I-deactivate",
    "Delete": "Tanggalin",
    "Add": "Idagdag",
    "ADD_USER": "Idagdag ang gumagamit",
    "First_name": "Pangalan",
    "Last_name": "Huling pangalan",
    "AgendId": "User #",
    "Email_address": "Email address",
    "Address": "Address",
    "Required_fields": "Mga kailangang punan",
    "Save": "I-save",
    "Cancel": "Kanselahin",
    "EDIT_USER": "I-edit ang user",
    "Yes": "Oo",
    "DELETE_USER": "Tanggalin ang user",
    "DETAIL_USER": "Detalye ng gumagamit",
    "DELETE_CONFIRM": "Sigurado ka bang gusto mong tanggalin ang napiling user?",
    "Update_user_fail": "Naganap ang mga error habang ina-update ang user",
    "Update_user_success": "Matagumpay na na-update ang user",
    "Create_user_fail": "Naganap ang mga error habang gumagawa ng user",
    "Create_user_success": "Matagumpay na nalikha ang account na {0}. Pakisuri ang email {1} ​​para sa mga detalye ng kredensyal sa pag-log in.",
    "Load_user_fail": "Naganap ang mga error habang nilo-load ang user",
    "Update_user_status_fail": "Naganap ang mga error habang ina-update ang status ng user",
    "Delete_user_fail": "Naganap ang mga error habang tinatanggal ang user",
    "Delete_user_success": "Matagumpay na natanggal ang user",
    "CONFIRM_RESET_PASSWORD": "Gusto mo bang i-reset ang password?",
    "RESET_PASSWORD": "Gusto mo bang i-reset ang password?",
    "Reset_password": "Reset password",
    "Select_permission": "Piliin ang Pahintulot",
    "Reset_password_success": "Matagumpay na i-reset ang password"
  },
  "report": {
    "query": {
      "errorMessage": {
        "bookFrom": "Mangyaring pumili ng booking mula sa",
        "paymentMethod": "Mangyaring pumili ng paraan ng pagbabayad",
        "bookingType": "Mangyaring pumili ng uri ng booking",
        "ratingType": "Mangyaring pumili ng uri ng rating",
        "stars": "Mangyaring pumili ng uri ng rating",
        "type": "Uri",
        "transactionType": "Mangyaring pumili ng uri ng transaksyon",
        "serviceType": "Mangyaring pumili ng uri ng serbisyo",
        "rideService": "Mangyaring pumili ng serbisyo sa pagsakay",
        "invalidEmail": "Mangyaring magpasok ng wastong email address."
      },
      "search": {
        "driverReferalPax": "Hanapin: Pangalan ng Driver, Numero ng Telepono",
        "passengerRefersPassengers": "Hanapin: Pangalan ng Customer, Numero ng Telepono",
        "driverReferalPaxDetail": "Hanapin: Pangalan ng Pasahero",
        "bookingDetails": "Search: Booking ID, Operator, Customer phone, Customer name, Driver name, Mã tài xế, License plate, Queuing area, Note, Trip, Support ID",
        "financial": "Hanapin: Pangalan ng driver, Telepono",
        "completedTime": "Nakumpletong Oras",
        "collectedTime": "Nakolektang Oras",
        "financialDriver": "Hanapin: Booking ID, Driver name, ID# / National IC#",
        "carActivity": "Hanapin: License plate",
        "affiliation": "Maghanap: Booking ID",
        "prepaidTopUp": "Paghahanap: Pangalan ng kumpanya, Top up ID, Transaction ID",
        "operatorLogs": "Paghahanap: Pangalan ng operator, Pangalan ng gumagamit, Paglalarawan",
        "corporate": "Search: Booking ID, Driver name, Corp Users, Client case matter, Charge code",
        "bookingLogs": "Search: Booking ID, Customer name, Support ID, Driver name, ID# / National IC#",
        "rating": "Maghanap: Booking ID, Customer phone, Customer name, Plate number",
        "mDispatcher": "Maghanap: Booking ID, pangalan ng kasosyo",
        "totalAdjustment": "Maghanap: Booking ID",
        "incidentCancellation": "Maghanap: Booking ID, Customer phone, Support Id, Customer name",
        "acceptanceCancellationRate": "Paghahanap: Pangalan ng driver, numero ng telepono",
        "revenueDriver": "Hanapin: Pangalan ng driver",
        "dailyDriver": "Maghanap: Telepono ng driver, Pangalan ng driver, ID# / Pambansang IC#",
        "dailyCar": "Hanapin: License plate, Pahintulot",
        "dailyDriverCar": "Search: Driver phone, Driver name, Mã tài xế, License plate",
        "driverLoginStatus": "Search: Driver phone, Driver name, Mã tài xế, License plate",
        "bookingSummary": "Paghahanap: Pangalan ng operator, User name, Booking ID",
        "creditWallet": "Paghahanap: Pangalan ng kumpanya, Pangalan ng Driver, Booking ID, Transaction ID, Operator",
        "cashWallet": "Paghahanap: Pangalan ng kumpanya, Pangalan ng Driver, Booking ID, Numero ng telepono",
        "settlement": "Hanapin: Pangalan ng driver, ID# / Pambansang IC#",
        "driverRating": "Maghanap: Booking ID, Customer phone, Customer name, Plate number, Support ID",
        "driverWithdrawal": "Pangalan ng driver, Telepono, Kumpanya, Transaction ID, Reference #",
        "penaltyCompensation": "Hanapin: Booking ID, Pangalan ng Driver, Pangalan ng Pasahero",
        "incompletePayment": "Maghanap: Booking ID, Support ID, Pangalan ng Customer",
        "passengerWallet": "Maghanap: Booking ID, Transaction ID, Pangalan ng Customer",
        "driverDeposit": "Hanapin: Pangalan ng kumpanya, Pangalan ng Driver, Booking ID, Operator",
        "refund": "Maghanap: Booking ID, Trip Id, Support ID, Pangalan ng customer",
        "driverRefersCustomerBookings": "Paghahanap: Referral code, Support ID",
        "driverHistory": "Paghahanap: Payout ID",
        "merchantHistory": "Paghahanap: Payout ID",
        "firstWillWin": "Maghanap: pangalan ng customer, telepono ng customer, customer ID",
        "firstWillWinDriver": "Maghanap: pangalan ng driver, telepono ng driver, username",
        "merchant": "Hanapin: tindahan",
        "merchantCashTransactionHistory": "Maghanap: Booking ID, Transaction ID, Pangalan ng Merchant",
        "merchantCreditTransactionHistory": "Maghanap: Booking ID, Transaction ID, Pangalan ng Merchant",
        "deleteAccountRequest": "Hanapin: Telepono",
        "affiliationPayoutHistory": "Paghahanap: Payout ID",
        "thirdPartyBooking": "Maghanap: Booking Id, External Id"
      },
      "common": {
        "all": "Lahat"
      },
      "sendDownloadLink": "Email Address ng Tatanggap",
      "generaltingYourExcel": "Pakitandaan, maaaring magtagal ang pagbuo ng iyong excel file. I-email namin ito sa iyo sa sandaling handa na ito.",
      "today": "Ngayong araw",
      "thisMonth": "Sa buwang ito",
      "lastMonth": "Noong nakaraang buwan",
      "past30Days": "Nakalipas na 30 araw",
      "thisYear": "Ngayong taon",
      "custom": "Custom",
      "customize": "Customize",
      "thisWeek": "Ngayong linggo",
      "past7Days": "Huling 7 Araw",
      "weekly": "Linggu-linggo",
      "monthly": "Buwan-buwan",
      "allTime": "Lahat ng oras",
      "tomorrow": "Bukas",
      "nextWeek": "Susunod na linggo",
      "lastWeek": "Nakaraang linggo",
      "nextMonth": "Susunod na buwan",
      "yesterday": "Kahapon",
      "fromDate": "Mula sa petsa",
      "toDate": "Hanggang ngayon",
      "driver": "Driver: %{name}",
      "customer": "Pangalan ng Customer}",
      "referralCode": "Referral Code: %{name}",
      "referer": "Referrer: %{name}",
      "referee": "Referee: %{name}",
      "usedPromoCode": "Promosyon: %{name}",
      "voucherCode": "Code: %{name}",
      "city": "lungsod",
      "company": "kumpanya",
      "company_report": "Pangalan ng Kumpanya}",
      "campaign_report": "Kampanya: %{name}",
      "operator": "Operator",
      "farmType": "Uri ng sakahan",
      "carLicensePlate": "Plat ng lisensya ng sasakyan: %{name}",
      "settlementType": "Uri",
      "bookingService": "Uri ng network",
      "payoutOption": "Opsyon sa pagbabayad",
      "currency": "Pera",
      "bookingFrom": "Pagbu-book mula sa",
      "thirdPartyAction": "Aksyon",
      "paymentMethod": "Payment method",
      "paymentStatus": "Katayuan ng pagbabayad",
      "bookingType": "Uri ng booking",
      "adjustPrice": "Ayusin ang Presyo",
      "intercityRoute": "Intercity na ruta",
      "ratingType": "Uri ng rating",
      "stars": "Marka",
      "driverRating": "Rating ng driver",
      "vehicleRating": "Rating ng sasakyan",
      "mDispatcherType": "Uri ng kasosyo",
      "mDispatcher": "Pangalan ng kasosyo",
      "campaign": "Kampanya",
      "promocode": "Promo code",
      "canceledBy": "Kinansela ni",
      "timezone": "Timezone",
      "month": "buwan",
      "year": "taon",
      "view": "Tingnan",
      "action": "Aksyon",
      "status": "Katayuan",
      "module": "Module",
      "driverType": "Uri",
      "vehicleType": "Uri ng kotse",
      "serviceType": "Uri ng serbisyo",
      "expireDate": "Petsa ng pag-expire",
      "rideSharing": "Pagbabahagi ng pagsakay",
      "rideService": "Serbisyo sa pagsakay",
      "transactionType": "Uri ng Transaksyon",
      "completeFromCC": "Kumpleto mula sa CC",
      "withDrawalTransactionType": "Uri ng Transaksyon",
      "bookingStatus": "Katayuan ng booking",
      "withdrawalStatus": "Katayuan",
      "originalStatus": "Katayuan ng orihinal na pagbabayad",
      "currentStatus": "Kasalukuyang katayuan ng pagbabayad",
      "tip": "Tip",
      "type": "Uri",
      "userType": "Uri ng user",
      "eventType": "Uri ng kaganapan",
      "eventState": "Katayuan ng kaganapan",
      "eventName": "Pangalan ng pangyayari",
      "merchantType": "Uri ng mangangalakal",
      "merchantService": "Serbisyong mangangalakal",
      "storeName": "Tindahan",
      "merchant": "Mangangalakal",
      "event": "Pangalan ng kaganapan: %{name}",
      "store": "Pangalan ng tindahan: %{name}",
      "selectedMerchant": "Merchant: %{name}",
      "associatedApps": "Mga nauugnay na app",
      "rideServiceItem": {
        "all": "Lahat",
        "regulation": "Regulasyon",
        "rideSharing": "Pagbabahagi ng Sakay"
      },
      "actionItem": {
        "all": "Lahat",
        "login": "Mag log in",
        "logout": "Log out",
        "bookIn": "Available",
        "bookOff": "Hindi magagamit",
        "deny": "Tanggihan",
        "ignore": "Huwag pansinin",
        "accept": "Tanggapin",
        "startTrip": "On my way",
        "updateBooking": "Update",
        "arrive": "Dumating",
        "pickup": "Pulutin",
        "drop": "Ilaglag",
        "complete": "Kumpleto",
        "noShow": "Walang Palabas",
        "rqJob": "Nagpapadala",
        "cancel": "Kanselahin"
      },
      "loginStatusItem": {
        "all": "Lahat",
        "online": "Logging in",
        "offline": "Hindi naka-log in"
      },
      "payoutType": "Uri ng pagbabayad",
      "affiliationPayoutTypeItem": {
        "auto": "Auto payout",
        "manual": "Manu-manong pagbabayad"
      },
      "farmTypeItem": {
        "all": "Lahat",
        "farmIn": "Magsasaka sa",
        "farmOut": "Ilipat sa iba",
        "affiliationOwner": "May-ari ng kaakibat",
        "farmInAndAffiliationOwner": "Farm in at kaakibat na may-ari",
        "farmOutAndAffiliationOwner": "Farm out at kaakibat na may-ari",
        "roaming": "Roaming"
      },
      "settlementDriverTypeItem": {
        "all": "Lahat ng driver",
        "individual": "Company Car"
      },
      "shortTrip": {
        "all": "Lahat",
        "shortTrip": "Maikling biyahe",
        "nonShortTrip": "Hindi maikling biyahe"
      },
      "paymentMethodItem": {
        "B2BTerminal": "B2BTerminal",
        "webBooking": "Pagbu-book sa web",
        "pendingPayment": "Nakabinbing bayad",
        "all": "Lahat",
        "cash": "Cash",
        "cashBySender": "Cash ng nagpadala",
        "cashByRecipient": "Cash ng tatanggap",
        "personalCard": "Personal na card",
        "corporateCard": "Corporate card",
        "qrCodePayment": "Pagbabayad ng QR code",
        "directBilling": "Direktang pag-invoice",
        "externalCard": "Panlabas na card",
        "mDispatcherCard": "Card ng kasosyo",
        "corporatePrepaid": "Corporate prepaid",
        "canceled": "Kinansela",
        "noShow": "Walang Palabas",
        "incident": "Insidente",
        "fleetCard": "Panlabas na card",
        "applePay": "Apple pay",
        "creditCardExternal": "Credit card",
        "paxWallet": "Wallet",
        "tngeWallet": "TnG eWallet",
        "vippseWallet": "Vipps",
        "zainCash": "ZainCash",
        "gCash": "GCash",
        "completedWithoutService": "Nakumpleto nang walang driver",
        "BankOfGeorgia": "Bangko ng Georgia",
        "googlePay": "Google Pay",
        "partialCompleted": "Bahagyang Nakumpleto",
        "failed": "Nabigo",
        "paymentLink": "Link sa Pagbabayad"
      },
      "paymentStatusItem": {
        "all": "Lahat",
        "fullPayment": "Buong bayad",
        "partialPayment": "Paunang bayad",
        "pendingPayment": "Nakabinbing bayad",
        "partialRefund": "Bahagyang refund",
        "fullRefund": "Buong refund"
      },
      "companySettlementTypeItem": {
        "all": "Lahat",
        "fleetOweCompany": "Fleet na may utang na kumpanya",
        "companyOweFleet": "Ang kumpanya ay may utang na armada"
      },
      "bookingStatusItem": {
        "all": "Lahat",
        "completed": "Completed",
        "incident": "Insidente",
        "paxNoShow": "Walang pasok si Pax",
        "driverNoShow": "Walang palabas ang driver",
        "rejected": "Tinanggihan",
        "canceledByTimeOut": "Kinansela ng timeout",
        "canceledByTimeOutRejected": "Kinansela ng timeout (tinanggihan)",
        "canceledByBookingBoard": "Kinansela ng booking board",
        "canceledByCustomer": "Kinansela ng customer",
        "canceledBySupplier": "Kinansela ng supplier"
      },
      "bookingFromItem": {
        "all": "Lahat",
        "paxApp": "Pax app",
        "mDispatcher": "Kasosyo",
        "kiosk": "Kiosk",
        "cc": "Command Center",
        "webBooking": "Pagbu-book sa web",
        "carHailing": "Car-hailing",
        "streetSharing": "pagbabahagi ng kalye",
        "dashboard": "Booking board",
        "corp": "Booking board (CTM)",
        "dmc": "Booking board (DMC)",
        "Boost_web_link": "Pag-book ng boBoost web linkard"
      },
      "thirdPartyActionItem": {
        "all": "Lahat",
        "searched": "Hinanap",
        "allocated": "Inilaan",
        "updated": "Na-update",
        "cancelled": "Kinansela"
      },
      "bookingTypeItem": {
        "batchDelivery": "Batch Delivery",
        "all": "Lahat",
        "now": "Ngayon (Transport)",
        "reservation": "Pagpapareserba (Transportasyon)",
        "intercity": "Intercity",
        "delivery": "Paghahatid",
        "shuttle": "Shuttle"
      },
      "adjustPrices": {
        "all": "Lahat",
        "normalPrice": "Normal na presyo",
        "addonPrice": "Presyo ng addon"
      },
      "bookingServiceItem": {
        "all": "Lahat",
        "roaming": "Roaming",
        "homeFleet": "Ilipat sa iba",
        "localBooking": "In-house",
        "farmIn": "Magsasaka sa",
        "farmInAndAffiliationOwner": "Farm in at may-ari ng kaakibat",
        "provideService": "Magsasaka sa"
      },
      "ratingTypeItem": {
        "all": "Lahat",
        "like": "Gaya ng",
        "dislike": "hindi gusto"
      },
      "starsItem": {
        "all": "Lahat",
        "star1": "1 Bituin",
        "star2": "2 Bituin",
        "star3": "3 Bituin",
        "star4": "4 Bituin",
        "star5": "5 Bituin"
      },
      "incidentCanceledTypes": {
        "all": "Lahat",
        "incident": "Insidente",
        "canceled": "Kinansela"
      },
      "canceledByItem": {
        "all": "Lahat",
        "CorpAD": "Admin ng Kumpanya",
        "passenger": "pasahero",
        "driver": "Driver",
        "merchant": "Mangangalakal",
        "CC": "CC",
        "mDispatcher": "Kasosyo",
        "Partner": "Kasosyo",
        "webBooking": "Pagbu-book sa web",
        "timeout": "Timeout"
      },
      "bookingSummaryActionItem": {
        "all": "Lahat",
        "create": "Lumikha",
        "update": "Update",
        "cancel": "Kanselahin",
        "complete": "Kumpleto",
        "incident": "Insidente"
      },
      "transactionTypeItem": {
        "bankAccount": "Inilipat sa bank account",
        "sentToCustomer": "Ipadala sa customer",
        "receivedFromDriver": "Natanggap mula sa driver",
        "sentToDriver": "Ipinadala sa driver",
        "creditWallet": "Inilipat sa credit wallet",
        "netEarning": "Net na Kita",
        "referralEarning": "Referral na Kita",
        "all": "Lahat",
        "topUp": "Top up",
        "editBalance": "I-edit ang balanse",
        "bookingDeduction": "Pagbawas sa booking",
        "bookingPromo": "Promosyon ng booking",
        "topUpGCash": "Mag-top up sa GCash",
        "cashWallet": "Cash wallet",
        "topUpMOLPay": "Mag-top up sa pamamagitan ng Razer Pay",
        "topUpZainCash": "Mag-top up sa pamamagitan ng ZainCash",
        "topUpTelebirr": "Top up sa pamamagitan ng Telebirr",
        "topUpKsher": "Top up ni Ksher",
        "topUpWallet": "Top up ng {0}",
        "TnG": "Top up ng TnG",
        "credit": "Mag-top up gamit ang credit card",
        "ride": "Sumakay",
        "tipAfterCompleted": "Tip pagkatapos ng biyahe",
        "debtWriteOff": "Pagtanggal ng utang",
        "refund": "Refund",
        "excessOffsetAmount": "Labis na Cash Credit",
        "excessDeductionAmount": "Labis na Cash Debit",
        "cashExcess": "Labis na Cash Debit",
        "gateway": "Mag-top up mula sa gateway",
        "driverTopUpForPax": "Labis na Cash Debit",
        "updatedBalance": "Na-update na balanse",
        "partialEarning": "Kumita",
        "payout": "Payout",
        "payoutManual": "Manual ng pagbabayad",
        "payoutDriver": "Payout",
        "itemValue": "Mga halaga ng item",
        "redeemVoucher": "I-redeem ang code #",
        "topUpReferrer": "Gantimpala ng referral",
        "driverInsurance": "Insurance sa Pagmamaneho",
        "cancellationPenalty": "Parusa sa Pagkansela",
        "WingBank": "Mga paglilipat sa Wingbank account",
        "customerDebt": "Utang ng Customer",
        "googlePay": "Mag-top up sa pamamagitan ng Google Pay",
        "applePay": "Mag-top up sa pamamagitan ng Apple Pay",
        "merchantCommission": "Komisyon ng mangangalakal"
      },
      "moduleItem": {
        "all": "Lahat",
        "bookings": "Mga booking",
        "customers": "Mga customer",
        "dispatch": "Pagpapadala",
        "general": "Heneral",
        "promotion": "Promosyon",
        "quest": "Paghanap",
        "rate": "Rate",
        "car": "kotse",
        "city": "lungsod",
        "company": "kumpanya",
        "drivers": "Mga driver",
        "driverSettlement": "Pag-aayos ng driver",
        "login": "Mag log in",
        "logout": "Mag-logout",
        "mDispatcher": "Kasosyo",
        "mDispatcherType": "Uri ng kasosyo",
        "operation": "Operasyon",
        "partners": "Mga kasosyo",
        "permission": "Pahintulot",
        "queuingArea": "Lugar ng pila",
        "report": "Ulat",
        "shift": "Paglipat",
        "user": "Gumagamit",
        "voiceSms": "Boses at SMS",
        "intercityZone": "Zone (intercity)",
        "Payout": "Payout",
        "code#": "Code #",
        "ApiManagement": "Pamamahala ng API"
      },
      "serviceTypeItem": {
        "all": "Lahat",
        "oneWay": "Isang daanan",
        "fromAirport": "Mula sa airport",
        "toAirport": "Sa airport",
        "hourly": "Oras-oras/araw-araw",
        "roundTrip": "Papunta at pabalik",
        "fromAirportHourlyDaily": "Mula sa paliparan - oras-oras/araw-araw",
        "fromAirportRoundTrip": "Mula sa airport - round trip",
        "toAirportHourlyDaily": "Sa airport - oras-oras/araw-araw",
        "toAirportRoundTrip": "Papunta sa airport - round trip"
      },
      "merchantTransactionTypeItem": {
        "all": "Lahat",
        "editBalance": "I-edit ang balanse",
        "payout": "Payout",
        "earning": "Kumita",
        "itemValue": "Halaga ng item",
        "orderDeduction": "Pagbawas ng order"
      },
      "withdrawalTransactionType": {
        "WingBank": "Inilipat sa Wingbank account",
        "bankAccount": "Inilipat sa bank account",
        "cashWallet": "Inilipat sa cash wallet",
        "creditWallet": "Inilipat sa credit wallet"
      },
      "payoutDriver": {
        "driversHaveBankAccount": "May bank account ang mga driver",
        "driversDontHaveBankAccount": "Walang bank account ang mga driver",
        "payoutEndTime": "Oras ng pagtatapos ng pagbabayad",
        "driverHaveInvalidBankAccount": "Ang mga driver ay may di-wastong bank account"
      },
      "payoutMerchant": {
        "HaveBankAccount": "May bank account ang mga merchant",
        "DontHaveBankAccount": "Walang bank account ang mga merchant",
        "HaveInvalidBankAccount": "May di-wastong bank account ang mga merchant",
        "payoutEndTime": "Oras ng pagtatapos ng pagbabayad"
      },
      "messageSuccess": "Kasalukuyang isinasagawa ang file. Makakatanggap ka ng email kapag handa na. Subaybayan ang pag-unlad o direktang mag-download mula sa Mga Ulat/Kasaysayan sa Pag-export",
      "settlement": "Settlement",
      "affiliationSettlementItem": {
        "payable": "Mababayaran",
        "receivable": "Matatanggap"
      },
      "affiliationPaymentMethod": {
        "online": "Online",
        "directInvoicing": "Direktang pag-invoice"
      },
      "payoutPaymentStatusItem": {
        "paid": "Binayaran",
        "pending": "Nakabinbin"
      }
    },
    "error": [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      "Para sa pagganap ng system maaari mo lamang tingnan ang mga ulat sa %{interval} %{unitLabel}, mangyaring ayusin ang iyong hanay ng petsa"
    ],
    "result": {
      "idPermitType": "Uri ng ID",
      "noResultFound": "Walang natagpuang resulta",
      "page": "Pahina",
      "itemsPerPage": "Mga item bawat pahina",
      "showPages": "Ipinapakita ang %{from} - %{to} ng %{total}",
      "exportToExcel": "I-export sa excel",
      "exportToCSV": "I-export sa csv",
      "exportToPaymentFile": "I-export sa file ng pagbabayad",
      "pay": "Magbayad",
      "paySelected": "Pinili ang bayad",
      "payAll": "Bayaran lahat",
      "invalidBankAccount": "Di-wastong bank account",
      "validBankAccount": "Wastong bank account",
      "bookingDetails": {
        "successSendMessage": "ang iyong mensahe ay naipadala na",
        "packageName": "Pangalan ng package",
        "transactionId": "ID ng Transaksyon",
        "proofOfFailure": "Patunay ng Pagkabigo",
        "recipientsNumber": "Bilang ng tatanggap",
        "recipientsInfo": "Impormasyon ng tatanggap",
        "creditTransactionFee": "Mga bayarin sa credit card",
        "carTypeRequest": "Hiniling na cartype",
        "bookingId": "# ng booking",
        "bookingStatus": "Katayuan ng booking",
        "tripId": "# ng biyahe",
        "bookFrom": "Pagbu-book mula sa",
        "bookingService": "Uri ng network",
        "bookingType": "Uri ng booking",
        "serviceType": "Uri ng serbisyo",
        "operator": "Operator",
        "customerPhone": "Numero ng telepono ng pasahero",
        "customerName": "Pangalan ng pasahero",
        "customerIdNumber": "Passenger ID# / IC#",
        "corpDivision": "Dibisyon",
        "corpDept": "Dept",
        "chargeCode": "Code ng pagsingil",
        "tripDescription": "Paglalarawan ng biyahe",
        "rideSharing": "Pagbabahagi ng Sakay",
        "queueName": "Lugar ng pila",
        "bookingTime": "Oras ng paglikha",
        "pickupTime": "Oras ng pagkuha",
        "expectedPickupTime": "Inaasahang Oras ng Pagkuha",
        "dispatchTime": "oras ng pag aalis",
        "onBoardTime": "Oras ng sakay",
        "droppedOffTime": "Oras ng pagbaba",
        "onBoardDuration": "Ang tagal ng sakay",
        "dispatch": "Dispatch #",
        "completedTime": "Nakumpletong Oras",
        "driverName": "Pangalan ng driver",
        "driverIdPermit": "ID# / Pambansang IC#",
        "driverLicenseNumber": "Numero ng lisensya sa pagmamaneho",
        "companyName": "kumpanya",
        "carType": "Uri ng kotse",
        "intercityRoute": "Intercity na ruta",
        "pickupLocation": "Lokasyon ng pickup",
        "geoPickup": "Ang lokasyon ng pickup ay lat/mahaba",
        "extraDestination": "Mga karagdagang destinasyon",
        "originDestination": "Orihinal na destinasyon",
        "originEstDistance": "Orihinal na tinatayang distansya",
        "destination": "Tunay na destinasyon",
        "geoDestination": "Aktwal na destinasyon lat/long",
        "pickupFrom": "Lokasyon ng pickup mula sa",
        "distanceTour": "distansya ng P.O.B",
        "internalNotes": "Panloob na mga tala",
        "notes": "Mga Tala",
        "basicFareCalculator": "Pangunahing calculator ng pamasahe",
        "basicFare": "Pangunahing pamasahe",
        "editFare": "I-edit ang pangunahing pamasahe",
        "oldBasicFare": "Lumang pangunahing pamasahe",
        "airportFee": "Bayad sa paliparan",
        "meetDriver": "Kilalanin ang driver",
        "rushHour": "Patong na singil",
        "surchargeParameter": "Dynamic na surcharge",
        "dynamicFare": "Dynamic na pamasahe",
        "heavyTraffic": "Matinding trapiko",
        "tollFee": "Toll fee",
        parkingFee: 'Parking fee',
        gasFee: 'Gas fee',
        "otherFees": "Iba pang bayad",
        "subtotal": "Subtotal",
        "subTotalFF": "Subtotal na FF",
        "techFee": "bayad sa tech",
        "serviceFee": "Karagdagang serbisyo",
        "fleetServiceFee": "Mga serbisyo ng fleet",
        "bookingFee": "Bayad sa pagpapareserba",
        "salesTax": "Buwis sa pagbebenta",
        "tipAmt": "Tip amt",
        "promoCode": "Promo code",
        "promoAmt": "Promo amt",
        "requestedTotalAmount": "Hiniling na kabuuang halaga",
        "total": "Kabuuang amt",
        "totalNotRounded": "ROUND (Kabuuang amt)",
        "actualReceived": "Aktwal na natanggap",
        "differenceAmt": "Pagkakaiba (natanggap)",
        "customerDebt": "Utang ng Customer",
        "markupDifference": "Pagkakaiba ng markup",
        "receivedWhenDropOff": "Natanggap kapag bumaba",
        "refundAmount": "Refund amt",
        "requestedPaymentMethod": "Hiniling na paraan ng pagbabayad",
        "paidBy": "Binayaran ni",
        "splitPayment": "Hati sa bayad",
        "paidByWallet": "Binayaran ng wallet",
        "paidByOtherMethod": "Binabayaran ng ibang paraan",
        "cashReceived": "Natanggap ang cash",
        "transferredChangeByWallet": "Maglipat ng pagbabago sa pamamagitan ng wallet",
        "returnedChangeByCash": "Ibalik ang pagbabago sa pamamagitan ng cash",
        "instructions": "Mga tagubilin",
        "additionalServiceName": "Pangalan",
        "additionalServiceValue": "Value",
        "sendReceiptHeader": "Header",
        "sendReceipt": "Magpadala ng resibo",
        "send": "Ipadala",
        "cancel": "Kanselahin",
        "sentReceiptSuccess": "Matagumpay na naipadala ang resibo",
        "sentReceiptFailed": "Ang resibo ay hindi maipadala sa ngayon. Pakisubukang muli.",
        "coRider": "Co-rider",
        "coriderName": "Pangalan",
        "stopAddress": "Karagdagang destinasyon",
        "stopGeo": "Lat/long",
        "addonPrice": "Customer ayusin ang presyo",
        "oldSubTotal": "Subtotal bago ayusin",
        "view_good_receipt": "Resibo ng mga kalakal",
        "view_delivery_receipt": "Larawan ng paghahatid",
        "view": "Tingnan",
        "tipAfterCompletedDetails": "Inilipat: {0}{1}; Bayad sa transaksyon: {0}{2}",
        "fleetMarkup": "Kita",
        "editedDriverEarning": "Na-edit na kita ng driver",
        "driverEarning": "Kumita ng driver",
        "pickUpZoneName": "Pickup zone"
      },
      "financial": {
        "driverName": "Pangalan ng driver",
        "driverPhone": "Numero ng telepono",
        "driverUserName": "Username",
        "companyName": "kumpanya",
        "bookingList": "Listahan ng booking",
        "totalFare": "Kabuuang pamasahe na walang promo",
        "netEarning": "Net na kita",
        "showBookingList": "Ipakita",
        "referralEarning": "Kumita ng referral",
        "totalEarningNet": "Kabuuang kita (net)",
        "totalEarningNetTooltip": "Pera na kinita mo pagkatapos ibalik ang lahat ng bayarin sa fleet. Kabuuang kita (net) = Net na kita + Tip pagkatapos ng biyahe",
        "totalEarningNetTooltipRefferal": "Pera na kinita mo pagkatapos ibalik ang lahat ng bayarin sa fleet. Kabuuang kita (net) = Referal na kita + Net na kita + Tip pagkatapos ng biyahe",
        "totalEarningGross": "Kabuuang kita (gross)",
        "totalEarning": "Kabuuang kita",
        "totalEarningGrossTooltip": "Pera na kinita mo mula sa pag-book ng pamasahe at mga tip. Kabuuang kita (gross) = Gross na kita + Tip pagkatapos ng biyahe",
        "totalEarningGrossTooltipRefferal": "Pera na kinita mo sa pag-book ng pamasahe at tip. Kabuuang kita (gross) = Referal na kita + Gross na kita + Tip pagkatapos ng biyahe",
        "note": "Tandaan",
        "shortTrip": "Maikling biyahe"
      },
      "financialDriver": {
        "payoutType": "Uri ng pagbabayad",
        "date": "Petsa",
        "driverTipAfterCompleted": "Tip pagkatapos ng biyahe",
        "bookingId": "Booking ID",
        "paidBy": "Binayaran ni",
        "bookFrom": "Pagbu-book mula sa",
        "bookingType": "Uri ng booking",
        "companyName": "kumpanya",
        "driverName": "Driver",
        "driverIdPermit": "ID# / Pambansang IC#",
        "totalFare": "Kabuuang pamasahe nang walang promo",
        "subtotal": "Subtotal",
        "airportFee": "Bayad sa paliparan",
        "meetDriver": "Kilalanin ang driver",
        "tollFee": "Toll fee",
        parkingFee: 'Parking fee',
        gasFee: 'Gas fee',
        "rushHour": "Rush hour",
        "transactionFee": "Bayad sa transaksyon",
        "techFee": "bayad sa tech",
        "salesTax": "Buwis sa pagbebenta",
        "tipAmt": "Tip amt",
        "bookingFee": "Bayad sa pagpapareserba",
        "commission": "Komisyon",
        "deduction": "Pagbawas",
        "promoAmt": "Promo amt",
        "ridePayment": "Pagbabayad sa pagsakay",
        "driverTax": "Buwis sa pagmamaneho",
        "grossEarning": "Kabuuang kita",
        "driverDeduction": "Pagbawas ng driver",
        "netEarning": "Net na Kita",
        "payToDriver": "Magbayad sa driver",
        "payToFleet": "Magbayad sa fleet",
        "totalNotRounded": "ROUND (Kabuuang amt)",
        "fleetServiceFee": "Mga bayarin sa serbisyo ng fleet",
        "editedDriverEarning": "Na-edit na kita ng driver"
      },
      "financialCompany": {
        "companyName": "kumpanya",
        "homeFleetNetEarning": "Home fleet Net Kita",
        "affiliateNetEarning": "Affiliate Net Kita",
        "totalNetEarning": "Total Driver Net Kita",
        "status": "Katayuan",
        "companyAmount": "Kita ng kumpanya"
      },
      "companySettlement": {
        "companyName": "kumpanya",
        "pendingSettlement": "Nakabinbing settlement",
        "viewDetails": "Tingnan ang mga detalye",
        "settlementType": "Uri"
      },
      "companySettlementDetails": {
        "bookId": "Booking ID",
        "dateTime": "Oras ng petsa",
        "originalPaymentStatus": "Katayuan ng orihinal na pagbabayad",
        "subTotal": "Subtotal",
        "fleetAmount": "Dami ng fleet",
        "driverAmount": "Ang dami ng driver",
        "companyAmount": "Ang halaga ng kumpanya",
        "companyOweFleet": "Ang kumpanya ay may utang na armada",
        "fleetOweCompany": "Fleet na may utang na kumpanya",
        "notes": "Mga Tala"
      },
      "financialProfit": {
        "bookingId": "# ng booking",
        "subTotal": "Subtotal",
        "subTotalFF": "Subtotal na FF",
        "fleetCommission": "Komisyon ng fleet",
        "techFee": "bayad sa tech",
        "promotion": "Promosyon",
        "markupDifference": "Pagkakaiba ng markup",
        "Profit": "Kita",
        "currency": "Pera",
        "fleetServiceFee": "Serbisyo ng fleet (fleet take)",
        "fleetServiceFeeTooltip": "Ang halaga ng fleet ay kumukuha ng komisyon mula sa mga bayarin sa serbisyo"
      },
      "promotion": {
        "date": "Petsa",
        "promoCode": "Promo code",
        "users": "Mga gumagamit",
        "uses": "Mga gamit",
        "checked": "Sinuri",
        "applied": "Inilapat",
        "totalPromoAmount": "Kabuuang halaga ng promo",
        "totalReceipt": "Kabuuang resibo",
        "numberOfUsers": "Bilang ng mga gumagamit",
        "numberOfPromoCodeAppliance": "Bilang ng promo code appliance",
        "numberOfSuccessfulBookings": "Bilang ng mga matagumpay na booking",
        "numberOfCompletedBookings": "Bilang ng mga nakumpletong booking",
        "numberOfCompletedUsers": "Bilang ng mga nakumpletong user",
        "numberOfUsersApplyPromotion": "Bilang ng mga gumagamit na nag-aplay ng promosyon",
        "numberOfUsersApplyPromotionAndCompleteBooking": "Bilang ng mga user na nag-apply ng promosyon at kumpletong booking",
        "numberOfPromoCodeApplianceTooltip": "Bilang ng promo code appliance ng lahat ng user",
        "numberOfSuccessfulBookingsTooltip": "Bilang ng mga booking na matagumpay na nagawa gamit ang promotion code",
        "numberOfCompletedBookingsTooltip": "Bilang ng mga nakumpletong booking na naglapat ng promotion code"
      },
      "export": {
        "createdDate": "Petsa",
        "sessionName": "Pangalan ng Sesyon",
        "download": "I-download",
        "delete": "Tanggalin",
        "percent": "Porsiyento",
        "status": "Katayuan",
        "name": "Pangalan ng module"
      },
      "redeemed": {
        "date": "Petsa",
        "code": "Code",
        "customers": "Mga customer",
        "phone": "Numero ng telepono",
        "value": "Halaga",
        "currency": "Pera",
        "campaignName": "Kampanya"
      },
      "carActivity": {
        "plateNumber": "plaka ng sasakyan",
        "vehicleType": "Uri ng kotse",
        "companyName": "kumpanya",
        "day1st": "1st",
        "day2nd": "2nd",
        "day3rd": "3rd",
        "day4th": "4th",
        "day5th": "5th",
        "day6th": "6th",
        "day7th": "7th",
        "day8th": "8th",
        "day9th": "9th",
        "day10th": "10th",
        "day11th": "11th",
        "day12th": "12th",
        "day13th": "13th",
        "day14th": "14th",
        "day15th": "15th",
        "day16th": "16th",
        "day17th": "17th",
        "day18th": "18th",
        "day19th": "19th",
        "day20th": "20th",
        "day21st": "21st",
        "day22nd": "22nd",
        "day23rd": "23rd",
        "day24th": "24th",
        "day25th": "25th",
        "day26th": "26th",
        "day27th": "27th",
        "day28th": "28th",
        "day29th": "29th",
        "day30th": "30th",
        "day31st": "31st",
        "operationDays": "Mga araw ng operasyon",
        "activeCars": "Kabuuang aktibong sasakyan:"
      },
      "carActivityLogsTitle": "Mga log",
      "carActivityLogs": {
        "companyName": "kumpanya",
        "bookingId": "Booking ID",
        "driverName": "Pangalan ng driver",
        "driverIDNumber": "ID# / Pambansang IC#",
        "date": "Tinanggap na oras",
        "bookFrom": "Pagbu-book mula sa"
      },
      "affiliation": {
        "date": "Oras ng pagkuha",
        "bookingId": "Booking ID",
        "bookingType": "Uri ng booking",
        "homeFleet": "Home fleet",
        "providerFleet": "Fleet ng provider",
        "driver": "Driver",
        "passenger": "pasahero",
        "paidBy": "Binayaran ni",
        "airportFee": "Bayad sa paliparan",
        "meetDriver": "Kilalanin ang driver",
        "rushHour": "Patong na singil",
        "tollFee": "Toll fee",
        parkingFee: 'Parking fee',
        gasFee: 'Gas fee',
        "heavyTraffic": "Matinding trapiko",
        "otherFees": "Iba pang bayad",
        "provider": "Supplier",
        "basicFareProvider": "Basic fare provider",
        "subTotalProvider": "Sub kabuuang provider",
        "techFeeProvider": "Tagabigay ng bayad sa teknolohiya",
        "bookingFeeProvider": "Tagabigay ng bayad sa booking",
        "taxValueProvider": "Tagabigay ng halaga ng buwis",
        "tipProvider": "Tagabigay ng tip",
        "totalFareProvider": "Kabuuang tagapagbigay ng pamasahe",
        "basicFare": "Pangunahing pamasahe",
        "subtotal": "Subtotal",
        "techFee": "bayad sa tech",
        "bookingFee": "Bayad sa pagpapareserba",
        "tax": "Buwis",
        "tip": "Tip",
        "totalFare": "Kabuuang pamasahe",
        "payoutInLocalCurrency": "Sumakay sa kita sa lokal na pera",
        "payoutInUSD": "Kumita ng ride sa USD",
        "fleetCommInUSD": "Fleet comm sa USD",
        "bookingFeeInUSD": "Bayad sa booking sa USD",
        "totalPayoutInUSD": "Kabuuang payout sa USD",
        "subTotal": "Subtotal",
        "bookingStatus": "Katayuan ng booking",
        "vehicle": "Sasakyan",
        "customerPhone": "Numero ng telepono ng customer",
        "driverPhone": "Numero ng telepono ng driver",
        "total": "Kabuuan",
        "totalInUSD": "Kabuuang pamasahe (USD)",
        "supplierPayout": "Pagbabayad ng supplier",
        "supplierPayoutInUSD": "Payout ng supplier (USD)",
        "buyerPayout": "Komisyon ng mamimili",
        "buyerPayoutInUSD": "Komisyon (USD)",
        "buyerCommission": "Komisyon",
        "buyerCommissionInUSD": "Komisyon (USD)",
        "receivableInUSD": "Matatanggap (USD)",
        "payableInUSD": "Payable (USD)",
        "fleetMarkupInUSD": "Kita (USD)",
        "paymentMethod": "Paraan ng Pagbayad",
        "paymentStatus": "Katayuan ng pagbabayad"
      },
      "prepaidTopUp": {
        "date": "Petsa at oras",
        "topUpId": "Top up ID",
        "transactionId": "ID ng Transaksyon",
        "name": "Pangalan",
        "company": "kumpanya",
        "paidBy": "Binayaran ni",
        "amount": "Halaga",
        "newAmount": "Bagong balanse",
        "transactionType": "Uri ng transaksyon"
      },
      "operatorLogs": {
        "date": "Petsa",
        "operator": "Operator",
        "username": "Username",
        "module": "Module",
        "description": "Paglalarawan"
      },
      "corporate": {
        "date": "Petsa",
        "bookingId": "Booking ID",
        "operatorName": "Operator ng korporasyon",
        "corporateName": "kumpanya",
        "corpTraveler": "Mga Gumagamit ng Corp",
        "travelerSignature": "Lagda ng manlalakbay",
        "corpId": "Corp id",
        "corpDivision": "Dibisyon ng korporasyon",
        "costCentre": "Sentro ng gastos",
        "corpPO": "Corp PO",
        "managerName": "Pangalan ng manager",
        "managerEmail": "Email ng manager",
        "title": "Pamagat",
        "department": "Kagawaran",
        "clientCaseMatter": "Usapin sa kaso ng kliyente / Paglalarawan ng biyahe",
        "chargeCode": "Code ng singil / Code ng biyahe",
        "driverName": "Driver",
        "pickupLocation": "Lokasyon ng pickup",
        "destination": "Patutunguhan",
        "total": "Kabuuang amt",
        "subtotal": "Subtotal",
        "subTotalFF": "Subtotal FF",
        "commission": "Komisyon",
        "commissionBooking": "Mag-apply ng komisyon para sa mga booking mula sa app/pwa",
        "paidBy": "Binayaran ni",
        "statusDisplay": "Katayuan",
        "flightNumber": "Numero ng flight"
      },
      "bookingLogs": {
        "bookingId": "Booking ID",
        "bookingService": "Uri ng network",
        "bookingType": "Uri ng booking",
        "driverName": "Driver",
        "driverIdPermit": "ID# / Pambansang IC#",
        "customerName": "pasahero",
        "pickupTime": "Oras ng pagkuha",
        "onBoardTime": "Oras ng sakay",
        "droppedOffTime": "Oras ng pagbaba",
        "pickupLocation": "Lokasyon ng pickup",
        "destination": "Patutunguhan",
        "dispatchHistory": "Kasaysayan ng pagpapadala",
        "otwDistance": "O.T.W ang layo",
        "distanceTour": "distansya ng P.O.B",
        "duration": "Ang tagal ng P.O.B",
        "paidBy": "Binayaran ni",
        "bookFrom": "Pagbu-book Mula sa",
        "map": "Mapa",
        "showDispatchHistory": "Ipakita",
        "showMap": "Ipakita",
        "paymentActivities": "Mga aktibidad sa pagbabayad"
      },
      "rating": {
        "date": "Petsa",
        "bookingId": "Booking ID",
        "driverName": "Driver",
        "driverIdPermit": "ID# / Pambansang IC#",
        "customerName": "Pangalan ng Customer",
        "customerPhone": "Telepono ng customer",
        "like": "Gaya ng",
        "dislike": "hindi gusto",
        "notes": "Magkomento",
        "stars": "Marka",
        "vehicleRating": "Rating ng Sasakyan",
        "driverRating": "Rating ng Driver",
        "plateNumber": "Plate Number"
      },
      "mDispatcher": {
        "date": "Petsa",
        "bookingId": "Booking ID",
        "mDispatcherName": "Pangalan ng kasosyo",
        "mDispatcherType": "Uri ng kasosyo",
        "subtotal": "Subtotal",
        "commission": "Komisyon",
        "paidBy": "Binayaran ni"
      },
      "totalAdjustment": {
        "date": "Petsa",
        "bookingId": "Booking ID",
        "oldAmount": "Kabuuan",
        "type": "Uri",
        "markupDifference": "Pagkakaiba ng markup",
        "newAmount": "Na-edit ang kabuuan/Markup",
        "username": "Operator",
        "reason": "Dahilan"
      },
      "incidentCancellation": {
        "date": "Petsa",
        "bookingId": "Booking ID",
        "customerName": "Pangalan ng Customer",
        "customerPhone": "Telepono ng customer",
        "bookFrom": "Pagbu-book mula sa",
        "canceledBy": "Kinansela ni",
        "type": "Uri",
        "reason": "Dahilan",
        "reasonCode": [
          "Nakahanap ako ng isa pang opsyon sa paglalakbay",
          "Masyadong malayo ang driver",
          "Maling lokasyon ang pinasok ko",
          "Naghintay ako ng sobrang tagal",
          "Hiniling ng driver na kanselahin",
          "Binago ko ang aking mga plano",
          "Nais kong magpalit ng lokasyon",
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          "Karamihan o lahat ng mga item ay hindi magagamit",
          "Masyado kaming abala ngayon",
          "Sarado na kami ngayon",
          "Malapit na kaming magsara"
        ],
        "canceller": "Canceller"
      },
      "acceptanceCancellationRate": {
        "companyId": "Company Id",
        "driverId": "Driver Id",
        "driverName": "Pangalan ng driver",
        "phoneNumber": "Numero ng telepono",
        "company": "kumpanya",
        "acceptanceRate": "Rate ng pagtanggap",
        "cancellationRate": "Rate ng pagkansela",
        "rating": "Marka"
      },
      "revenueFleet": {
        "date": "Petsa",
        "fareRevenue": "Kita sa pamasahe",
        "trip": "Trip",
        "avgFareTrip": "Avg na pamasahe/biyahe",
        "drivers": "# Mga driver",
        "avgTripPerDriver": "Avg na biyahe/driver",
        "dispatcher": "# Dispatcher"
      },
      "revenueDriver": {
        "date": "Petsa",
        "driverName": "Driver",
        "fareRevenue": "Kita sa pamasahe",
        "trip": "# Trip",
        "avgFareTrip": "Avg na pamasahe/biyahe"
      },
      "revenueMonthly": {
        "month": "buwan",
        "trip": "Tapos na mga biyahe",
        "subtotal": "Subtotal",
        "tipAmt": "Tip",
        "techFee": "bayad sa tech",
        "salesTax": "Buwis",
        "partnerCommission": "Komisyon ng kasosyo/ ODD",
        "totalFare": "Kabuuang pamasahe nang walang promo",
        "netEarning": "Net na Kita",
        "fleetServiceFee": "Mga bayarin sa serbisyo ng fleet"
      },
      "dailyDriver": {
        "driverName": "Driver",
        "driverIDPermit": "ID# / Pambansang IC#",
        "driverPhone": "Telepono",
        "companyName": "kumpanya",
        "workingHoursOnline": "Online (oras)",
        "workingHoursAvailable": "Available (oras)",
        "driverWorkingHours": "Oras ng trabaho ng driver",
        "distance": "Layo ng nilakbay ng driver",
        "CC_activity": "Aktibidad ng CC",
        "fare_summary": "Aktibidad ng CC",
        "workingHoursNotAvailable": "Hindi available (mga oras)",
        "workingHoursPob": "P.O.B (mga oras)",
        "workingHoursPobToolTip": "Tagal ng sakay ng pasahero",
        "workingHoursAccepted": "Accepted",
        "workingHoursReject": "Tanggihan",
        "workingHoursIgnore": "Huwag pansinin",
        "workingHoursCancel": "Kanselahin",
        "completedTripsTotal": "Kabuuan",
        "completedTripsCc": "Mula sa CC",
        "completedTripsCarHailing": "Mula sa car-hailing",
        "completedTripsStreetSharing": "Mula sa Street Sharing",
        "completedTripsPaxApp": "Mula sa pax app",
        "completedTripsWebBooking": "Mula sa web booking",
        "completedTripsMDispatcher": "Mula sa partner",
        "completedTripsKiosk": "Mula sa Kiosk",
        "completedTripsApi": "Mula sa API",
        "ccActivityDispatchedTrips": "Mga pinadalang biyahe",
        "ccActivityAccepted": "Tinanggap",
        "ccActivityReject": "Tanggihan",
        "ccActivityIgnore": "Huwag pansinin",
        "ccActivityCancelByPassenger": "Kinansela ng pasahero",
        "ccActivityCancelByDriver": "Kinansela ng driver",
        "ccActivityCancelByCc": "Kinansela ni CC",
        "ccActivityCancelByAPI": "Kinansela ng API",
        "ccActivityNoShow": "Walang Palabas",
        "fareSummaryTotalCash": "Cash",
        "fareSummaryTotalCreditCard": "Credit card",
        "fareSummaryTotalExternalCard": "Panlabas na card",
        "fareSummaryTotalPaxWallet": "Wallet",
        "fareSummaryTotalDirectBilling": "Drect na pag-invoice",
        "fareSummaryTotalQrCode": "QR code",
        "fareSummaryTotalPrePaid": "Corporate Prepaid",
        "fareSummaryTotalApplePay": "Apple pay",
        "fareSummaryTotalTngeWallet": "TNG eWallet",
        "fareSummaryTotalVippseWallet": "Vipps",
        "fareSummaryTotal": "Kabuuan",
        "ratingNotes": "Bilang ng mga tala",
        "ratingDislike": "Bilang ng Hindi gusto",
        "ratingLike": "Bilang ng gusto",
        "fareDetailsBasicFare": "Pangunahing pamasahe",
        "fareDetailsAirportFee": "Bayad sa paliparan",
        "fareDetailsRushHour": "Patong na singil",
        "fareDetailsHeavyTraffic": "Matinding trapiko",
        "fareDetailsOtherFees": "Iba pang bayad",
        "fareDetailsSubTotal": "Subtotal",
        "fareDetailsSubTotalFF": "Subtotal FF",
        "fareDetailsTechFee": "bayad sa tech",
        "fareDetailsBookingFee": "Bayad sa pagpapareserba",
        "fareDetailsSalesTax": "Buwis sa pagbebenta",
        "fareDetailsTip": "Tip amt",
        "fareDetailsPromoAmount": "Promo amt",
        "fareDetailsTotalAmt": "Kabuuang amt",
        "availableDistance": "Available",
        "otwDistance": "O.T.W",
        "otwDistanceToolTip": "Sa layo ng daan",
        "pobDistance": "P.O.B",
        "pobDistanceToolTip": "Distansya ng pasahero sa board",
        "ratingStars1": "No. 1 star",
        "ratingStars2": "No. 2 stars",
        "ratingStars3": "No. 3 stars",
        "ratingStars4": "No. 4 stars",
        "ratingStars5": "No. 5 stars",
        "ratingAvg": "Katamtaman",
        "ssnNumber": "SSN #",
        "driverEmail": "Email address",
        "driverAddress": "Address ng bahay",
        "fareDetailsAddonPrice": "Customer ayusin ang presyo",
        "fareDetailsOldSubTotal": "Subtotal bago ayusin"
      },
      "dailyCar": {
        "carInfo": "Impormasyon ng sasakyan",
        "fareDetails": "Mga detalye ng pamasahe",
        "completedTrips": "Mga nakumpletong biyahe",
        "carInfoCompanyName": "kumpanya",
        "carInfoPermission": "Pahintulot",
        "carInfoVhcId": "# ng sasakyan",
        "carInfoCarType": "Uri ng kotse",
        "completedTripsTotal": "Kabuuan",
        "completedTripsCc": "Mula sa CC",
        "completedTripsCarHailing": "Mula sa car-hailing",
        "completedTripsStreetSharing": "Mula sa Street Sharing",
        "completedTripsPaxApp": "Mula sa pax app",
        "completedTripsWebBooking": "Mula sa web booking",
        "completedTripsMDispatcher": "Mula sa partner",
        "completedTripsKiosk": "Mula sa Kiosk",
        "completedTripsAPI": "Mula sa API",
        "fareDetailsBasicFare": "Pangunahing pamasahe",
        "fareDetailsAirportFee": "Bayad sa paliparan",
        "fareDetailsMeetDriver": "Kilalanin ang driver",
        "fareDetailsRushHour": "Patong na singil",
        "fareDetailsHeavyTraffic": "Matinding trapiko",
        "fareDetailsTollFee": "Toll fee",
        "fareDetailsParkingFee": "Parking fee",
        "fareDetailsGasFee": "Gas fee",
        "fareDetailsOtherFees": "Iba pang bayad",
        "fareDetailsSubTotal": "Subtotal",
        "fareDetailsSubTotalFF": "Subtotal FF",
        "fareDetailsTechFee": "bayad sa tech",
        "fareDetailsBookingFee": "Bayad sa pagpapareserba",
        "fareDetailsSalesTax": "Buwis sa pagbebenta",
        "fareDetailsTip": "Tip",
        "fareDetailsPromoAmount": "halaga ng promo",
        "fareDetailsTotalAmt": "Kabuuang amt",
        "fareDetailsAddonPrice": "Customer ayusin ang presyo",
        "fareDetailsOldSubTotal": "Subtotal bago ayusin"
      },
      "dailyDriverCar": {
        "driverInfo": "Impormasyon ng driver",
        "driverName": "Pangalan ng driver",
        "driverIDPermit": "ID# / Pambansang IC#",
        "driverPhone": "Numero ng telepono",
        "companyName": "kumpanya",
        "actionInfo": "Mga aksyon",
        "date": "Petsa",
        "action": "Aksyon",
        "bookingId": "Booking ID",
        "carInfo": "Impormasyon ng sasakyan",
        "permission": "Pahintulot",
        "carId": "# ng sasakyan",
        "carType": "Uri ng kotse"
      },
      "driverLoginStatus": {
        "driver": "Driver",
        "driverIdPermit": "ID# / Pambansang IC#",
        "phone": "Numero ng telepono",
        "username": "Username",
        "company": "kumpanya",
        "carType": "Uri ng kotse",
        "licensePlate": "Plaka",
        "status": "Katayuan"
      },
      "bookingSummary": {
        "date": "Petsa",
        "operator": "Operator",
        "username": "Username",
        "action": "Aksyon",
        "bookingId": "Booking ID"
      },
      "cashWallet": {
        "bankAccount": "Inilipat sa bank account",
        "creditWallet": "Inilipat sa credit wallet",
        "tipAfterCompleted": "Tip Pagkatapos Nakumpleto",
        "netEarning": "Net na Kita",
        "referralEarning": "Referral na Kita",
        "receiverAccount": "Receiver Account",
        "dateAndTime": "Petsa at oras",
        "driverName": "Pangalan ng driver",
        "phoneNumber": "Numero ng telepono",
        "userName": "Username",
        "company": "kumpanya",
        "type": "Uri",
        "bookingId": "# ng booking",
        "operator": "Operator",
        "reason": "Dahilan",
        "amount": "Halaga",
        "newAmount": "Bagong balanse",
        "currency": "Pera",
        "topUpGCashReason": "Top up sa Gcash",
        "topUpReason": "Mag-top up mula sa credit card *{0}",
        "cashWalletReason": "Mag-top up mula sa Cash Wallet",
        "topUpMOLPayReason": "Razer Pay [ {0} ]",
        "chargedAmount": "halaga ng singil",
        "differentPercent": "Diskwento / Dagdag na singil (%)",
        "operatorName": "Operator",
        "sendPayout": "Aksyon",
        "sendPayoutTitle": "Muling ipadala ang email ng payout",
        "payoutReason": "{0} (Payout {1} {2})"
      },
      "creditWallet": {
        "receiverType": "Uri ng Tatanggap",
        "receiverName": "Pangalan ng Makatatanggap",
        "receiverPhone": "Numero ng telepono ng tatanggap",
        "senderPhone": "Numero ng telepono ng nagpadala",
        "senderName": "Pangalan ng nagpadala",
        "sentToCustomer": "Ipadala sa customer",
        "sentToDriver": "Ipadala sa driver",
        "dateAndTime": "Petsa at oras",
        "driverName": "Pangalan ng driver",
        "phoneNumber": "Numero ng telepono",
        "userName": "Username",
        "company": "kumpanya",
        "type": "Uri",
        "bookingId": "# ng booking",
        "operator": "Operator",
        "reason": "Dahilan",
        "amount": "Halaga",
        "newAmount": "Bagong balanse",
        "currency": "Pera",
        "transactionId": "ID ng Transaksyon",
        "topUpGCashReason": "Top up sa Gcash",
        "topUpReason": "Mag-top up mula sa credit card *{0}",
        "cashWalletReason": "Mag-top up mula sa Cash Wallet",
        "topUpMOLPayReason": "Razer Pay [ {0} ]",
        "topUpWalletReason": "{0} [ {1} ]",
        "chargedAmount": "halaga ng singil",
        "differentPercent": "Diskwento / Dagdag na singil (%)",
        "cashExcessReason": "Dapat magbayad ang customer ng {0}; Natanggap ang pera {1}",
        "referralEarningReason": "Nakumpleto ng customer na {0} {1} ang unang booking {2}",
        "referralEarningReason_2": "Nakumpleto ng customer na {0} {1} ang pag-book {2}",
        "insurance": "Insurance sa pagmamaneho"
      },
      "passengerWallet": {
        "dateTime": "Petsa at oras",
        "customerName": "Pangalan ng Customer",
        "phoneNumber": "Numero ng telepono",
        "type": "Uri",
        "bookingId": "# ng booking",
        "changedAmount": "Binago ang halaga",
        "amount": "Binago ang halaga",
        "newBalance": "Bagong balanse",
        "currency": "Pera",
        "transactionId": "ID ng Transaksyon",
        "Note": "Tandaan",
        "credit": "Topup sa pamamagitan ng credit card *{0}",
        "TnG": "Topup ng TnG #{0}",
        "MOLPay": "Mag-top up mula sa [RazerPay - #{0}]",
        "gatewayName": "Top up mula sa [{0} - #{1}]",
        "cashExcess": "Ang customer ay dapat magbayad ng {0}, Cash na ibinigay {1}",
        "editBalance": "I-edit ang Balanse",
        "ride": "Sumakay",
        "topUp": "Top up",
        "topUpReferee": "Referral na reward ({0})",
        "topUpReferrer": "Bagong referee (#{0}): {1} ({2})"
      },
      "recipientsInfo": {
        "order": "Bilang",
        "orderId": "Order #",
        "name": "Pangalan ng makakatanggap",
        "phone": "Telepono ng tatanggap #",
        "address": "Recipient address",
        "deliveryFee": "Bayad sa paghahatid",
        "ItemValue": "Halaga ng Item (COD)",
        "status": "Katayuan",
        "Performer": "Tagapagganap",
        "Reason": "Dahilan",
        "proofOfFailure": "Patunay ng kabiguan",
        "referenceNumber": "Numero ng Sanggunian"
      },
      "dispatchHistory": {
        "performer": "Tagapagganap",
        "name": "Pangalan",
        "action": "Aksyon",
        "date": "Petsa",
        "location": "Lokasyon ng driver",
        "estimate": "Pagtatantya ng lokasyon ng sasakyan-sa-pickup",
        "passenger": "Customer"
      },
      "payToDriver": {
        "driverName": "Driver",
        "totalUnsettledTransactions": "Kabuuang hindi maayos na mga transaksyon",
        "totalUnsettledAmount": "Kabuuang hindi naayos na halaga",
        "actions": "Aksyon",
        "pay": "Magbayad",
        "remove": "Alisin",
        "clearSuccess": "Matagumpay na na-clear ang kita ng napiling driver.",
        "clearFailed": "Nabigong i-clear ang data ng kita ng driver. Pakisubukang muli.",
        "selectDriver": {
          "body": "Mangyaring pumili ng driver na babayaran.",
          "buttonTitle": "OK"
        },
        "confirmClear": {
          "body": "Sigurado ka bang gusto mong i-clear ang data ng kita ng driver na ito? Ang gawaing ito ay hindi pwedeng baguhin.",
          "buttonTitle": "Oo",
          "closeButtonText": "Hindi"
        },
        "confirmPay": {
          "body": "Sigurado ka bang gusto mong magbayad sa (mga) napiling driver?",
          "buttonTitle": "Oo",
          "closeButtonText": "Hindi"
        },
        "paymentSuccessfully": "Matagumpay na naayos ang mga pagbabayad sa (mga) driver"
      },
      "customerQuest": {
        "name": "Pangalan",
        "phoneNumber": "Numero ng telepono",
        "supportId": "ID ng suporta",
        "ranking": "Pagraranggo",
        "points": "Mga puntos",
        "numberOfReservation": "Bilang ng reserbasyon",
        "driver": "Driver",
        "customer": "Customer",
        "firstWillWin": "Unang mananalo",
        "firstWillWinLuckyDraw": "Una ang mananalo (lucky draw)",
        "largestWillWin": "Pinakamalaki ang mananalo (leaderboard)",
        "qualified": "Kwalipikadong",
        "notQualified": "Hindi qualified",
        "userName": "User name",
        "completedDate": "Nakumpleto ang petsa",
        "isQualified": "Katayuan",
        "ticket": "Ticket",
        "numOfComplete": "Bilang ng natapos",
        "bookingDistance": "distansya ng P.O.B",
        "numberOfIntercity": "Bilang ng intercity",
        "numberOfDelivery": "Bilang ng paghahatid",
        "pleaseSelectEventName": "Mangyaring piliin ang pangalan ng kaganapan.",
        "numberOfQualifiedSetting": "Bilang ng mga kwalipikado (setting):",
        "numberOfActualQualified": "Bilang ng aktwal na kwalipikado:",
        "driverRating": "Rating ng Driver",
        "acceptanceRating": "Rating ng Pagtanggap",
        "cancellationRating": "Rating ng Pagkansela",
        "period": "Panahon:"
      },
      "payoutToDriver": {
        "IFSCCode": "IFSC Code",
        "payoutID": "Payout ID",
        "driverName": "Driver",
        "driverPhone": "Numero ng telepono",
        "companyName": "kumpanya",
        "period": "Panahon:",
        "driverId": "ID# / Pambansang IC#",
        "cashWalletBalance": "Balanse ng cash wallet",
        "creditBalance": "Credit balance",
        "holdAmount": "Hawak ang halaga",
        "minPayout": "Min payout",
        "enterMinimumPayoutAmount": "Ipasok ang pinakamababang halaga ng payout",
        "payoutAmount": "Halaga ng bayad",
        "payoutDate": "Petsa ng pagbabayad",
        "actions": "Aksyon",
        "pay": "Magbayad",
        "remove": "Alisin",
        "payoutEndTime": "Oras ng pagtatapos ng pagbabayad",
        "clearSuccess": "Matagumpay na na-clear ang kita ng napiling driver.",
        "clearFailed": "Nabigong i-clear ang data ng kita ng driver. Pakisubukang muli.",
        "amountKeepInDriverCashWallet": "Halagang itago sa Cash Wallet ng driver",
        "selectDriver": {
          "title": "pamagat",
          "body": "Mangyaring pumili ng driver na babayaran.",
          "buttonTitle": "OK"
        },
        "confirmClear": {
          "body": "Sigurado ka bang gusto mong i-clear ang data ng kita ng driver na ito? Ang gawaing ito ay hindi pwedeng baguhin.",
          "buttonTitle": "Oo",
          "closeButtonText": "Hindi"
        },
        "confirmPay": {
          "body": "Sigurado ka bang gusto mong magbayad sa (mga) napiling driver? <br> - Bilang ng mga driver: <b>%{drivers}</b> <br> - Min payout out para sa bawat driver: %{currency} <b>%{min}</b> <br> - Hold halaga: %{currency}<b> %{hold}</b> <br> - Kabuuang payout: %{currency} <b>%{total}</b>",
          "buttonTitle": "Oo",
          "closeButtonText": "Hindi"
        },
        "paymentSuccessfully": "Matagumpay na naayos ang mga pagbabayad sa (mga) driver",
        "paymentCompleted": "Nakumpleto ang pagbabayad: <br> - Matagumpay na magbayad: %{success} <br> - Nabigo: %{failed} <br> - Matagumpay na kabuuang payout: %{currency} %{total}",
        "noteSelectDrivers": "Tandaan: piliin ang mga driver na may bank account na babayaran.",
        "sendPayout": "Magpadala ng matagumpay na notification ng payout sa email address",
        "accountNumber": "Account number",
        "iBanNumber": "Numero ng IBAN",
        "accountHoldName": "Pangalan ng may hawak ng account",
        "bankName": "Pangalan ng bangko",
        "selelctedDriverHasBeenPaid": "Nabayaran na ang (mga) napiling driver",
        "selelctedDriverHasBeenFailed": "Nabigo ang (mga) napiling driver",
        "selelctedDriverHasBeenUpdateAsInvalid": "Na-update ang napiling (mga) driver bilang 'Di-wastong bank account'",
        "selelctedDriverHasBeenUpdateAsValid": "Na-update ang napiling (mga) driver bilang 'Valid na bank account'",
        "isBankAccountOwner": "Ang benepisyaryo ay katulad ng driver",
        "isBankAccountOwner_true": "Oo",
        "isBankAccountOwner_false": "Hindi",
        "beneficiaryIDIC": "ID# / IC# ng benepisyaryo",
        "bankRelationship": "Relasyon",
        "bankRelationship_1": "asawa",
        "bankRelationship_2": "Anak na babae anak na lalaki",
        "bankRelationship_3": "Kuya/Ate",
        "bankRelationship_4": "Kamag-anak",
        "bankRelationship_5": "Mga kaibigan",
        "bankRelationship_6": "Iba pa: {0}"
      },
      "payoutToMerchant": {
        "payoutId": "Payout ID",
        "merchantName": "Pangalan ng Merchant",
        "phone": "Numero ng telepono",
        "accountNumber": "Account number",
        "iBanNumber": "Numero ng IBAN",
        "bankAccountHolder": "Pangalan ng may hawak ng account",
        "bankName": "Pangalan ng bangko",
        "pendingAmount": "Nakabinbing halaga",
        "payoutAmount": "Halaga ng bayad",
        "payoutDate": "Petsa ng pagbabayad",
        "holdAmount": "Hawak ang halaga",
        "minPayout": "Min payout",
        "selelctedMerchantHasBeenPaid": "Nabayaran na ang (mga) napiling merchant",
        "selelctedMerchantHasBeenFailed": "Nabayaran na ang (mga) napiling merchant",
        "pay": "Magbayad",
        "selectMerchant": {
          "title": "Babala",
          "body": "Mangyaring pumili ng merchant na babayaran.",
          "buttonTitle": "OK"
        },
        "confirmPay": {
          "body": "Sigurado ka bang gusto mong magbayad sa napiling (mga) merchant? <br> - Bilang ng mga merchant: <b>%{merchants}</b> <br> - Min payout out para sa bawat driver: %{currency} <b>%{min}</b> <br> - Hold halaga: %{currency}<b> %{hold}</b> <br> - Kabuuang payout: %{currency} <b>%{total}</b>",
          "buttonTitle": "Oo",
          "closeButtonText": "Hindi"
        },
        "sendPayout": "Magpadala ng matagumpay na notification ng payout sa email address",
        "HasBeenUpdateAsInvalid": "Na-update ang napiling (mga) merchant bilang 'Di-wastong bank account'",
        "HasBeenUpdateAsValid": "Na-update ang napiling (mga) merchant bilang 'Valid na bank account'"
      },
      "settlementHistory": {
        "date": "Petsa",
        "driverName": "Pangalan ng driver",
        "dateRange": "Saklaw ng petsa",
        "totalTransaction": "Kabuuang naayos na mga transaksyon",
        "paidAmount": "Kabuuang naayos na halaga"
      },
      "settlement": {
        "driverName": "Driver",
        "driverIdPermit": "ID# / Pambansang IC#",
        "totalBookings": "Kabuuang booking",
        "total": "Kabuuang amt",
        "approved": "Approved",
        "acct": "ACCT",
        "gross": "GROSS",
        "percent": 0.03,
        "net": "NET"
      },
      "documentExpiry": {
        "driverName": "Pangalan ng driver",
        "driverPhone": "Telepono ng driver",
        "company": "kumpanya",
        "documentName": "Pangalan ng dokumento",
        "expiredDate": "Expired date",
        "status": "Katayuan",
        "statusStr": {
          "NA": "N/A",
          "expired": "Nag-expire na",
          "active": "Aktibo",
          "expireSoon": "Malapit nang mag-expire"
        }
      },
      "referralDetail": {
        "bookingId": "Book #",
        "completedDate": "Nakumpletong Oras",
        "paidBy": "Binayaran ni",
        "bookFrom": "Pagbu-book mula sa",
        "driverName": "Pangalan ng driver",
        "driverIdPermit": "ID# / Pambansang IC#",
        "companyName": "kumpanya",
        "Passenger": "pasahero",
        "Customer": "Customer",
        "subtotal": "Subtotal",
        "subTotalFF": "Subtotal na FF",
        "subtotalWithPromo": "Subtotal na may promosyon",
        "referralEarning": "Referral na Kita",
        "Amount": "Halaga",
        "Note": "Tandaan",
        "Currency": "Pera",
        "PercentOfSubTotal": "{0}% ng subtotal",
        "max": "max {0}",
        "referrerInfo": "Tinutukoy ng driver ang customer",
        "phoneReferral": "Telepono ng driver #",
        "driverNumber": "ID# / Pambansang IC#",
        "referralCode": "Referral code"
      },
      "referralHistory": {
        "driverName": "Pangalan ng driver",
        "phoneNumber": "Numero ng telepono",
        "companyName": "kumpanya",
        "referralCode": "Referral code",
        "commission": "Kabuuang komisyon",
        "expectedReferralEarning": "Asahan ang kita ng referral",
        "activePaxTooltip": "Ang mga pasaherong nag-redeem ng referral code mula sa driver na may kahit isang booking ay matagumpay na nagawa.",
        "referredPaxTooltip": "Mga pasaherong nag-redeem ng referral code mula sa driver ngunit hindi pa nakakagawa ng anumang booking.",
        "activePax": "Kabuuang mga aktibong customer",
        "referredPax": "Kabuuang tinukoy na mga customer",
        "detailFields": {
          "passengerId": "ID ng pasahero",
          "passengerName": "Pangalan ng Customer",
          "firstBookingDate": "Unang nakumpletong petsa ng booking",
          "firstBookingDateGMT": "Unang nakumpletong petsa ng booking (GMT)",
          "passengerPhone": "Telepono ng customer",
          "appliedDate": "Referred date",
          "referredByDriver": "Tinukoy ng driver",
          "driverPhone": "Numero ng telepono ng driver",
          "referralCode": "Referral code"
        },
        "showDetail": "Ipakita",
        "detail": "Detalye"
      },
      "paxReferralHistory": {
        "customerName": "Pangalan ng Customer",
        "phoneNumber": "Numero ng telepono",
        "referralCode": "Referral code",
        "commission": "Kabuuang komisyon",
        "activePaxTooltip": "Ang mga pasaherong na-redeem ang referral code na may kahit isang booking ay matagumpay na nagawa.",
        "referredPaxTooltip": "Mga pasaherong na-redeem ang referral code ngunit hindi pa nakakagawa ng anumang booking.",
        "activePax": "Kabuuang mga aktibong customer",
        "referredPax": "Kabuuang tinukoy na mga customer",
        "detailFields": {
          "passengerId": "ID ng pasahero",
          "passengerName": "Pangalan ng pasahero",
          "firstBookingDate": "Unang nakumpletong petsa ng booking",
          "firstBookingDateGMT": "Unang nakumpletong petsa ng booking (GMT)"
        },
        "showDetail": "Ipakita",
        "detail": "Detalye"
      },
      "driverRefersDriver": {
        "companyName": "kumpanya",
        "phoneNumber": "Numero ng telepono",
        "username": "Username",
        "referralCode": "Referral code",
        "referrer": "Referrer",
        "numberOfReferees": "Bilang ng mga referee",
        "activeReferees": "Mga aktibong referee",
        "referee": "Referee",
        "refereePhone": "Numero ng telepono ng referee",
        "refereeUsername": "username ng referee",
        "referredDate": "Referred date",
        "activatedDate": "Na-activate na petsa",
        "referrerByDriver": "Referrer",
        "referrerPhone": "Numero ng telepono ng referrer",
        "referralStatus": "Katayuan ng referral",
        "lifeTimeBookings": "Mga life time booking",
        "inReview": "Sa pagsusuri",
        "activated": "Na-activate"
      },
      "driverWithdrawal": {
        "currentBalanceTooltip": "Ang kasalukuyang balanse ng cash wallet ng driver bago ang kahilingan sa pag-withdraw",
        "paymentTime": "Oras ng pagbabayad",
        "requestTime": "Oras ng kahilingan",
        "referenceId": "Reference #",
        "transactionId": "ID ng Transaksyon",
        "description": "Paglalarawan",
        "transferAmount": "halaga ng paglipat",
        "currency": "Pera",
        "approved": "Approved",
        "rejected": "Tinanggihan",
        "failed": "Nabigo",
        "reason": "Dahilan"
      },
      "penaltyCompensation": {
        "penaltyAmount": "Parusa",
        "totalCollect": "Kailangang magbayad sa lokal na pera",
        "totalCollectInUSD": "Kailangang magbayad sa USD",
        "compensationAmount": "Magbayad",
        "compensationAmountInUSD": "Compensate (USD)",
        "penaltyReason": "Dahilan ng Parusa",
        "estimatedPrice": "Tinatayang presyo",
        "estimatedPriceInUSD": "Tinantyang presyo (USD)",
        "penaltyAmountInUSD": "Parusa (USD)",
        "amount": "Halaga",
        "amountInUSD": "Halaga (USD)"
      },
      "imcompletePayment": {
        "bookingId": "Booking ID",
        "bookFrom": "Pagbu-book mula sa",
        "bookingTime": "Petsa/Oras na Pag-book",
        "completedTime": "Pangalan ng Customer",
        "customerName": "Pangalan ng Customer",
        "requestedPaymentMethod": "Hiniling na paraan ng pagbabayad",
        "originalPaymentStatus": "Katayuan ng orihinal na pagbabayad",
        "currentPaymentStatus": "Kasalukuyang katayuan ng pagbabayad",
        "updatedDateTime": "Petsa/Oras na nakolekta ang Balanse",
        "totalAmt": "Kabuuang amt",
        "originalOutstandingAmount": "Orihinal na natitirang halaga",
        "currentOutstandingAmount": "Kasalukuyang natitirang halaga",
        "outstandingAmountPaidBy": "Natitirang halaga na kinokolekta ng",
        "writeOffDebtMethod": "Natitirang halaga na kinokolekta ng",
        "reason": "Dahilan",
        "customerPhone": "Numero ng telepono",
        "amountCollected": "Halagang nakolekta",
        "partialPaymentAmount": "Bahagyang bayad kapag bumaba"
      },
      "driverDeposit": {
        "dateAndTime": "Petsa at oras",
        "userName": "Username",
        "type": "Uri",
        "newAmount": "Bagong balanse",
        "topUpGCashReason": "Top up by Gcash",
        "topUpReason": "Mag-top up mula sa credit card *{0}",
        "cashWalletReason": "Mag-top up mula sa Cash Wallet",
        "topUpMOLPayReason": "Razer Pay [ {0} ]"
      },
      "refund": {
        "fullRefund": "Buong refund",
        "partialRefund": "Bahagyang refund",
        "tripId": "Trip ID",
        "route": "Ruta",
        "refundAmount": "halaga ng refund",
        "refundTime": "Oras ng refund",
        "paymentStatus": "Katayuan ng pagbabayad"
      },
      "merchant": {
        "store": "Tindahan",
        "name": "Pangalan",
        "phoneNumber": "Numero ng telepono",
        "bookingId": "# ng booking",
        "totalOrders": "Kabuuang mga order",
        "completedOrders": "Nakumpleto ang mga order",
        "canceledOrders": "Kinansela ang mga order",
        "incidentOrders": "Insidente",
        "revenue": "Kita",
        "commission": "Komisyon",
        "earning": "Kumita",
        "food": "Pagkain",
        "mart": "Mart",
        "preferredMerchant": "Ginustong mangangalakal",
        "regularMerchant": "Regular na mangangalakal"
      },
      "merchantCashTransactionHistory": {
        "dateTime": "Petsa at oras",
        "merchantUserName": "Gumagamit ng mangangalakal",
        "merchantUserPhone": "Numero ng telepono",
        "merchantUserEmail": "Email",
        "merchantName": "Mangangalakal",
        "transactionType": "Uri",
        "bookId": "# ng booking",
        "transactionId": "ID ng Transaksyon",
        "operatorName": "Operator",
        "reason": "Dahilan",
        "amount": "Halaga",
        "newBalance": "Bagong balanse",
        "currencyISO": "Pera"
      },
      "merchantCreditTransactionHistory": {
        "dateTime": "Petsa at oras",
        "merchantUserName": "Gumagamit ng mangangalakal",
        "merchantUserPhone": "Numero ng telepono",
        "merchantUserEmail": "Email",
        "merchantName": "Mangangalakal",
        "transactionType": "Uri",
        "bookId": "# ng booking",
        "transactionId": "ID ng Transaksyon",
        "operatorName": "Operator",
        "reason": "Dahilan",
        "amount": "Halaga",
        "newBalance": "Bagong balanse",
        "currencyISO": "Pera"
      },
      "deleteAccountRequest": {
        "date": "Petsa",
        "phone": "Telepono",
        "username": "Username",
        "supportId": "ID ng suporta",
        "requestFrom": "Kahilingan mula sa",
        "associatedApps": "Mga nauugnay na app",
        "reason": "Dahilan"
      },
      "affiliationPayoutHistory": {
        "payoutId": "Payment ID",
        "dateTime": "Petsa at oras",
        "payoutType": "Uri",
        "accountHolderName": "Pangalan ng may hawak ng account",
        "bankName": "Pangalan ng bangko",
        "accountNumber": "Account number",
        "totalPayout": "Kabuuang bayad (USD)",
        "viewDetails": "Tingnan ang mga detalye",
        "notes": "Note",
        "bookId": "# ng booking",
        "transactionType": "Uri ng transaksyon",
        "transactionId": "Transaction Id (auto transfer)",
        "settlement": "Settlement",
        "paymentMethod": "Uri",
        "networkType": "Uri ng network"
      },
      "thirdPartyBooking": {
        "date": "Petsa",
        "bookingId": "Booking ID",
        "externalId": "Panlabas na ID",
        "psgPhone": "Numero ng telepono",
        "psgName": "Pangalan ng pasahero",
        "pickupLocation": "Lokasyon ng pickup",
        "pickupLocationLatLong": "Ang lokasyon ng pickup ay lat/mahaba",
        "dropoffLocation": "Lokasyon ng dropoff",
        "dropoffLocationLatLong": "Ang lokasyon ng dropoff ay lat/mahaba",
        "pickupTime": "Oras ng pagkuha",
        "numOfPassenger": "Bilang ng pasahero",
        "action": "Aksyon"
      }
    }
  },
  "project": {
    "name": "QUp Bagong CC"
  },
  "zoneSetting": {
    "Add": "Idagdag",
    "Import": "Angkat",
    "Name": "Pangalan",
    "Status": "Katayuan",
    "Active": "Aktibo",
    "Inactive": "Hindi aktibo",
    "Deactivate": "I-deactivate",
    "Activate": "I-activate",
    "Delete": "Tanggalin",
    "Cancel": "Kanselahin",
    "Input_zone_name": "Pangalan ng zone ng input",
    "Save_zone": "I-save ang zone",
    "Clear_draw": "Malinaw na draw",
    "Edit": "I-edit",
    "Delete_zone_success": "Matagumpay na natanggal ang Zone",
    "Delete_zone_fail": "Naganap ang mga error habang tinatanggal ang zone",
    "Update_zone_fail": "Naganap ang mga error habang ina-update ang zone",
    "Update_zone_success": "Matagumpay na na-update ang Zone hase",
    "Create_zone_fail": "Naganap ang mga error habang gumagawa ng zone",
    "Create_zone_success": "Matagumpay na nalikha ang bagong zone",
    "Draw_zone": "Mangyaring gumuhit ng zone sa mapa",
    "Import_zones_successful": "Matagumpay ang mga import zone",
    "Import_zones_fail": "Nabigong mag-import ng mga zone",
    "DELETE_ZONE": "Tanggalin ang Zone",
    "ZONE_CONFIRM": "Sigurado ka bang gusto mong tanggalin ang napiling zone ?",
    "Name_zone_required": "Pangalan at Sona ang kailangan!",
    "Select_file": "Pumili ng file",
    "No": "Hindi",
    "Yes": "Oo",
    "Import_zones_intercity_successful": "Matagumpay na na-import ang [{0}] (mga) zone, nabigo ang [{1}].",
    "Import_zones_intercity_successful_failed": "Matagumpay na na-import ang [{0}] (mga) zone, nabigo ang [{1}].",
    "Flat_zone": "Flat zone",
    "Json_file": "Json file",
    "Import_zones": "Import zones",
    "timezone": "Time Zone"
  },
  "companySetting": {
    "DELETE_COMPANY": "Tanggalin ang kumpanya",
    "DELETE_CONFIRM": "Gusto mo bang tanggalin ang kumpanya?",
    "DETAIL_COMPANY": "Detalye ng kumpanya",
    "commissionValue": "Komisyon ng kumpanya",
    "connectStatus": "Stripe Connect",
    "connectStripe": "Kumonekta",
    "stripeConnectId": "Stripe account ID",
    "loginStripe": "Dashboard",
    "disconnectStripe": "Idiskonekta",
    "Yes": "Oo",
    "No": "Hindi",
    "Cancel": "Kanselahin",
    "EDIT_COMPANY": "I-EDIT ANG SUPPLIER NG COMPANY",
    "ADD_COMPANY": "ADD COMPANY SUPPLIER",
    "Name": "Pangalan",
    "Required_fields": "Mga kailangang punan",
    "Save": "I-save",
    "Actions": "Aksyon",
    "Edit": "I-edit",
    "Add": "Idagdag",
    "Show_per_page": "Mga item bawat pahina",
    "Page": "Pahina",
    "show_pages": "{0} - {1} ng {2} ang ipinakita",
    "Create_company_success": "Matagumpay na nalikha ang bagong kumpanya",
    "Create_company_fail": "Naganap ang mga error habang gumagawa ng kumpanya",
    "Update_company_success": "Ang kumpanya ay matagumpay na na-update",
    "Update_company_fail": "Naganap ang mga error habang ina-update ang kumpanya",
    "Load_company_fail": "Naganap ang mga error habang nilo-load ang kumpanya",
    "Delete_company_success": "Matagumpay na natanggal ang kumpanya",
    "Delete_company_fail": "Naganap ang mga error habang tinatanggal ang kumpanya",
    "hideFare": "Laging itago ang pamasahe",
    "paymentMethodsApply": "Suportahan ang paraan ng pagbabayad"
  },
  "dispatchSetting": {
    "GENERAL": "Heneral",
    "Offer_interval": "Interval ng alok (driver)",
    "Offer_interval_merchant": "Agwat ng alok (merchant)",
    "Offer_interval_merchant_toolTip": "Pinakamataas na oras para sa isang merchant na tumanggap ng isang order",
    "Offer_interval_toolTip": "Pinakamataas na oras para sa isang driver na tumanggap ng booking",
    "soundMarketplace": "Tunog",
    "sound": "Tunog",
    "Auto_re_dispatch_interval_toolTip": "Oras ng pahinga sa pagitan ng 2 round ng pagpapadala",
    "Maximum_auto_dispatch_duration_toolTip": "Pinakamataas na oras ng pagpapadala ng booking sa mga driver",
    "Seconds": "Mga segundo",
    "Auto_re_dispatch_interval": "Auto re-dispatch interval",
    "Maximum_auto_dispatch_duration": "Pinakamataas na tagal ng auto-dispatch",
    "Time_out": "Time out",
    "mins": "mins",
    "minTimeBeforeReservation": "Huwag payagan ang Driver na magsimula ng reserbasyon bago ang oras ng pagkuha",
    "IN_ADVANCE": "IN ADVANCE",
    "Assign_reservation_to_driver": "Magtalaga ng reserbasyon sa driver",
    "Dispatcher_confirms_job_for_driver": "Kinukumpirma ng dispatcher ang trabaho para sa driver",
    "Dispatcher_confirms_job_for_offline_driver": "Kinukumpirma ng dispatcher ang pagpapareserba para sa offline na driver",
    "Minimum_book_ahead": "Minimum na libro sa unahan",
    "Maximum_book_ahead": "Maximum na aklat sa unahan - isang order",
    "Maximum_period_recurring_oders_fleet": "Pinakamataas na panahon para sa mga umuulit na order (fleet operator)",
    "Maximum_period_recurring_oders_corp": "Pinakamataas na panahon para sa mga umuulit na order (corporate)",
    "Day": "Araw",
    "Week": "Linggo",
    "Month": "buwan",
    "hrs": "oras",
    "Driver_cancellation_duration": "Tagal ng pagkansela ng driver",
    "Mins": "mins",
    "Maximum_Day": "Maaaring tanggapin ng driver ang maximum na reservation sa isang araw.",
    "Maximum_Accept_Reservation": "Kabuuang bilang ng mga reservation na maaaring tanggapin ng isang driver sa isang pagkakataon.",
    "Local_notification_before": "Lokal na abiso bago",
    "Dispatch_mode": "Dispatch mode",
    "Manual": "Manwal",
    "Immediate_auto_dispatch": "Agarang awtomatikong pagpapadala",
    "Delayed_auto_dispatch": "Naantala ang auto-dispatch",
    "Auto_dispatch_before_pickup_time": "Auto dispatch bago ang oras ng pickup",
    "Required_fields": "Mga kailangang punan",
    "Shuttle": "SHUTTLE",
    "Shuttle_Dispatch_mode": "Dispatch mode",
    "Dispatch_setting_Shuttle_Dispatch_mode_tooltip": "- Manual mode: Ise-save ang reservation at mananatili sa Cue ng iyong Command Center sa ilalim ng status na \"Nakabinbin\" kapag nagawa na ang reservation. Pagkatapos mag-click sa \"I-update ang booking\" sa detalye ng booking, ipapadala ang reserbasyon sa iyong mga nakatalagang driver o sa lahat ng mga driver kung sakaling walang magtalaga ng driver. Isasama ito sa kalendaryo ng driver device at magiging available ang paalala. <br>- Delayed mode:Ise-save ang reservation at mananatili sa Cue ng iyong Command Center sa ilalim ng status na “Pending” kapag nagawa na ang reservation. Awtomatikong ipapadala ang reserbasyon bago ang \"halaga ng oras sa \"Proseso nang maaga\"\" mula sa oras ng pag-pick up sa nakatalagang driver o sa pinakamalapit na available kung sakaling walang magtalaga ng driver. <br>- Agad na auto-dispatch mode: Awtomatikong ipapadala ang reserbasyon sa lahat ng driver kapag nagawa na ang reservation. Isasama ito sa kalendaryo ng driver device at magiging available ang paalala.",
    "Save": "I-save",
    "Process_in_advance": "Proseso nang maaga",
    "Save_dispatch_success": "Ang dispatch ay matagumpay na na-update",
    "Save_dispatch_fail": "Nagkaroon ng mga error noong ina-update ang dispatch",
    "Dispatch_setting_general_tooltip": "Mag-apply sa booking on demand, booking nang maaga nang may naantalang auto-dispatch",
    "Dispatch_setting_Assign_reservation_to_driver_tooltip": "Ang reserbasyon ay awtomatikong itinalaga at tinatanggap para sa driver kapag pinagana ang opsyong ito",
    "Dispatch_setting_Driver_cancellation_duration_tooltip": "Ang tagal ay mula sa oras kung kailan kinansela ng oras ng pagkuha sa driver ang booking",
    "Dispatch_setting_Dispatch_mode_tooltip": "- Manual mode: Ise-save ang reservation at mananatili sa Cue ng iyong Command Center sa ilalim ng status na \"Nakabinbin\" kapag nagawa na ang reservation. Pagkatapos mag-click sa \"I-update ang booking\" sa detalye ng booking, ipapadala ang reserbasyon sa iyong mga nakatalagang driver o sa lahat ng mga driver kung sakaling walang magtalaga ng driver. Isasama ito sa kalendaryo ng driver device at magiging available ang paalala. <br>- Delayed mode:Ise-save ang reservation at mananatili sa Cue ng iyong Command Center sa ilalim ng status na “Pending” kapag nagawa na ang reservation. Awtomatikong ipapadala ang reserbasyon bago ang \"halaga ng oras sa \"Proseso nang maaga\"\" mula sa oras ng pag-pick up sa nakatalagang driver o sa pinakamalapit na available kung sakaling walang magtalaga ng driver. <br>- Agad na auto-dispatch mode: Awtomatikong ipapadala ang reserbasyon sa lahat ng driver kapag nagawa na ang reservation. Isasama ito sa kalendaryo ng driver device at magiging available ang paalala.",
    "Notification_nearby_drivers_before_pickup_time": "Abisuhan ang mga kalapit na driver bago ang oras ng pickup",
    "Dispatch_setting_Maximum_Day_tooltip": "Ito ay para limitahan ang mga driver na magkaroon ng masyadong maraming reservation sa parehong araw. Naaangkop para sa Marketplace at Intercity.",
    "Maximum_Accept_Reservation_tooltip": "Ang bilang ng mga tinatanggap na reservation ng driver ay hindi maaaring lumampas sa numerong ito. Nililimitahan nito ang driver mula sa pagtanggap ng masyadong marami sa isang pagkakataon. Naaangkop para sa Marketplace at Intercity.",
    "Notification_nearby_drivers_before_pickup_time_tooltip": "Kung ang reserbasyon ng isang customer ay hindi tinanggap ng sinumang driver bago ang itinakdang oras, bago ang oras ng pickup, aabisuhan ng system ang mga driver sa malapit. Sana ay ma-prompt sila nito na tingnan ang marketplace para tanggapin ang booking."
  },
  "voiceSMSSetting": {
    "ACCOUNT_SETTINGS": "Mga setting ng account",
    "Account_SID": "Account SID",
    "Auth_Token": "Auth Token",
    "Application_ID": "Application ID",
    "SMS_number_1": "SMS number 1",
    "SMS_number_2": "SMS number 2",
    "SMS_SETTINGS": "MGA SETTING ng SMS",
    "MASKED_PHONE_SETTINGS": "MGA SETTING NA MAY MASAKITANG TELEPONO",
    "SMS_MARKETING_SETTINGS": "MGA SETTING NG SMS MARKETING",
    "Phone_number": "Numero ng telepono",
    "Save": "I-save",
    "voiceSMS": "TWILIO",
    "DRIVER_APP": "Driver App",
    "PASSENGER_APP": "App ng Pasahero",
    "allowDriverToCallCC": "Payagan ang Driver na tumawag sa Helpdesk sa pamamagitan ng:",
    "allowDriverToCallHelpdesk": "Payagan ang Driver na tumawag sa Helpdesk sa pamamagitan ng:",
    "allowDriverToCallPassenger": "Pahintulutan ang Driver na tumawag sa Pasahero sa pamamagitan ng:",
    "allowPassengerToCallCC": "Pahintulutan ang Pasahero na tumawag sa Helpdesk sa pamamagitan ng:",
    "allowPassengerToCallHelpdesk": "Pahintulutan ang Pasahero na tumawag sa Helpdesk sa pamamagitan ng:",
    "allowPassengerToCallDriver": "Pahintulutan ang Pasahero na tumawag sa Driver sa pamamagitan ng:",
    "voip": "VOIP",
    "mobile": "Mobile",
    "bookingSMS": "Template ng mensahe",
    "bookingSMSTemplate": {
      "name": "Pangalan",
      "content": "Nilalaman",
      "action": "Aksyon",
      "DELETE": "Tanggalin ang template ng Mensahe",
      "DELETE_CONFIRM": "Sigurado ka bang gusto mong tanggalin ang napiling template ng Mensahe?",
      "EDIT": "I-edit ang template ng Mensahe",
      "ADD": "Magdagdag ng template ng Mensahe",
      "DETAIL": "Template ng Detalye ng Mensahe",
      "Update_success": "Matagumpay na na-update ang template ng mensahe",
      "Create_success": "Matagumpay na nalikha ang template ng mensahe",
      "Delete_success": "Matagumpay na natanggal ang template ng mensahe",
      "Delete_fail": "Naganap ang mga error habang tinatanggal ang template ng Mensahe",
      "Load_fail": "Naganap ang mga error habang nilo-load ang template ng Mensahe",
      "Update_fail": "Naganap ang mga error habang ina-update ang template ng Mensahe",
      "Create_fail": "Naganap ang mga error habang gumagawa ng template ng Mensahe"
    },
    "bookingSMSModal": {
      "title": "Magdagdag ng template ng SMS",
      "save": "I-save",
      "cancel": "Kanselahin",
      "titleMessage": "Magpadala ng Mensahe",
      "template": "Template",
      "phoneNumber": "Numero ng telepono",
      "content": "Nilalaman",
      "messageType": "Uri ng mensahe",
      "inbox": "Inbox",
      "sms": "SMS",
      "sendTo": "Ipinadala sa",
      "passenger": "pasahero",
      "driver": "Driver"
    }
  },
  "regularSetting": {
    "serviceType": "Uri ng Serbisyo",
    "transport": "Transportasyon",
    "delivery": "Paghahatid",
    "Fer_Stop": "Fee fer stop",
    "First_Stop": "Bayad para sa unang paghinto",
    "Each_Stop": "Bayad para sa bawat dagdag na paghinto",
    "Name": "Pangalan",
    "Pay_to_driver_Cash": "Magbayad sa driver (Cash booking)",
    "Last_Update": "Huling Update",
    "Status": "Katayuan",
    "Required_fields": "Mga kailangang punan",
    "Yes": "Oo",
    "No": "Hindi",
    "Cancel": "Kanselahin",
    "Save": "I-save",
    "Actions": "Aksyon",
    "Edit": "I-edit",
    "Add": "Idagdag",
    "Show_per_page": "Mga item bawat pahina",
    "Page": "Pahina",
    "show_pages": "{0} - {1} ng {2} ang ipinakita",
    "DELETE_CONFIRM": "Do you want to delete?",
    "ADD_REGULAR_RATE": "Magdagdag ng regular na rate",
    "EDIT_REGULAR_RATE": "I-edit ang regular na rate",
    "DETAIL_REGULAR_RATE": "Detalye ng regular na rate",
    "DELETE_REGULAR_RATE": "Tanggalin ang regular na rate",
    "Delete_confirm_regular_rate": "Gusto mo bang tanggalin ang item na ito?",
    "STARTING": "Nagsisimula",
    "Starting_Now": "Simula ngayon)",
    "Starting_Reservation": "Simula (Pagpapareserba)",
    "FIRST_DISTANCE_FEE": "Bayad sa unang distansya",
    "First_distance_fee": "Unang distansya",
    "From": "Mula sa",
    "To": "Upang",
    "Fee_for_first_distance": "Bayad para sa unang distansya",
    "SECOND_DISTANCE_FEE": "Bayad sa pangalawang distansya",
    "Second_distance_fee": "Pangalawang distansya",
    "AFTER_SECOND_DISTANCE": "Pagkatapos ng pangalawang distansya",
    "Fee_after_second_distance": "Bayad pagkatapos ng pangalawang distansya",
    "PER_MINUTE": "Bawat minuto",
    "Fee_per_minute": "Pamasahe kada minuto",
    "mi": "mi",
    "hour": "oras",
    "MINIMUM": "pinakamababa",
    "Minimum_Now": "Minimum (Ngayon)",
    "Minimum_Reservation": "Minimum (Reservation)",
    "PAX_MDISPATCHER_CANCELLATION": "Pagkansela ng pax/partner",
    "On_demand": "Pagkansela ng pax/partner - On demand",
    "Penalty": "Halaga ng parusa",
    "In_advance": "Pagkansela ng pax/partner - In advance",
    "PAX_MDISPATCHER_NO_SHOW": "Hindi sumipot ang Pax/ partner",
    "Driver_cancel_demand": "Patakaran sa pagkansela ng driver - on demand",
    "Driver_cancel_advance": "Patakaran sa pagkansela ng driver - nang maaga",
    "Assign_car_type": "Magtalaga ng uri ng kotse",
    "Select_car_type": "Pumili ng uri ng kotse",
    "Active": "Aktibo",
    "Inactive": "Hindi aktibo",
    "Deactivate": "I-deactivate",
    "DELETE_FLATE_RATE": "Tanggalin ang flate rate",
    "Delete_confirm_flat_rate": "Gusto mo bang tanggalin ang item na ito?",
    "EDIT_FLAT_RATE": "I-edit ang flat rate",
    "ADD_FLAT_RATE": "Magdagdag ng flat rate",
    "DELETE_FLAT_RATE": "Tanggalin ang flat rate",
    "DELETE_HOURLY_RATE": "Tanggalin ang oras-oras/araw-araw na rate",
    "Delete_confirm_hour_rate": "Gusto mo bang tanggalin ang item na ito?",
    "EDIT_HOURLY_RATE": "I-edit ang oras-oras/ araw-araw na rate",
    "ADD_HOURLY_RATE": "Magdagdag ng oras-oras/ araw-araw na rate",
    "DELETE_FLAT_ROUTE": "Tanggalin ang patag na ruta",
    "DELETE_ROUTE_CONFIRM": "Sigurado ka bang tatanggalin ang ruta?",
    "EDIT_ROUTE": "I-edit ang ruta",
    "ADD_ROUTE": "Magdagdag ng ruta",
    "Route_name": "Pangalan ng ruta",
    "Route_type": "Uri ng Ruta",
    "Zone_to_zone": "Zone sa zone",
    "Zipcode_to_zipcode": "Zipcode sa zipcode",
    "First_location": "Unang lokasyon",
    "Second_location": "Pangalawang lokasyon",
    "First_location_zip_note": "*Mga Tala: Maaari kang magpasok ng maraming zip code nang sabay-sabay sa pamamagitan ng paggamit ng simbolo na ','. Para sa magkakasunod na code, mangyaring gumamit ng simbolo na '->' sa halip na mag-input ng isa-isa ( hal: 90001 -> 90099, 90101, 90102, 90103, 90174, 90185)",
    "Second_location_zip_note": "*Mga Tala: Maaari kang magpasok ng maraming zip code nang sabay-sabay sa pamamagitan ng paggamit ng simbolo na ','. Para sa magkakasunod na code, mangyaring gumamit ng simbolo na '->' sa halip na mag-input ng isa-isa ( hal: 90001 -> 90099, 90101, 90102, 90103, 90174, 90185)",
    "SINGLE_TRIP_SETUP": "Pag-setup ng solong biyahe",
    "Route_1": "Ruta 1 (Unang lokasyon -> Pangalawang lokasyon)",
    "Route_2": "Ruta 2 (Ikalawang lokasyon -> Unang lokasyon)",
    "Limitation": "Limitasyon",
    "Unlimited": "Walang limitasyon",
    "Limited": "Limitado",
    "Time_coverage": "Saklaw ng oras",
    "Extra_time": "Sobrang oras",
    "ROUND_TRIP_SETUP": "Round trip setup",
    "Round_trip": "Papunta at pabalik",
    "ROUTES": "ROUTES",
    "Route_1_Fare": "Pamasahe sa Ruta 1",
    "Route_2_Fare": "Pamasahe sa Ruta 2",
    "Round_Trip_Fare": "Round Trip Fare",
    "Select_a_zone": "Pumili ng zone",
    "minute": "minuto",
    "VIEW_REGULAR_RATE": "Tingnan ang detalye regular na rate",
    "VIEW_FLAT_RATE": "Tingnan ang flat rate",
    "VIEW_HOURLY_RATE": "Tingnan ang oras-oras na rate",
    "HOURLY_PACKAGE_RATE": "Oras na rate ng package",
    "Package_name": "Pangalan ng package",
    "Package_duration": "Tagal ng package",
    PackageId: 'Package ID',
    "Base_rate": "Base rate",
    "Distance_coverage": "Saklaw ng distansya",
    "Extra_duration": "Dagdag tagal",
    "Extra_distance": "Dagdag na distansya",
    "DELETE_HOURLY_PACKAGE": "Tanggalin ang oras-oras na pakete",
    "DELETE_PACKAGE_CONFIRM": "Gusto mo bang tanggalin ang rate ng package?",
    "EDIT_PACKAGE": "I-edit ang package",
    "VIEW_PACKAGE": "Tingnan ang detalye ng package",
    "ADD_PACKAGE": "Magdagdag ng pakete",
    "Fee_per_extra_duration": "Bayad sa bawat dagdag na tagal",
    "Fee_per_extra_distance": "Bayad sa bawat dagdag na distansya",
    "hours": "(mga) oras",
    "days": "Mga Araw",
    "Update_flat_rate_success": "Matagumpay na na-update ang flat rate",
    "Update_flat_rate_fail": "Naganap ang mga error habang ina-update ang flat rate",
    "Created_flat_rate_success": "Matagumpay na nalikha ang flat rate",
    "Created_flat_rate_fail": "Naganap ang mga error habang gumagawa ng flat rate",
    "Update_flat_rate_route_success": "Matagumpay na na-update ang ruta ng flat rate",
    "Update_flat_rate_route_fail": "Naganap ang mga error habang ina-update ang flat rate na ruta",
    "Created_flat_rate_route_success": "Matagumpay na nagawa ang flat rate na ruta",
    "Created_flat_rate_route_fail": "Naganap ang mga error habang gumagawa ng flat rate na ruta",
    "Loading_flat_rate_fail": "Naganap ang mga error habang nilo-load ang detalye ng flat rate",
    "Delete_flat_rate_route_fail": "Naganap ang mga error habang tinatanggal ang flat rate na ruta",
    "Delete_flat_rate_success": "Matagumpay na natanggal ang flat rate",
    "Delete_flat_rate_fail": "Naganap ang mga error habang tinatanggal ang flat rate",
    "Update_intercity_rate_success": "Matagumpay na na-update ang intercity rate",
    "Update_intercity_rate_fail": "Naganap ang mga error habang ina-update ang rate ng intercity",
    "Created_intercity_rate_success": "Matagumpay na nagawa ang intercity rate",
    "Created_intercity_rate_fail": "Naganap ang mga error habang gumagawa ng intercity rate",
    "Update_intercity_rate_route_success": "Matagumpay na na-update ang ruta ng intercity rate",
    "Update_intercity_rate_route_fail": "Naganap ang mga error habang ina-update ang ruta ng intercity rate",
    "Created_intercity_rate_route_success": "Matagumpay na nalikha ang ruta ng intercity rate",
    "Created_intercity_rate_route_fail": "Naganap ang mga error habang gumagawa ng intercity rate na ruta",
    "Loading_intercity_rate_fail": "Naganap ang mga error habang nilo-load ang detalye ng intercity rate",
    "Delete_intercity_rate_route_fail": "Naganap ang mga error habang tinatanggal ang ruta ng intercity rate",
    "Delete_intercity_rate_success": "Matagumpay na natanggal ang intercity rate",
    "Delete_intercity_rate_fail": "Naganap ang mga error habang tinatanggal ang rate ng intercity",
    "Update_regular_rate_success": "Matagumpay na na-update ang regular na rate",
    "Update_regular_rate_fail": "Naganap ang mga error habang ina-update ang regular na rate",
    "Create_regular_rate_success": "Matagumpay na nalikha ang regular na rate",
    "Create_regular_rate_fail": "Naganap ang mga error habang gumagawa ng regular na rate",
    "Update_hourly_rate_success": "Matagumpay na na-update ang oras-oras na rate",
    "Update_hourly_rate_fail": "Naganap ang mga error habang ina-update ang oras-oras na rate",
    "Create_hourly_rate_success": "Matagumpay na nalikha ang oras-oras na rate",
    "Create_hourly_rate_fail": "Naganap ang mga error habang gumagawa ng oras-oras na rate",
    "Update_hourly_rate_package_success": "Matagumpay na na-update ang package ng oras-oras na rate",
    "Update_hourly_rate_package_fail": "Naganap ang mga error habang ina-update ang package ng oras-oras na rate",
    "Create_hourly_rate_package_success": "Matagumpay na nalikha ang package ng oras-oras na rate",
    "Create_hourly_rate_package_fail": "Ang mga error ay nangyari habang gumagawa ng hourly rate package",
    "Load_hourly_rate_fail": "Naganap ang mga error habang naglo-load ng mga detalye ng oras-oras na rate",
    "Update_hourly_status": "Naganap ang mga error habang ina-update ang status ng oras-oras na rate",
    "Delete_hourly_fail": "Naganap ang mga error habang tinatanggal ang oras-oras na rate",
    "Delete_hourly_success": "Matagumpay na natanggal ang oras-oras na rate",
    "Loading_regular_rate_fail": "Naganap ang mga error habang naglo-load ng regular na rate",
    "Update_regular_status_fail": "Naganap ang mga error habang ina-update ang status ng regular na rate",
    "Delete_regular_rate_success": "Matagumpay na natanggal ang regular na rate",
    "Delete_regular_rate_fail": "Naganap ang mga error habang tinatanggal ang regular na rate",
    "Please_go_to_Car_Type_settings": "Upang ilapat ang rate na ito sa anumang uri ng kotse, mangyaring pumunta sa Mga Setting > Kotse > Uri ng Kotse.",
    "Car_type_filter": "Filter ng Uri ng Kotse",
    "Delete_package_error": "Nagkaroon ng error noong tinatanggal ang package!",
    "Pax_mDispatcher_cancellation_tooltip": "Inilapat ang patakaran sa pagkansela para sa mga pasahero",
    "Penalty_tooltip": "Ang halaga ng customer ay sisingilin. Bilang karagdagan sa multa maaari kang magdagdag ng buwis o tech fee.",
    "Pax_mDispatcher_no_show_tooltip": "No show policy na inilapat para sa mga pasahero. Sisingilin ang bayad na ito kung ita-tap ng mga driver ang \"No show\" kapag hindi nila nakita ang mga pasahero sa lokasyon ng pickup.",
    "detail_route": "Detalye ng ruta",
    "detail_package": "Detalye ng Package",
    "THIRD_DISTANCE_FEE": "Bayad sa ikatlong distansya",
    "AFTER_DISTANCE_FEE": "Pagkatapos ng third distance fee",
    "Loading_delivery_rate_fail": "Naganap ang mga error habang nilo-load ang detalye ng rate ng paghahatid",
    "Update_delivery_rate_fail": "Naganap ang mga error habang ina-update ang rate ng paghahatid",
    "Update_delivery_rate_success": "Ang rate ng paghahatid ay matagumpay na na-update",
    "Created_delivery_rate_success": "Matagumpay na nalikha ang rate ng paghahatid",
    "Created_delivery_rate_fail": "Naganap ang mga error habang gumagawa ng rate ng paghahatid",
    "Starting_fee": "Panimulang bayad",
    "First_distance": "Unang distansya",
    "Second_distance": "Pangalawang distansya",
    "Third_distance": "Pangatlong distansya",
    "Fee_for_second_distance": "Fee for second distance",
    "Fee_for_third_distance": "Fee for third distance",
    "Fee_after_third_distance": "Fee after third distance",
    "Assign_rate_to_car_type": "Assign rate to cartype",
    "car_type": "Cartype",
    "PRICE_ADJUSTABLE": "Price adjustable",
    "minimum_Percent_Adjust": "Minimum % from estimated fare",
    "maximum_Percent_Adjust": "Maximum % from estimated fare",
    "Amount_Step": "Price adjustable",
    "Tax": "Buwis",
    "TechFee": "Tech fee",
    "Pay_to_driver": "Pay to driver",
    "Pay_to_driver_tooltip": "Options to compensate drivers:\n - Fixed amount: A fixed amount the driver will receive.\n - Commission: Driver’s set commission applied to the penalty amount.",
    "Fix_amount": "Fixed amount",
    "Commission": "Commission",
    "fareFlat": "Fare flat",
    "flatRoute": "Flat route"
  },
  "flatRateSettings": {
    "flatZoneNote": "The zones you create here are only used to setup your flat rates."
  },
  "deliverySetting": {
    "Name": "Pangalan",
    "Last_Update": "Last Update",
    "Status": "Status",
    "Actions": "Aksyon"
  },
  "sharingSetting": {
    "EDIT_RATE": "Edit street sharing rate",
    "ADD_RATE": "Add street sharing rate",
    "VIEW_RATE": "View street sharing rate",
    "Name": "Pangalan",
    "startingFee": "Starting fare",
    "minimumFee": "Minimum fare",
    "Update_streetSharing_rate_fail": "The errors occurred while updating street sharing rate",
    "Update_streetSharing_rate_success": "Street sharing rate has been updated successfully",
    "Created_streetSharing_rate_success": "Street sharing rate has been created successfully",
    "Created_streetSharing_rate_fail": "The errors occurred while creating street sharing rate",
    "Loading_streetSharing_rate_fail": "The errors occurred while loading street sharing rate detail",
    "Delete_streetSharing_rate_success": "Street sharing rate has been deleted successfully",
    "Delete_streetSharing_rate_fail": "The errors occurred while deleting street sharing rate",
    "Delete_streetSharing_rate": "Delete street sharing rate",
    "Assign_Car": "Assign car type"
  },
  "carSetting": {
    Details: 'Details',
    vehicleInfo: 'Vehicle Info',
    Documents: 'Documents',
    vehicle: 'Vehicle',
    addVehicle: 'Add Vehicle',
    detailVehicle: 'Vehicle Details',
    additionalVehicleTitle: 'Additional Vehicle Information',
    Phone_Number_Tooltip: 'Enter a vehicle-specific phone number for customer use. If not provided, calls will default to the driver’s personal number. Ideal for suppliers who equip vehicles with dedicated contact phones.',
    discardChange: 'Discard changes',
    "model_name_is_required": "Model name is required",
    "DELETE_CAR": "Delete car",
    "DELETE_CONFIRM": "Do you want to delete selected car?",
    "Yes": "Oo",
    "Cancel": "Kanselahin",
    "EDIT_CAR": "Edit car",
    "CREATE_CAR": "Create car",
    "Plate_Number": "Plate Number",
    "Car_Type": "Uri ng kotse",
    "Select_car_type": "Select car type",
    "Make": "Make",
    "Select_car_make": "Select car make",
    "Model": "Model",
    "Select_a_car_model": "Select a car model",
    "Year": "Production Year",
    "Select_year": "Select year",
    "Hardware_Meter": "Hardware Meter",
    "VIN": "Vehicle identification number (VIN)",
    "Company": "kumpanya",
    "Select_company": "Select company",
    "Max_Passengers": "Max Passengers",
    "Max_Luggage": "Max Luggage",
    "Color": "Color",
    "License_expiry": "License expiry",
    "Required_fields": "Required fields",
    "Save": "Save",
    "Add": "Add",
    "Car_Model": "Car Model",
    "Created_Date": "Created Date",
    "Status": "Status",
    "Actions": "Aksyon",
    "Show_per_page": "Items per page",
    "Page": "Page",
    "show_pages": "{0} - {1} of {2} shown",
    "Active": "Active",
    "Inactive": "Inactive",
    "Edit": "Edit",
    "Deactivate": "Deactivate",
    "Activate": "Activate",
    "Delete": "Tanggalin",
    "Name": "Pangalan",
    "MODEL_LIST": "Model list",
    "Model_Name": "Model name",
    "Add_Model": "Add model",
    "DELETE_CAR_MODEL": "Delete car model",
    "DELETE_CONFIRM_MODEL": "Do you want to delete car model?",
    "DELETE_CAR_MAKE": "Delete car make",
    "DELETE_CONFIRM_CAR_MAKE": "Do you want to delete this car make?",
    "EDIT_CAR_MAKE": "Edit car make",
    "CREATE_CAR_MAKE": "Add car make",
    "Assign_Rate": "Assign Rate",
    "ASSIGN_RATE": "Assign rate",
    "Car_type_ID": "Car type ID",
    "App_Display_Name": "App Display Name",
    "Affiliate_car_type": "Affiliate Car Type",
    "Regular_rate": "Regular rate",
    "Zone": "Zone",
    "Rate": "Rate",
    "Zone_Rate": "Zone - Rate",
    "Flat_Fare": "Flat Rate",
    "Flat_Delivery": "Delivery flat",
    "Select_flat_rate": "Select flat rate",
    "Hourly_daily_rate": "Hourly/ Daily",
    "Dispatch_To": "Dispatch To",
    "Normal_Fare": "Regular",
    "Upload_an_image": "Upload an image",
    "DETAIL_CAR": "Car detail",
    "DELETE_MULTI_CAR": "Delete selected cars",
    "DELETE_MULTI_CAR_CONFIRM": "Do you want to delete these vehicles ,Just only vehicles in the inactive status can be delete?",
    "DETAIL_CAR_MAKE": "Car Make",
    "Car_Make": "Car Make",
    "Create_car_success": "New car has been created successfully",
    "Update_car_success": "This car has been updated successfully.",
    "Update_car_fail": "The errors occurred while updating car",
    "Create_car_fail": "The errors occurred while creating car",
    "Load_car_fail": "The errors occurred while loading car",
    "Delete_car_fail": "The errors occurred while deleting car",
    "Delete_car_success": "Car has been deleted successfully",
    "Change_cars_status_success": "All car statuses have been changed successfully",
    "Change_cars_status_fail": "The errors occurred while changing car statuses",
    "Delete_cars_success": "All selected cars have been deleted successfully",
    "Delete_cars_fail": "The errors occurred while deleting cars",
    "Update_car_type_success": "Car type has been updated successfully",
    "Update_car_type_fail": "The errors occurred while updating car type",
    "Load_car_type_details_fail": "Could not load car type details",
    "Update_car_make_fail": "The errors occurred while updating car make",
    "Update_car_make_success": "Car make has been updated successfully",
    "Create_car_make_fail": "The errors occurred while creating car make",
    "Create_car_make_success": "New car make has been created successfully",
    "Update_car_model_fail": "The errors occurred while updating car model",
    "Update_car_model_success": "Car model has been updated successfully",
    "Create_car_model_fail": "The errors occurred while creating car model",
    "Create_car_model_success": "New car model has been created successfully",
    "Loading_car_model_fail": "The errors occurred while loading car model",
    "Delete_car_make_success": "Car make has been deleted successfully",
    "Delete_cat_make_fail": "The errors occurred while deleting car make",
    "Delete_regular_rate_success": "Delete Regular rate successfull",
    "Delete_regular_rate_fail": "Delete Regular rate error",
    "Update_status_error": "Update status error",
    "Case_number": "Case number",
    "Phone_Number": "Numero ng telepono",
    "ERROR_INPUT_VALIDPHONE": "Please enter a valid phone number.",
    "Car_type_filter": "Car type filter",
    "No": "No",
    "Intercity_Rate": "Intercity Rate",
    "Select_intercity_rate": "Select intercity rate",
    "Delivery_Rate": "Delivery Rate",
    "Select_delivery_rate": "Select delivery rate",
    "vehicleOwner": "Name of vehicle’s owner",
    "EDIT_CAR_COLOR": "Edit car color",
    "DETAIL_CAR_COLOR": "Car color detail",
    "CREATE_CAR_COLOR": "Add car color",
    "Car_Color": "Car color",
    "Update_car_color_fail": "The errors occurred while updating car color.",
    "Update_car_color_success": "Car color has been updated successfully.",
    "Create_car_color_fail": "The errors occurred while creating car color.",
    "Create_car_color_success": "New car color has been created successfully",
    "Delete_car_color_success": "Car color has been deleted successfully.",
    "Delete_car_color_fail": "The errors occurred while deleting car color.",
    "DELETE_CAR_COLOR": "Delete car color",
    "DELETE_CONFIRM_CAR_COLOR": "Do you want to delete this car color?",
    "sharing_Rate": "Street Sharing Rate",
    "bookingAffiliate": "Affiliate Booking.com car type",
    "streetSharing": "Street Sharing"
  },
  "operationSetting": {
    "Driver": "Driver",
    "Search_driver_name": "Search driver name",
    "Company": "kumpanya",
    "Shift": "Shift",
    "Zone": "Zone",
    "Required_fields": "Required fields",
    "Save": "Save",
    "No": "Bilang",
    "Car_Type": "Uri ng kotse",
    "Plate_Number": "Plate Number",
    "Unassign": "Unassign",
    "Please_choose_driver": "Please choose driver.",
    "Please_choose_company": "Please choose company.",
    "Please_license_plate": "Please choose license plate.",
    "Please_choose_shift": "Please choose shift.",
    "Please_choose_zone": "Please choose zone.",
    "CONFIRM_UNASSIGN": "Do you want to unassigned this driver?",
    "Assign_success": "Operation has been assigned successfully",
    "Unassign_success": "Driver has been unassigned successfully."
  },
  "paymentMethod": {
    "cash": "Cash",
    "cashBySender": "Cash by sender",
    "cashByRecipient": "Cash by recipient",
    "personalCard": "Personal na card",
    "corporateCard": "Corporate card",
    "qrCodePayment": "QR code payment",
    "directBilling": "Direct Invoicing",
    "externalCard": "External card",
    "mDispatcherCard": "Partner card",
    "corporatePrepaid": "Corporate prepaid",
    "fleetCard": "External card",
    "applePay": "Apple pay",
    "creditExternalCard": "Credit card",
    "googlePay": "Google Pay",
    "wallet": "Wallet",
    "BankOfGeorgia": "Bank Of Georgia",
    "tnGeWalletlet": "TnG eWallet",
    "paymentMethod_1": "Cash",
    "paymentMethod_2": "Personal na card",
    "paymentMethod_3": "Partner card",
    "paymentMethod_4": "Corporate card",
    "paymentMethod_5": "Direct Invoicing",
    "paymentMethod_6": "External card",
    "paymentMethod_7": "Corporate prepaid",
    "paymentMethod_8": "QR code payment",
    "paymentMethod_9": "Apple pay",
    "paymentMethod_11": "Airpay",
    "paymentMethod_12": "Credit card",
    "paymentMethod_13": "Wallet",
    "paymentMethod_14": "TnG eWallet",
    "paymentMethod_16": "Cash by sender",
    "paymentMethod_17": "Cash by recipient",
    "paymentMethod_19": "Boost eWallet",
    "paymentMethod_20": "Vipps",
    "paymentMethod_21": "ZainCash",
    "paymentMethod_23": "Google Pay",
    "vippseWallet": "Vipps",
    "paymentMethod_credit": "Personal na card",
    "paymentMethod_razerPay": "RazerPay",
    "paymentMethod_gCash": "GCash",
    "paymentMethod_zainCash": "ZainCash",
    "paymentMethod_ksher": "Ksher",
    "paymentMethod_paydunya": "PayDunya",
    "paymentMethod_fawry": "Fawry",
    "paymentMethod_BankOfGeorgia": "Bank Of Georgia",
    "paymentLink": "Payment link "
  },
  "changePassword": {
    "Change_password": "Change password",
    "Current_password": "Current password",
    "New_password": "New password",
    "Confirm_password": "Confirm password",
    "ERROR_INPUT_PASSWORD": "Please input password",
    "ERROR_INPUT_VALID_PASSWORD": "Password must contain at least 8 characters, one numeric digit and a special character",
    "ERROR_INPUT_MATCH_PASSWORD": "Password does not match. Please try again",
    "Save": "Save",
    "Cancel": "Kanselahin"
  },
  "userProfile": {
    "My_account": "My account",
    "Username": "Username",
    "First_name": "Firstname",
    "Last_name": "Huling pangalan",
    "Phone_number": "Numero ng telepono",
    "Email_address": "Email address",
    "Address": "Address",
    "Save": "Save",
    "Edit_profile": "Edit profile",
    "Change_password": "Change password",
    "ERROR_INPUT_USERNAME": "Please input username.",
    "ERROR_INPUT_FIRSTNAME": "Please input first name.",
    "ERROR_INPUT_VALID_EMAIL": " Please enter valid email.",
    "Required_fields": "Required fields"
  },
  "General": {
    "searchQueuingDriver": "Search: Driver Name, Phone Number",
    "search": "Search",
    "User": "User",
    "My_Account": "My Account",
    "Sign_out": "Sign out",
    "My_site": "My site",
    "Command_center": "Command Center",
    "Notifications": "Notifications",
    "Show_all": "Show All",
    "SOS_Notifications": "SOS Notifications",
    "You_dont_have_any_message": "You do not have any message",
    "Mon": "Mon",
    "Tue": "Tue",
    "Wed": "Wed",
    "Thu": "Thu",
    "Fri": "Fri",
    "Sat": "Sat",
    "Sun": "Sun",
    "Now": "Now",
    "hrs": "hrs",
    "patmentMethod_1": "Cash",
    "patmentMethod_2": "Personal na card",
    "patmentMethod_3": "Partner card",
    "patmentMethod_4": "Corporate Card",
    "patmentMethod_5": "Direct invoicing",
    "patmentMethod_6": "External card",
    "patmentMethod_7": "Corporate Prepaid",
    "patmentMethod_8": "QR Code",
    "patmentMethod_9": "Apple Pay",
    "patmentMethod_10": "Apple Pay",
    "patmentMethod_12": "Credit card",
    "patmentMethod_13": "Wallet",
    "patmentMethod_14": "TnG eWallet",
    "patmentMethod_16": "Cash by sender",
    "patmentMethod_17": "Cash by recipient",
    "patmentMethod_19": "Boost",
    "patmentMethod_20": "Vipps",
    "patmentMethod_23": "Google Play",
    "patmentMethod_24": "Payment Link",
    patmentMethod_25: 'Easy Paisa',
    patmentMethod_26: 'Jazz Cash',
    "patmentMethod_ZainCash": "ZainCash",
    "patmentMethod_GCash": "GCash",
    "patmentMethod_cash": "Cash",
    "patmentMethod_credit": "Personal na card",
    "patmentMethod_mDispatcher": "Partner card",
    "patmentMethod_corpCredit": "Corporate Card",
    "patmentMethod_directBilling": "Direct invoicing",
    "patmentMethod_fleetCard": "External card",
    "patmentMethod_prepaid": "Corporate Prepaid",
    "patmentMethod_QRCode": "QR Code",
    "patmentMethod_ApplePay": "Apple Pay",
    "patmentMethod_GooglePay": "Google Pay",
    "patmentMethod_creditCardExternal": "Credit card",
    "patmentMethod_PaxWallet": "Wallet",
    "patmentMethod_TnGeWallet": "TnG eWallet",
    "patmentMethod_boosteWallet": "Boost",
    "patmentMethod_Vipps": "Vipps",
    "patmentMethod_exZainCash": "ZainCash",
    "patmentMethod_exGCash": "GCash",
    "patmentMethod_exPayTM": "PayTM",
    "patmentMethod_exTelebirr": "GCash",
    "paymentMethod_exzainCash": "ZainCash",
    "patmentMethod_exKsher": "Ksher",
    "paymentMethod_paydunya": "PayDunya",
    "paymentMethod_exfawry": "Fawry",
    "patmentMethod_exBankOfGeorgia": "Bank Of Georgia",
    "patmentMethod_exPayDunya": "PayDunya",
    "patmentMethod_21": "ZainCash",
    "patmentMethod_paymentLink": "Payment Link",
    "patmentMethod_card": "Personal na card",
    "patmentMethod_corporateCard": "Corporate card",
    "patmentAirlineMethod_5": "Direct invoicing",
    "patmentAirlineMethod_4": "Airline Card",
    "patmentAirlineMethod_7": "Airline Prepaid",
    "Name": "Pangalan",
    "Delete": "Tanggalin",
    "App_SOS_dialog_form_message_1": "{0} Emergency SOS!",
    "App_SOS_dialog_form_message_2": "I need help at",
    "App_SOS_dialog_form_message_3": "Booking: {0}, Driver: {1}, Passenger: {2}, License Plate: {3} ({4})",
    "mins": "mins",
    "Credit_Card": "Credit card",
    "ALL": "Lahat",
    "NotFound": "The page you requested could not be found",
    "ServerError": "Something went wrong",
    "AccessDenied": "You don't have permission to access this module!",
    "Retry": "Retry",
    "Can_not_get_data": "Can't get data"
  },
  "shiftSettings": {
    "Add": "Add",
    "Show_per_page": "Items per page",
    "Page": "Page",
    "Name": "Pangalan",
    "Time_Range": "Time Range",
    "Actions": "Aksyon",
    "show_pages": "{0} - {1} of {2} shown",
    "Edit": "Edit",
    "Delete": "Tanggalin",
    "EDIT_SHIFT_SETTING": "Edit shift setting",
    "ADD_SHIFT_SETTING": "Add shift setting",
    "DETAIL_SHIFT_SETTING": "Detail shift setting",
    "DELETE_SHIFT_SETTING": "Delete shift setting",
    "Delete_confirm_shift_setting": "Do you want to delete this item?",
    "Required_fields": "Required fields",
    "Save": "Save",
    "Cancel": "Kanselahin",
    "From": "From",
    "To": "To",
    "Edit_shift_template": "Edit Shift Template",
    "Add_shift_template": "Add Shift Template",
    "Detail_shift_template": "Detail Shift Template",
    "Delete_shift_template": "Delete Shift Template",
    "Delete_confirm_shift_template": "Do you want to delete this item?",
    "Working_time": "Working Time",
    "Create_shift_settings_success": "New shift setting has been created successfully",
    "Create_shift_settings_fail": "The errors occurred while creating shift setting",
    "Update_shift_settings_success": "Shift setting has been updated successfully",
    "Update_shift_settings_fail": "The errors occurred while updating shift setting",
    "Delete_shift_settings_success": "Shift setting has been deleted successfully",
    "Delete_shift_settings_fail": "The errors occurred while deleting shift setting",
    "Create_shift_template_success": "New shift template has been created successfully",
    "Create_shift_template_fail": "The errors occurred while creating shift template",
    "Update_shift_template_success": "Shift template has been updated successfully",
    "Update_shift_template_fail": "The errors occurred while updating shift template",
    "Delete_shift_template_success": "Shift template has been deleted successfully",
    "Delete_shift_template_fail": "The errors occurred while deleting shift template",
    "Yes": "Oo",
    "No": "No"
  },
  "promotionSettings": {
    "Detail_campaign": "Detail Campaign",
    "Edit_campaign": "Edit Campaign",
    "Add_campaign": "Add Campaign",
    "Delete_campaign": "Delete Campaign",
    "Delete_confirm_campaign": "Do you want to delete selected campaign?",
    "Add": "Add",
    "Corporate_list": "Corporate list",
    "permittedCorporate": "Permitted corporate list",
    "CorporateCustomers": "Corporate Customers",
    "auto_add_corporate": "Auto add to Corporate User's promotion list",
    "Show_per_page": "Items per page",
    "Page": "Page",
    "Name": "Pangalan",
    "Actions": "Aksyon",
    "Private": "Private",
    "show_pages": "{0} - {1} of {2} shown",
    "Edit": "Edit",
    "Delete": "Tanggalin",
    "Send_to_inbox": "Send to inbox",
    "Save": "Save",
    "Duplicate": "Duplicate",
    "Cancel": "Kanselahin",
    "Required_fields": "Required fields",
    "Promotion_code": "Promotion Code",
    "Promotion_type": "Promotion Type",
    "Campaign": "Campaign",
    "Type": "Type",
    "Value": "Value",
    "keepMinFee": "Total fare after promo must greater than minimum fare",
    "Zone": "Zones",
    "paymentMethodsApply": "Payment methods",
    "schedules": "Schedules",
    "minimumEstFareApply": "Minimum estimate total fare to apply code",
    "quantityLimitPerYear": "Maximum uses/user/year",
    "termAndCondition": "Term & condition",
    "Valid_from": "Valid From",
    "Valid_to": "Valid To",
    "Total_uses_user": "Total Uses/User",
    "Released_quantity": "Released Quantity",
    "Number_of_sending_inbox": "Number Of Sending Inbox",
    "Status": "Status",
    "Activate": "Activate",
    "Deactivate": "Deactivate",
    "Add_promotion_code": "Add Promotion Code",
    "Edit_promotion_code": "Edit Promotion Code",
    "Detail_promotion_code": "Detail Promotion Code",
    "Delete_promotion_code": "Delete Promotion Code",
    "Delete_confirm_promotion_code": "Do you want to delete selected promotion code?",
    "New_customer": "New customers",
    "Budget_per_user": "Budget/user (for whole promotion time period)",
    "Budget_per_user_day": "Budget/user/day",
    "Budget_per_user_month": "Budget/user/month",
    "Budget_per_user_year": "Budget/user/year",
    "Unlimited": "Unlimited",
    "Limited": "Limited",
    "Total_uses_per_user": "Maximum uses/user (for whole promotion time period)",
    "Send_an_in_box_automatically_to_new_customers": "Send an inbox automatically to new customers",
    "Notes": "Mga Tala",
    "Public": "Public",
    "Create_campaign_success": "New campaign has been created successfully",
    "Create_campaign_fail": "The errors occurred while creating campaign",
    "Update_campaign_success": "Campaign has been updated successfully",
    "Update_campaign_fail": "The errors occurred while updating campaign",
    "Delete_campaign_success": "Campaign has been deleted successfully",
    "Delete_campaign_fail": "The errors occurred while deleting campaign",
    "Loading_campaign_fail": "The errors occurred while loading campaign",
    "Update_campaign_status_success": "Campaign status has been updated successfully",
    "Update_campaign_status_fail": "The errors occurred while updating campaign status",
    "Loading_promotion_code_fail": "The errors occurred while loading promotion code",
    "Select_campaign": "Select Campaign",
    "Create_promotion_code_success": "New promotion code has been created successfully",
    "Create_promotion_code_fail": "The errors occurred while creating promotion code",
    "Update_promotion_code_status_success": "Promotion code status has been updated successfully",
    "Update_promotion_code_status_fail": "The errors occurred while updating promotion code status",
    "Delete_promotion_code_confirmation": "Do you want to delete these promotion codes?",
    "Just_delete_inactive": "** Just only promotion code in the inactive status can be deleted.",
    "Send_inbox_confirmation": "Are you sure you want to send this promo code to passenger inbox?",
    "Send_inbox_success": "Promotion code has been sent successfully.",
    "Send_inbox_fail": "Promotion code has been sent failed. Please try again!",
    "Confirmation": "Kumpirmasyon",
    "Delete_promotion_codes_success": "Promotion code has been deleted successfully",
    "Delete_promotion_codes_fail": "The errors occurred while deleting promotion code",
    "Delete_multiple_promotion_codes_success": "Promotion codes has been deleted successfully",
    "Delete_multiple_promotion_codes_fail": "The errors occurred while deleting promotion codes",
    "Delete_promotion_code_success": "Promotion code has been deleted successfully",
    "Delete_promotion_code_fail": "The errors occurred while deleting promotion code",
    "Update_multiple_promotion_code_status_success": "Promotion code statuses has been updated successfully",
    "Update_multiple_promotion_code_status_fail": "The errors occurred while deleting promotion code statuses",
    "Search_here": "Maghanap dito",
    "Please_input_campaign_name": "Please input campaign name",
    "Please_input_promotion_code": "Please input promotion code",
    "Please_input_promotion_code_at_least_4_characters": "Please enter at least 4 characters.",
    "Please_select_campaign": "Please select campaign",
    "Please_input_value": "Please input value",
    "Please_select_date": "Please select date",
    "Please_add_schedules": "Please add schedule",
    "Please_add_corporate": "Please add corporate",
    "Please_choose_at_least_one_customer": "Please choose at least one customer.",
    "This_campaign_name_has_been_existing_on_the_system_Please_input_another_one": "This campaign name has been existing on the system. Please input another one.",
    "This_promotion_code_has_been_existing_on_the_system_Please_input_another_one": "This promotion code has been existing on the system. Please input another one.",
    "Invalid_file": "File content is invalid",
    "File_size_larger_than_5Mb": "File size is larger than 5Mb",
    "Import_promotions_success": "File has been imported successfully",
    "Import_Promotions": "Import File",
    "Number_successful_imports": "Imported items",
    "Number_skipped": "Skip items",
    "File": "File",
    "Phone_Numbers": "phone number(s)",
    "Import_Promotion_code_success": "Promotion code <b>{0}</b> has been imported successfully",
    "Import_Promotion_code_fail": "Promotion code <b>{0}</b> has been failed to import. Please try again",
    "Customer_type": "Customer type",
    "All": "Lahat",
    "Referred_customers": "Referred customers",
    "Common_customers": "Common customers",
    "Operation_zone": "Operation zones",
    "totalUsesLimitPerDay": "Maximum uses/user/day",
    "quantityLimitPerDay": "Maximum quantity/day",
    "quantityLimitPerMonth": "Maximum quantity/user/month",
    "applyVerifiedCustomers": "Apply for verified Malaysian customers only",
    "applyWhenBudgetLimit": "User can apply code till budget limit is finished",
    "applyVerifiedCustomersTooltip": "This promotion is applicable for Malaysian who has verified national ID and uploaded their ID card to the system only.",
    "valueLimitPerUse": "Maximum value/use (Apply for app v48 and above)",
    "Promotion_Services": "Service",
    "transport": "Transport",
    "shuttle": "Shuttle",
    "parcel": "Parcel",
    "food": "Pagkain",
    "mart": "Mart",
    "intercity": "Intercity",
    "addSchedule": "Add Schedule",
    "editSchedule": "Edit Schedule",
    "date": "Date",
    "Time_range": "Time range",
    "startTime": "Start time",
    "endTime": "End time",
    "applyForOtherDays": "Apply for other days",
    "scheduleTimeOverlap": "Schedule time must not overlap with each others",
    "schedule_startDate_greater_than_endDate": "The start date is greater than the end date",
    "scheduleTimeDuplicate": "Schedule time must not duplicate with each others",
    "promoIcon": "Promo icon",
    "bannerImage": "Banner image",
    "Upload_an_image": "Upload an image",
    "Click_here_to_view": "Click here to view."
  },
  "quest_event": {
    "name": "Pangalan",
    "userType": "Type of User",
    "carType": "Uri ng kotse",
    "operationZones": "Operation zone",
    "type": "Event Type",
    "isLuckyDraw": "Lucky Draw Round",
    "start_date": "Start Date",
    "end_date": "End Date",
    "numOfQualify": "Number of Qualified",
    "event_progress": "Event Progress",
    "isActive": "Status",
    "Actions": "Aksyon",
    "passenger": "Customer",
    "driver": "Driver",
    "first_will_win": "First will win",
    "largest_will_win": "Largest will win (leaderboard)",
    "not_start": "Not start",
    "active": "Active",
    "closed": "Sarado",
    "event_details": "Quest Details",
    "add_event": "Add Quest",
    "limited": "Limited",
    "unlimited": "Unlimited",
    "eventImage": "Event Image",
    "sharedImage": "Shared Image",
    "qualifiedImage": "Qualified Image",
    "expiredImage": "Expired Image",
    "luckyDrawImage": "Lucky Draw Image",
    "Upload_an_image": "Upload an image",
    "Required_Image": "Image Extensions (.jpg, .png, .gif)",
    "ok": "OK",
    "eventId": "Event ID",
    "passengerMetric": {
      "booking_distance": "Booking distance",
      "booking_time": "Booking time",
      "booking_amount_spent": "Booking amount spent",
      "num_of_ondemand": "Number of on-demand",
      "num_of_reservation": "Number of reservation",
      "num_of_intercity": "Number of intercity",
      "num_of_delivery": "Number of delivery",
      "num_of_referral": "Number of referral",
      "num_of_sharing": "Number of sharing",
      "topup_wallet_amount": "Topup wallet amount",
      "num_of_complete_wallet": "Number of complete booking (wallet)",
      "num_of_complete_booking_by_referee": "Number of complete booking (by referee)",
      "number_of_complete_booking": "Number of complete booking",
      "number_of_food_mart": "Number of food / mart"
    },
    "driverMetric": {
      "booking_distance": "Job distance",
      "booking_time": "Job time",
      "num_of_referral": "Number of referral",
      "num_of_complete": "Number of complete booking (unique customer)",
      "num_of_referral_driver": "Number of referral (driver)",
      "num_of_accept": "Number of accept booking",
      "num_of_receive": "Number of receive booking",
      "num_of_cancel": "Number of cancel booking by driver",
      "sum_of_rating": "Total number of rating star",
      "total_rating": "Total number of rating times",
      "earnings_amount": "Earnings Amount",
      "num_of_complete_wallet": "Number of complete booking (wallet)",
      "num_of_complete_booking_by_referee": "Number of complete booking (by referee)",
      "available_time": "Online time"
    },
    "Click_here_to_view": "Click here to view.",
    "view": "Click here to view.",
    "metricType": "Type",
    "task": "Task",
    "value": "Value",
    "quantity": "Quantity",
    "quests_list": "Quests",
    "rankImages": "Rank image (point)",
    "qualifiedPrizeOrder": "Qualified Prizes",
    "luckyDrawPrizeOrder": "Lucky Draw Prizes",
    "reward": "Item",
    "from": "From",
    "to": "To",
    "image": "Image",
    "Add_Prize": "Add",
    "bannerText": "Banner text",
    "buttonText": "Button text",
    "bannerLink": "Banner link",
    "shortLink": "Short link",
    "buttonLinkDesktop": "Button link (Desktop)",
    "buttonLinkAndroid": "Button link (Android)",
    "buttonLinkiOS": "Button link (iOS)",
    "termsURL": "Terms & Conditions Link",
    "metadataTitle": "Metadata title",
    "metadataDescription": "Metadata description",
    "isDarkMode": "Dark Mode",
    "isActive_details": "Active",
    "Required_fields": "Required fields",
    "change": "Change",
    "Please_input_name": "Please input name",
    "Please_select_date": "Please select date",
    "endDate_invalid": "End Date invalid",
    "startDate_greater_than_endDate": "The start date is greater than the end date",
    "Please_input_termsURL": "Please input Terms & Conditions Link",
    "Please_input_metadataTitle": "Please input metadata title",
    "Please_input_metadataDescription": "Please input metadata description",
    "Please_input_bannerText": "Please input banner text",
    "Please_input_buttonText": "Please input button text",
    "Please_input_bannerLink": "Please input banner link",
    "Please_input_shortLink": "Please input short link",
    "Please_input_buttonLinkDesktop": "Please input button link (Desktop)",
    "Please_input_buttonLinkAndroid": "Please input button link (Android)",
    "Please_input_buttonLinkiOS": "Please input button link (iOS) ",
    "the_range_1_to_5": "Please input value in the range 1 to 5 for %{name}!",
    "the_range_1_to_100": "Please input value in the range 1 to 100 for %{name}!",
    "task_is_empty": "Please input task for %{name}!",
    "task_limit_50_characters": "Task maximum length is 50 characters for %{name}!",
    "please_input_points": "Please input points for %{name}!",
    "please_input_value": "Please input value for %{name}!",
    "value_is_invalid": "%{name}, value must be a number",
    "please_upload_image": "Please upload image for item %{name}!",
    "rank_invalid": "Rank %{name} is invalid!",
    "rank_invalid_to": "Rank \"To\" must be a number",
    "deepLink": "Deeplink",
    "point_per_task": "Point per task",
    "points": "Mga puntos",
    "points_per_some_think": "Points per %{unit}",
    "criterias": "Criteria (optional)",
    "criterias_list": "Criteria",
    "criteriaType": "Type",
    "criteriaValue": "Value",
    "acceptance_rating": "Acceptance Rating (greater than or equal)",
    "cancellation_rating": "Cancellation Rating (less than or equal)",
    "driver_rating": "Driver Rating (greater than or equal)",
    "please_upload_image_rank": "Please upload image for rank %{name}!",
    "Update_event_success": "Quest has been updated successfully",
    "Update_event_fail": "The errors occurred while updating quest",
    "Create_event_success": "New quest has been created successfully",
    "Create_event_fail": "The errors occurred while creating quest",
    "suspend_list": "Suspend List"
  },
  "voucherSettings": {
    "Search_here": "Maghanap dito",
    "Voucher_code": "Maghanap dito",
    "Campaign": "Campaign",
    "Type": "Type",
    "Value": "Value",
    "Valid_from": "Valid From",
    "Valid_to": "Valid To",
    "isActive": "Status",
    "Activate": "Activate",
    "Deactivate": "Deactivate",
    "Actions": "Aksyon",
    "Active": "Active",
    "Inactive": "Inactive",
    "redeemed": "Redeemed",
    "expired": "Expired",
    "Add_Voucher_code": "Add code",
    "Add": "Add",
    "Show_per_page": "Items per page",
    "Page": "Page",
    "Name": "Pangalan",
    "show_pages": "{0} - {1} of {2} shown",
    "Edit": "Edit",
    "Delete": "Tanggalin",
    "Save": "Save",
    "Duplicate": "Duplicate",
    "Cancel": "Kanselahin",
    "Required_fields": "Required fields",
    "Detail_voucher_code": "Detail Voucher Code",
    "Delete_voucher_code": "Delete Voucher Code",
    "Confirmation": "Kumpirmasyon",
    "Delete_confirm_voucher_code": "Do you want to delete selected voucher code?",
    "Delete_voucher_code_confirmation": "Do you want to delete these voucher codes?",
    "Just_delete_inactive": "** Just only voucher code in the inactive status can be deleted.",
    "Loading_voucher_code_fail": "The errors occurred while loading voucher code",
    "Select_campaign": "Select Campaign",
    "Create_voucher_code_success": "New voucher code has been created successfully",
    "Create_voucher_code_fail": "The errors occurred while creating voucher code",
    "Update_voucher_code_status_success": "Voucher code status has been updated successfully",
    "Update_voucher_code_status_fail": "The errors occurred while updating voucher code status",
    "Delete_voucher_codes_success": "Voucher code has been deleted successfully",
    "Delete_voucher_codes_fail": "The errors occurred while deleting voucher code",
    "Delete_multiple_voucher_codes_success": "Voucher codes has been deleted successfully",
    "Delete_multiple_voucher_codes_fail": "The errors occurred while deleting voucher codes",
    "Delete_voucher_code_success": "Voucher code has been deleted successfully",
    "Delete_voucher_code_fail": "The errors occurred while deleting voucher code",
    "Update_multiple_voucher_code_status_success": "Voucher code statuses has been updated successfully",
    "Update_multiple_voucher_code_status_fail": "The errors occurred while deleting voucher code statuses",
    "Please_input_voucher_code": "Please input voucher code",
    "Please_input_voucher_code_at_least_4_characters": "Please enter at least 4 characters.",
    "Please_select_campaign": "Please select campaign",
    "Please_input_value": "Please input value",
    "Please_select_date": "Please select date",
    "Invalid_file": "File content is invalid",
    "File_size_larger_than_5Mb": "File size is larger than 5Mb",
    "Import_vouchers_success": "File has been imported successfully",
    "Import_vouchers": "Import File",
    "Number_successful_imports": "Imported items",
    "Number_skipped": "Skip items",
    "File": "File",
    "Phone_Numbers": "phone number(s)",
    "Import_voucher_code_success": "Voucher code <b>{0}</b> has been imported successfully",
    "Import_voucher_code_fail": "Voucher code <b>{0}</b> has been failed to import. Please try again",
    "All": "Lahat"
  },
  "pointConfig": {
    "point_config": "Point config",
    "Convert_spent_amount_to_points": "Convert spent amount to points",
    "Convert_topup_amount_to_points": "Convert topup amount to points",
    "Redeem_points_to_voucher": "Redeem points to voucher",
    "others": "Other",
    "Online_Payment": "Online payment",
    "transport": "Transport",
    "parcel": "Parcel",
    "food": "Pagkain",
    "mart": "Mart",
    "delivery": "Delivery",
    "intercity": "Intercity",
    "applyCash": "Apply for cash",
    "point": "Point",
    "vourcherName": "Pangalan",
    "pointsToRedeem": "Points to redeem",
    "vourcherValue": "Voucher value",
    "validTime": "Valid time",
    "month": "Month(s)",
    "day": "Mga Araw",
    "termAndCondition": "Terms & conditions",
    "Actions": "Aksyon",
    "add_voucher": "Add",
    "instructionURL": "Instruction link",
    "displayName": "Show name on super app",
    "isActive": "Active",
    "EDIT_VOUCHER": "Update Voucher",
    "DETAIL_VOUCHER": "Voucher Details",
    "CREATE_VOUCHER": "Add Voucher",
    "update_successfully": "Point config setting has been updated successfully",
    "Create_voucher_successfully": "New voucher has been created successfully",
    "Create_voucher_fail": "The errors occurred while creating voucher",
    "Update_voucher_successfully": "Voucher has been updated successfully",
    "Update_voucher_fail": "The errors occurred while updating voucher",
    "Delete_voucher_successfully": "Voucher has been deleted successfully",
    "Delete_voucher_fail": "The errors occurred while deleting voucher",
    "Confirmation": "Kumpirmasyon",
    "Delete_voucher_confirmation": "Do you want to delete this voucher?",
    "Please_enter_value_least_one": "Conversion rate is required! Set up spent amount or top-up amount to continue.",
    "Please_enter_value_is_number_spentAmount": "Please enter valid amount at \"Convert spent amount\" service \"{0}\".",
    "Please_enter_value_is_number_topUp": "Please enter valid amount at \"Convert topup amount\" method \"{0}\"."
  },
  "resetPassword": {
    "New_Password": "New Password",
    "New_Comfirm_Password": "New Confirm Password",
    "Save": "Save",
    "INFO_SUCCESS": "A reset password link has been sent to your email",
    "TOKEN_EXPIRED": "Your reset password token has been expired",
    "Back_To_Login_Page": "Back To Login Page"
  },
  "permission": {
    "editBooking": "Edit farm-out bookings",
    "companyOperator": "Company Operator",
    "Third_Party_Integration_Booking": "3rd party Integration (Booking.com)",
    supplierPermission: 'Supplier permission',
    "Hint_edit_booking": "Allow companies/suppliers to edit bookings farmed out by the fleet owner.",
    "View": "View",
    "Actions": "Aksyon",
    "EditFare": "Edit Fare",
    "EditDriverEarning": "Edit Driver Earning",
    "EditTotal": "Edit Total",
    "ImportBookings": "Import Transport Bookings",
    "ImportDeliveryBookings": "Import Delivery Bookings",
    "ShowFare": "Show Fare",
    "delete_permission": "Delete Permission",
    "DELETE_CONFIRM": "Are you sure you want to delete the selected permission!",
    "Yes": "Oo",
    "No": "No",
    "Cancel": "Kanselahin",
    "DRIVERS": "DRIVERS",
    "Driver_list": "Driver list",
    "Enter_to_search": "Enter to search",
    "Permitted_driver_list": "Permitted driver list",
    "Save": "Save",
    "CAR": "CAR",
    "Car_list": "Car list",
    "Permitted_car_list": "Permitted car list",
    "Edit_permission": "Edit permission",
    "Add_permission": "Add permission",
    "Detail_permission": "Detail permission",
    "Name": "Pangalan",
    "Driver_Car": "Driver/Car",
    "All": "Lahat",
    "Company": "kumpanya",
    "By_specific": "By specific",
    "Drivers": "Drivers",
    "Cars": "Cars",
    "Show_per_page": "Items per page",
    "Page": "Page",
    "show_pages": "{0} - {1} of {2} shown",
    "Delete": "Tanggalin",
    "Edit": "Edit",
    "Status": "Status",
    "Action": "Aksyon",
    "Inactive": "Inactive",
    "Active": "Active",
    "Update_status_error": "Update status error",
    "All_tooltip": "All driver(s) will be displayed on Map, Cue, Drivers, Operation and Reports. All car(s) will be displayed on Car mgmt",
    "Company_tooltip": "Permitted driver(s) in this company will be displayed on Map, Cue, Drivers, Operation and Reports. Permitted car(s) in this company will be displayed on Car mgmt",
    "By_specific_tooltip": "Permitted driver(s) in this list will be displayed on Drivers, Operation. Permitted car(s) in this list will be displayed on Car mgmt",
    "Full_permissions": "Full permissions",
    "ChangeBalance": "Change Credit Balance",
    "ChangeCashBalance": "Change Cash Balance",
    "TotalBalance": "Total Balance",
    "WriteOffDebt": "Write Off Debt",
    "topupPaxWallet": "Change Balance",
    "SeFleetCommission": "Set fleet commission (all drivers)",
    "EditFleetCommission": "Edit fleet commission",
    "EditBalance": "Edit Balance",
    "ViewHeatMap": "View Heat Map",
    "Delete_permission_success": "The permission has been deleted successfully",
    "Delete_permission_fail": "The errors occurred while deleting permission",
    "Create_permission_fail": "The errors occurred while creating new permission",
    "Create_permission_success": "New permission has been created successfully",
    "Update_permission_fail": "The errors occurred while updating permission",
    "Update_permission_success": "The permission has been updated successfully",
    "No_action_selected": "Please tick at least one action in the action list",
    "Required_fields": "Required fields",
    "sendSMS": "Send Message (SMS)",
    "sendInbox": "Send Message (Inbox)",
    "PayToDriver": "View 'Pay to Driver'",
    "PayoutHistory": "View 'Payout History'",
    "Pay": "Pay driver",
    "ActivateDriver": "Activate Driver",
    "Export": "Export",
    "Export_csv": "Export .csv",
    "Street-sharing_rate": "Street sharing rate"
  },
  "thirdPartySettings": {
    "category": "Category",
    "Not_over_limit_subs": "You can not create more than 10 sub-locations",
    "Show_per_page": "Items per page",
    "Page": "Page",
    "show_pages": "{0} - {1} of {2} shown",
    "Delete": "Tanggalin",
    "Edit": "Edit",
    "Status": "Status",
    "Actions": "Aksyon",
    "Action": "Aksyon",
    "Inactive": "Inactive",
    "Active": "Active",
    "website": "Website",
    "Check_Google_Address": "Check Google address from latitude & longitude",
    "Delete_Category_success": "Category has been deleted successfully",
    "Delete_category_fail": "The errors occurred while deleting category",
    "availableTime": "Available time",
    "Please_choose_at_least_one_category": "Please choose at least one category",
    "Please_input_category_name": "Please input category name",
    "Create_category_success": "Category has been created successfully",
    "Create_category_fail": "The errors occurred while creating category",
    "Update_third_party_success": "Location has been updated successfully",
    "Update_category_fail": "The errors occurred while updating category",
    "All_Category": "All category",
    "Delete_Category": "Edit category",
    "Edit_Category": "Edit category",
    "Add_Category": "Add category",
    "Add_Third_Party_location": "Add 3rd Party Location",
    "Add": "Add",
    "Edit_Third_Party_location": "Edit 3rd Party Location",
    "Location_Not_Found": "Location not found",
    "Create_third_party_success": "Location has been created successfully",
    "Create_third_party_fail": "The errors occurred while creating location",
    "Update_third_party_fail": "The errors occurred while updating location",
    "Alias": "Alias",
    "Address": "Address",
    "Latitude": "Latitude",
    "Longitude": "Longitude",
    "Please_input_address": "Please input address",
    "Please_input_name": "Please input location name",
    "Please_input_latitude": "Please input latitude",
    "Please_input_longitude": "Please input longitude",
    "Save": "Save",
    "Cancel": "Kanselahin",
    "Required_fields": "Required fields",
    "Last_update": "Last Update",
    "Username": "Username",
    "locationType": "Location Type",
    "single": "Single location",
    "multiple": "Multiple locations",
    "AddMore": "Add more",
    "locationList": "List of locations",
    "instructionLink": "Instruction Link",
    "location": "Location",
    "checkOnMap": "Check on map",
    "Remove": "Alisin",
    "placeType": "Type",
    "all": "Lahat",
    "PU": "Pickup",
    "DO": "Drop off",
    "namePlaceholder": "e.g. terminal 1",
    "instructionLinkPlaceholder": "Html page with full instruction",
    "instructionLinkTooltip": "Please enter a link for instruction page. Passenger can follow the instruction on this link to find pickup location.",
    "longitudePlaceholder": 108.1837969,
    "latitudePlaceholder": 16.0393381,
    "Error_message_lat_in_range": "The latitude must be between -90.0 and 90.0",
    "Error_message_lng_in_range": "The longitude must be between -180.0 and 180.0.",
    "Delete_third_party_success": "Location has been deleted successfully",
    "Delete_third_party_fail": "The errors occurred while deleting location",
    "Delete_location": "Delete location",
    "Note_guide": "Note: when searching on the map, your location will be quickly marked with a pin."
  },
  "city": {
    "DELETE_CITY": "Delete city",
    "DELETE_CONFIRM": "Are you sure you want to delete the selected city?",
    "Yes": "Oo",
    "No": "No",
    "Cancel": "Kanselahin",
    "EDIT_CITY": "Edit city",
    "ADD_CITY": "Add City",
    "Name": "Pangalan",
    "Required_fields": "Required fields",
    "Save": "Save",
    "Add": "Add",
    "Show_per_page": "Items per page",
    "Page": "Page",
    "show_pages": "{0} - {1} of {2} shown",
    "Delete": "Tanggalin",
    "Edit": "Edit",
    "DETAIL_CITY": "Detail city",
    "Update_city_success": "The city has been updated successfully",
    "Create_city_success": "New city has been created successfully",
    "Delete_city_success": "The city has been deleted successfully",
    "cityName": "City name",
    "Service": "Service",
    "serviceTransportation": "Transportation",
    "serviceAssistant": "Assistant",
    "serviceDelivery": "Delivery",
    "isShowDriverSignUp": "Show in provider signup",
    "note_isShowDriverSignUp": "(Apply for new signup module from 4.6.46 and above)"
  },
  "jobTab": {
    "DELETE_JOBTAB": "Delete Jobs Tab",
    "DELETE_CONFIRM": "Are you sure you want to delete the selected Jobs Tab?",
    "Yes": "Oo",
    "No": "No",
    "Cancel": "Kanselahin",
    "EDIT_JOBTAB": "Edit Job Tab",
    "ADD_JOBTAB": "Add Jobs Tab",
    "Name": "Pangalan",
    "Required_fields": "Required fields",
    "Save": "Save",
    "Add": "Add",
    "Show_per_page": "Items per page",
    "Page": "Page",
    "show_pages": "{0} - {1} of {2} shown",
    "Delete": "Tanggalin",
    "Edit": "Edit",
    "DETAIL_JOBTAB": "Detail Jobs Tab",
    "Update_JOBTAB_success": "The Jobs Tab has been updated successfully",
    "Create_JOBTAB_success": "New Jobs Tab has been created successfully",
    "Delete_JOBTAB_success": "The Jobs Tab has been deleted successfully",
    "JOBTABName": "Jobs Tab name",
    "jobsLink": "Link"
  },
  "driverNote": {
    "name": "Driver note",
    "DELETE": "Delete Driver Note",
    "DELETE_CONFIRM": "Are you sure you want to delete the selected Driver Note?",
    "EDIT": "Edit Driver Note",
    "ADD": "Add Driver Note",
    "DETAIL": "Detail Driver Note",
    "Update_success": "The Driver Note has been updated successfully",
    "Create_success": "New Driver Note has been created successfully",
    "Delete_success": "The Driver Note has been deleted successfully",
    "Delete_fail": "The errors occurred while deleting driver note",
    "Load_fail": "The errors occurred while loading driver note",
    "Update_fail": "The errors occurred while updating driver note",
    "Create_fail": "The errors occurred while creating driver note"
  },
  "flightIntegration": {
    "Username": "Username",
    "provider": "Flight Provider",
    "Key": "API Key",
    "apiId": "API Id",
    "Save": "Save",
    "Update_flight_integration_success": "The flight integration has been updated successfully",
    "Update_flight_integration_fail": "The errors occurred while updating flight integration"
  },
  "smsIntegration": {
    "provider": "SMS Provider",
    "apiToken": "API Token",
    "subAccountId": "Sub Account ID",
    "senderIdSource": "Sender ID (source):",
    "apiKey": "API Key",
    "username": "Username",
    "shortCode": "Short Code",
    "key": "Key",
    "webService": "Web service",
    "bearerToken": "Bearer token",
    "keyId": "Key ID",
    "email": "Email",
    "appKey": "App key",
    "sender": "Sender",
    "password": "Password",
    "maskedPhone": "Masked phone number",
    "senderId": "Sender ID",
    "webservice": "Web service",
    "brandname": "Brand name",
    "appSecret": "App secret",
    "consumerKey": "Consumer key",
    "requestUrl": "Request Url",
    "authId": "Auth ID",
    "authToken": "Auth Token",
    "phloId": "PHLO ID",
    "dlcNumber": "10DLC Number",
    "app_key": "App key",
    "api_key": "API key",
    "accountSID": "Account SID",
    "applicationId": "Application ID",
    "phoneNumber": "SMS number 1",
    "phoneNumber2": "SMS number 2",
    "smsMarketingNumber": "SMS makerting phone number",
    "updateSMSSuccess": "The sms integration has been updated successfully"
  },
  "mDispatcher": {
    "mDispatchers": "Partners",
    "Active": "Active",
    "Inactive": "Inactive",
    "Deactivate": "Deactivate",
    "Activate": "Activate",
    "Delete": "Tanggalin",
    "Save": "Save",
    "Edit": "Edit",
    "ADD_MDISPATCHER": "Add partner",
    "EDIT_MDISPATCHER": "Edit partner",
    "Phone_number": "Numero ng telepono",
    "first_name": "Pangalan",
    "last_name": "Huling pangalan",
    "email": "Email",
    "address": "Address",
    "mDispatcher_type": "Partner type",
    "mDispatcher_type_toolTip": "To add more partner types, please go to Settings > Partner Type",
    "queuing_area_toolTip": "To add more queuing areas, please go to Queuing Area menu.",
    "mDispatcher": "Partner",
    "card_management": "Card Management",
    "Select_mDispatcher_type": "Select Partner Type",
    "Amount": "Amount",
    "Percent": "Percent",
    "Yes": "Oo",
    "Required_fields": "Required fields",
    "Cancel": "Kanselahin",
    "commission_type": "Commission Type",
    "commission_value": "Commission Value",
    "Name": "Pangalan",
    "Phone_Number": "Numero ng telepono",
    "queuing_area": "Queuing Area",
    "mdispatcher_type": "Partner Type",
    "registered_from": "Registered From",
    "registration_date": "Registration Date",
    "status": "Status",
    "actions": "Mga aksyon",
    "Select_queue_area": "Select queue area",
    "for_new_mDipatcher_account_password_default_is_password": "For new partner account, the default password is \"12345678\"",
    "Create_mDispatcher_success": "New partner has been created successfully",
    "Create_mDispatcher_fail": "The errors occurred while creating partner",
    "Load_mDispatcher_fail": "The errors occurred while loading partner",
    "Update_mDispatcher_success": "Partner has been updated successfully",
    "Update_mDispatcher_fail": "The errors occurred while updating partner",
    "Update_mDispatcher_status_success": "Partner status has been updated successfully",
    "Update_mDispatcher_status_fail": "The errors occurred while updating partner status",
    "Update_mDispatcher_statuses_success": "Partner statuses has been updated successfully",
    "Update_mDispatcher_statuses_fail": "The errors occurred while updating partner statuses",
    "Delete_mDispatcher_success": "Partner has been deleted successfully",
    "Delete_mDispatcher_fail": "The errors occurred while deleting partner",
    "Delete_mDispatchers_success": "Partners has been deleted successfully",
    "Delete_mDispatchers_fail": "The errors occurred while deleting partners",
    "Do_you_want_to_delete_these_mDipatchers": "Do you want to delete these partners?",
    "Only_mDistcher_in_the_inactive_status_can_be_deleted": "***Just only mDistcher in the inactive status can be deleted",
    "No_card": "No card found",
    "Reset_Password": "Reset Password",
    "Reset_password_success": "Partner password has been reset successfully",
    "Reset_password_fail": "The errors occurred while reseting partners password",
    "Do_you_want_to_reset_password": "Do you want to reset password ?",
    "ERROR_EXISTED_PHONE": "This phone has been existing on the system. Please input another one",
    "ERROR_INPUT_VALIDPHONE": "Please enter a valid phone number.",
    "Partner_detail": "Partner details",
    "ALERT_SELECT_ACTIVATE": "Please select partners to activate.",
    "ALERT_SELECT_DEACTIVATE": "Please select partners to deactivate.",
    "CONFIRM_DEACTIVATE_MULTI": "Do you want to deactivate these partners?",
    "CONFIRM_ACTIVATE_MULTI": "Do you want to activate these partners?",
    "ERROR_COMMISSION_NUMBERS": "Please enter a valid number",
    "No_Support": "New card adding is currently not supported in this area",
    "Add": "Add",
    "Default_zone": "Default zone",
    "required_email_payment": "The email of partner are required. Please update profile to continue.",
    "titleGuestQR": "Partner Guest-QR Link",
    "questQRContent": "Your personalized partner booking link is here! Display it prominently in bars, hotel receptions, guest rooms, and on your website—anywhere your customers might need a ride or delivery. Consider designing standees with your unique QR code, allowing customers a quick and easy scan to book their journey!",
    "View_Guest_QR": "View Guest QR"
  },
  "mDispatcherType": {
    "Name": "Pangalan",
    "Required_fields": "Required fields",
    "Save": "Save",
    "Cancel": "Kanselahin",
    "No": "No",
    "Add": "Add",
    "Show_per_page": "Items per page",
    "Page": "Page",
    "show_pages": "{0} - {1} of {2} shown",
    "Delete": "Tanggalin",
    "Edit": "Edit",
    "DELETE_MDISPATCHER_TYPE": "Delete Partner Type",
    "DELETE_CONFIRM": "Are you sure to delete partner type?",
    "Yes": "Oo",
    "EDIT_MDISPATCHER_TYPE": "Edit Partner Type",
    "ADD_MDISPATCHER_TYPE": "Add Partner Type",
    "DETAIL_MDISPATCHER_TYPE": "Detail Partner Type",
    "Actions": "Mga aksyon",
    "Delete_mDispatcherType_success": "Partner Type has been deleted successfully",
    "Delete_mDispatcherType_fail": "The errors occurred while deleting partner type",
    "Load_mDispatcherType_fail": "The errors occurred while loading partners type",
    "Create_mDispatcherType_success": "New Partner Type has been created successfully",
    "Create_mDispatcherType_fail": "The errors occurred while creating partners type",
    "Update_mDispatcherType_success": "Partner Type has been updated successfully",
    "Update_mDispatcherType_fail": "The errors occurred while updating partners type"
  },
  "queuingArea": {
    "queuing_area_info": "Queue area info",
    "queuingDrivers": "Active drivers",
    "Name": "Pangalan",
    "Required_fields": "Required fields",
    "Save": "Save",
    "Cancel": "Kanselahin",
    "Yes": "Oo",
    "No": "No",
    "Add": "Add",
    "Show_per_page": "Items per page",
    "Enter_to_search": "Enter to search",
    "Page": "Page",
    "show_pages": "{0} - {1} of {2} shown",
    "Delete": "Tanggalin",
    "Edit": "Edit",
    "Active": "Active",
    "Inactive": "Inactive",
    "Deactivate": "Deactivate",
    "Activate": "Activate",
    "drivers": {
      "order": "Order",
      "Driver_name": "Pangalan ng driver",
      "Phone_Number": "Numero ng telepono",
      "driverLicenseNumber": "License plate",
      "Car_Type": "Uri ng kotse",
      "checkInTime": "Check in time",
      "duration": "Tagal",
      "timestampScore": "Timestamp Score",
      "changeOrderDriver": "Change order",
      "detailOrderDriver": "Driver order",
      "Yes": "Oo",
      "No": "No",
      "insert": "Insert",
      "refresh": "Refresh",
      "insertDriver": "Insert a driver to the queue",
      "orderDriver": "Order #",
      "reason": "Dahilan",
      "removeDriver": "Remove Driver from Queue",
      "removeDriverConfirm": "Do you want to remove this driver from the queue?",
      "driverNotStay": "This driver doesn't stay within queue area. Please try again later.",
      "overOrder": "The order # cannot be greater than the total number of drivers in the queue.",
      "noDriverFound": "No driver found",
      "driverUpdateErrRs": "The driver is not in the queue area. Please try again later.",
      "orderUpdateErrRs": "The order # cannot be greater than the total number of drivers in the queue.",
      "removeDriverSuccess": "The driver has been successfully removed from the queue area.",
      "removeDriverFail": "An error occurred while removing driver from the queue area.",
      "operationInsertFailRs": "The driver does not exist or is unavailable.",
      "versionInsertFailRs": "To apply this feature, the driver needs to upgrade his driver app to the latest version.",
      "queueInsertFailRs": "The queue does not exist or the driver is not in the queue area. Please try again later.",
      "vehicleInsertFailRs": "The queue does not support the vehicle type of driver.",
      "orderInsertFailRs": "The order # cannot be greater than the total number of drivers in the queue.",
      "positionInsertFailRs": "An error occurred while inserting driver into the queue area.",
      "insertDriverSuccess": "The driver has been successfully inserted to the queue area.",
      "changeOrderSuccess": "The driver's position has been successfully changed.",
      "insertDriverFail": "An error occurred while inserting driver from the queue area.",
      "changeOrderFail": "An error occurred while changing driver's position from the queue area.",
      "selectToInsert": "Search: Driver name, Phone number",
      "updateBy": "Updated by operator",
      "changeOrder": "Change order",
      "remove": "Alisin"
    },
    "keepDriverPlaceCancelNoShow": "Keep driver's place when booking is canceled / no-show",
    "keepDriverPlaceUnavailable": "Keep driver's place when driver is unavailable",
    "range": "Queue range",
    "queueRangeTooltip": "This is how drivers will see their queuing position number on driver app. For example:\n  - Setting queue range at 1: drivers will see their position in queue as a single number: 1, 2, 3, 4, etc.\n  - Setting queue range at 5: drivers will see their position in queue as a range: 1-5, 6-10, 11-15, 16-20, etc.\n  - Setting queue range at 10: drivers will see their position in queue as a range: 1-10, 11-20, 21-30, 31-40, etc.",
    "queueCanceledTooltip": "- When passenger cancel or no-show, the driver will remain on the top position if the driver returns to the queue within the setting time.\n  - The driver's position will be set high priority if the driver returns to the queue within the setting time after completing a short-trip booking.",
    "DELETE_QUEUING_AREA": "Delete Queuing Area",
    "DELETE_CONFIRM": "Do you want to delete Queuing Area?",
    "EDIT_QUEUING_AREA": "Edit Queuing Area",
    "ADD_QUEUING_AREA": "Add Queuing Area",
    "DETAIL_QUEUING_AREA": "Detail queuing area",
    "Queuing_area": "Queuing area",
    "Address": "Address",
    "Enter_a_location": "Enter a location",
    "Entered_Address_is_incorrect": "The system cannot determine your location. Please use the suggested address.",
    "Not_supported_zone": "This queue area is not in the supported zone",
    "Active_mDispatcher_list": "Active partner list",
    "Queue_list": "Queue list",
    "Allow_radius": "Allow radius",
    "Min_drivers_required": "# Min Drivers Required",
    "Open_queuing_area": "Open queuing area",
    "Auto_dispatch": "Auto dispatch to drivers in queue (no need to use Partner app)",
    "Active_car_list": "Active car list",
    "mDispatcher": "Partner",
    "Google_Address": "Google Address",
    "Status": "Status",
    "Update_status_error": "Update status error",
    "driver_checkin_area": "Driver check-in area",
    "EDIT_POLYGONS": "Edit polygons",
    "pax_pickup_area": "Passenger pick-up area",
    "pickup_area": "Pick-up area",
    "your_chosen_polygon": "Your chosen polygon",
    "other_queues": "Other queues",
    "operation_zones": "Operation zones",
    "delete_pickup_area_confirm": "Are you sure you want to delete this pick-up area?",
    "delete_pickup_area": "Delete pick-up area",
    "please_choose_driver_check_in_area": "Please choose driver check-in area.",
    "please_choose_pick_up_area": "Please choose pick-up area.",
    "Load_queuing_area_fail": "The errors occurred while loading queuing area",
    "Create_queuingArea_success": "Queuing area has been created successfully",
    "Update_queuingArea_success": "Queuing area has been updated successfully",
    "Delete_queuingArea_success": "Queuing area has been deleted successfully",
    "Delete_queuingArea_fail": "The errors occurred while deleting queuing area",
    "Create_queuingArea_fail": "The errors occurred while creating queuing area",
    "Load_queuingArea_fail": "The errors occurred while loading queuing area",
    "Update_queuingArea_fail": "The errors occurred while updating queuing area"
  },
  "appBanner": {
    "Save": "Save",
    "Pax_app": "Pax app",
    "Apply_to": "Apply to",
    "Please_select_view": "Please select view",
    "Select_Type": "Please select type of banner",
    "Home_view": "Home view",
    "Other_view": "Other view",
    "Banner_1": "Banner #1",
    "Upload_an_image": "No image selected",
    "Action": "Aksyon",
    "NA": "N/A",
    "Link_to_website": "Link to website",
    "Make_a_call": "Make a call",
    "Send_an_email": "Send an email",
    "Banner_2": "Banner #2",
    "Banner_3": "Banner #3",
    "Driver_app": "Driver app",
    "pax": "Passenger app",
    "driver": "Driver app",
    "Name": "Pangalan",
    "viewType": "Type",
    "status": "Status",
    "image": "Image",
    "html": "HTML",
    "Delete_Banner": "Delete banner",
    "Delete_confirm_banner": "Are you sure you want to delete the selected banner?",
    "Delete_banner_success": "Banner has been deleted successfully",
    "Delete_banner_fail": "The errors occurred while deleting banner",
    "edit_banner": "Edit Banner",
    "detail_banner": "Detail Banner",
    "create_banner": "Add Banner",
    "note_1": "*  The file extension must be .jpg, .jpeg, .png, .gif and less than %{limit}MB.",
    "note_2": "*  Images should have a 16:9 aspect ratio.",
    "note_3": "* Other views including Inbox, Refer friend, Receipt, My booking, Promo code, Add Credit card/Payment methods, Profile, Waiting for process/Confirmed reservation, Completed view.",
    "Update_app_banner_success": "App banner has been updated successfully",
    "Update_app_banner_fail": "Failed to update the app banner!",
    "Create_app_banner_success": "App banner has been created successfully",
    "Create_app_banner_fail": "Failed to create the app banner!",
    "Note": "Please use the file in .png format (transparent background, sized 400:200px) for better app performance",
    "Limit_size_upload": "The file size must be less than 8Mb.",
    "display_duration": "Display duration",
    "playback_settings": "Playback settings",
    "display_banner_again_after": "Display banners again after",
    "stop_display_banner_after": "Stop display banner after",
    "enable_playback": "Enable playback",
    "Update_playback_success": "Playback settings has been updated successfully",
    "Update_playback_fail": "Failed to update the playback settings!",
    "language": "Language",
    "single": "Single Language",
    "multi": "Multi Languages",
    "es-ES": "Spanish",
    "en-US": "English",
    "fr-FR": "French",
    "id-ID": "Bahasa indonesia",
    "vi-VN": "Vietnamese",
    "ur-PK": "Urdu",
    "ms-MY": "Malaysia",
    "ar-AR": "Arabic",
    "nb-NO": "Norwegian",
    "en-GB": "English (United Kingdom)",
    "am-ET": "Amharic",
    "ro-RO": "Romanian",
    "version": "App Version",
    "comparisonVersionApp": {
      "is": "Is",
      "is_not": "Is not",
      "is_one_of": "Is one of",
      "is_not_one_of": "Is not one of",
      "greater_or_equal": "Greater or equal",
      "less_or_equal": "Less or equal"
    },
    "platform": "Platform",
    "platformItem": {
      "all": "Lahat",
      "iOS": "iOS",
      "android": "Android"
    },
    "userStatus": "User status",
    "Active": "Active",
    "Inactive": "Inactive",
    "in_review_completed": "In review (profile completed)",
    "in_review_incomplete": "In review (profile incomplete)",
    "in_review_inProgress": "In review (profile in progress)",
    "zones": "Zones"
  },
  "reservation": {
    "name": "Reservation",
    "company": "kumpanya",
    "carType": "Uri ng kotse",
    "licensePlate": "License Plate: %{name}",
    "driver": "Driver: %{name}",
    "showAllDrivers": "Show All Drivers",
    "available": "Available",
    "reserved": "Reserved",
    "search_holder": "Company, Driver Name, Car Type, License Plate",
    "all": "Lahat",
    "today": "Today",
    "thisWeek": "This Week",
    "day": "Daily",
    "week": "Weekly",
    "bookingID": "Booking ID",
    "tripId": "Trip ID",
    "pickupTime": "Pickup time",
    "pickupLocation": "Pickup location",
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday",
    "sunday": "Sunday",
    "overlapped": "Overlapped",
    "noData": "No data was found.",
    "bookingDetails": "Reservation Details",
    "reservedHint": "Reservation bookings are confirmed",
    "availableHint": "Don't have any confirmed reservation bookings",
    "overlappedHint": "Two or more confirmed reservation bookings overlap"
  },
  "dashboard": {
    "API": "API",
    "cc": "CC",
    "dmc": "Booking board",
    "streetSharing": "Street-Sharing",
    "kiosk": "Kiosk",
    "mDispatcher": "Partner",
    "paxApp": "Pax App",
    "webBooking": "Web Booking",
    "carHailing": "Car-Hailing",
    "acceptance": "Acceptance",
    "booking_com": "Booking.com",
    "completed": "Completed",
    "noShow": "No Show",
    "canceled": "Canceled",
    "incident": "Incident",
    "rate1": "1 Star",
    "rate2": "2 Stars",
    "rate3": "3 Stars",
    "rate4": "4 Stars",
    "rate5": "5 Stars",
    "Export_to_Excel": "Export to Excel",
    "Profit": "Profit",
    "day_change": "Daily change",
    "week_change": "Weekly change",
    "month_change": "Monthly change",
    "Booking_requests": "Booking requests",
    "Complete_bookings": "Completed bookings",
    "New_customers": "New customers",
    "Booking_dashboard": "Booking board",
    "others": "Others",
    "Revenue_booking": "Profit & Finished Booking",
    "Profit_finish_booking": "Profit & Finished Booking",
    "Booking_request": "Booking request",
    "Finished_booking": "Finished booking",
    "failed": "Failed",
    "partialCompleted": "Partial Completed",
    "Finished_booking_char_header": "Finished booking ({0})",
    "Bookings": "Bookings ({0})",
    "Ratings": "Ratings ({0})",
    "Daily": "Daily",
    "Weekly": "Weekly",
    "Monthly": "Monthly",
    "Total_customer": "Total customer {0}",
    "ProfitToolTips": "The money fleet makes after excluding all expenses",
    "RevenueToolTips": "The money fleet actually receives during selected period, including promotion, deductions for drivers, tax, etc.",
    "ProfitChangeToolTips": "The percentage of change in the fleet's profits compared to previous period",
    "BookingRequestsToolTips": "Total number of on-demand bookings and reservations which are created during selected period",
    "PercentageOfAcceptanceToolTips": "The percentage of accepted bookings during selected period",
    "BookingChangeHint": "The percentage of change in created bookings compared to previous period",
    "CompletedBookingHint": "Total number of on-demand bookings and reservations which are completed during selected period",
    "PercentageOfSatisfactionHint": "The percentage of satisfaction during selected period",
    "CompleteBookingDailyChangeHint": "The percentage of change in completed bookings compared to previous period",
    "NewCustomersHint": "Total number of new customers registered during selected period",
    "CustomerChangeHint": "The number and percentage of change in customers compared to previous period",
    "TotalCustomer": "Total number of customers in system",
    "ProfitAndFinishedBookingHint": "Profit & finished bookings of fleet during selected period",
    "BookingRequestHint": "Total number of on-demand bookings and reservations which are created during selected period",
    "FinishedBookingChartsHint": "Total number of on-demand bookings and reservations which are closed during selected period, including cancel, no show, incident, and completed",
    "Customer_count": "{0} customer count",
    "Revenue": "Revenue"
  },
  "referral": {
    "first_package": "1st package (i.e the first completed booking of Pax)",
    "driver_refers_to_pax": "Driver refers customer",
    "driver_will_get": "Driver will get",
    "money": "Money",
    "amount": "Amount",
    "percent": "Percent",
    "settlement": "Commission (% subtotal amount of each completed booking)",
    "pax_will_get": "Pax will get",
    "promotion_code": "Promotion Code",
    "select_a_promotion": "Select a promotion code",
    "maximum": "Maximum",
    "second_package": "2nd package",
    "from": "From",
    "to": "To",
    "After_2nd_package": "After 2nd package",
    "from_end_value_of_above_package": "From value of the last above package + 1",
    "Expiration_date": "Expiration date",
    "Never": "Never",
    "Customized_date": "Customized date",
    "limited": "Limited",
    "unlimited": "Unlimited",
    "Valid_from": "Valid From",
    "Valid_to": "Valid to",
    "Invitation_limit": "Referee limit/driver",
    "Status": "Status",
    "Active": "Active",
    "Inactive": "Inactive",
    "note": "* Please note that once the campaign is active, any update on reward values will only affect new customers.",
    "Required_fields": "Required fields",
    "refferal_update_successfully": "Referral setting has been updated successfully",
    "distributeIncentiveToDriver": "Distribute incentive to driver",
    "Immediate": "Immediate",
    "Periodic": "Periodic (Manually)",
    "Period": "Period",
    "startDate": "Start date",
    "nextDistributionDate": "Next distribution date",
    "oneMonth": "1 month",
    "threeMonth": "3 months",
    "sixMonth": "6 months",
    "passenger_refers_to_pax": "Customer refers customer",
    "referer_will_get": "Referer will get",
    "referee_will_get": "Referee will get",
    "promo": "Promotion",
    "Pax_invitation_limit": "Invitation limit/user",
    "promoPaidBy": "Promotion paid by",
    "fleet": "Fleet",
    "driver": "Driver",
    "promoCode": "Promotion code",
    "isSendInbox": "Send inbox",
    "isDefaultCode": "Auto apply on app",
    "autoApplyOnWeb": "Auto apply on web",
    "isDefaultCode_hint": "Add promotion code to customer's list and auto apply to booking when customer creates order",
    "Actions": "Mga aksyon",
    "add_promoCode": "Add",
    "Send_an_in_box_automatically_to_new_customers": "Send inbox to customer",
    "set_default_promo_code": "Auto apply on app",
    "EDIT_PROMO_CODE": "Edit promotion code",
    "DETAIL_PROMO_CODE": "Promotion code details",
    "CREATE_PROMO_CODE": "Create promotion code",
    "DELETE_PROMO_CODE": "Delete promotion code",
    "DELETE_CONFIRM_PROMO_CODE": "Do you want to delete this promotion code?",
    "ERROR_INPUT_SOURCE": "Please choose at least one."
  },
  "upload_document": {
    "NO_FILE_CHOSEN": "No file chosen",
    "Limit_size_upload": "File size exceeds the limit allowed {0}Mb."
  },
  "payment_info": {
    "member_since": "Member since",
    "package_name": "Package name",
    "last_payment": "Last payment",
    "credit_card": "Credit card",
    "last_digits": "Last 4 digits",
    "default": "Default",
    "add_new_card": "Magdagdag ng bagong card",
    "history": "History",
    "transaction_date": "Transaction date",
    "transaction_id": "ID ng Transaksyon",
    "billing_cycle": "Billing cycle",
    "amount": "Amount",
    "payment_history": "Billing history",
    "card_information": "Card information",
    "card_holder": "Card holder",
    "card_number": "Numero ng card",
    "expiration_date": "Expiration date",
    "cvv": "CVV"
  },
  "search_by": {
    "group_id": "Group ID",
    "booking_id": "# ng booking",
    "recurring_id": "Recurring #",
    "ExternalID": "External #",
    "tripId": "Trip #",
    "driver_name": "Pangalan ng driver",
    "driver_phone_number": "Driver phone number",
    "vehicle": "Vehicle",
    "operator": "Operator",
    "passenger_email": "Passenger email",
    "passenger_name": "Pangalan ng pasahero",
    "passenger_phone_number": "Passenger phone number",
    "destination": "Patutunguhan",
    "pick_up": "Pick-up",
    "flight_number": "Flight #",
    "book_from": "Pagbu-book mula sa",
    "note": "Note",
    "email": "Email",
    "phone": "Phone"
  },
  "withdrawal": {
    "requestTime": "Request time",
    "referenceId": "Reference #",
    "driverName": "Pangalan ng driver",
    "phoneNumber": "Numero ng telepono",
    "company": "kumpanya",
    "description": "Description",
    "requestAmount": "Request amount",
    "transferAmount": "Transfer amount",
    "currentBalance": "Current balance",
    "currency": "Pera",
    "action": "Aksyon",
    "approve": "Approve",
    "reject": "Reject",
    "transferToBankAccount": "Transferred to bank account *{0}",
    "transferToWingBank": "Transferred to Wing bank ID *{0}",
    "transferToCreditWallet": "Transferred to credit wallet",
    "reason": "Dahilan",
    "search": "Driver name, phone, company, transaction ID, reference #",
    "message": {
      "approve": "Are you sure you want to pay this driver an amount of {0}",
      "approveMulti": "Are you sure you want to pay selected driver(s)?",
      "approveSuccess": "Selected request has been paid",
      "approveFail": "Selected request was not successful. Please try again.",
      "approveMultiSuccess": "{0} out of {1} selected request(s) has been paid.",
      "approveMultiFail": "{0} out of {1} selected request(s) was not successful. Please try again.",
      "buttonConfirmTitle": "Oo",
      "closeButtonText": "No",
      "pleaseInputReason": "Please input reason",
      "rejectSuccess": "Driver's request has been rejected",
      "rejectFail": "Fail to reject the selected request"
    },
    "getDataFailure": "Can't get data",
    "rejectRequest": "Reject request"
  },
  "dynamicSurcharge": {
    "operationZone": "Operation zone",
    "activeSurcharge": "Active surcharge",
    "surcharge": "Patong na singil",
    "surchargeName": "Surcharge name",
    "parameter": "Parameter (e.g 1.x)",
    "pickupPoint": "Pickup zone",
    "dropOffPoint": "Drop-off zone",
    "delete_surcharge_success": "Surcharge has been deleted successfully",
    "delete_surcharge_fail": "The errors occurred while deleting surcharge",
    "update_surcharge_fail": "The errors occurred while updating surcharge",
    "update_surcharge_success": "Surcharge has been updated successfully",
    "create_surcharge_fail": "The errors occurred while creating surcharge",
    "create_surcharge_success": "Surcharge has been created successfully",
    "active_surcharge_fail": "The errors occurred while activating surcharge",
    "active_surcharge_success": "Surcharge has been activated successfully",
    "deactive_surcharge_fail": "The errors occurred while deactivating surcharge",
    "deactive_surcharge_success": "Surcharge has been deactivated successfully",
    "delete_surcharge": "Delete surcharge",
    "delete_confirm_message": "Are you sure you want to delete the selected surcharge?",
    "note": "*Note: this is how much your basic fare will be multiplied by. Example: a fare of $20 would be $30 when parameter is at 1.5x"
  },
  "dynamicFare": {
    "operationZone": "Operation zone",
    "activeFare": "Active fare",
    "fare": "Fare",
    "fareName": "Fare name",
    "factor": "Multiply by factor",
    "fix_amount": "Fixed amount",
    "parameter": "Parameter (e.g 1.x)",
    "delete_fare_success": "Fare has been deleted successfully",
    "delete_fare_fail": "The errors occurred while deleting fare",
    "update_fare_fail": "The errors occurred while updating fare",
    "update_fare_success": "Fare has been updated successfully",
    "create_fare_fail": "The errors occurred while creating fare",
    "create_fare_success": "Fare has been created successfully",
    "active_fare_fail": "The errors occurred while activating fare",
    "active_fare_success": "Fare has been activated successfully",
    "deactive_fare_fail": "The errors occurred while deactivating fare",
    "deactive_fare_success": "Fare has been deactivated successfully",
    "delete_fare": "Delete fare",
    "delete_confirm_message": "Are you sure you want to delete the selected fare?",
    "note": "*Note: this is how much your basic fare will be multiplied by. Example: a fare of $20 would be $30 when parameter is at 1.5x",
    "note_amount": "*Note: this is how much your basic fare will be added. Example: a fare of $20 would be $25 when set amount is $5"
  },
  "notifications": {
    "search": "Search booking ID, external ID",
    "searchProviderName": "Search provider name",
    "statusItem": {
      "all": "All notifications",
      "canceledByDriver": "Transport - Canceled by Driver",
      "canceledByPartner": "Affiliate - Canceled by partner",
      "reservationReminder": "Reservation reminder",
      "localPending": "Local - Pending",
      "reservationIsNotStarted": "Reservation isn't started",
      "partnerRequestsCars": "Partner request cars",
      "timeout": "Timeout",
      "noShow": "Affiliate - No show",
      "affiliatePending": "Affiliate - Pending",
      "affiliatePaymentFailed": "Affiliate Payment Failed",
      "affiliateTimeout": "Affiliate Timeout",
      "flightApi": "Flight API",
      "DebtWriteOff": "Debt write-off",
      "driverCancelTrip": "Intercity - Canceled by Driver",
      "minimumSeatRequired": "Minimum seats required",
      "newFarmInJobFrom3rd": "Booking.com - New booking",
      "canceledJobBy3rd": "Booking.com - Canceled",
      "updatedJobBy3rd": "Booking.com - Updated"
    },
    "sos": {
      "sosSearch": "Search booking ID, pax name, driver name",
      "statusItem": {
        "all": "Lahat",
        "driver": "Drivers",
        "passenger": "pasahero",
        "crew": "Crew"
      }
    }
  },
  "smartData": {
    "No": "Bilang",
    "driverName": "Pangalan",
    "phone": "Phone",
    "rating": "Rating",
    "completed": "Completed",
    "noShow": "No-show",
    "incident": "Incident",
    "canceled": "Canceled by driver",
    "accepted": "Accepted",
    "reject": "Reject",
    "ignore": "Ignore",
    "earning": "Earning",
    "query": {
      "top20": "Top 20",
      "top50": "Top 50",
      "top100": "Top 100",
      "bottom20": "Bottom 20",
      "bottom50": "Bottom 50",
      "bottom100": "Bottom 100"
    },
    "status": {
      "canceled": "Canceled by driver",
      "noShow": "No-show",
      "incident": "Incident",
      "completed": "Completed",
      "earning": "Earning",
      "reject": "Reject",
      "ignore": "Ignore",
      "accepted": "Accepted"
    }
  },
  "Reject": "No Show",
  "Do not allow Driver to start reservat": "Affiliation",
  "intercityRate": {
    "Delete_intercity_rate": "Delete intercity rate",
    "edit_intercity_rate": "Edit intercity rate",
    "view_intercity_rate": "View intercity rate",
    "add_intercity_rate": "Add intercity rate",
    "Delete_confirm_intercity_rate": "Do you want to delete this item?",
    "Update_intercity_rate_success": "Intercity rate has been updated successfully",
    "Update_intercity_rate_fail": "The errors occurred while updating intercity rate",
    "Created_intercity_rate_success": "Intercity rate has been created successfully",
    "Created_intercity_rate_fail": "The errors occurred while creating intercity rate",
    "Update_intercity_rate_route_success": "Intercity rate route has been updated successfully",
    "Update_intercity_rate_route_fail": "The errors occurred while updating intercity route",
    "Created_intercity_rate_route_success": "Intercity rate route has been created successfully",
    "Created_intercity_rate_route_fail": "The errors occurred while creating intercity route",
    "Loading_intercity_rate_fail": "The errors occurred while loading intercity rate detail",
    "Delete_intercity_rate_route_fail": "The errors occurred while deleting intercity rate route",
    "Delete_intercity_rate_success": "Intercity rate has been deleted successfully",
    "Delete_intercity_rate_fail": "The errors occurred while deleting intercity rate"
  },
  "intercityRoute": {
    "alias": "Alias",
    "routeNameHint": "To change route name, please go to Settings > Zone > Intercity and change name of city zones accordingly.",
    "pricePerSeat": "Price per seat (tax excluded)",
    "distance": "Distance",
    "duration": "Tagal",
    "cancellationPenalty": "Penalty amount per seat",
    "noShow": "Pax/partner no show",
    "penalty": "Penalty amount per seat",
    "no_show_tooltip": "The amount customer will be charged per seat. In addition to the penalty customer will pay tax or tech fee if applied",
    "firstLocation": "First location",
    "secondLocation": "Second location",
    "routeOneSetup": "Setup route 1",
    "routetwoSetup": "Setup route 2",
    "schedules": "Schedules",
    "date": "Date",
    "time": "Time",
    "editSchedule": "Edit schedule",
    "addSchedule": "Add schedule",
    "requestTimeEnable": "Allow customer to set request time",
    "extraFee": "Extra fee",
    "hideRouteEnable": "Hide this route",
    "timeRange": "Time range",
    "workingTime": "Working time",
    "applyForOtherDays": "Apply for other days",
    "limitSeatLuggage": "Limit seat / luggage",
    "warningMinimumSeats": "Minimum seats required",
    "warningMinimumSeatsHint": "Minimum number of seats required to make a trip happen",
    "allowStartLessMinRequiredSeats": "Driver to start trip regardless of the minimum seat requirement",
    "allowStartLessMinRequiredSeatsHint": "Allow driver to start the trip even if the requested number of seats is less than the minimum seat required",
    "freeLuggagePerSeat": "Free luggage per seat",
    "freeLuggagePerSeatHint": "Number of free luggage included per seat",
    "allowToAddExtraLuggage": "Add additional luggage",
    "allowToAddExtraLuggageHint": "Allow Passenger to add additional luggages",
    "feePerExtraLuggage": "Fee per extra luggage",
    "Delete_intercity_route": "Delete intercity route",
    "Delete_confirm_intercity_route": "Do you want to delete this route?",
    "Update_intercity_route_fail": "The errors occurred while updating intercity route",
    "Update_intercity_route_success": "Intercity route has been updated successfully",
    "created_intercity_route_success": "Intercity route has been created successfully",
    "created_intercity_route_fail": "The errors occurred while creating intercity route",
    "Delete_intercity_route_success": "Intercity route has been deleted successfully",
    "Delete_intercity_route_fail": "The errors occurred while deleting intercity route",
    "penalty_less_than_or_equal_to_price": "Please enter a value less than or equal to price per seat value.",
    "scheduleTimeOverlap": "Schedule time must not overlap with each others",
    "allows_adding_extra": "Allows adding extra charge when customer request time which isn't scheduled by fleet.",
    "this_is_to_limit_users": "This is to limit users (i.e. customer, driver, operator) from creating new booking or trip on this route. Existing bookings or trips are remained as reserved"
  },
  "dayOfWeek": [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ],
  "intercityBooking": {
    "route": "Route",
    "departureDate": "Departure date",
    "departureTime": "Departure time",
    "Departure": "Departure",
    "Arrival": "Arrival",
    "RefundFare": "Refund fare",
    "intercityZoneNote": "The zones you create here are only used to setup your intercity rates.",
    "zoneNameHint": "Please keep your zone name short and clear so that your customers can search and select easily.",
    "zoneUploadNote_2": "*  It is recommended that image resolution should be 1095x360 and file size less than 5MB.",
    "Complete_booking": "Complete Booking",
    "ConfirmCancel": "Confirm cancel"
  },
  "deliveryRate": {
    "Delete_delivery_rate": "Delete delivery rate",
    "edit_delivery_rate": "Edit delivery rate",
    "view_delivery_rate": "View delivery rate",
    "add_delivery_rate": "Add delivery rate",
    "Delete_confirm_delivery_rate": "Do you want to delete this item?",
    "Update_delivery_rate_success": "Delivery rate has been updated successfully",
    "Update_delivery_rate_fail": "The errors occurred while updating delivery rate",
    "Created_delivery_rate_success": "Delivery rate has been created successfully",
    "Created_delivery_rate_fail": "The errors occurred while creating delivery rate",
    "Update_delivery_rate_route_success": "Delivery rate route has been updated successfully",
    "Update_delivery_rate_route_fail": "The errors occurred while updating delivery route",
    "Created_delivery_rate_route_success": "Delivery rate route has been created successfully",
    "Created_delivery_rate_route_fail": "The errors occurred while creating delivery route",
    "Loading_delivery_rate_fail": "The errors occurred while loading delivery rate detail",
    "Delete_delivery_rate_route_fail": "The errors occurred while deleting delivery rate route",
    "Delete_delivery_rate_success": "Delivery rate has been deleted successfully",
    "Delete_delivery_rate_fail": "The errors occurred while deleting delivery rate",
    "distance_km": "Fee per km",
    "distance_mile": "Fee per mil",
    "flat": "Flat Fee",
    "startingFee": "Starting fee",
    "feeFirstDistance": "Fee first distance",
    "feeSecondDistance": "Fee second distance",
    "feeThirdDistance": "Fee third distance",
    "feeAfterThirdDistance": "Fee after third distance",
    "feePerMinute": "Fare per minute",
    "feeMinimum": "Fee minimum",
    "feeFirstStop": "Fee for first stop",
    "feeExtraStop": "Fee for each extra stop"
  },
  "pwaSettings": {
    "pwa": "PWA",
    "iconName": "Icon & name",
    "logoBackgroundColor": "Logo, background & color",
    "longName": "Long name",
    "shortName": "Short name",
    "icon192": "Icon on Home Screen (192x192)",
    "icon512": "Icon on Splash Screen",
    "logo": "Logo (300x300)",
    "logoMenu": "Logo menu (300x300)",
    "backgroundImage": "Background image - Transport (1024x500)",
    "backgroundImageDelivery": "Background image - Delivery (1024x500)",
    "mainColor": "Main color",
    "secondaryColor": "Secondary color",
    "showMainColor": "Show main color instead of background image",
    "success": "PWA setting has been updated successfully",
    "failed": "Failed to update PWA setting. Please try again!",
    "errorUploadFileSize": "The required dimensions are {0}x{0} px.",
    "hideWBInstallAppBtn": "Hide install app button",
    "enableBookNow": "Now",
    "enableGuestCheckout": "Guest checkout",
    "enableBookLater": "Later",
    "enableHideRecipient": "Hide recipient info (Package content, Delivery instructions)",
    "pwaLink": "PWA for Passenger",
    "partnerLink": "PWA for Partner"
  },
  "emailConfig": {
    "emailServer": "Email server",
    "default": "Default",
    "customized": "Customized",
    "fromName": "From name",
    "email": "From email",
    "replyTo": "Reply to",
    "userAuth": "Username",
    "password": "Password",
    "smtpServer": "SMTP server",
    "port": "SMTP port",
    "isAuthenticated": "Authenticated",
    "option": "Enable Option",
    "none": "None",
    "tls": "TLS",
    "ssl": "SSL",
    "save": "Save",
    "cancel": "Kanselahin",
    "success": "Update email server success",
    "fail": "Update email server fail",
    "loadFail": "Load email server detail fail"
  },
  "driverFields": {
    "Edit": "Edit",
    "View": "View",
    "Cancel": "Kanselahin",
    "show": "Show",
    "hide": "Hide",
    "EDIT_DRIVER_FIELDS": "Edit Driver Fields",
    "DETAIL_DRIVER_FIELDS": "Detail Driver Fields",
    "ADD_DRIVER_FIELDS": "Add Driver Fields",
    "fieldKey": "Fields key",
    "fieldName": "Fields name",
    "availableCC": "Available in Command Center",
    "availableDrvSignUp": "Available in Driver sign up",
    "availableCompanyCar": "Company Car - Transport drivers",
    "deliveryCompany": "Company Car - Delivery drivers",
    "availableIndividualCar": "Individual Car - Transport drivers",
    "deliveryIndividual": "Individual Car - Delivery drivers",
    "required": "Required",
    "Update_fail": "The errors occurred while updating driver fields",
    "Update_success": "The Driver Fields has been updated successfully",
    "FieldKey": "Fields Key",
    "FieldName": "Fields Name (American English)",
    "CommandCenter": "Available In Command Center",
    "SignUp": "Available In Driver Sign Up",
    "CompanyTransport": "Company Car - Transport",
    "CompanyDelivery": "Company Car - Delivery",
    "IndividualTransport": "Individual Car - Transport",
    "IndividualDelivery": "Individual Car - Delivery",
    "Require": "Required",
    "Actions": "Mga aksyon",
    "fieldType": "Fields Type",
    "default": "Default",
    "customized": "Customized"
  },
  "driverDocument": {
    "Edit": "Edit",
    "View": "View",
    "Cancel": "Kanselahin",
    "show": "Show",
    "hide": "Hide",
    "EDIT_DRIVER_DOCUMENT": "Edit Driver Document",
    "DETAIL_DRIVER_DOCUMENT": "Detail Driver Document",
    "ADD_DRIVER_DOCUMENT": "Add Driver Document",
    "documentKey": "Document key",
    "documentName": "Document name",
    "documentNameDetail": {
      "zh-HK": "Traditional Chinese",
      "it-IT": "Standard Italian",
      "es-ES": "Spanish",
      "en-US": "American English",
      "fr-FR": "Standard French",
      "zh-CN": "Simplified Chinese",
      "id-ID": "Bahasa Indonesia",
      "vi-VN": "Vietnamese",
      "ur-PK": "Urdu (Islamic Republic of Pakistan)",
      "ms-MY": "Malay (Malaysia)",
      "ar-AR": "Arabic",
      "en-GB": "English (United Kingdom)",
      "nb-NO": "Norwegian",
      "am-ET": "Amharic",
      "ro-RO": "Romanian",
      "th-TH": "Thai",
      "ta-IN": "Tamil",
      "hi-IN": "Hindi",
      "te-IN": "Telugu",
      "ka-GE": "Georgian",
      "ru-RU": "Russian",
      "kn-IN": "Kannada"
    },
    "isIndividual": "Company car - Transport drivers",
    "deliveryCompany": "Company car - Delivery drivers",
    "isOwner": "Individual driver - Transport drivers",
    "deliveryIndividual": "Individual driver - Delivery drivers",
    "availableCC": "Available in Command Center",
    "availableDrvSignUp": "Available in Driver sign up",
    "isRequired": "Required",
    "isExpiredDate": "Expiry date - Command Center",
    "expiredDateForDrvSignUp": "Expiry date - Driver signup",
    "requireExpiredDate": "Require Expiry Date",
    "requireExpiredDateForDrvSignUp": "Require Expiry Date",
    "requireDocumentId": "Require Document ID",
    "documentId": "Document ID - Command Center",
    "documentIdForDrvSignUp": "Document ID - Driver signup",
    "Update_fail": "The errors occurred while updating driver document",
    "Update_success": "The Driver Document has been updated successfully",
    "DocumentKey": "Document Key",
    "DocumentName": "Document Name (American English)",
    "CommandCenter": "Available In Command Center",
    "SignUp": "Available In Driver Sign Up",
    "CompanyTransport": "Company Car - Transport",
    "CompanyDelivery": "Company Car - Delivery",
    "IndividualTransport": "Individual Car - Transport",
    "IndividualDelivery": "Individual Car - Delivery",
    "Require": "Required",
    "Actions": "Mga aksyon"
  },
  supplier: {
    permissionRequired: 'Operator need to select a permission',
    offerDetail: 'Offer Detail',
    orderDetail: 'Order Detail',
    bookingNumber: 'Booking Number',
    date: 'Date',
    pickupLocation: 'Pick up Location',
    dropoffLocation: 'Drop off Location',
    passenger: 'Passenger',
    luggage: 'Luggage',
    pickupService: 'Pickup Service',
    pickupSignage: 'Pickup Signage',
    flightNumber: 'Flight Number',
    departure: 'Departure',
    arrival: 'Arrival',
    terminal: 'Terminal',
    gate: 'Gate',
    meetGreet: 'Meet & Greet',
    specialRequests: 'Special Requests',
    additionalServices: 'Additional Services',
    price: 'Price',
    prices: 'Prices',
    guest: 'Guest',
    serviceClass: 'Service Class',
    distance: 'Distance',
    duration: 'Duration',
    acceptOffer: 'Accept Offer',
    updateBooking: 'Update Booking',
    package: 'Package',
    route: 'Route',
    assignedChauffer: 'Assigned Chauffeur',
    assignedVehicle: 'Assigned Vehicle',
    logs: 'Logs',
    link: 'Links',
    desLink: 'Click to copy link',
    trackLink: 'Track link',
    driverLink: 'Driver link',
    close: 'Close',
    logs: 'Logs',
    links: 'Links',
    linksDes: 'Click to copy link',
    trackLink: 'Track link',
    driverLink: 'Driver link',
    status: 'Status',
    rating: 'Rating',
    permissions: 'Permissions',
    permissionsNote: 'Assign different levels of authorization to your team. You can choose whether your operators have visibility of open offers or only assignments already accepted.',
    owner: 'Owner',
    ownerNote: 'Full Access',
    manageUsers: 'Manage Users',
    manageUsersNote: 'Add/remove new operators',
    manageDrivers: 'Manage Drivers',
    manageDriversNote: 'Add/remove drivers',
    manageVehicles: 'Manage Vehicles',
    manageVehiclesNote: 'Add/remove vehicles',
    mapView: 'Live Map',
    mapViewNote: 'Track drivers and active trips live on the map.',
    calendarView: 'Calendar View',
    calendarViewNote: 'View calendar of all assigned trips.',
    bookingView: 'Booking View',
    bookingViewNote: 'View and manage current bookings, assign drivers, and allocate vehicles.',
    offersView: 'Offers View',
    offersViewNote: 'Accept new job offers.',
    pricing: 'Pricing',
    pricingNote: 'View trip prices',
    userDetail: 'User Detail',
    addOperator: 'Add Operator',
    updateOperator: 'Update Operator',
    supplier: 'Supplier',
    zone: 'Zone',
    emails: 'Emails',
    email: 'Email',
    phoneNumber: 'Phone Number',
    Deactivate: 'Deactivate',
    Activate: 'Activate',
    ALERT_SELECT_DEACTIVATE: 'Please select supplier to deactivate',
    ALERT_SELECT_ACTIVATE: 'Please select supplier to activate',
    CONFIRM_DEACTIVATE_MULTI: 'Do you want to deactivate these suppliers?',
    CONFIRM_ACTIVATE_MULTI: 'Do you want to activate these suppliers?',
    Update_supplier_user_status_success: 'Supplier status has been updated successfully',
    Update_supplier_user_status_fail: 'The errors occurred while updating supplier status',
    agent: 'Agent',
    Supplier: 'Supplier',
    addSupplier: 'Add Supplier',
    detailSupplier: 'Supplier Detail',
    addUser: 'Add User',
    Add: 'Add',
    ContactInfo: 'Contact Info',
    policy: 'Policy',
    users: 'Users',
    setting: 'Setting',
    zones: 'Zones',
    status: 'Status',
    actions: 'Actions',
    companyName: 'Company Name',
    corporationType: 'Corporation Type',
    country: 'Country',
    ADMIN_INFORMATION: 'Admin Information',
    firstName: 'First Name',
    lastName: 'Last Name',
    phoneNumber: 'Phone Number',
    email: 'Email',
    commissionValue: 'Fleet commission',
    commissionValueDes: 'Set commissions the fleet earns for every complete order.',
    paymentMethodsApplyDes: 'Job specific payment methods that suppliers can process:',
    bookingSettings: 'Booking Settings',
    bookingSettingsDes: 'Configure job visibility, release times, and delays for efficient supplier operations.',
    advanceBookingVisibilityTooltip: 'Specify how many days in advance suppliers can see the orders.',
    delayBroadcastToolTip: 'Set a delay time from the release time if set or the start of the day (00:00). This delay controls when the jobs become visible to the suppliers after the initial release time.',
    releaseTimeToolTip: 'Set the time of day when jobs are released to suppliers, starting from the first day they can see bookings. Ensure this time allows suppliers enough preparation time.',
    otherSettings: 'Other Settings',
    hideFare: 'Driver - Always hide fare',
    hideFareDes: 'Hide the fare for all drivers of this supplier.',
    supplierJobRequired: 'Supplier Job Acceptance Required',
    supplierJobRequiredDes: 'Suppliers must acknowledge and accept jobs before they can assign a driver/vehicle or allow the job to be viewed by drivers in the marketplace.',
    supplierApproval: 'Suppliers Driver/Vehicle Approval',
    supplierApprovalDes: 'Allow supplier operators to activate drivers and vehicles within their fleet.',
    emailsApply: 'Email Notifications',
    emailsApplyDes: 'Emails to send new jobs, booking updates.',
    address: 'Address',
    zipCode: 'Zip/Postal Code',
    city: 'City',
    state: 'State/Province',
    tax: 'Tax Identification Number',
    businessRegistration: 'Business Registration Number',
    vatNumber: 'VAT ID/Number',
    mobile: 'Mobile',
    editOperator: 'Edit Operator',
    searchEmail: 'Search Email',
    acceptOfferSuccess: 'Accept offer successfully',
    acceptOfferFail: 'Booking not found or another supplier has accepted the booking',
    driverPayout: 'Driver payout',
    payout: 'Payout',
    totalPayout: 'Total payout',
    totalPayoutDes: 'The total payout includes the base payout amount plus any additional fees you have chosen to allocate to your providers.',
    supplierPayout: 'Supplier payout',
    driverTip: 'Driver tip',
    cancel: 'Cancel',
    save: 'Save',
    activeSupplier: 'Active supplier',
    userName: 'Username',
    bookingCompletedView: 'Completed Bookings',
    bookingCompletedViewNote: 'View the history of all completed bookings.',
    driverEarning: 'Driver earning',
  },
}
