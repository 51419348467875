import { I18n } from 'react-redux-i18n';
import {
  calculatorFleetORAdditionalService,
  checkCompanyHasSupportPayment,
  getLanguageAutoCompleteSearch,
  roundOff,
} from '../../../../src/utils/commonFunctions';
import {
  PAYOUT_CUSTOM_TYPE,
  PAYOUT_TBD,
  PROVIDER_MAP_TYPE,
  REGULAR_MODE,
  STATUS_BOOKING,
  STATUS_EXTENAL_INFO,
  booking3rdPartyStatus,
  thirdPartyIntegration,
} from '../../../constants/commondata';
import vehicle from '../../../containers/vehicle';
import { getChannelMap, getPositionInfomation } from '../../../utils/mapUtils';
function onChangePickUpHour(e) {
  let value = e.target.value;
  if (this.state.data.time) {
    this.state.data.time.hour(value);
  }
  this.setState(
    {
      data: {
        ...this.state.data,
      },
    },
    () => {
      this.etaFareCalculator();
    }
  );
}

function onChangePickUpMin(e) {
  let value = e.target.value;
  if (this.state.data.time) {
    this.state.data.time.minute(value);
  }
  this.setState(
    {
      data: {
        ...this.state.data,
      },
    },
    () => {
      this.etaFareCalculator();
    }
  );
}

function onBookingDetailChangePickUpHour(e) {
  let value = e.target.value;
  if (this.state.data.request.pickUpTime) {
    this.state.data.request.pickUpTime.hour(value);
  }
  this.setState(
    {
      data: {
        ...this.state.data,
      },
    },
    () => {
      this.etaFareCalculator();
    }
  );
}

function onBookingDetailChangePickUpMin(e) {
  let value = e.target.value;
  if (this.state.data.request.pickUpTime) {
    this.state.data.request.pickUpTime.minute(value);
  }
  this.setState(
    {
      data: {
        ...this.state.data,
      },
    },
    () => {
      this.etaFareCalculator();
    }
  );
}

function handleCompanyDriverChange(e) {
  let newDriverCompany = {};
  this.state.driverCompany = null;
  this.state.data.request.companyId = '';
  [...this.props.commonData.companies, ...this.props.commonData.suppliers].map((cp) => {
    if (cp._id == e.target.value) {
      newDriverCompany = cp;
      this.state.driverCompany = cp;
      this.state.data.request.companyId = cp._id;
      this.state.data.request.companyName = cp.name;
    }
  });
  if (
    !checkCompanyHasSupportPayment(
      this.props.paymentMethod,
      newDriverCompany,
      this.state.data?.request?.paymentType
    )
  ) {
    this.state.data.request.paymentType = 0;
  }
  this.setState(
    {
      driverCompany: this.state.driverCompany,
    },
    () => {
      this.carTypeBaseLocation().then((service) => {
        if (service.ok && service.res) {
          let cartypeSelected = null;
          // this.updateExtraServiceForEta(service, true)
          if (service.res.vehicleType && service.res.vehicleType.length > 0) {
            cartypeSelected = service.res.vehicleType.find((car) => {
              return (
                car.vehicleType == this.state.data.request.vehicleTypeRequest
              );
            });
            if (!cartypeSelected) {
              cartypeSelected = service.res.vehicleType[0];
              this.state.data.request.vehicleTypeRequest =
                cartypeSelected.vehicleType || cartypeSelected.ty;
            }
          }
          if (cartypeSelected != null) {
            //update vehicle type
            this.setState(
              {
                cartypeSelected,
              },
              () => {
                this.carTypeBaseLocation().then((res) => {
                  if (this.updateExtraServiceForEta) {
                    this.updateExtraServiceForEta(res, true);
                  }
                  this.etaFareCalculator();
                });
              }
            );
          } else {
            this.setState({
              cartypeSelected,
            });
          }
        }
      });
    }
  );
}

function handleDeliveryCompanyDriverChange(e) {
  let newDriverCompany = {};
  this.state.driverCompany = null;
  this.state.data.request.companyId = '';
  [...this.props.commonData.companies, ...this.props.commonData.suppliers].map((cp) => {
    if (cp._id == e.target.value) {
      this.state.driverCompany = cp;
      this.state.data.request.companyId = cp._id;
      this.state.data.request.companyName = cp.name;
    }
  });
  this.setState(
    {
      driverCompany: this.state.driverCompany,
    },
    () => {
      this.carTypeBaseLocation().then((service) => {
        if (service.ok && service.res) {
          let cartypeSelected = null;
          if (service.res.vehicleType && service.res.vehicleType.length > 0) {
            let vehicleTypes = service.res.vehicleType.filter(
              (item) => item.delivery
            );
            if (vehicleTypes.length) {
              cartypeSelected = vehicleTypes.find((car) => {
                return (
                  car.vehicleType == this.state.data.request.vehicleTypeRequest
                );
              });
              if (!cartypeSelected) {
                cartypeSelected = vehicleTypes[0];
                this.state.data.request.vehicleTypeRequest =
                  cartypeSelected.vehicleType || cartypeSelected.ty;
              }
            }
          }
          if (cartypeSelected != null) {
            //update vehicle type
            this.setState(
              {
                cartypeSelected,
              },
              () => {
                this.carTypeBaseLocation().then((res) => {
                  this.etaFareCalculator();
                });
              }
            );
          } else {
            this.state.data.request.estimate.fare = null;
            this.setState({
              cartypeSelected,
              etaFare: null,
              data: this.state.data,
            });
          }
        }
      });
    }
  );
}

export const convertParamAutocomplete = ({q, auth, sessiontoken, forceProvider, language, bookId, options = {}}) => {
  const params = {
    input: q,
    fleetId: auth?.selectedFleet?.fleetId,
    sessiontoken,
    channel: getChannelMap(),
    forceProvider: forceProvider,
    language: getLanguageAutoCompleteSearch(language?.locale),
    bookId,
    userId: auth?.user?._id,
    ...options
  }
  return _.omitBy(params, _.isNil)
}

const checkResetDrvWhenChangeCompany = (companyIdDriver, companyIds = [], isBookDetail) => {
  if(isBookDetail) return true;
  if (
    !companyIdDriver ||
    companyIds?.length === 0 ||
    companyIds?.includes(companyIdDriver)
  )
    return false;
  return true;
};

const checkResetVehicleWhenChangeCompany = (vehicle, suppliers = [], isBookDetail) => {
  if(isBookDetail) return true;
  if (
    !vehicle?.vehicleId ||
    suppliers?.length === 0 ||
    suppliers?.includes(vehicle.companyId)
  )
    return false;
  return true;
};

// #KAN-3415 we need to let the operator confirm the booking which has PAMM or PCAN status manually.
const confirmBooking3rdBookingStatus = (
  book,
  is3rdBooking,
  nameThirdParty,
  fleetSetting
) => {
  if (!is3rdBooking) return false;

	// is HolidayTaxis
	if(nameThirdParty === thirdPartyIntegration.holidaytaxis) {
		const isAutoAccept = _.get(
			fleetSetting?.thirdPartyIntegration?.list?.find(
				(ob) => ob.thirdParty === nameThirdParty
			),
			'isAutoAccept',
			false
		);
		if (
			!isAutoAccept &&
			[
				STATUS_EXTENAL_INFO.PAMM,
				STATUS_EXTENAL_INFO.PCAN,
				STATUS_EXTENAL_INFO.PCON,
			].includes(book?.externalInfo?.status)
		) {
			return true;
		}
	}

	// is Booking.com 
	if(nameThirdParty === thirdPartyIntegration.bookingAPI) {
		return book.status === booking3rdPartyStatus.allocated;
	}

  return false;
}

export const validateWhenUpdateCreateBook = ({bookInfo, nameThirdParty}) => {
  // checkRequiredDispatch()
  
  /**
   * Booking Detail
   */

  // later book, Nếu đang có driver thì bắt buộc phải có vehicle
  if(
    [STATUS_BOOKING.booked, STATUS_BOOKING.arrived].includes(bookInfo.status) &&
    bookInfo?.request.pickUpTime !== 'Now' &&
    !bookInfo?.vehicle?.plateNumber &&
    bookInfo?.drvInfo?.phone
  ) {
    return 'The vehicle is required'
  }

  if(
    nameThirdParty === thirdPartyIntegration.bookingAPI &&
    bookInfo.drvInfo.phone &&
    !bookInfo?.vehicle?.vehicleId
  ) {
    return 'The vehicle is required'
  }
  return ''
}

export const searchVehicles = (vehicles, textSearch) => {
  const results = [];
  const pattern = new RegExp(textSearch, 'i'); // 'i' for case-insensitive search

  vehicles.forEach(vehicle => {
    const year = vehicle.year ? vehicle.year.toString() : '';
    const color = vehicle.color || '';
    const vehicleMake = vehicle.vehicleModel.vehicleMake || '';
    const vehicleModelName = vehicle.vehicleModel.name || '';

    if (
      pattern.test(year) ||
      pattern.test(color) ||
      pattern.test(vehicleMake) ||
      pattern.test(vehicleModelName)
    ) {
      results.push(vehicle);
    }
  });

  return results;
}

export const checkShowFare = ({ puPoints, doPoints, etaFare }) => {
  if (
    _.isEmpty(etaFare) ||
    puPoints?.length < 1 ||
    (doPoints?.length < 1 && etaFare?.rateType !== 'hourly')
  )
    return false;

  return true;
}

export const getVehicleWhenChangeDriver = ({
  oldVehicle,
  pickupTime,
  drvInfo,
  vehicleTypes,
  associateCarType = []
}) => {

  if (pickupTime === 'Now') {
    return drvInfo?.vehicle || {};
  }

  // later book
  if(oldVehicle?.vehicleId) return oldVehicle
  if(drvInfo?.vehicles?.length === 1) return drvInfo?.vehicles[0]

  // chọn xe default cua vehicleType của book later
  const vehicleDefault = (drvInfo?.vehicles || []).find(
    (vhc) => vehicleTypes.includes(vhc.vehicleType) && vhc.isDefault
  ) || {}
  if(vehicleDefault?.vehicleType) return vehicleDefault


  if(associateCarType?.length > 0) {
    // Nếu có duy nhất 1 vehicle default được asociate thì auto fill
    const vhcAssociatedDefault = drvInfo?.vehicles?.filter(vhc => associateCarType.includes(vhc.vehicleType) && vhc.isDefault)
    if(vhcAssociatedDefault?.length === 1 && vhcAssociatedDefault?.[0]?.vehicleType) return vhcAssociatedDefault[0]
    
    // Nếu có duy nhất 1 vehicle được asociate thì auto fill
    const vhcAssociatedNotDefault = drvInfo?.vehicles?.filter(vhc => associateCarType.includes(vhc.vehicleType))
    if(vhcAssociatedNotDefault?.length === 1 && vhcAssociatedNotDefault?.[0]?.vehicleType) return vhcAssociatedNotDefault[0]
  }

  return {}
};
export const resetVehicleWhenChangeDispatchType = ({pickupTime, currentVehicle, newDispatchType, isBookDetail}) => {
  if(pickupTime === 'Now') return {}
  if(isBookDetail && newDispatchType === 0) return {}
  return currentVehicle
}

export const getMsgCreateUpdateBook = (data = {}) => {
  let msg = ''
  if (data.errorCode === 'EXTERNAL_ID_EXISTED') {
    msg = I18n.t('messages.booking.EXTERNAL_ID_EXISTED')
    return msg
  }
  if (data.errorCode === 'INVALID_VEHICLE') {
    msg = 'Vehicle not supported'
    return msg
  }
  if (data.errorCode === 'INVALID_RECURRING_TIME') {
    msg = 'Invalid recurring time range. Over maximum book ahead'
    return msg
  }
  return msg
}
// only use with edit fare flow
export const calculateFareWithTipChange = ({
  auth,
  data,
  promoInfo,
  etaFare: oldFare,
  tipPercent,
}) => {
  const tipAmount = (tipPercent * oldFare.subTotal) / 100;
  const editFare = {
    tip: tipAmount.toFixed(2),
  };
  return calculateFareWhenEdit({ 
    editFare: {
      ...oldFare,
      ...editFare,
    }, 
    changeFare: true, 
    currency: auth?.selectedFleet?.currencies?.[0], 
    selectedFleet: auth.selectedFleet, 
    promoInfo, 
    data, 
    minFare: oldFare?.minFare || 0 
  });
};

// only use with edit fare flow
export const calculateFareWithServiceFeeChange = ({
  auth,
  data,
  promoInfo,
  etaFare: oldFare,
}) => {
  const editFare = {
    serviceFee: calculatorFleetORAdditionalService(
      data.extraServices,
      oldFare.subTotal
    )
  };
  return calculateFareWhenEdit({ 
    editFare: {
      ...oldFare,
      ...editFare,
    }, 
    changeFare: true, 
    currency: auth?.selectedFleet?.currencies?.[0], 
    selectedFleet: auth.selectedFleet, 
    promoInfo, 
    data, 
    minFare: oldFare?.minFare || 0 
  });
};

// only use with edit fare flow
export const calculateFareWithPromoChange = ({
  auth,
  data,
  promoInfo,
  etaFare: oldFare,
}) => {
  const editFare = {
    promoAmount: promoInfo?.value || 0,
  };
  return calculateFareWhenEdit({ 
    editFare: {
      ...oldFare,
      ...editFare,
    }, 
    changeFare: true, 
    currency: auth?.selectedFleet?.currencies?.[0], 
    selectedFleet: auth.selectedFleet, 
    promoInfo, 
    data, 
    minFare: oldFare?.minFare || 0 
  });
};

const parseFloatValue = (value) => parseFloat(value) || 0;
const sumValues = (values) =>
  values.reduce((sum, value) => sum + parseFloatValue(value), 0);

export const getLocationWhenDrapEnd = ({results, id, callback, auth, language, bookId, isTencent}) => {
  getPositionInfomation({
    latLng: results.latLng,
    callback: callback,
    id,
    auth: auth,
    language: getLanguageAutoCompleteSearch(language?.locale),
    bookId: bookId,
    isTencent
  })
}

export const compareLocations = ( oldLocation = [], newLocation = [] ) => {
  if (oldLocation?.length !== newLocation?.length) {
    return false;
  }

  return _.every(oldLocation, (item, index) => {
    return (
      item?.lat === newLocation[index]?.lat &&
      item?.lng === newLocation[index]?.lng
    );
  });
};

export const filterPointEmpty = (points = []) => {
  if (points && points.length === 0) return []
  return points.filter(ob => !_.isEmpty(_.get(ob, 'address.address', '')))
}

export function checkLocationHasChange (newPoints) {
  const isSamePoints = compareLocations(this.state.lastPointsDirection, newPoints)
  this.setState({
    lastPointsDirection: newPoints
  })
  return !isSamePoints
}

const calculateSubTotal = (
  basicFare,
  rushHourFee,
  otherFees,
  deliveryFee,
  serviceFee,
  isNewSettingAdditionFee,
  startingFee,
  moveFeeInJourney,
  waitFeeInJourney,
  mode
) => {
  if(mode === REGULAR_MODE.timeOrMileage) {
    basicFare = (parseFloat(startingFee) || 0) +
      (parseFloat(moveFeeInJourney) || 0) +
      (parseFloat(waitFeeInJourney) || 0)
  }
  let subTotal = sumValues([basicFare, rushHourFee, otherFees, deliveryFee]);
  if (!isNewSettingAdditionFee) {
    subTotal += parseFloatValue(serviceFee);
  }
  return subTotal;
};

const calculateTax = (
  estEditedSubTotal,
  taxSetting,
  serviceTax,
) => {
  let tax =
    (parseFloatValue(estEditedSubTotal) * parseFloatValue(taxSetting)) / 100 || 0;
  tax += serviceTax || 0;
  return tax;
};

const calculateTotalWithoutPromo = (
  subTotal,
  airportFee,
  meetDriverFee,
  tollFee,
  parkingFee,
  gasFee,
  techFee,
  tip,
  bookingFee,
  tax,
  serviceFee,
  isNewSettingAdditionFee
) => {
  let totalWithoutPromo = sumValues([
    subTotal,
    airportFee,
    meetDriverFee,
    tollFee,
    parkingFee,
    gasFee,
    techFee,
    tip,
    bookingFee,
    tax,
  ]);
  if (isNewSettingAdditionFee) {
    totalWithoutPromo += parseFloatValue(serviceFee);
  }
  return totalWithoutPromo;
};

const calculatePromoAmount = (subTotal, promoInfo) => {
  if (promoInfo?.type?.toLowerCase() === 'percent') {
    let promoAmount =
      (parseFloatValue(subTotal) * parseFloatValue(promoInfo.value)) / 100;
    if (promoInfo?.maximumValue?.isLimited && promoInfo?.maximumValue?.value) {
      promoAmount = Math.min(promoAmount, promoInfo.maximumValue.value);
    }
    return promoAmount || 0;
  } else {
    return promoInfo?.value || 0;
  }
};

export const calculateFareWhenEdit = ({
  editFare,
  changeFare,
  currency,
  selectedFleet,
  promoInfo = {},
  data,
  minFare,
}) => {
  if (!changeFare) {
    return _.cloneDeep(editFare);
  }

  if(editFare.mode === REGULAR_MODE.timeOrMileage) {
    editFare.basicFare = (parseFloat(editFare.startingFee) || 0) +
      (parseFloat(editFare.moveFeeInJourney) || 0) +
      (parseFloat(editFare.waitFeeInJourney) || 0)
  }

  const {
    basicFare,
    rushHourFee,
    otherFees,
    deliveryFee,
    serviceFee,
    taxSetting,
    airportFee,
    meetDriverFee,
    tollFee,
    parkingFee,
    gasFee,
    techFee,
    tip,
    bookingFee,
    creditTransactionFee,
    serviceTax,
    startingFee,
    moveFeeInJourney,
    waitFeeInJourney,
    mode
  } = editFare;

  let estEdited = {
    ...editFare,
    subTotal: calculateSubTotal(
      basicFare,
      rushHourFee,
      otherFees,
      deliveryFee,
      serviceFee,
      data?.isNewSettingAdditionFee,
      startingFee,
      moveFeeInJourney,
      waitFeeInJourney,
      mode
    ),
  };

  // const serviceFeeValue = calculatorFleetORAdditionalService(
  //   data.extraServices,
  //   estEdited.subTotal
  // )
  // estEdited.serviceFee = serviceFeeValue

  const tax = calculateTax(
    estEdited.subTotal,
    taxSetting,
    serviceTax,
  )
  estEdited.tax = tax

  estEdited.promoAmount = calculatePromoAmount(estEdited.subTotal, promoInfo);

  estEdited.totalWithoutPromo = calculateTotalWithoutPromo(
    estEdited.subTotal,
    airportFee,
    meetDriverFee,
    tollFee,
    parkingFee,
    gasFee,
    techFee,
    tip,
    bookingFee,
    tax,
    serviceFee,
    data.isNewSettingAdditionFee
  )

  let total = sumValues([
    estEdited.subTotal,
    airportFee,
    meetDriverFee,
    tollFee,
    parkingFee,
    gasFee,
    techFee,
    tip,
    bookingFee,
    tax,
    -estEdited.promoAmount,
    creditTransactionFee,
  ]);

  if (data.isNewSettingAdditionFee) {
    total += parseFloatValue(serviceFee);
  }

  if (promoInfo && promoInfo.keepMinFee && total < minFare) {
    total = minFare;
    estEdited.isMinimumTotal = true;
  } else {
    estEdited.isMinimumTotal = false;
  }

  const totalRoundOff = roundOff(total, currency.iso, selectedFleet.rounding);
  estEdited.etaFare = !totalRoundOff || totalRoundOff < 0 ? 0 : totalRoundOff;
  estEdited.totalOrder = estEdited.etaFare;

  return estEdited;
};

export const checkRequiredDispatch = ({dispatchType, isBookNow, driverPhone, vehicleId, isShuttle}) => {
  if((dispatchType === 1 || dispatchType === 3) && !driverPhone) {
    return true
  }
  return false
}

export const calculatePayout = ({
  driverInfo,
  companyIdsSelected,
  companiesInfo,
  etaFare,
  bookType,
  fareSettings,
  selectedFleet,
}) => {
  // multiple Agent/Supplier: Payout = TBD
  if (
    !companyIdsSelected ||
    companyIdsSelected?.length > 1 ||
    companyIdsSelected?.length === 0
  )
    return PAYOUT_TBD;

  // case 1 supplier/agent
  if (companyIdsSelected?.length === 1) {
    const { etaFare: total = 0 } = etaFare;
    const companyInfo = companiesInfo.find(
      (c) => c._id === companyIdsSelected[0]
    );

    // agent
    if (companyInfo?.type === 'company') {
      if (driverInfo?.phone) {
        const fleetCommission = calculatorFleetCommission({
          fareSettings,
          bookType,
          driverInfo,
          selectedFleet,
          etaFare: etaFare,
        });
        let serviceFee = selectedFleet?.additionalFees === 1 ? etaFare.fleetCommissionFromFleetServiceFee : 0
        const subFare =
          (parseFloat(serviceFee) || 0) +
          (parseFloat(etaFare.techFee) || 0) +
          (parseFloat(etaFare.creditTransactionFee) || 0) +
          (parseFloat(etaFare.markupDifference) || 0) -
          (parseFloat(etaFare.promoAmount) || 0);
        // 'Total - fleet commission x subtotal - all fee by to fleet'
        return total - (fleetCommission || 0) - subFare;
      }
      return PAYOUT_TBD;
    // case supplier 
    } else {
      const supplierCommission = calculatorSupplierCommission({
        bookType,
        etaFare: etaFare,
        supplierInfo: companyInfo,
      });
      // 'Total - (Subtotal * Fleet commission(setting on Supplier profile) / 100)'
      return total - (supplierCommission || 0);
    }
  }
};


const valFareCalculation = (editFare, fleetSetting) => {
  const commissionBasedOnTotalFare = fleetSetting?.fleetDeduction?.commissionBasedOnTotalFare;
  return commissionBasedOnTotalFare ? (parseFloatValue(editFare.totalWithoutPromo)) : parseFloatValue(editFare.subTotal);
}

export const handleGetFreeForDriver = (editFare, serviceFeeByZone, fleetFare, fleetSetting) => {
  const calculateService = (serviceFeeByZone, editFare) => {
    let total = 0
    let services = serviceFeeByZone?.fleetServices?.filter(item => editFare?.services?.includes(item.serviceId)) || [];
    for(let data of services) {
      if (data.applyTax) {
        const serviceFeeByCurrencies = data.serviceFeeByCurrencies
        const serviceFeePercent = data.serviceFeePercent || 0;
        let fleetCommissionCurrency = data.fleetCommission?.commissionByCurrencies[0]?.value || 0;
        let commisType = data.fleetCommission?.commissionType;

        if (data.serviceFeeType === 'percent') {
          if (commisType === 'percent') {
            let newServiceFeePercent = (serviceFeePercent * parseFloatValue(editFare.subTotal)) / 100
            total += ((parseFloatValue(editFare.taxSetting) * newServiceFeePercent) / 100) - ((parseFloatValue((editFare.taxSetting) * newServiceFeePercent) / 100)*fleetCommissionCurrency )/100 
          } else {
            total += (parseFloatValue(editFare.taxSetting) * (serviceFeePercent * parseFloatValue(editFare.subTotal)) / 100) / 100;
          }
        } else {
          if (commisType === 'percent') {
            total += ((parseFloatValue(editFare.taxSetting) * serviceFeeByCurrencies[0].value) / 100) - (((parseFloatValue(editFare.taxSetting) * serviceFeeByCurrencies[0].value) / 100)*fleetCommissionCurrency)/100
          } else {
            total += (parseFloatValue(editFare.taxSetting) * serviceFeeByCurrencies[0].value) / 100;
          }
        }
      }
    }
    return total
  }

  const calculateDriverCommissionFromFleetServiceFee = (serviceFee, editFare) => {
    let total = 0;
    let services = serviceFee?.fleetServices?.filter(item => editFare?.services?.includes(item.serviceId)) || [];
    for(let data of services) {
      let fleetCommissionCurrency = data.fleetCommission?.commissionByCurrencies[0]?.value || 0;
      const serviceFeeByCurrencies = data.serviceFeeByCurrencies[0]?.value || 0;
      if (data.serviceFeeType === 'percent') {
        let newVal = data.serviceFeePercent * parseFloatValue(editFare.subTotal) / 100
        if (data.fleetCommission?.commissionType === 'percent') {
          total += newVal - (newVal * fleetCommissionCurrency / 100)
        } else {
          total += newVal - fleetCommissionCurrency
        }
      } else {
        if (data.fleetCommission?.commissionType === 'percent') {
          total += serviceFeeByCurrencies  - (serviceFeeByCurrencies * fleetCommissionCurrency / 100)
        } else {
          total += serviceFeeByCurrencies - fleetCommissionCurrency
        }
      }
    }
    return total
  }

  let totalFee = 0;
  let enableTax = fleetSetting?.fleetDeduction?.enableTax;
  let separateTax = fleetSetting?.fleetDeduction?.separateTax;
  const calculateTaxSup = (editFareProps) => {
    let tax = parseFloatValue(editFareProps.tax);
      if (enableTax) {
        switch (separateTax) {
          case 'allTax':
            tax = 0;
            break;
          case 'fleetTax':
            tax = 0;
            break;
          default:
            tax = 0;
            break;
        }
      }
    return tax;
  }

  if (fleetFare?.applyType !== 'all') {
    if (serviceFeeByZone?.tollFeePayTo === 0) {
      totalFee += parseFloatValue(editFare.tollFee);
    }
    if (serviceFeeByZone?.parkingFeePayTo === 0) {
      totalFee += parseFloatValue(editFare.parkingFee);
    }
    if (serviceFeeByZone?.gasFeePayTo === 0) {
      totalFee += parseFloatValue(editFare.gasFee);
    }
    if (serviceFeeByZone?.taxActive) {
      totalFee += calculateTaxSup(editFare);
    }
    if (fleetSetting.additionalFees === 1) {
      totalFee += calculateDriverCommissionFromFleetServiceFee(serviceFeeByZone, editFare);
    }

  } else {
    if (fleetFare?.tollFeePayTo === 0) {
      totalFee += parseFloatValue(editFare.tollFee);
    }
    if (fleetFare?.parkingFeePayTo === 0) {
      totalFee += parseFloatValue(editFare.parkingFee);
    }

    if (fleetFare?.gasFeePayTo === 0) {
      totalFee += parseFloatValue(editFare.gasFee);
    }

    if (fleetFare?.taxActive) {
      totalFee += calculateTaxSup(editFare);
    }
    if (fleetSetting.additionalFees === 1) {
      totalFee += calculateDriverCommissionFromFleetServiceFee(fleetFare, editFare);
    }
  }

  if (fleetFare?.airport?.payTo === 0) {
    totalFee += parseFloatValue(editFare.airportFee);
  }

  if (fleetFare?.meetDriver?.payTo === 0) {
    totalFee += parseFloatValue(editFare.meetDriverFee);
  }

  let totalServiceFee = calculateService(serviceFeeByZone, editFare);
  totalFee += totalServiceFee;
  return totalFee;
}

export const getAllPointNotEmpty = ({ puPoints = [], doPoints = [], roundTrip }) => {
  const puPointsNotEmpty = filterPointEmpty(puPoints),
    doPointsNotEmpty = filterPointEmpty(doPoints)
  let points = [];
  if (
    puPointsNotEmpty.length > 0 ||
    doPointsNotEmpty.length > 0

  ) {
    puPointsNotEmpty.forEach((point) => {
      let geo = point?.address?.geo || []
      if (_.isEmpty(geo)) return
      points.push({
        lat: geo[1],
        lng: geo[0],
      });
    })
    doPointsNotEmpty.forEach((point) => {
      let geo = point?.address?.geo || []
      if (_.isEmpty(geo)) return
      points.push({
        lat: geo[1],
        lng: geo[0],
      });
    })
    if (roundTrip && puPointsNotEmpty[0]) {
      let geo = puPointsNotEmpty?.[0]?.address?.geo || []
      points.push({
        lat: geo[1],
        lng: geo[0]
      });
    }
  }
  return points
}

export function calculatorPayoutWhenCustom(editFare = {}, serviceFeeByZone, fleetFare, fleetSetting, company, serviceType, fareSettings, driverInfo) {
  if(_.isEmpty(editFare)) return 0
  let addForDriver = handleGetFreeForDriver(editFare, serviceFeeByZone, fleetFare, fleetSetting);
  
  let payoutValue = 0
  if(editFare.supplierEarningType === PAYOUT_CUSTOM_TYPE.amount)
    payoutValue = parseFloatValue(editFare.editedSupplierEarning);

  if(editFare.supplierEarningType === PAYOUT_CUSTOM_TYPE.percent) 
    payoutValue = valFareCalculation(editFare, fleetSetting) * parseFloatValue(editFare.editedSupplierEarning) / 100

  if (editFare.supplierEarningType === PAYOUT_CUSTOM_TYPE.default) {
    if (company) {
      const {commissionCompanyType, commissionValue = 0, commissionCompanyValue} = company;
      if (commissionCompanyType === 'customize') {
        let commission = commissionCompanyValue?.find(item => item.serviceType === serviceType);
        let commissionValueService = commission?.value || 0;
        payoutValue = valFareCalculation(editFare, fleetSetting) - valFareCalculation(editFare, fleetSetting) * commissionValueService / 100;
      } else if (commissionCompanyType === 'default') {
        payoutValue = valFareCalculation(editFare, fleetSetting) - valFareCalculation(editFare, fleetSetting) * commissionValue / 100;
      } else if (commissionCompanyType === 'payToDriver') {
        if (driverInfo) {
          payoutValue = calculateSupplierPayoutDriver(
            fareSettings,
            driverInfo,
            serviceType,
            fleetSetting,
            valFareCalculation(editFare, fleetSetting) - (parseFloatValue(editFare.tip)),
            serviceFeeByZone.zoneId,
          );
        }
      }
    } else {
      if (driverInfo) {
        payoutValue = calculateSupplierPayoutDriver(
          fareSettings,
          driverInfo,
          serviceType,
          fleetSetting,
          valFareCalculation(editFare, fleetSetting) - (parseFloatValue(editFare.tip)),
          serviceFeeByZone.zoneId,
        );
      }
    }
  }
  if (fleetSetting?.fleetDeduction?.commissionBasedOnTotalFare) {
    // totalWithoutPromo includes tip and fleet can not get tip
    let newFleet = parseFloatValue(editFare.totalWithoutPromo) - parseFloatValue(editFare.subTotal) - parseFloatValue(editFare.tip) - addForDriver
    payoutValue = payoutValue - newFleet
  } else {
    payoutValue = parseFloatValue(addForDriver) + payoutValue
  }

  payoutValue += parseFloatValue(editFare.tip)
  
  return payoutValue
}

export function calculatePayoutOfferBooking (editFare = {}, serviceType, company, fleetSetting) {
  const {commissionCompanyType, commissionValue = 0, commissionCompanyValue} = company;
    const {feePayToSupplier = 0} = editFare;
    let newFeeSupplier = 0;
    if (commissionCompanyType === 'customize') {
      let commission = commissionCompanyValue?.find(item => item.serviceType === serviceType);
      let commissionValueService = commission?.value || 0;
      newFeeSupplier = feePayToSupplier + valFareCalculation(editFare, fleetSetting) * (100 - commissionValueService) / 100;
    } else if (commissionCompanyType === 'default') {
      newFeeSupplier = feePayToSupplier + valFareCalculation(editFare, fleetSetting) * (100 - commissionValue) / 100;
    } else if (commissionCompanyType === 'payToDriver') {
      newFeeSupplier = feePayToSupplier + valFareCalculation(editFare, fleetSetting);
    }
    let payoutValue = 0;
    if (fleetSetting?.fleetDeduction?.commissionBasedOnTotalFare) {
      let newFleet = parseFloatValue(editFare.totalWithoutPromo) - newFeeSupplier;
      payoutValue = parseFloatValue(editFare.subTotal) - newFleet
    } else {
      payoutValue = newFeeSupplier
    }
    return payoutValue;
}

export const calculateSupplierPayoutDriver = (
  fareSettings,
  driverInfo,
  bookType,
  selectedFleet,
  payout,
  zoneId,
) => {
  const differentFleetCommission = _.get(
    selectedFleet,
    'generalSetting.differentFleetCommission',
    false
  );
  let driverPayout = 0;
  const driver = _.get(driverInfo, 'driverId.driverInfo', null) || _.get(driverInfo, 'driverInfo', null) || {};
  // custome commission based service
  if (differentFleetCommission) {
    let fleetCommissionObj = {};

    // get fleetCommission by setting fleet
    fleetCommissionObj = _.find(
      _.get(fareSettings, `fare.fleetCommission`, []),
      (ob) => ob.serviceType === bookType
    );

    if (_.get(fareSettings, `fare.defaultFleetCommissionType`) === 'differentZones') {
      fleetCommissionObj = _.find(
        _.get(fareSettings, `fare.defaultFleetCommissionValue.differentZones`, []).find(ob => ob.zoneId === zoneId)?.value || [],
        (ob) => ob.serviceType === bookType
      )
    } else {
      fleetCommissionObj = _.find(
        _.get(fareSettings, `fare.defaultFleetCommissionValue.sameZones`, []),
        (ob) => ob.serviceType === bookType
      )
    }
    // check driver has customize fleet commission
    if (
      !_.isEmpty(driver) &&
      driver.commissionDriverType === 'customize' &&
      driver.commissionDriverValue &&
      driver.commissionDriverValue.length > 0
    ) {
      fleetCommissionObj = _.find(
        _.get(driver, 'commissionDriverValue', []),
        (ob) => ob.serviceType === bookType
      );
    }
    if (!_.isEmpty(fleetCommissionObj)) {
      driverPayout = payout - fleetCommissionObj.value;
      if (fleetCommissionObj.type === 'percent') {
        driverPayout = payout -  payout * fleetCommissionObj.value / 100;
      }
    } else {
      driverPayout = payout;
    }
  } else {
    const commissionValue = _.get(
      driver,
      'commissionByCurrencies[0].commissionValue',
      0
    );
    if (!_.isEmpty(driver) && commissionValue > 0) {
      driverPayout = payout - commissionValue;
      if (driver.commissionType === 'percent') {
        driverPayout = payout - (payout * commissionValue) / 100;
      }
    }
  }
  return driverPayout;
}

export const calculatorFleetCommission = ({
  fareSettings,
  driverInfo,
  bookType,
  selectedFleet,
  etaFare,
}) => {
  const differentFleetCommission = _.get(
    selectedFleet,
    'generalSetting.differentFleetCommission',
    false
  );
  let fleetCommissionValue = 0;

  const driver = _.get(driverInfo, 'driverId.driverInfo', {});
  // custome commission based service
  if (differentFleetCommission) {
    let fleetCommissionObj = {};

    // get fleetCommission by setting fleet
    fleetCommissionObj = _.find(
      _.get(fareSettings, `fare.fleetCommission`, []),
      (ob) => ob.serviceType === bookType
    );

    // check driver has customize fleet commission
    if (
      !_.isEmpty(driver) &&
      driver.commissionDriverType === 'customize' &&
      driver.commissionDriverValue &&
      driver.commissionDriverValue.length > 0
    ) {
      fleetCommissionObj = _.find(
        _.get(driver, 'commissionDriverValue', []),
        (ob) => ob.serviceType === bookType
      );
    }

    if (!_.isEmpty(fleetCommissionObj)) {
      fleetCommissionValue = fleetCommissionObj.value;
      if (fleetCommissionObj.type === 'percent') {
        fleetCommissionValue = (etaFare.subTotal * fleetCommissionValue) / 100;
      }
    }
  } else {
    const driver = _.get(driver, 'driverId.driverInfo', {});
    const commissionValue = _.get(
      driver,
      'commissionByCurrencies[0].commissionValue',
      0
    );
    if (!_.isEmpty(driver) && commissionValue > 0) {
      fleetCommissionValue = commissionValue;
      if (driver.commissionType === 'percent') {
        fleetCommissionValue = (etaFare.subTotal * commissionValue) / 100;
      }
    }
  }

  return fleetCommissionValue;
};

export const calculatorSupplierCommission = ({
  supplierInfo,
  bookType,
  etaFare,
}) => {
  let commissionFinal = supplierInfo.commissionValue || 0;

  if (supplierInfo?.commissionCompanyType === 'customize') {
    const commissionCustom = _.find(
      supplierInfo.commissionCompanyValue,
      (ob) => ob.serviceType === bookType
    );
    if (!_.isEmpty(commissionCustom)) commissionFinal = commissionCustom.value;
  }

  return (etaFare.subTotal * commissionFinal) / 100;
};

export const checkRoundTrip = ({ fare = {}, locationService = {} }) => {
  if (!fare?.normalFare) return false;
  const routeExists = (fare?.route || '').trim().length > 0;

  return (
    routeExists &&
    (locationService?.roundTrip || locationService?.crossZone)
  );
};

export {
  onChangePickUpHour,
  onChangePickUpMin,
  onBookingDetailChangePickUpHour,
  onBookingDetailChangePickUpMin,
  handleCompanyDriverChange,
  handleDeliveryCompanyDriverChange,
  checkResetDrvWhenChangeCompany,
	confirmBooking3rdBookingStatus,
  checkResetVehicleWhenChangeCompany
};
