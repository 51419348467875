import React, { PureComponent } from 'react';
import currencyFormatter from 'currency-formatter';
import { Translate, I18n } from 'react-redux-i18n';
import { FormGroup } from 'react-bootstrap';
import { roundOff } from '../../../utils/commonFunctions';
import { PAYMENT_LINK_NAME } from '../../../constants/commondata';
import { isMobile } from 'react-device-detect';

const EditTotalButton = ({ booking, handleEditTotal, lineShort, isFareEdited }) => {
  if(lineShort) return null
  return (
    <FormGroup className={`cue-edit-container${lineShort ? " cue-edit-container__lineShort" : ""}`}>
      <p className={`text-ellipsis ${booking.updatedTotal ? booking.request.addOnPrice && booking.request.addOnPrice !== 0 ? booking.request.addOnPrice > 0 ? 'adjust total-edited' : 'adjust-slow total-edited' : null : booking.request.addOnPrice && booking.request.addOnPrice !== 0 ? booking.request.addOnPrice > 0 ? 'adjust' : 'adjust-slow' : null}`}>
        {currencyFormatter.format(booking.completedInfo.total, {
          code: booking.currencyISO
        })}
      </p>
      {" "}
    </FormGroup>
  )
};

export const PaymentText = ({ booking }) => {
  const isDisplayCancelNoShowAmount = booking => {
    return [
      'canceled',
      'canceledByPassenger',
      'canceledByPartner',
      'canceledBymDispatcher',
      'canceledByCorpAd',
      'canceledByAPI',
      'canceledByWebBooking',
      'canceledByCC',
      'canceledByDriver',
      'noShow',
      'incident'
    ].includes(booking.status)
      && booking.cancelInfo
      && booking.cancelInfo.policies.value
      && booking.cancelInfo.policies.value !== '0';
  };

  const renderPaymentType = (paymentType) => {
    switch (paymentType) {
      case 0:
        return <Translate value="paymentMethod.cash" />;
      case 16:
        return <Translate value="paymentMethod.cashBySender" />;
      case 17:
        return <Translate value="paymentMethod.cashByRecipient" />;
      case 1:
      case 2:
      case 4:
        return <Translate value="paymentMethod.personalCard" />;
      case 3:
        return <Translate value="paymentMethod.fleetCard" />;
      case 5:
        return <Translate value="paymentMethod.mDispatcherCard" />;
      case 6:
        return <Translate value="paymentMethod.directBilling" />;
      case 7:
        return <Translate value="paymentMethod.corporateCard" />;
      case 8:
        return <Translate value="paymentMethod.corporatePrepaid" />;
      case 9:
        return <Translate value="paymentMethod.qrCodePayment" />;
      case 10:
        return <Translate value="paymentMethod.applePay" />;
      case 23:
        return <Translate value="paymentMethod.googlePay" />;
      case 25:
        return <Translate value="paymentMethod.easyPaisa" />;
      case 26:
        return <Translate value="paymentMethod.jazzCash" />;
      case 12:
        return <Translate value="paymentMethod.creditExternalCard" />;
      case 13:
        return <Translate value="paymentMethod.wallet" />;
      case 14:
        return <Translate value="paymentMethod.tnGeWalletlet" />;
      case 20:
        return <Translate value="paymentMethod.vippseWallet" />;
      case 25:
        return <Translate value="paymentMethod.easyPaisa" />;
      case 26:
        return <Translate value="paymentMethod.jazzCash" />;
      default:
        return '';
    }
  };

  const convertPaymentType_21 = () => {
    if(booking.completedInfo?.walletName) {
      return booking.completedInfo.walletName
    }

    if(booking.request?.walletName) return booking.request?.walletName

    return I18n.t(`paymentMethod.${booking.request?.gateway}`)
  }

  return (
    <div>
      {/* booking completed */}
      {booking?.completedInfo?.total >= 0 && (booking?.status === 'completed' || booking?.status === 'completedWithoutService') && (
        <p className="text-ellipsis" style={{ color: 'white' }}>
          {renderPaymentType(booking.completedInfo.paymentType)}
          {booking?.completedInfo?.paymentType === 21 && convertPaymentType_21()}
          {booking?.completedInfo?.paymentType === 24 &&
            `${I18n.t('paymentMethod.paymentLink')} ${PAYMENT_LINK_NAME[booking?.completedInfo?.stripeMethod] ? `(${PAYMENT_LINK_NAME[booking?.completedInfo?.stripeMethod]})` : ''}`}
        </p>
      )}

      {/* booking cancel */}
      {isDisplayCancelNoShowAmount(booking) && (
        <p className="text-ellipsis" style={{ color: 'white' }}>
          {booking.request.paymentType === 1 && <Translate value="paymentMethod.cash" />}
          {booking.request.paymentType === 2 && <Translate value="paymentMethod.personalCard" />}
          {booking.request.paymentType === 3 && <Translate value="paymentMethod.mDispatcherCard" />}
          {booking.request.paymentType === 4 && <Translate value="paymentMethod.corporateCard" />}
          {booking.request.paymentType === 5 && <Translate value="paymentMethod.directBilling" />}
          {booking.request.paymentType === 6 && <Translate value="paymentMethod.externalCard" />}
          {booking.request.paymentType === 7 && <Translate value="paymentMethod.corporatePrepaid" />}
          {booking.request.paymentType === 13 && <Translate value="paymentMethod.wallet" />}
          {booking.request.paymentType === 14 && <Translate value="paymentMethod.tnGeWalletlet" />}
          {booking.request.paymentType === 20 && <Translate value="paymentMethod.vippseWallet" />}
          {booking.request.paymentType === 25 && <Translate value="paymentMethod.easyPaisa" />}
          {booking.request.paymentType === 26 && <Translate value="paymentMethod.jazzCash" />}
          {booking.request.paymentType === 21 && convertPaymentType_21()}
        </p>
      )}

      {/* pendingPayment */}
      {booking.completedInfo && booking.completedInfo.total >= 0 && (
        <p className="text-ellipsis" style={{ color: 'white' }}>
          {booking.completedInfo.paymentStatus === 'pending' && <Translate value="report.query.paymentStatusItem.pendingPayment" />}
          {booking.completedInfo.paymentStatus === 'partial' && <Translate value="report.query.paymentStatusItem.partialPayment" />}
        </p>
      )}
    </div>
  );
};
export default class PaymentInfoColumn extends PureComponent {
  isEditableBooking = data => {
    const { auth, permissions } = this.props;
    const disable = data.pricingType
      || data.status !== 'completed'
      || !auth.selectedFleet.generalSetting
      || !auth.selectedFleet.generalSetting.editTotalCue
      || (data.status === 'completed'
        && data.completedInfo.paymentType === 0
        && auth.selectedFleet.driverDeposit);

    // only edit pull payment
    if (data.completedInfo && data.completedInfo.paymentStatus !== 'full') {
      return false;
    }

    if (disable) return false;

    if (auth.user.isAdmin) return true;

    if (!permissions || permissions.edittotal) return true;
    return false;
  };

  isDisplayCancelNoShowAmount = booking => {
    if (
      [
        'canceled',
        'canceledByPassenger',
        'canceledByPartner',
        'canceledBymDispatcher',
        'canceledByCorpAd',
        'canceledByAPI',
        'canceledByWebBooking',
        'canceledByCC',
        'canceledByDriver',
        'noShow',
        'incident'
      ].includes(booking.status)
      && booking.cancelInfo
      && booking.cancelInfo.policies.value
      && booking.cancelInfo.policies.value !== '0'
    ) {
      return true;
    }
    return false;
  };

  roundOffAmount = amount => {
    const { auth: selectedFleet, booking } = this.props;
    return roundOff(amount, booking.currencyISO, selectedFleet.rounding);
  }

  renderFareFinished = () => {
    const { booking, handleEditTotal, lineShort, isMobile } = this.props;
    let isFareEdited = false;
    if (
      booking.request.estimate &&
      (booking.request.estimate.isFareEdited ||
        booking.request.estimate.markupValue)
    ) {
      isFareEdited = true;
    }
    return (
      <>
        {
          !lineShort && !this.isEditableBooking(booking) ? (
            booking.completedInfo &&
            (booking.completedInfo.total >= 0 ||
              booking.completedInfo.totalBuyFare > 0) &&
            (booking.status === 'completed' ||
              booking.status === 'completedWithoutService') ? (
              <p
                className={
                  booking.request.addOnPrice && booking.request.addOnPrice !== 0
                    ? booking.request.addOnPrice > 0
                      ? 'adjust'
                      : 'adjust-slow'
                    : null && booking.updatedTotal
                    ? 'total-edited'
                    : null
                }
                style={{ color: 'white' }}
                title={booking.updatedTotal ? I18n.t('cue.total_edited') : ''}
              >
                {booking.pricingType === 0 ||
                booking.request.psgFleetId === this.props.auth.selectedFleet.fleetId
                  ? currencyFormatter.format(
                      this.roundOffAmount(booking.completedInfo.total),
                      {
                        code: booking.currencyISO,
                      }
                    )
                  : currencyFormatter.format(booking.completedInfo.totalBuyFare, {
                      code: booking.currencyISO,
                    })}{' '}
                {isFareEdited && <i className="fa fa-pencil" />}
              </p>
            ) : this.isDisplayCancelNoShowAmount(booking) ? (
              <p
                className={
                  booking.request.addOnPrice && booking.request.addOnPrice !== 0
                    ? booking.request.addOnPrice > 0
                      ? 'adjust'
                      : 'adjust-slow'
                    : null
                }
                style={{ color: 'white' }}
              >
                {currencyFormatter.format(booking.cancelInfo.policies.value || 0, {
                  code: booking.currencyISO,
                })}
              </p>
            ) : (
              <>
                {
                  isMobile ? null : (
                    <div
                      className={`payment-none ${lineShort ? ' custom__lineShort' : ''}`}
                    >
                      <p>---</p>
                      <p>---</p>
                    </div>
                  )
                }
              </>
            )
          ) : (
            <EditTotalButton
              booking={booking}
              handleEditTotal={handleEditTotal}
              lineShort={lineShort}
              isFareEdited={isFareEdited}
            />
          )
        }
      </>
    )
  }

  
  render() {
    const { booking, handleEditTotal, lineShort } = this.props;
    if(this.props.isMobile) {
      return (
        <>
          <PaymentText booking={booking}/>
          {this.renderFareFinished()}
        </>
      )
    }
    return (
      <div>
        <div className={`payment-info ${lineShort ? " custom__lineShort" : ""}`}>
          <PaymentText booking={booking}/>
        </div>
        {this.renderFareFinished()}
      </div>
    );
  }
}
