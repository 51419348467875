import React, { Component } from 'react';
import {
  Button,
  Modal,
  FormGroup,
  Form,
  FormControl,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Fragment } from 'react';
import _ from 'lodash';
// import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

import * as bookingDetailActions from '../../../../actions/bookingDetailAction';
import * as loadingBarActions from '../../../../actions/loadingBarActions';
import { CCLiteCommonFunc } from '../../../../utils/commonFunctions';
import { socketDispatchApi } from '../../../../utils/socketUtils';
import { socketConfigs } from '../../../../constants/socketConfigs';


const CompleteStatus = ['booked', 'arrived', 'delivering', 'collecting'];

class CompleteButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentStep: 0,
      paymentComplete: {
        isPending: false,
      },
      fareSettings: {
        fare: {}
      }
    };
  }

  componentDidMount() {
    this.getFareSettings()
  }

  getFareSettings = () => {
    const { bookInfo, selectedFleet } = this.props;
    const zoneId = _.get(bookInfo, 'request.pickup.zoneId');
    const ob = { fleetId: bookInfo.request.psgFleetId, zoneId };
    this.props.bookingDetailActions.getFleetFareSetting(ob).then(data => {
      if (data.ok && data.res) {
        this.setState(
          {
            fareSettings: data.res
          }
        );
      } else {
        this.context.notification('error', I18n.t('bookingdetail.Can_not_get_fare_setting'));
      }
    });
  }

  handleCompletePaymentIsPendingChange = (e) => {
    const { paymentComplete } = this.props;
    this.setState({ paymentComplete: {
      ...paymentComplete,
      isPending: e.target.checked
    }})
  }

  getTextSubmitButton = (paymentComplete) =>
    paymentComplete.isPending
      ? I18n.t('bookingdetail.Complete_booking')
      : I18n.t('bookingdetail.Pay_here');

  closeModal = () => {
    this.setState({ visible: false });
  };

  showConfirm = () => {
    this.setState({
      visible: true,
      paymentComplete: {
        isPending: false,
      },
    });
  };

  completeWithPaymentOkButtonClick = () => {
    // if(this.props.completedMarkFailed) {
    //   this.props.submitMarkFailedRecipient()
    //   return
    // }
    this.doCompleteWithPayment();
  };

  doCompleteWithPayment = () => {
    const { bookInfo, bookInfo: { request }, selectedFleet } = this.props;
    const { estimate: { fare } } = request;
    const paymentObject = {
      address: request.destination.address,
      geo: request.destination.geo,
      fleetId: selectedFleet.fleetId,
      zipCode: request.destination.zipCode,
      bookId: bookInfo.bookId,
      total: parseFloat(fare.etaFare),
      fare: parseFloat(fare.basicFare || 0),
      tip: parseFloat(fare.tip || 0),
      tax: parseFloat(fare.tax || 0),
      promoCode: fare.promoCode,
      promoAmount: fare.promoAmount,
      heavyTraffic: 0,
      creditTransactionFee: parseFloat(fare.creditTransactionFee || 0),
      airportSurcharge: 0,
      meetDriverFee: 0,
      rushHour: 0,
      dynamicSurcharge: 0,
      techFee: parseFloat(selectedFleet.techFeeActive ? fare.techFee : 0),
      otherFees: parseFloat(fare.otherFees || 0),
      subTotal: parseFloat(fare.subTotal || 0),
      partnerCommission:  0,
      paymentType: CCLiteCommonFunc.paymentMappingActiveToCompleted(
        request.paymentType
      ),
      isMinimum: parseFloat(fare.min ? 1 : 0),
      tollFee: parseFloat(fare.tollFee || 0),
      parkingFee: parseFloat(fare.parkingFee || 0),
      gasFee: parseFloat(fare.gasFee || 0),
      dynamicFare: parseFloat(fare.dynamicFare || 0),
      serviceFee: parseFloat(fare.serviceFee || 0),
      isPending: this.state.paymentComplete.isPending || false
    };

    if(this.props.completedMarkFailed) {
      this.props.submitMarkFailedRecipient(paymentObject)
      return
    }

    this.props.bookingDetailActions.completeBooking(paymentObject)
    .then(res => {
      this.handleResultCompleteBooking(res)
    })
    this.props.loadingBarActions.showLoadingSpiner();
  }

  handleResultCompleteBooking = (data) => {
    this.props.loadingBarActions.hideLoadingSpiner();
    if (data.returnCode == 200) {
      this.props.bookingDetailActions.bookingDetailClosed();
    } else {
      var msg = '';
      if (
        data.response &&
        data.response.response &&
        data.response.response.message
      ) {
        msg += ' (' + data.response.response.message + ' )';
      }

      const errorCode = _.get(data, 'response.returnCode');
      if (errorCode && errorCode === 521) {
        msg = '(' + I18n.t(`errors.completeBooking.${errorCode}`) + ')';
      }

      if (errorCode && errorCode === 113) {
        msg = '(' + I18n.t(`errors.completeBooking.${errorCode}`) + ')';
        // return this.verifyStripeSCA(data.response.response.clientSecret);
      }

      this.context.notification(
        'error',
        I18n.t('messages.booking.complete_fail'),
        msg
      );
    }
  }

  handleCancelClick = () => {
    if(this.props.completedMarkFailed) {
      this.props.closeModalMarkFailCompleted() 
      return
    }
    this.setState({ visible: false });
  };

  render() {
    const { 
      bookInfo, 
      destinations, 
      pickups, 
      completedMarkFailed, 
    } = this.props;

    let { visible, paymentComplete, fareSettings } = this.state;
    if(completedMarkFailed) {
      visible = true
    } else {
      if (!bookInfo) {
        return null;
      }
    }

    return (
      <Fragment>
        {
          // Completed khi mark fail thì ẩn đi 
          !completedMarkFailed &&
          <Button onClick={this.showConfirm} className="btn-reset mr-sm ml-sm">
            <Translate value="intercityBooking.Complete_booking" />
          </Button>
        }
        <Modal
          show={visible}
          backdrop="static"
          dialogClassName="confirm-dialog"
          onHide={this.handleCancelClick}
          className={'confirm'}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate
                value="bookingdetail.Order_detail"
                className="white-text"
              />
              <span className="white-text">:</span> #{bookInfo.bookId}
              <span className="white-text"> -</span>{' '}
              <Translate
                className="white-text text-tranform-none"
                value={this.props.statusText}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="newbooking-form complete-with-payment md-payment">
            <div className={'fill payment-dialog step1'} >
              <SecondStep 
                paymentComplete={paymentComplete} 
                fareSettings={fareSettings} 
                handleCompletePaymentIsPendingChange={this.handleCompletePaymentIsPendingChange} 
                pickups={pickups}
                completedMarkFailed
                destinations={destinations}
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="pt0">
            <Button
              className={'btn-cancel'}
              onClick={this.handleCancelClick}
            >
              { I18n.t('bookingdetail.Cancel') }
            </Button>
            <Button
              className="btn-save"
              onClick={this.completeWithPaymentOkButtonClick}
            >
              { this.getTextSubmitButton(paymentComplete) }
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    selectedFleet: state.auth.selectedFleet,
    bookInfo: state.bookingDetail.data
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bookingDetailActions: bindActionCreators(bookingDetailActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CompleteButton);

