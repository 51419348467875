
import React, { Component } from 'react';
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    DirectionsRenderer,
    Polyline
} from "react-google-maps";
// import pickupIcon from './../../../constants/staticFiles/mk_pickup.png'
// import distinationIcon from './../../../constants/staticFiles/mk_destination.png'
import pickupIcon from './../../../assets/images/icons/pickup.svg'
import distinationIcon from './../../../assets/images/icons/destination.svg'
import puPointsIcon from './../../../assets/images/icons/puPoints.svg'
import doPointsIcon from './../../../assets/images/icons/doPoints.svg'
import {
    locationType,
} from './../../../constants/commondata'
import {
    QQMap,
    QQMarker,
    QQDirection,
    QQPolyline,
    QQWebservice,
    QQAutocomplete,
    QQUltis
} from '../../../components/qqMap'
import {decodePolyline} from './../../../utils/commonFunctions'
import _ from 'lodash';

/* global google */

const GettingStartedGoogleMap = withGoogleMap(props => {

  //fitBounds khi khong ve duong duoc tren map voi server Map
  let points = [...props.puPoints, ...props.doPoints]
  let numberBounds = 0
  const bounds = new google.maps.LatLngBounds();
  if(props.center) {
    bounds.extend(props.center)
  }
  if(points.length > 0) {
    points.map(point => {
      if(point.address && point.address.geo[1]) {
        numberBounds += 1
        bounds.extend(new google.maps.LatLng(point.address.geo[1], point.address.geo[0]))
      }
    })
  }

  return (
      <GoogleMap
          // ref={props.onMapLoad}
          defaultZoom={14}
          center={props.center}
          options={{ gestureHandling: 'greedy' }}
          ref={map => {
              if(numberBounds > 1) map && map.fitBounds(bounds)
            }
          }
      >
          {
            props.puPoints.length > 0 &&
            props.puPoints.map((point) => {
              if(point.address && point.address.geo[1]) {
                return (
                  <Marker
                      draggable={point.from != locationType.thirdParty && props.isPickupCanDrag}
                      onDragEnd={
                        (result, status) => {
                          props.handlePickupDrapEnd(result, status, point.id) 
                        } 
                      }
                      position={{
                        lat: point.address && point.address.geo[1],
                        lng: point.address && point.address.geo[0]
                      }}
                      icon={puPointsIcon} 
                      key={point.id}
                      label={{
                        text: `${point.order}`,
                        fontSize: "16px",
                        fontWeight: "bold",
                        color:'white'
                      }}
                  />
                )
              }
              return null
            })
          }
          {
            props.doPoints.length > 0 &&
            props.doPoints.map((point) => {
              if(point.address && point.address.geo[1]) {
                return (
                  <Marker
                    draggable={point.from != locationType.thirdParty && props.isDestinationCanDrag}
                    onDragEnd={
                      (result, status) => { props.handleDestinationDrapEnd(result, status, point.id) } 
                    }
                    position={{
                      lat: point.address && point.address.geo[1],
                      lng: point.address && point.address.geo[0]
                    }}
                    icon={doPointsIcon} 
                    key={point.id}
                    label={{
                      text: `${point.order}`,
                      fontSize: "16px",
                      fontWeight: "bold",
                      color:'white'
                    }}
                  />
                )
              }
              return
            })
          }
          {
              props?.showNavigationPUDO &&
              props?.directions?.points && <Polyline
              path={decodePolyline(props.directions.points)}
              // key={index}
              options={{
                strokeColor: "#419AF2",
                strokeOpacity: 0.9,
                strokeWeight: 6,
                fillColor: "#419AF2",
                fillOpacity: 0.9,
                zIndex: 2
              }}
            />
                  // return <DirectionsRenderer directions={dr} key={index} options={{ suppressMarkers: true }} />
          }
          {/* {
              props.directions && props.showNavigationPUDO && props.directions.map((dr, index) => {
                const overview_polyline_points = _.get(dr, 'routes[0].overview_polyline.points', '');
                if (overview_polyline_points) {
                  let points = decodePolyline(overview_polyline_points);
                  return <Polyline
                    path={points}
                    key={index}
                    options={{
                      strokeColor: "#419AF2",
                      strokeOpacity: 0.9,
                      strokeWeight: 6,
                      fillColor: "#419AF2",
                      fillOpacity: 0.9,
                      zIndex: 2
                    }}
                  />
                }
                  // return <DirectionsRenderer directions={dr} key={index} options={{ suppressMarkers: true }} />
              })
          } */}
      </GoogleMap>
  )
});

class BookingMap extends Component {

    constructor(e) {
        super(e)
    }

    renderQQMap = () => {
        const {
            center, handleMapLoad, locationPickUp, handlePickupDrapEnd, pickupFrom,
            locationDestination, handleDestinationDrapEnd, destinationFrom,
            extraDestination, handleExtraDestinationDrapEnd, directions,
            showNavigationPUDO
        } = this.props
        let qqCenter = null
        let drNew;
        if(directions?.points) {
          drNew = QQUltis.PolylineDecompression(
            decodePolyline(directions?.points)
          );
        }

        if (center) {
            qqCenter = new window.qq.maps.LatLng(center.lat, center.lng)
        }
        return <QQMap className='fill' onLoad={handleMapLoad} options={{ center: qqCenter }} 
          locationPickUp={locationPickUp}
          locationDestination={locationDestination}>
            {
                locationDestination && <QQMarker mapkey='dest'
                    options={{ icon: distinationIcon, draggable: locationDestination.from != locationType.thirdParty, position: locationDestination }}
                    events={{ dragend: handleDestinationDrapEnd }} />
            }
            {
                locationPickUp && <QQMarker mapkey='pickup'
                    options={{ icon: pickupIcon, draggable: locationPickUp.from != locationType.thirdParty, position: locationPickUp }}
                    events={{ dragend: handlePickupDrapEnd }} />
            }
            {/* {
                extraDestination && <QQMarker mapkey='extdest'
                    options={{ icon: distinationIcon, draggable: pickupFrom != locationType.thirdParty, position: extraDestination }}
                    events={{ dragend: handleExtraDestinationDrapEnd }} />
            } */}
            {
                showNavigationPUDO && drNew && <QQPolyline path={drNew} mapkey={'1'} />
            }
        </QQMap>
    }

    renderGGMap = () => {
        const {
            center, handleMapLoad, handlePickupDrapEnd,
            handleDestinationDrapEnd, directions, puPoints, doPoints,
            isDestinationCanDrag = true, isPickupCanDrag = true, showNavigationPUDO
        } = this.props
        return <GettingStartedGoogleMap
            showNavigationPUDO={showNavigationPUDO}
            containerElement={
                <div className='fill booking-map' />
            }
            mapElement={
                <div style={{ height: `100%` }} />
            }
            onMapLoad={handleMapLoad}
            isPickupCanDrag={isPickupCanDrag}
            isDestinationCanDrag={isDestinationCanDrag}
            center={center}
            handlePickupDrapEnd={handlePickupDrapEnd}
            puPoints={puPoints}
            doPoints={doPoints}
            handleDestinationDrapEnd={handleDestinationDrapEnd}
            directions={directions}
        />
    }

    render() {
        const { isChina } = this.props
        if (isChina) return this.renderQQMap();
        return this.renderGGMap();
    }
}

export default BookingMap