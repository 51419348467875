import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  FormGroup,
  Form,
  FormControl,
  Button,
  Modal,
  InputGroup,
  ButtonToolbar,
} from 'react-bootstrap';
import TableActions from '../../../components/table/tableAction/TableActions';
import { connect } from 'react-redux';
import * as settingActions from '../../../actions/settingActions';
import * as bookingDetailAction from '../../../actions/bookingDetailAction';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import StickyTable from '../../../components/table/stickyTable/StickyTable';
import _, { forEach } from 'lodash';
import { Translate, I18n } from 'react-redux-i18n';
import copy from 'copy-to-clipboard';
import CloseIcon from '@icons/material/CloseIcon';
import { convertFormat24h } from '../../../utils/commonFunctions';
import Confirm from '../../../components/confirm/Confirm';
import moment from 'moment';
import currencyFormatter from 'currency-formatter';
import { PAYMENT_LINK_NAME } from '../../../constants/commondata';

const tableColumnsDefault = [
  {
    key: 'amount',
    label: 'cue.Amount',
    width: 120,
    fixed: true,
    textEllipsis: true,
    customCell: (data) =>
      currencyFormatter.format(data.amount, {
        code: data.currencyISO,
      }),
  },
  {
    key: 'createdTime',
    label: 'invoice.Date',
    width: 180,
    customCellAndEllipis: true,
    customCell: (data, index, fleetSetting) => {
      return data?.createdTime
        ? moment(data?.createdTime).tz(
          fleetSetting.timezone
        ).format(
            `YYYY/MM/DD ${convertFormat24h(fleetSetting?.format24Hour)}`
          )
        : '';
    },
  },
  {
    key: 'status',
    label: 'invoice.Status',
    width: 150,
    customCell: (data) => {
      return data.status;
    },
    textEllipsis: true,
  },
  {
    key: 'chargeNote',
    label: 'bookingdetail.Note',
    width: 150,
    textEllipsis: true,
  },
  {
    key: 'paymentMethod',
    label: 'bookingdetail.PAYMENT_METHOD',
    width: 250,
    textEllipsis: true,
    customCellAndEllipis: true,
  },
  {
    key: 'operatorName',
    label: 'bookingdetail.Operator',
    width: 180,
    textEllipsis: true,
    customCellAndEllipis: true,
    customCell: (data) => {
      if(!data.operatorUserName) return data.operatorName || '';
      return `${data.operatorUserName} - ${data.operatorName}`
    },
  },
  {
    key: 'actions',
    label: 'mDispatcher.Actions',
    width: 150,
    align: 'right',
    cellClass: 'table-actions',
  },
];

const ACTIONS_TYPE = {
  copyLink: 'copyLink',
  deactivate: 'deactivate',
};

const ACTION_ITEMS = [
  {
    eventKey: ACTIONS_TYPE.copyLink,
    label: 'Copy Link',
  },
  {
    eventKey: ACTIONS_TYPE.deactivate,
    label: 'Deactivate',
  },
];

const PaymentLogs = ({ bookInfo, hasPermissionAction, ...props }, context) => {
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [rowHeight, setRowHeight] = useState(60);
  const [confirm, setConfirm] = useState();

  useEffect(() => {
    getPaymentHistoryList();
  }, []);

  const getPaymentHistoryList = () => {
    if (bookInfo.bookId) {
      props.bookingDetailAction
        .getPaymentActivities({
          fleetId: props.auth.selectedFleet.fleetId,
          bookId: bookInfo.bookId,
        })
        .then((response) => {
          if (response?.res?.returnCode === 200 && response?.res?.response) {
            setPaymentHistory(response?.res?.response);
          } else {
            context.notification('error', '');
          }
        })
        .catch(() => {});
    }
  };

  const getTableColumns = () => {
    let tableColums = Object.assign([], tableColumnsDefault);
    forEach(tableColums, (col) => {
      switch (col.key) {
        case 'actions':
          col.customCell = (obj, rowIndex) => {
            if (
              obj.paymentMethod === 'paymentLink' &&
              obj.status === 'pending'
            ) {
              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={obj}
                  onSelect={(eventKey) => handleMenuClick(eventKey, obj)}
                  totalRow={paymentHistory ? paymentHistory.length : 0}
                  rowHeight={rowHeight}
                  menuItems={
                    hasPermissionAction
                      ? ACTION_ITEMS
                      : _.filter(
                          [...ACTION_ITEMS],
                          (ob) => ob.eventKey !== ACTIONS_TYPE.deactivate
                        )
                  }
                  tableHeight={getTableHeight()}
                  tableId={'table_webhook'}
                />
              );
            }
            return null;
          };
          break;
        case 'status':
          col.customCell = (obj) => {
            return (
              <span className={`pm-${obj.status} btnStatus`}>
                {obj?.status}
              </span>
            );
          };
          break;
        case 'paymentMethod':
          col.customCell = (obj) => {
            if (
              ['credit', 'card', 'corporateCard'].includes(obj.paymentMethod)
            ) {
              return (
                <div>
                  <span>{`${I18n.t(
                    `paymentMethod.paymentMethod_${obj.paymentMethod}`
                  )} (**${obj?.cardMasked?.slice(-4)})`}</span>
                  <br />
                  <span>{obj.transactionId}</span>
                </div>
              );
            }
            if (obj.paymentMethod === 'paymentLink') {
              if (obj.stripeMethod) {
                return (
                  <div>
                    <span>{`
                      Payment Link (${PAYMENT_LINK_NAME[obj.stripeMethod]})
                      ${
                        obj?.cardMasked?.slice(-4)
                          ? ` (**${obj?.cardMasked?.slice(-4)})`
                          : ''
                      }
                    `}</span>
                    <br />
                    <span>{obj.transactionId}</span>
                  </div>
                );
              }
              return 'Payment Link';
            }
            if (obj.paymentMethod === 'externalWallet' && obj.walletName) {
              return obj.walletName
            }
            return I18n.t(`General.patmentMethod_${obj.paymentMethod}`);
          };
          break;
        default:
          break;
      }
    });
    return tableColums;
  };

  const handleMenuClick = (key, obj) => {
    switch (key) {
      case ACTIONS_TYPE.copyLink: {
        handleCopyClick(obj.paymentLink);
        break;
      }
      case ACTIONS_TYPE.deactivate: {
        setConfirm({
          id: ACTIONS_TYPE.deactivate,
          title: 'Deactivate',
          body: 'Are you sure you want to deactivate this payment link?',
          buttonTitle: 'Deactivate',
          closeButtonText: 'Cancel',
          paymentLinkId: obj?.paymentLinkId,
        });
        break;
      }
      default:
        break;
    }
  };

  const handleConfirmButtonClick = (id, confirm) => {
    switch (id) {
      case ACTIONS_TYPE.deactivate: {
        props.bookingDetailAction
          .deactivatePrepaidPaymentLink({
            fleetId: props.auth.selectedFleet.fleetId,
            paymentLinkId: confirm.paymentLinkId,
            bookId: bookInfo.bookId,
          })
          .then((response) => {
            if (response?.res?.returnCode === 200) {
              context.notification(
                'success',
                'Payment Link has been updated successfully.'
              );
              handleConfirmCloseClick();
              getPaymentHistoryList();
            } else {
              context.notification('error', '');
            }
          })
          .catch(() => {});
        break;
      }
    }
  };

  const handleConfirmCloseClick = () => {
    setConfirm(null);
  };

  const getTableHeight = () => {
    return 400;
  };

  const handleCopyClick = (key) => {
    copy(key);
    context.notification('success', 'Copied!');
  };

  const closeModal = () => {
    setPaymentHistory([]);
    props.handleClose();
  };

  return (
    <>
      <Modal
        show={true}
        backdrop={true}
        dialogClassName="confirm-dialog"
        className="bookingPopup paymentLogs"
        onHide={closeModal}
      >
        <Modal.Body>
          <div className="titlePopup apiKeysWH">
            <h2>{I18n.t('bookingdetail.pmActivity')}</h2>
            <Button
              type="button"
              className="btn-header text-add-header"
              // aria-label="Close"
              onClick={closeModal}
            >
              <CloseIcon color={'white'} />
            </Button>
          </div>
          <div className="gridViewTable" id="table_webhook">
            <StickyTable
              columns={getTableColumns()}
              bodyData={paymentHistory || []}
              noPagination={true}
              rowHeight={rowHeight}
              getTableHeight={getTableHeight}
              settings={props.auth?.selectedFleet}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Confirm
        confirm={confirm}
        className={'confirmInvoice'}
        handleConfirmButtonClick={handleConfirmButtonClick}
        handleConfirmCloseClick={handleConfirmCloseClick}
      />
    </>
  );
};

PaymentLogs.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bookingDetailAction: bindActionCreators(bookingDetailAction, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentLogs);
