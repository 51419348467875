import React, { useRef, useState } from 'react';
import ReactDatetime from 'react-datetime';
import { Translate, I18n } from 'react-redux-i18n';
import moment from 'moment';
import { useRootClose } from 'react-overlays';
import OverlayCC from '../OverlayCC';

const DateSelectBooking = ({
  pickUpTimeDisabled,
  hasNowButton,
  language,
  value,
  className,
  placeholder,
  disablePickupDate,
  bookDetails,
  ...props
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const handleSelectDate = (e) => {
    setShowOverlay(false);
    props.onChangeDate(e);
  };

  const datePickerValue =
    value === 'Now'
      ? I18n.t('General.Now')
      : value
      ? moment(value).format('MM/DD/YYYY')
      : '';

  return (
    <div>
      {hasNowButton &&
        !pickUpTimeDisabled &&
        !disablePickupDate &&
        !bookDetails ? (
          <div
            className="pickup-time-now"
            onClick={() => handleSelectDate('Now')}
          >
            <Translate value="General.Now" />
          </div>
        ) : null}
      <OverlayCC
        showOverlay={showOverlay}
        setShowOverlay={setShowOverlay}
        childrenAction={
          <>
             <div
                className="form-control form-custom datepicker-input"
              >
                {datePickerValue ? (
                  <span>{datePickerValue}</span>
                ) : (
                  <Translate value={placeholder} className="placeholder" />
                )}
              </div>
          </>
        }
        childrenOverlay={
          // <div className="datePicker-container">
            <ReactDatetime
              value={value === 'Now' ? moment().format('MM/DD/YYYY') : value}
              onChange={handleSelectDate}
              timeFormat={false}
              viewMode={'days'}
              dateFormat="MM/DD/YYYY"
              isValidDate={(current) => current.isSameOrAfter(moment(), 'day')}
              locale={language}
              input={false}
            />
          // </div>
        }
      />
    </div>
  );
};

DateSelectBooking.defaultProps = {
  pickUpTimeDisabled: false,
  hasNowButton: true,
  language: 'en',
  value: 'Now',
  onChangeDate: () => {},
  className: '',
  placeholder: '',
};

export default DateSelectBooking;
