import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Moment from 'react-moment';
import { Translate, I18n } from 'react-redux-i18n';
import Checkbox from "react-custom-checkbox";
import CCDropDown from "../../components/dropdown/ccDropDown";
import SelectMulti from '../../components/SelectMulti';
import {
  FormGroup,
  FormControl,
  Form,
  Button,
  Modal,
  Tab,
  Tabs,
  Image,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap'
import RadioButton from '../../components/radioButton/radio'
import FormGroupTitle from '../../components/formGroupTitile/FormGroupTitle'
import Select from '../../components/select/Select'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'

import './cue.scss'
import {
  paymentMethod,
  rideServiceSearchType,
  userType,
  CUESearchBy,
  allStatusCUE,
  statusNotDeliveryCUE,
  PAID_STATUS,
  TYPE_BOOKING_GROUP,
  BOOK_TYPE,
  CUEOfferSearchBy,
  PAYOUT_TBD,
  GROUP_ACTIONS
} from '../../constants/commondata';
import * as cueActions from '../../actions/cueActions';
import * as bookingDetailActions from '../../actions/bookingDetailAction';
import * as settingActions from '../../actions/settingActions';
import * as loadingActions from '../../actions/loadingBarActions';
import * as commonActions from '../../actions/commonDataAction';
import * as corporateCompanyActions from '../../actions/corporateCompanyAction';
import * as corporateActions from '../../actions/corporateAction';
import {
  CCLiteCommonFunc,
  Validation,
  textboxNumberHelper,
  dispatchOuterTableChange,
  getServiceType,
  getTableDynamicColumn,
  setTableDynamicColumn,
  filterPaymentMethods,
  getLineShort,
  setLineShort,
  getBookingType,
  checkBookingCanGroupOrder,
  checkListBookingCanGroupOrder,
  checkListBookingCanAssignSuplier,
  getSupplierInfoFromID,
  checkCorpHasCustomeSupplier,
  checkCanRemoveLastOptionCorporate,
  filterSupplierByZone,
  showWarningAssignSupplier,
  getSuppilerPermission,
  checkCanAssignSuplierWhenOneBook,
  handleMultiSelectCompanyAll,
  checkCanAssignDriver,
  checkCanAssignSuplier,
  checkCanDetachDriver,
  checkListBookingCanDetachDriver,
  getBookingTypeGroup,
  isBookingReservation,
  getBooksBetween,
  checkListBookingCanOfferDriver,
  checkCanOfferDriver,
  checkListBookingCanAssignDriver,
  parserIntNumberInString,
  checkShowUnassignVehicleBtn
} from '../../utils/commonFunctions';
import { realtimeHelper } from '../../utils/realTimeHelper';
import { Validator, ValidCase } from '../../components/validator';
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox';
import StickyTable from '../../components/table/stickyTable/StickyTable';
import { CueColumns } from './headerData';
import CueFilter from './components/CueFilter';
import PaymentInfoColumn from './components/PaymentInfoColumn';
import RideSharingColumn from './components/RideSharingColumn';
import LocationColumn from './components/LocationColumn';
import PassengerColum from './components/PassengerColum';
import EstimatedFareColumn from './components/EstimatedFareColumn';
import StatusColumn from './components/StatusColumn';
import tabToFullIcon from '../../assets/images/icons/tap_to_full.svg';
import tabToShortIcon from '../../assets/images/icons/tap_to_short.svg';
import { DeliveryType, Hourly, RateType, RoundTrip } from './components/ListBookingMobile/ComponentMobileUI';
import BookId from './components/BookId';
import AirportPickup from './components/AirportPickup';
import { isIPad13, isMobile, isTablet } from 'react-device-detect';
import PickupDropOffLocalTime from './components/PickupDropOffLocalTime';
import AutosuggestAssignDriver from './components/AutosuggestAssignDriver';
import ListBookingMobile from './components/ListBookingMobile';
import PickupDropOffTime from './components/PickupDropOffTime';
import DriverColumn from './components/DriverColumn';
import { cueActiveKey, cueFinishedKey, cueOfferRemoveKey } from '../../components/supplierComponent/bookingDetail/constantKey';
import Confirm from '../../components/confirm/Confirm';
import VehicleColumn from './components/VehicleColumn';
import { verifyStatusShowUnassignAllBtn } from './cueFunctions';
import { convertDataBookingForSupplier } from '../../utils/transformData';

let currencyFormatter = require('currency-formatter')

let isWorking = false

const ItemPerPage = 30

const STATUS_SHOW_SPOTTIME = ['arrived', 'booked']
const COLOR_SHOW_SPACETIME = ['confirmed', 'booked', 'pending', 'action']
const COLUMN_DEFAULT_SHOW = [
  'bookingType',
  'groupId',
  'pickupDropOff',
  'status',
  'location',
  'actual',
  'vehicle',
  'driver',
  'scheduled',
  'request.note',
  'passenger',
]

const ACTIONS_DRIVER_NAME = {
  assign: 'assign',
  unassign: 'unassign',
  offer: 'offer',
  unassignVehicle: 'unassignVehicle'
}

class Cue extends Component {
  constructor(props) {
    super(props)
    const storageCueSort = parseFloat(localStorage.getItem('cue_sort') || 1)
    this.state = {
      selectedTab: this.props?.location?.state?.selectedTab == 0 
      ? 0 : (this.props?.location?.state?.selectedTab || 
          (this.props?.auth?.user?.roles?.isSupplier 
            ? (this.props?.auth?.user?.roles?.supplierPermission?.booking ? 0 : 
              this.props.commonData?.companies[0]?.supplierJobRequired
              && this.props.auth.user?.roles?.supplierPermission?.offers
                ? 3 : 1
              ) 
            : 0) 
          || 0),
      sort: {
        time: storageCueSort,
      },
      selectItem: null,
      searchText: '',
      txtSearch: this.props.location.query.txtSearch
        ? this.props.location.query.txtSearch
        : '',
      searchBy: this.props.location.query.searchBy
        ? this.props.location.query.searchBy
        : CUESearchBy[0].value,
      showRideSharingFilter: false,
      editTotal: null,
      valid: {},
      rowHeight: 100,
      activeTableColumns: this.getLocalColumns(
        'cue_active_table',
        ['payment'],
        true
      ),
      finishedTableColumns: this.getLocalColumns(
        'cue_finished_table',
        ['estimatedFare'],
        true
      ),
      rearangeColumn: [],
      showDataPage: 1,
      bookingType: 'all',
      lineShort: false,
      checkedGroup: [],
      typeBookingGroup: '',
      openGroupModal: false,
      newGroup: 'new',
      groupIdChecking: '',
      isShowRearrangeModal: false,
      companyList: [],
    }
    this.isFiltered = false
    this.searchGroupDebounce = _.debounce(this.searchGroup, 500)
  }

  componentDidMount() {

    const userTypeLogin = this.props.auth.user.userType 
    if ( 
      userTypeLogin == userType.FleetAdmin || 
      userTypeLogin == userType.FleetUser
    ) {
      if (this.props.auth.selectedFleet.moduleSettings.corporate) {
        this.props.corporateCompanyActions.corporateCompany(
          this.props.auth.selectedFleet.fleetId
        )
      }
      if (this.props.auth.selectedFleet.moduleSettings.airlineBusiness) {
        this.props.corporateCompanyActions.corporateCompany(
          this.props.auth.selectedFleet.fleetId,
          true
        )
      }
    }
    this.props.settingActions
      .getAllCompany({
        fleetId: this.props.selectedFleet.fleetId,
        limit: 500,
      })
      .then((data) => {
        if (data.ok && data.res && data.res.list) {
          let listFiter = data.res.list
          let suplierOption = []
          const newData = listFiter.map((item) => {
            item.value = item._id
            item.label = item.name
            if(item.isActive) {
              suplierOption.push({
                value: item._id,
                label: item.name,
                operationZone: item.operationZone
              })
            }
            return item;
          });

          const supplierData = this.props.commonData.suppliers?.map((item) => {
            return {
              value: item._id,
              label: item.name,
              operationZone: item.operationZone
            }
          })

          // get list company if is operator coporate
          const corporateAccount = this.props.auth?.user?.corporateAccount
          let iscorporateUser = false
          if(corporateAccount && checkCorpHasCustomeSupplier(this.props.auth.user, corporateAccount)) {
            suplierOption = suplierOption.filter(ob => corporateAccount.supplier?.value?.includes(ob.value))
            iscorporateUser = true
          } else {
            suplierOption = [
              {value: 'all', label: 'All Company'},
              ...suplierOption
            ]
          }

          this.setState({
            companyList: [...newData, ...this.props.commonData.suppliers],
            suplierOption: [...suplierOption, ...supplierData],
            iscorporateUser
          });
        }
      })
    realtimeHelper.setTimeout()
    this.props.commonActions.autoRefreshCueAndMapChange(true)

    if (this.props.location.query.txtSearch) {
      this.isFiltered = true
      this.currentFilterFull = false
    }

    let paymentMethods = filterPaymentMethods(
      paymentMethod,
      this.props.auth.selectedFleet
    )
    this.props.cueActions.updateCueFillter(
      {
        txtSearch: this.props.location.query.txtSearch
          ? this.props.location.query.txtSearch
          : '',
        searchBy: this.props.location.query.searchBy
          ? this.props.location.query.searchBy
          : CUESearchBy[0].value,
        bookingService: 'all',
        networkType: 'all',
        supportService: 'all',
        intercityRoutes: [],
        routeId: null,
        routeNumber: null,
        rideService: 'all',
        rideSharing: 'all',
        corporateId: null,
        rideType: 'all',
        paidStatus: 'all',
        bookingType: 'all',
        dateFrom: null,
        dateTo: null,
        operator: '',
        airline: null,
        bookFrom: [],
        carType: [],
        requestCompanyIds: [],
        fleetId: null,
        status:
          (this.props.auth.selectedFleet.delivery &&
            this.props.auth.selectedFleet.delivery.enable) ||
          (this.props.auth.selectedFleet.food &&
            this.props.auth.selectedFleet.food.enable) ||
          (this.props.auth.selectedFleet.mart &&
            this.props.auth.selectedFleet.mart.enable)
            ? allStatusCUE
            : statusNotDeliveryCUE,
        finishedStatus: {
          all: true,
          completed: true,
          noShow: true,
          incident: true,
          canceled: true,
          completedWithoutService: true,
        },
        offerStatus: {
          all: true,
          pending: true,
          dispatching: true,
          action: true,
          confirmed: true,
          allocated: true
      },
        corporate:
          this.props.auth.user.userType === userType.CorporateAdmin ||
          this.props.auth.user.userType === userType.CorporateUser
            ? this.props.auth.user.corporateId
            : null,
        driverCorporate: (this.props.auth.user.roles.isSupplier && this.props.auth.user.roles.companyId) || '',
        currentAgent: !this.props.auth.user.roles.isSupplier && this.props.auth.user.roles.companyId,
        currentSupplierCompaniesList: [...this.props.commonData.suppliers, ...this.props.commonData.companies] || [],
        process: this.props.auth?.selectedFleet?.process,
        companyOfOperator: getSupplierInfoFromID(this.props.commonData.companies, this.props.auth?.user?.roles?.companyId),
        fleetEnableBroadcastBooking: this.props.auth?.selectedFleet?.process?.broadcastBooking || true,
        paymentMethod: paymentMethods,
        zone: '',
        vip: null,
        isOffer: this.state.selectedTab == 3 ? true : false
      },
      false
    )
    this.props.cueActions.cueBookingUpdateBookingChanged()
    this.state.updateDataChangeCronJobId = this.cueAutoUpdateCronjob()
    this.setState({
      rearangeColumn: this.filterRerrangeStorageBySetting(0),
      fleetEnableBroadcastBooking: this.props.auth?.selectedFleet?.process?.broadcastBooking || true,
      companyOfOperator: getSupplierInfoFromID(this.props.commonData.companies, this.props.auth?.user?.roles?.companyId),
    }, () => {
      this.cueDataRequest({}, this.state.selectedTab)
    })
    if (getLineShort()) {
      this.handleChangeLineHeightRow()
    }
  }

  filterRerrangeStorageBySetting = (tabId = -1) => {
    const tabSelecting = tabId > -1 ? tabId : this.state.selectedTab
    const localStorageName =
      tabSelecting === 1 ? 'rearrangeColumnFinished' : 'rearrangeColumnActive'
    const rearrangeColumnStorage =
      JSON.parse(localStorage.getItem(localStorageName)) || []
    const columnBySetting = this.removeColumnBySetting(true, tabSelecting)
    // Remove columns by setting
    let columnFinal = rearrangeColumnStorage.filter((rrColumn) => {
      return columnBySetting.some((stColumn) => stColumn.key === rrColumn.key)
    })

    // Add miss columns by setting
    columnBySetting.map((cl) => {
      if (!columnFinal.some((clFinal) => clFinal.key === cl.key)) {
        columnFinal.push(cl)
      }
    })
    return columnFinal
  }

  componentWillUnmount() {
    clearInterval(this.state.updateDataChangeCronJobId)
    this.props.resetCueFilter()
  }

  getLocalColumns = (tableName, removeKeys, isFirstTime) => {
    let localColumns = getTableDynamicColumn(tableName) || []
    if (localColumns.length > 0) return localColumns
    if (isFirstTime) {
      let localColumns = []
      _.forEach(CueColumns, (col) => {
        if (!removeKeys.includes(col.key) && col.isAlwayShow) {
          localColumns.push(col.key)
        }
      })
      localColumns = [...localColumns, ...COLUMN_DEFAULT_SHOW]
      return localColumns
    }
    if (!localColumns.length) {
      _.forEach(CueColumns, (col) => {
        if (!removeKeys.includes(col.key)) {
          if (isFirstTime) {
            if (col.isAlwayShow) localColumns.push(col.key)
          } else {
            localColumns.push(col.key)
          }
        }
      })
    }
    return localColumns
  }

  updateFillterPaymentMethod = (newValue) => {
    let fillter = {}
    let paymentMethods = filterPaymentMethods(
      paymentMethod,
      this.props.auth.selectedFleet
    )

    let paymentList = paymentMethods
    let cueFilterPaymentMethods = this.props.cue.fillter.paymentMethod
    let existAll = _.find(
      cueFilterPaymentMethods,
      (item) => item.value === 'all'
    )
    if (existAll) {
      if (newValue.includes('all')) {
        fillter.paymentMethod = _.filter(
          paymentList,
          (item) => item.value !== 'all' && newValue.includes(item.value)
        )
      } else {
        fillter.paymentMethod = []
      }
    } else {
      if (
        newValue.includes('all') ||
        newValue.length === paymentList.length - 1
      ) {
        fillter.paymentMethod = paymentList
      } else {
        fillter.paymentMethod = _.filter(paymentList, (item) =>
          newValue.includes(item.value)
        )
      }
    }

    this.handleFilterChange(fillter)
  }

  bookingTypeHandle = (value) => {
    let fillter = {}
    fillter.bookingType = value
    if (fillter.bookingType == 'reservation') {
      var status = {
        all: true,
        pending: true,
        dispatching: true,
        action: true,
        confirmed: true,
        booked: true,
        arrived: true,
        engaged: true,
        droppedOff: true,
      }
      this.setState({ sort: {}, status: status })
      fillter.sort = {}
      fillter.status = status
    } else if (fillter.bookingType == 'all') {
      var status = {
        all: true,
        pending: true,
        dispatching: true,
        action: true,
        confirmed: true,
        booked: true,
        arrived: true,
        engaged: true,
        droppedOff: true,
        orderAccepted: true,
        confirmedAccepted: true,
        driverSender: true,
        driverRecipient: true,
        driverCash: true,
        driverGoods: true,
        arrivedSender: true,
        arrivedCash: true,
        delivering: true,
        otwMerchant: true,
        arrivedAndWaitingToCollectItem: true,
        allocated: true
      };
      this.setState({ sort: {}, status: status });
      fillter.sort = { time: -1 };
      fillter.status = status;
    } else if (fillter.bookingType == 'delivery' || fillter.bookingType == 'batchDelivery') {
      var status = {
        all: true,
        action: true,
        orderAccepted: true,
        confirmedAccepted: true,
        driverSender: true,
        driverRecipient: true,
        driverCash: true,
        driverGoods: true,
        arrivedSender: true,
        arrivedCash: true,
        delivering: true,
        otwMerchant: true,
        arrivedAndWaitingToCollectItem: true,
      }
      this.setState({ sort: {}, status: status })
      fillter.sort = null
      fillter.status = status
    } else {
      var status = {
        all: true,
        pending: true,
        dispatching: true,
        action: true,
        confirmed: true,
        booked: true,
        arrived: true,
        engaged: true,
        droppedOff: true,
      }
      this.setState({ sort: {}, status: status })
      fillter.sort = null
      fillter.status = status
    }
    this.setState({ bookingType: value })
    this.handleFilterChange(fillter)
  }

  bookingServiceHandle = (value) => {
    this.handleFilterChange({ bookingService: value })
  }

  tabSelectHandle = (tabid) => {
    this.isFiltered = false
    const storageCueSort = parseFloat(localStorage.getItem('cue_sort') || 1)
    if (this.state.selectedTab !== tabid) {
      const localStorageName =
        (tabid == 0 || tabid == 3) ? 'rearrangeColumnActive' : 'rearrangeColumnFinished'
      const rearrangeColumnStorage =
        JSON.parse(localStorage.getItem(localStorageName)) || []
      const updateState = {
        selectedTab: tabid,
        searchText: '',
        searchBy: CUESearchBy[0].value,
        completedBookingLoaded: false,
        // rearangeColumn: rearrangeColumnStorage.length > 0 ? rearrangeColumnStorage : this.removeColumnBySetting(true, tabid)
        rearangeColumn: this.filterRerrangeStorageBySetting(tabid),
      }
      const dateFrom = null
      const yesterday = moment().subtract(1, 'days')
      if (tabid == 1) {
        updateState.dateFrom = yesterday._d
      }
      if (tabid == 0 || tabid == 3) {
        updateState.showDataPage = 1
      }

      let paymentMethods = filterPaymentMethods(
        paymentMethod,
        this.props.auth.selectedFleet
      )

      let queryCueFil = {
        searchBy: CUESearchBy[0].value,
        txtSearch: '',
        bookingService: 'all',
        networkType: 'all',
        bookingType: 'all',
        intercityRoutes: [],
        routeId: null,
        routeNumber: null,
        dateFrom,
        dateTo: null,
        dateRange: 'allTime',
        operator: '',
        bookFrom: [],
        carType: [],
        requestCompanyIds: [],
        status: [],
        fleetId: null,
        isDateRangeInputChanged: false,
        status:
          (this.props.auth.selectedFleet.delivery &&
            this.props.auth.selectedFleet.delivery.enable) ||
          (this.props.auth.selectedFleet.food &&
            this.props.auth.selectedFleet.food.enable) ||
          (this.props.auth.selectedFleet.mart &&
            this.props.auth.selectedFleet.mart.enable)
            ? allStatusCUE
            : statusNotDeliveryCUE,
        finishedStatus: {
          all: true,
          completed: true,
          noShow: true,
          incident: true,
          canceled: true,
          completedWithoutService: true,
          failed: true,
          partialCompleted: true
        },
        offerStatus: {
          all: true,
          pending: true,
          dispatching: true,
          action: true,
          confirmed: true,
          allocated: true
      },
        paymentMethod: paymentMethods,
        vip: null,
        rideService: 'all',
        rideSharing: 'all',
        supportService: 'all',
        corporateId: null,
        rideType: 'all',
        paidStatus: 'all',
        sort: { time: storageCueSort },
        zone: '',
        isOffer: tabid == 3 ? true : false
      }

      this.props.cueActions.updateCueFillter(
        queryCueFil,  
        tabid == 1 ? true : false
      )

      this.setState(updateState, () => {
        this.cueDataRequest({}, tabid)
      })
    }
  }

  handleSearchByChange = (value) => {
    this.setState({ searchBy: value })
  }

  txtSearchPressHandle = (e) => {
    if (e.key === 'Enter') {
      let fillter = this.props.cue.fillter
      fillter.txtSearch =
        this.state.searchBy === 'bookId'
          ? (e.target.value || '').trim().toLocaleLowerCase()
          : (e.target.value || '').trim()
      fillter.searchBy = this.state.searchBy
      if (this.state.selectedTab == 1) {
        //booking completed
        if (!fillter.isDateRangeInputChanged) {
          fillter.dateFrom = null
        }
        this.setState({
          completedBookingLoaded: false,
        })
      }
      this.props.cueActions.updateCueFillter(
        fillter,
        this.state.selectedTab == 1 ? true : false
      )
      this.isFiltered = true
      this.currentFilterFull = false
      this.cueDataRequest({}, this.state.selectedTab)
    }
  }

  cueAutoUpdateCronjob = () => {
    return setInterval(() => {
      if (isWorking) {
        return
      }
      isWorking = true
      if (realtimeHelper.isCueChanged() && (this.state.selectedTab == 0 || this.state.selectedTab == 3)) {
        if (
          this.props.auth.selectedFleet.map &&
          this.props.auth.selectedFleet.map.isAutoRefresh &&
          !this.props.commonData.isAutoRefreshCueAndMap
        ) {
          return
        } else {
          realtimeHelper.setIsCueChanged(false)
          this.props.cueActions.cueBookingUpdateBookingChanged()
        }

        let newTime = 500
        if (Object.keys(realtimeHelper.cueBookings()).length > 20) {
          if (
            this.props.auth.selectedFleet.map &&
            this.props.auth.selectedFleet.map.isAutoRefresh
          ) {
            newTime = this.props.auth.selectedFleet.map.value * 1000
          } else {
            newTime += realtimeHelper.listActiveSortedIndex().length / 20
          }
        }
        if (newTime > realtimeHelper.timeOut() + 50) {
          realtimeHelper.setTimeout(newTime)
          clearInterval(this.state.updateDataChangeCronJobId)
          this.state.updateDataChangeCronJobId = this.cueAutoUpdateCronjob()
        }
      }
      if (this.state.selectedTab == 0 || this.state.selectedTab == 3) {
        let data = this.getBookingList()
        let showedItem = this.state.showDataPage * ItemPerPage
        let realTimeCue = realtimeHelper.cueBookings()
        if (
          !realtimeHelper.isCueDataLoaded() &&
          (this.state.selectedTab == 0 || this.state.selectedTab == 3) &&
          !this.props.cue.isLoading
        ) {
          //check if booking is not enough , load more
          let page = 0 //for the first time loading
          if (this.afterFirstLoad) {
            page = this.props.cue.page + 1
          }
          if (
            data.length - showedItem < 20 &&
            (this.isFiltered ? !this.currentFilterFull : true)
          ) {
            //load more
            this.props.cueActions
              .cueFind(
                {
                  query: CCLiteCommonFunc.convertFilterToQuery(
                    this.props.cue.fillter,
                    this.state.selectedTab,
                  ),
                  sort: this.state.sort,
                  page,
                },
                this.state.selectedTab == 1
              )
              .then((result) => {
                if (this.state.selectedTab == 0 || this.state.selectedTab == 3) {
                  if (result.res) {
                    this.afterFirstLoad = true
                    if (result.res.list.length <= 0) {
                      if (this.isFiltered) {
                        this.currentFilterFull = true
                      } else {
                        realtimeHelper.setCueDataLoaded(true)
                      }
                    }
                  } else {
                    realtimeHelper.setCueDataLoaded(true)
                  }
                  this.props.cueActions.cueActiveLoadmore()
                }
              })
          }
        } else {
          if (data.length != Object.keys(realTimeCue).length) {
            this.props.cueActions.cueActiveLoadmore()
          }
        }
      }

      isWorking = false
    }, realtimeHelper.timeOut())
  }
  showMessageErrorBooking = (data) => {
    let message = I18n.t('messages.updateBookingMsg.' + data.code)
    if (data.error) {
      message = I18n.t(
        'messages.updateBookingMsg.' + Object.keys(data.error)[0]
      )
    }
    return message
  }

  handleScrollBottom = () => {
    if (!this.props.cue.isLoading) {
      let page = 0
      page = this.props.cue.page + 1
      if (this.state.selectedTab == 0 || this.state.selectedTab == 3) {
        let data = this.getBookingList()
        let showedItem = this.state.showDataPage * ItemPerPage
        if (data.length > showedItem) {
          this.setState({
            showDataPage: this.state.showDataPage + 1,
          })
        }
      } else {
        if (this.state.completedBookingLoaded) {
          return
        }
        this.props.cueActions
          .cueFind(
            {
              query: CCLiteCommonFunc.convertFilterToQuery(
                this.props.cue.fillter,
                this.state.selectedTab
              ),
              sort: this.state.sort,
              page: page,
            },
            this.state.selectedTab == 1
          )
          .then((data) => {
            if (this.state.selectedTab == 0 || this.state.selectedTab == 3) {
              this.props.cueActions.cueActiveLoadmore()
            }
            if (this.state.selectedTab == 1) {
              if (data.ok && data.res && data.res.list) {
                if (!data.res.list.length) {
                  this.setState({
                    completedBookingLoaded: true,
                  })
                }
              }
            }
          })
      }
    }
  }

  updateFillterOperactor = (newValue) => {
    this.handleFilterChange({ operator: newValue })
  }

  updateFillterCarTypes = (newValue) => {
    let fillter = {}
    fillter.carType = _.filter(this.props.commonData.carType, (item) => {
      return newValue.includes(item.vehicleType)
    })
    this.handleFilterChange(fillter)
  }

  updateFillterAirline = (newValue) => {
    this.handleFilterChange({ airline: newValue })
  }

  updateFillterIntercityRoute = (newValue) => {
    let fillter = {}
    var uniqueId = newValue.slice(0, -2)
    var uniqueRoute = parseInt(newValue.slice(-1))
    fillter.intercityRoutes = _.filter(
      this.props.commonData.intercityRoutes,
      (item) => {
        if (item._id === uniqueId && item.routeNumber === uniqueRoute) {
          var unique = true
        } else {
          var unique = false
        }
        return unique
      }
    )
    if (fillter.intercityRoutes && fillter.intercityRoutes[0]) {
      fillter.routeId = fillter.intercityRoutes[0]._id
      fillter.routeNumber = fillter.intercityRoutes[0].routeNumber
    } else {
      fillter.routeId = ''
      fillter.routeNumber = null
    }
    this.handleFilterChange(fillter)
  }

  updateFillterBookingFrom = (newValue) => {
    let fillter = {}
    fillter.bookFrom = _.filter(this.props.commonData.bookingFrom, (item) => {
      return newValue.includes(item.value)
    })
    this.handleFilterChange(fillter)
  }

  statusItemClicked = (key) => {
    let fillter = {
      status: this.props.cue.fillter.status,
      finishedStatus: this.props.cue.fillter.finishedStatus,
      offerStatus: this.props.cue.fillter.offerStatus
    }
    if (!fillter) return
    if (this.state.selectedTab == 0) {
      if (key == 'all') {
        if (fillter.status.all) {
          Object.keys(fillter.status).map((key) => {
            fillter.status[key] = false
          })
        } else {
          Object.keys(fillter.status).map((key) => {
            fillter.status[key] = true
          })
        }
      } else {
        fillter.status[key] = !fillter.status[key]
        fillter.status.all = true
        Object.keys(fillter.status).map((key) => {
          if (key != 'all' && fillter.status[key] == false) {
            fillter.status.all = false
          }
        })
      }
    } else if (this.state.selectedTab == 3) {
      if (key == 'all') {
        if (fillter.offerStatus.all) {
          Object.keys(fillter.offerStatus).map((key) => {
            fillter.offerStatus[key] = false
          })
        } else {
          Object.keys(fillter.offerStatus).map((key) => {
            fillter.offerStatus[key] = true
          })
        }
      } else {
        fillter.offerStatus[key] = !fillter.offerStatus[key]
        fillter.offerStatus.all = true
        Object.keys(fillter.offerStatus).map((key) => {
          if (key != 'all' && fillter.offerStatus[key] == false) {
            fillter.offerStatus.all = false
          }
        })
      }
    
    } else {
      if (key == 'all') {
        if (fillter.finishedStatus.all) {
          Object.keys(fillter.finishedStatus).map((key) => {
            fillter.finishedStatus[key] = false
          })
        } else {
          Object.keys(fillter.finishedStatus).map((key) => {
            fillter.finishedStatus[key] = true
          })
        }
      } else {
        fillter.finishedStatus[key] = !fillter.finishedStatus[key]
        fillter.finishedStatus.all = true
        Object.keys(fillter.finishedStatus).map((key) => {
          if (key != 'all' && fillter.finishedStatus[key] == false) {
            fillter.finishedStatus.all = false
          }
        })
      }
    }
    this.handleFilterChange(fillter)
  }

  updateFillterDateRange = (newValue, dateFrom = null, dateTo = null) => {
    let fillter = {}
    if (newValue === 'allTime' || newValue === 'custom') {
      fillter.dateFrom = null
      fillter.dateTo = null
    } else {
      fillter.dateFrom = dateFrom
      fillter.dateTo = dateTo
    }

    fillter.dateRange = newValue
    fillter.isDateRangeInputChanged = true
    this.props.cueActions.updateCueFillter(
      fillter,
      this.state.selectedTab == 1 ? true : false
    )
    this.isFiltered = true
    this.currentFilterFull = false
    this.setState(
      {
        completedBookingLoaded: false,
      },
      () => {
        this.cueDataRequest(fillter, this.state.selectedTab)
      }
    )
  }

  updateDatefrom = (date) => {
    let fillter = {}
    fillter.dateFrom = date
    fillter.dateRange = 'custom'
    fillter.isDateRangeInputChanged = true
    this.props.cueActions.updateCueFillter(
      fillter,
      this.state.selectedTab == 1 ? true : false
    )
    this.isFiltered = true
    this.currentFilterFull = false
    this.setState(
      {
        completedBookingLoaded: false,
      },
      () => {
        this.cueDataRequest(fillter, this.state.selectedTab)
      }
    )
  }

  updateDateTo = (date) => {
    let fillter = {}
    fillter.dateTo = moment(date).hour(23).minute(59).second(0).millisecond(59)
    fillter.dateRange = 'custom'
    fillter.isDateRangeInputChanged = true
    this.props.cueActions.updateCueFillter(
      fillter,
      this.state.selectedTab == 1 ? true : false
    )
    this.isFiltered = true
    this.currentFilterFull = false
    this.setState(
      {
        completedBookingLoaded: false,
      },
      () => {
        this.cueDataRequest(fillter, this.state.selectedTab)
      }
    )
  }
  cueDataRequest = (filter = {}, selectedTab = 0) => {
    let cue = Object.assign(this.props.cue.fillter, filter)
    let showingFilter = []
    if (selectedTab == 0) {
      showingFilter = _.filter(Object.keys(cue.status), (status) => {
        return cue.status[status]
      })
    } else if (selectedTab == 3) {
      showingFilter = _.filter(Object.keys(cue.offerStatus), (status) => {
        return cue.offerStatus[status]
      })
    } else {
      showingFilter = _.filter(Object.keys(cue.finishedStatus), (status) => {
        return cue.finishedStatus[status]
      })
    }
    if (showingFilter.length > 0) {
      if (this.state.completedBookingLoaded && this.state.selectedTab == 1)
        return
      this.props.cueActions
        .cueFind(
          {
            query: CCLiteCommonFunc.convertFilterToQuery(cue, this.state.selectedTab),
            sort: this.state.sort,
            page: 0,
            //limit: 30
          },
          this.state.selectedTab == 1
        )
        .then((data) => {
          this.props.cueActions.updateCueFillter(
            filter,
            this.state.selectedTab == 1 ? true : false
          )
          if (data.ok) {
            if (data.res && data.res.list) {
              if (!data.res.list.length) {
                this.setState({
                  completedBookingLoaded: true,
                })
              }
            }
          }
        })
    } else {
      //When filter list is all false, no need to call api and set list is empty
      this.props.cueActions.noShowCue({
        list: [],
        total: 0,
        page: 0,
        //limit: 30
      })
    }
  }
  bookingSortClick = () => {
    let sort = 1
    if (this.state.sort.booking) {
      sort = this.state.sort.booking != 1 ? 1 : -1
    }
    let fillter = {}
    fillter.sort = { booking: sort }
    if (realtimeHelper.isCueDataLoaded() && this.state.selectedTab == 0) {
      this.setState(
        {
          sort: { booking: sort },
        },
        () => {
          this.props.cueActions.updateCueFillter(
            fillter,
            this.state.selectedTab == 1 ? true : false
          )
        }
      )
    } else {
      this.setState(
        { sort: { booking: sort }, completedBookingLoaded: false },
        () => {
          this.cueDataRequest(fillter, this.state.selectedTab)
        }
      )
    }
  }

  handleChangeLineHeightRow = () => {
    const { lineShort } = this.state
    this.setState({
      lineShort: !lineShort,
      rowHeight: !lineShort ? 40 : 100,
    })
    setLineShort(!lineShort)
  }

  pickUpTimeSortClick = () => {
    let sort = 1
    if (this.state.sort.time) {
      sort = this.state.sort.time != 1 ? 1 : -1
    }
    let fillter = {}
    fillter.sort = { time: sort }
    localStorage.setItem('cue_sort', sort)
    if (realtimeHelper.isCueDataLoaded() && this.state.selectedTab == 0) {
      this.setState(
        {
          sort: { time: sort },
        },
        () => {
          this.props.cueActions.updateCueFillter(
            fillter,
            this.state.selectedTab == 1 ? true : false
          )
        }
      )
    } else {
      this.setState(
        { sort: { time: sort }, completedBookingLoaded: false },
        () => {
          this.cueDataRequest(fillter, this.state.selectedTab)
        }
      )
    }
  }

  createTimeSortClick = () => {
    let sort = 1
    if (this.state.sort.createdDate) {
      sort = this.state.sort.createdDate == 1 ? -1 : 1
    }
    let fillter = {}
    fillter.sort = { createdDate: sort }
    this.setState(
      { sort: { createdDate: sort }, completedBookingLoaded: false },
      this.cueDataRequest(fillter, this.state.selectedTab)
    )
  }

  rideTypeSortClick = () => {
    let sort = 1
    if (this.state.sort.rideType) {
      sort = this.state.sort.rideType == 1 ? -1 : 1
    }
    let fillter = {}
    fillter.sort = { rideType: sort }
    this.setState(
      { sort: { rideType: sort }, completedBookingLoaded: false },
      this.cueDataRequest(fillter, this.state.selectedTab)
    )
  }

  expectedTimeSortClick = () => {
    let sort = 1
    if (this.state.sort.expectedTime) {
      sort = this.state.sort.expectedTime == 1 ? -1 : 1
    }
    let fillter = {}
    fillter.sort = { expectedTime: sort }
    this.setState(
      { sort: { expectedTime: sort }, completedBookingLoaded: false },
      this.cueDataRequest(fillter, this.state.selectedTab)
    )
  }

  validatorEditFareCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid
      this.setState({ valid: this.state.valid })
    }
  }

  passengerTypeChangeHandle = (value) => {
    this.handleFilterChange({ vip: parseInt(value) })
  }

  paidStatusTypeChangeHandle = (value) => {
    this.handleFilterChange({ paidStatus: value })
  }

  rideServiceHandle = (value) => {
    let fillter = {}
    fillter.rideService = value
    this.props.cue.fillter.rideSharing =
      fillter.rideService == rideServiceSearchType.all
        ? rideServiceSearchType.all
        : this.props.cue.fillter.rideSharing
    this.setState({
      showRideSharingFilter:
        fillter.rideService == rideServiceSearchType.rideSharing ? true : false,
    })
    this.handleFilterChange(fillter)
  }

  rideSharingHandle = (value) => {
    this.handleFilterChange({ rideSharing: value })
  }

  supportServiceChangeHandle = (value) => {
    this.handleFilterChange({ supportService: value })
  }

  rideTypeChangeHandle = (value) => {
    let fillter = {}
    fillter.rideType = value
    fillter.corporateId = null
    this.handleFilterChange(fillter)
  }

  onZoneChange = (newValue) => {
    this.handleFilterChange({ zone: newValue })
  }

  corporateChangeHandle = (newValue) => {
    this.handleFilterChange({ corporateId: newValue })
  }

  companyChangeHandle = (newValue) => {
    let fillter = {}
    fillter.requestCompanyIds = _.filter(this.state.companyList, (item) => {
      return newValue.includes(item._id)
    })
    this.handleFilterChange(fillter)
  }

  networkTypeChange = (newValue) => {
    this.handleFilterChange({ networkType: newValue })
  }

  handleFilterChange = (newFilter) => {
    let isActiveTab = this.state.selectedTab == 0 || this.state.selectedTab == 3
    this.props.cueActions.updateCueFillter(newFilter, !isActiveTab)
    if (isActiveTab) {
      this.isFiltered = true
      this.currentFilterFull = false
      this.cueDataRequest(newFilter, this.state.selectedTab)
    }
  }

  handleSaveTotal = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({ totalFareSubmitted: true })
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return
    }
    let editOb = {
      bookId: this.state.editTotal.bookId,
      oldAmount: this.state.editTotal.oldAmount,
      newAmount: parseFloat(this.state.editTotal.total),
      reasonUpdate: this.state.editTotal.reason,
      userName: this.props.auth.user.userName,
      updateFrom: 'CC',
    }
    this.props.cueActions.editTotal(editOb).then((data) => {
      if (data.ok && data.res && data.res.returnCode == 200) {
        this.props.cueActions.cueBookingUpdateTotal({
          bookInfo: data.res.booking,
        })
        this.context.notification('success', I18n.t('cue.Edit_total_success'))
        this.setState({ totalFareSubmitted: false })
      } else {
        let currencySymbol =
          (data.res && data.res.response && data.res.response.currencySymbol) ||
          ''

        let minTotalAmount =
          (data.res && data.res.response && data.res.response.minTotalAmount) ||
          0
        this.context.notification(
          'error',
          I18n.t('cue.Edit_total_fail').format(
            currencyFormatter.format(minTotalAmount, { code: currencySymbol })
          )
        )
      }
    })
    this.setState({ editTotal: null })
  }
  handleEditTotal = (data) => {
    this.setState({
      editTotal: {
        total: data.completedInfo.total,
        reason: '',
        bookId: data.bookId,
        oldAmount: data.completedInfo.total,
      },
    })
  }
  handleCancelEditTotal = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ editTotal: null, totalFareSubmitted: false })
  }
  handleEditTotalChange = (e) => {
    if (this.state.editTotal) {
      this.state.editTotal.total = e.target.value
      this.setState({ totalFare: this.state.editTotal })
    }
  }

  handleEditTotalReasonChange = (e) => {
    if (this.state.editTotal) {
      this.state.editTotal.reason = e.target.value
      this.setState({ totalFare: this.state.editTotal })
    }
  }

  renderEditTotalDialog = () => {
    return (
      <Modal
        show={true}
        backdrop={true}
        dialogClassName="confirm-dialog"
        className="confirm"
        onHide={this.handleCancelEditTotal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value="cue.Booking" className="white-text" />
            <span className="white-text">:</span> #{this.state.editTotal.bookId}
            <span className="white-text"> - </span>
            <Translate
              value="cue.Edit_fare"
              className="white-text transform-none"
            />
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.handleCancelEditTotal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <FormGroup
            className={
              this.state.totalFareSubmitted
                ? this.state.valid.totalFare === false
                  ? 'error'
                  : null
                : null
            }
          >
            <Form.Label>
              <Translate value="cue.Total" />
              <span className="require">*</span>
            </Form.Label>
            <FormControl
              type="text"
              className="form-custom"
              onChange={this.handleEditTotalChange}
              onBlur={(e) => {
                textboxNumberHelper.onBlurHandle(e, this.handleEditTotalChange)
              }}
              onFocus={(e) => {
                textboxNumberHelper.onfocusHandle(e, this.handleEditTotalChange)
              }}
              value={this.state.editTotal.total}
            />
            <Validator id="totalFare" callback={this.validatorEditFareCallback}>
              <ValidCase
                valid={!Validation.isStringEmpty(this.state.editTotal.total)}
                message={I18n.t('messages.commonMessages.Required_field')}
                hide={!this.state.totalFareSubmitted}
              />
              <ValidCase
                valid={Validation.isNumber(this.state.editTotal.total)}
                message={I18n.t('messages.commonMessages.must_be_number')}
                hide={!this.state.totalFareSubmitted}
              />
            </Validator>
          </FormGroup>
          <FormGroup
            className={
              this.state.totalFareSubmitted
                ? this.state.valid.reason === false
                  ? 'error'
                  : null
                : null
            }
          >
            <Form.Label>
              <Translate value="cue.Reason" />
              <span className="require">*</span>
            </Form.Label>
            <FormControl
              type="text"
              className="form-custom"
              onChange={this.handleEditTotalReasonChange}
              value={this.state.editTotal.reason}
              maxLength={500}
            />
            <Validator id="reason" callback={this.validatorEditFareCallback}>
              <ValidCase
                valid={!Validation.isStringEmpty(this.state.editTotal.reason)}
                message={I18n.t('cue.Please_enter_reason')}
                hide={!this.state.totalFareSubmitted}
              />
            </Validator>
          </FormGroup>
          <Form.Label>
            <Translate value="cue.Edit_total_note" />
          </Form.Label>
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button className="btn-save mr-md" onClick={this.groupBookingApply}>
            {I18n.t('bookingdetail.Save')}
          </Button>
          <Button className="btn-cancel" onClick={this.handleCancelEditTotal}>
            {I18n.t('bookingdetail.Cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  getSpaceTime = (data = {}) => {
    if (getServiceType(data) === 'rideHailing') {
      // check is transport booking
      const { status = '', timeout = false } = data
      let colorCell = 'spareTime_red'
      let spareTime = ''
      const expectedTime = _.get(data, 'time.expectedPickupTime', 0)
      const spotTime = this.getSpotTime(data)
      const pickupTime = _.get(data, 'request.pickUpTime', 'Now')
      const isShow = this.checkShowETAColumns(data) && pickupTime != 'Now'
      if (expectedTime && spotTime) {
        spareTime = moment(expectedTime).diff(moment(spotTime), 'm')
      }
      if (spareTime > 0) {
        colorCell = 'spareTime_blue'
      }
      let spareTimeText = '-'
      if (isShow && spareTime) {
        spareTimeText = `${spareTime} min(s)`
      }
      if (
        pickupTime != 'Now' &&
        (status === 'confirmed' ||
          status === 'action' ||
          status === 'pending') &&
        timeout
      ) {
        spareTimeText = 'Not Start'
      }

      const hasShowColor = COLOR_SHOW_SPACETIME.some((ob) => {
        if (
          !timeout &&
          (status === 'confirmed' ||
            status === 'action' ||
            status === 'pending')
        )
          return false
        return status == ob
      })
      return {
        spareTimeText: spareTimeText || '',
        spareTime: spareTime || 0,
        colorCell: colorCell,
        hasShowColor: hasShowColor || '',
        pickupTime: pickupTime || '',
        status: status,
        timeout: timeout,
      }
    }
    return {}
  }

  getBookingList = () => {
    const { state } = this
    const { cues } = this.props.cue
    const { corporateCompany, auth } = this.props
    const showETA = _.get(auth, 'selectedFleet.cue.showETA', false)
    if (!cues) return []
    let list = []
    if (state.selectedTab == 0) {
      if (!Array.isArray(cues)) return []
      let count = 0
      _.forEach(cues, (key) => {
        let data = _.cloneDeep(realtimeHelper.cueBookings()[key])
        const {
          pickupTime = 'Now',
          hasShowColor = '',
          colorCell = '',
          spareTime = '',
          status = '',
          timeout = false,
        } = this.getSpaceTime(data)
        const syncStatus = data?.externalInfo?.syncStatus || ''
        if (
          (colorCell === 'spareTime_red' &&
            pickupTime != 'Now' &&
            hasShowColor &&
            (spareTime < 0 ||
              status === 'action' ||
              (timeout && status === 'confirmed')) &&
            showETA) ||
          syncStatus === 'driver_assigned_not_found' // neu boooking.com chua co driver 
        ) {
          data.colorCodeBooking = '#5d333e'
        } else if (
          data &&
          data.corporateInfo &&
          data.corporateInfo.corporateId &&
          corporateCompany.length > 0
        ) {
          let corporate = corporateCompany.find(
            (item) => item._id === data.corporateInfo.corporateId
          )
          data.colorCodeBooking =
            corporate &&
            corporate.companyInfo &&
            corporate.companyInfo.colorCodeBooking
              ? corporate.companyInfo.colorCodeBooking
              : ''
        }
        if (data) {
          count++
          list.push(data)
        }
      })
    } else if (state.selectedTab == 3) {
      if (!Array.isArray(cues)) return []
      let count = 0
      
      _.forEach(cues, (key) => {
        let data = _.cloneDeep(realtimeHelper.cueBookings()[key])
        if (data) {
          count++
          list.push(data)
        }
      })
    } else {
      _.forEach(cues, (value, key) => {
        if (value) {
          let data = _.cloneDeep(value)
          if (
            data &&
            data.corporateInfo &&
            data.corporateInfo.corporateId &&
            corporateCompany.length > 0
          ) {
            let corporate = corporateCompany.find(
              (item) => item._id === data.corporateInfo.corporateId
            )
            data.colorCodeBooking =
              corporate &&
              corporate.companyInfo &&
              corporate.companyInfo.colorCodeBooking
                ? corporate.companyInfo.colorCodeBooking
                : ''
          }
          list.push(data)
        }
      })
    }
    return list
  }

  getActualTime = (data) => {
    const flightInfo =
      (data.request &&
        data.request.moreInfo &&
        data.request.moreInfo.flightInfo) ||
      {}
    const bookType = (data.request && data.request.type) || 3
    let timezone = '',
      actual = ''
    const { actualarrivaltime, actualdeparturetime } = flightInfo

    // From Airport
    if (bookType === 1) {
      if (actualarrivaltime === -1) return { actual: '', timezone: '' }
      timezone = _.get(data, 'request.pickup.timezone', '')
      actual = flightInfo.actualarrivaltime
    }
    // To Airport
    if (bookType === 2) {
      if (actualdeparturetime === -1) return { actual: '', timezone: '' }
      timezone = _.get(data, 'request.destination.timezone', '')
      actual = flightInfo.actualdeparturetime
    }
    return {
      actual: actual || '',
      timezone: timezone || '',
    }
  }

  checkEnableSelectMultiBook = (bookInfo) => {
    if(!this.props?.permissions?.actions) return false
    // với book manifest thì chỉ cho chọn trong cùng 1 group
    if(
      this.state.typeBookingGroup === TYPE_BOOKING_GROUP.manifest &&
      bookInfo.groupId !== this.state?.checkedGroup?.[0]?.groupId
    ) {
      return false
    }

    // với book NOW thì chỉ cho select 1 book
    if(
      this.state.typeBookingGroup === TYPE_BOOKING_GROUP.transport_now &&
      this.state?.checkedGroup?.[0]?.bookId !== bookInfo.bookId
    ) return false;

    return (
      checkBookingCanGroupOrder(bookInfo, this.state.typeBookingGroup, this.props.auth) ||
      checkCanAssignSuplier(bookInfo, this.props.auth, this.state.typeBookingGroup) ||
      checkCanAssignDriver(bookInfo, this.state.typeBookingGroup) ||
      checkCanOfferDriver(bookInfo, this.state.typeBookingGroup)
    );

  };

  createOptionForGroup = (books = []) => {
    let actions = [],
      { auth } = this.props;
    if(checkListBookingCanAssignSuplier(books, this.props.auth)) {
      actions.push({
        value: GROUP_ACTIONS.assignSuppliers, title: 'cue.assignSuppliers'
      })
    }
    if(checkListBookingCanGroupOrder(books, this.props.auth)) {
      actions.push({
        value: GROUP_ACTIONS.groupManifest, title: 'cue.groupManifest'
      })
    }
    const isCorpBoard = auth?.user?.userType == userType.CorporateAdmin ||
      auth?.user?.userType == userType.CorporateUser
    const canUnassignDriver = checkListBookingCanDetachDriver(books),
      canUnassignVehicle = checkShowUnassignVehicleBtn(books);

    if(!isCorpBoard) {
      if(
        checkListBookingCanAssignDriver(books) ||
        canUnassignDriver ||
        checkListBookingCanOfferDriver(books)
      ) {
        actions.push({
          value: GROUP_ACTIONS.assignDriver, title: 'newbooking.Assign_driver_vehicle'
        })
      }
      if(canUnassignDriver) {
        actions.push({
          value: GROUP_ACTIONS.unassignDriver, title: 'cue.unassignDriver'
        })
      }
      if(canUnassignVehicle) {
        actions.push({
          value: GROUP_ACTIONS.unassignVehicle, title: 'cue.unassignVehicle'
        })
      }
      if(
        (canUnassignVehicle || canUnassignDriver) &&
        verifyStatusShowUnassignAllBtn(books)
      ) {
        actions.push({
          value: GROUP_ACTIONS.unassignAll, title: 'cue.unassignAll'
        })
      }
    }

    return actions
  }

  handleGroupWithShiftKey = (bookEnd, isChecked, typeBookingGroup) => {
    const { bookLast } = this.state;
    let bookSelected = getBooksBetween(bookLast, bookEnd, this.getBookingList());
    if(isChecked) {
      bookSelected = bookSelected.concat(this.state.checkedGroup);
    } else {
      _.remove(bookSelected, (bk) => bk.bookId === bookEnd.bookId);
      bookSelected = [...this.state.checkedGroup].filter(bk => {
        return !bookSelected.some(bkSelected => bkSelected.bookId === bk.bookId)
      })
    }

    bookSelected = _.uniqBy(bookSelected, 'bookId').filter(bk => {
      return getBookingTypeGroup(bk) === typeBookingGroup && // only allow grouping books of the same type
        this.checkEnableSelectMultiBook(bk); // only allow grouping books that can not enbale multi select
    }) 

    this.setState({
      checkedGroup: bookSelected,
      bookLast: bookEnd,
      optionsGroupAction: this.createOptionForGroup(bookSelected)
    })
  }

  handleClickGroup = (e, dataBooking = {}) => {
    e.stopPropagation();
    const { bookId } = dataBooking
    let checkedGroup = this.state.checkedGroup || [];
    let isChecked = true
    if (checkedGroup.some(bk => bk.bookId === bookId)) {
      _.remove(checkedGroup, (obj) => obj.bookId === bookId)
      isChecked = false
    } else {
      checkedGroup.push(dataBooking)
    }

    let typeBookingGroup = getBookingTypeGroup(dataBooking)
    
    if(checkedGroup.length === 0) typeBookingGroup = ''

    if(this.state.isShiftKey && this.state.checkedGroup.length > 0) {
      this.handleGroupWithShiftKey(dataBooking, isChecked, typeBookingGroup);
      return;
    }

    this.setState({
      checkedGroup,
      bookLast: dataBooking,
      typeBookingGroup: typeBookingGroup,
      optionsGroupAction: this.createOptionForGroup(checkedGroup)
    })
  }

  getSpotTime = (data) => {
    const status = _.get(data, 'status')
    let eta = 0
    if (status === 'arrived') {
      const time = _.get(data, 'time.arrived', 0)
      const timeZone = _.get(data, 'request.pickup.timezone')
      return moment.tz(time, timeZone)
    } else {
      eta = _.get(data, 'offeredInfo.estimateValue', 0)
    }
    if (!eta) return ''
    return moment().add(eta, 'seconds')
  }

  checkShowETAColumns = (data) => {
    if (!data) return false
    const bookingStatus = _.get(data, 'status')
    const pickupTime = _.get(data, 'request.pickUpTime', 'Now')
    return STATUS_SHOW_SPOTTIME.some((status) => status === bookingStatus)
  }

  removeColumnBySetting = (isRearrange = false, tabId) => {
    let selectedTab = this.state.selectedTab
    if (isRearrange && _.isNumber(tabId)) {
      selectedTab = tabId
    }
    let tableColumns = Object.assign([], CueColumns)
    // if(getSuppilerPermission(this.props.auth)) {
    //   tableColumns = tableColumns.filter(tb => !tb.hideWithSupplierPermission)
    // }
    if (this.props?.auth?.user?.roles?.isSupplier) {
      if (selectedTab == 0 || selectedTab == 3) {
        tableColumns = tableColumns.filter(o => cueActiveKey.includes(o.key))
        if (selectedTab == 3) {
          tableColumns = tableColumns.filter(o => !cueOfferRemoveKey.includes(o.key))
        }
          tableColumns = tableColumns.concat({
            key: "prices",
            label: "supplier.prices",
            textEllipsis: true,
            width: 210
          })
      } else {
        tableColumns = tableColumns.filter(o => cueFinishedKey.includes(o.key))
      }
    }
    const { auth, permissions } = this.props
    const flightAPIIntegration = _.get(
      auth,
      'selectedFleet.process.flight',
      false
    )
    const isEnableGroupBooking = _.get(
      auth,
      'selectedFleet.groupBooking',
      false
    )
    const affiliateSetting = _.get(auth, 'selectedFleet.affiliate', false)
    const hasRecurring = _.get(auth, 'selectedFleet.recurring.enable', false)
    const showETA = _.get(auth, 'selectedFleet.cue.showETA', false)
    const permissionShowFare = _.get(permissions, 'showfare', true)
    if (!showETA || selectedTab == 1) {
      _.remove(tableColumns, (col) => {
        return (
          col.key === 'spotTime' || col.key === 'eta' || col.key === 'spareTime'
        )
      })
    }
    if (
      !affiliateSetting.dispatching &&
      !affiliateSetting.receiveOndemandBooking &&
      !affiliateSetting.receiveReservationBooking
    ) {
      _.remove(tableColumns, (col) => {
        return col.key === 'networkType'
      })
    }
    if(!hasRecurring) {
      _.remove(tableColumns, (col) => {
        return col.key === 'recurring.batchId'
      });
    }
    if (
      !this.props.auth.selectedFleet.intercity ||
      this.props.auth.selectedFleet.intercity.enable === false
    ) {
      _.remove(tableColumns, (col) => {
        return col.key === 'intercityRoute'
      })
    }

    if (!flightAPIIntegration) {
      _.remove(tableColumns, (col) => {
        return (
          col.key === 'scheduled' ||
          col.key === 'actual' ||
          col.key === 'pickupDropOffLocalTime' ||
          col.key === 'flightStatus' ||
          col.key === 'expectedTime'
        )
      })
    }
    if(!_.get(this.props.auth, 'selectedFleet.cue.showETA', false)) {
      _.remove(tableColumns, (col) => {
        return (
          col.key === 'expectedTime'
        )
      })
    }
    if (!isEnableGroupBooking) {
      _.remove(tableColumns, (col) => {
        return col.key === 'groupId'
      })
    }

    if (!permissionShowFare) {
      _.remove(tableColumns, (col) => {
        return col.key === 'estimatedFare'
      })
      _.remove(tableColumns, (col) => {
        return col.key === 'payment'
      })
    }

    if (selectedTab == 0 || selectedTab == 3) {
      _.remove(tableColumns, (col) => {
        if (col.key === 'scheduled' || col.key === 'actual') {
          return (
            !this.state.activeTableColumns.includes('flightStatus') &&
            !isRearrange
          )
        }
        return (
          (!this.state.activeTableColumns.includes(col.key) && !isRearrange) ||
          col.key === 'payment'
        )
      })
    } else {
      _.remove(tableColumns, (col) => {
        if (col.key === 'scheduled' || col.key === 'actual') {
          return !this.state.finishedTableColumns.includes('flightStatus')
        }
        return (
          (!this.state.finishedTableColumns.includes(col.key) &&
            !isRearrange) ||
          col.key === 'estimatedFare' ||
          col.key === 'expectedTime'
        )
      })
    }
    return tableColumns
  }

  showActionsCheckBoxBooking = (booking) => {
    this.showActionsCheckBoxBooking()
  }

  getTableColumns = () => {
    const { state } = this
    const {
      auth: {
        selectedFleet: { 
          format24Hour, 
          airlineBusiness = {}, 
          groupBooking = false, // setting group for transport
          batchDelivery = false // setting group for deliver
        }
      },
      language: { locale: language },
    } = this.props
    const { lineShort, selectedTab } = this.state
    const timeFormat = format24Hour ? 'HH:mm' : 'hh:mm A'
    let tableColumns = this.removeColumnBySetting()
    let isAirlineBusiness =
      airlineBusiness && airlineBusiness.enable ? true : false
    _.forEach(tableColumns, (col) => {
      switch (col.key) {
        case 'bookId':
          col.fixed = window.innerWidth > 768 ? true : false;
          col.customCell = (data) => {
            let status = data.status;
            const isEnableGroupSetting = groupBooking || batchDelivery
            const checkedGroup = this.state.checkedGroup || []
            if (
              (data.jobType === 'food' || data.jobType === 'mart') &&
              data.status === 'collecting'
            ) {
              status = 'arrivedAndWaitingToCollectItem'
            }

            return (
              <BookId 
                booking={data}
                lineShort={lineShort}
                isEnableGroupSetting={isEnableGroupSetting}
                selectedTab={selectedTab}
                checkEnableSelectMultiBook={this.checkEnableSelectMultiBook(data)}
                checkedGroup={checkedGroup}
                handleClickGroup={this.handleClickGroup}
              />
            )
          }
          col.customHeader = (columnKey) => (
            <div>
              <div className={'line-height cursor-pointer'}>
                <Image
                  src={lineShort ? tabToFullIcon : tabToShortIcon}
                  onClick={this.handleChangeLineHeightRow}
                  className="line-height__icon"
                />
              </div>
              <div
                className="booking-id cursor-pointer"
                onClick={this.bookingSortClick}
              >
                <Translate value="cue.Bookingid" />
                {this.state.sort.booking ? (
                  <i
                    className={
                      this.state.sort.booking == 1
                        ? 'fa fa-sort-desc'
                        : 'fa fa-sort-asc'
                    }
                    aria-hidden="true"
                  />
                ) : (
                  <i className="fa fa-sort" aria-hidden="true" />
                )}
              </div>
            </div>
          )
          break
        case 'serviceType':
          col.customCell = (data) => {
            const bookType = getBookingType(data)
            if([BOOK_TYPE.batchDelivery, BOOK_TYPE.delivery].includes(bookType)) {
              return <DeliveryType booking={data} />
            }
            return (
              <>
                <RateType booking={data} />
                <Hourly booking={data} />
                <RoundTrip booking={data} />
              </>
            )
          }
          break
        case 'prices': 
          col.customCell = (data) => {
            let newDataBooking = convertDataBookingForSupplier({data, paramState: this.state, paramProps: this.props})
            const unit = this.props.auth?.selectedFleet?.unitDistance;
            let distanText = data?.request?.estimate?.distance;
            distanText = distanText?.replace('mi', '').replace('km', '');
            distanText = `${distanText} ${unit}`;

            let minutesDifference = Math.floor(parseInt(data?.request?.estimate?.time));
            let hoursDifference = Math.floor(minutesDifference / 60);
            let durationText =
              minutesDifference < 60
                ? minutesDifference + ' mins'
                : hoursDifference +
                  `${hoursDifference > 1 ? ' hours ' : ' hour '}` +
                  (minutesDifference % 60) +
                  ' mins';
            const calculatorCurrency = (value) => {
              return currencyFormatter.format(parseFloat(value || 0).toFixed(2), {
                symbol: this.props.auth?.selectedFleet?.currencies[0]?.iso,
              })
            }
            return (
              <div>
                {this.props?.auth?.user?.roles?.supplierPermission?.pricing &&
                  this.props.commonData?.companies[0]?.commissionCompanyType == 'payToDriver' ?
                  calculatorCurrency(data.request?.estimate?.fare?.etaFare)
                :
                <div>
                  {newDataBooking.dataFare} 
                </div>}
                {!lineShort && <div>
                  <div>{distanText} - {durationText}</div>
                  <div style={{color: "#04BE76", fontSize: "15px", cursor: "pointer"}}>{I18n.t('newbooking.Details')}</div>
                </div>}
              </div>
            )
          }
          break
        case 'externalInfo.bookingReference': 
            col.fixed = window.innerWidth > 768 ? true : false;
            break
        case 'status':
          col.customCell = (data) => (
            <StatusColumn
              data={data}
              selectedTab={state.selectedTab}
              lineShort={lineShort}
            />
          )
          break
        case 'drvInfo.companyName':
          col.customCell = (data) => {
            let companyName = data?.request?.companyName || data?.drvInfo?.companyName
            if(data.supplierCompanies?.length > 0) {
              companyName = data.supplierCompanies.map(id => {
                const detailCp = [...this.props.commonData.companies, ...this.props.commonData.suppliers].find(cp => cp._id === id)
                if (detailCp) {
                  return detailCp.name
                }
                return ''
              })
              .filter(item => item !== null && item !== undefined && item !== '')
              .join(', ')
            }
            if(checkCanAssignSuplierWhenOneBook(data) && companyName) {
              return (
                <div
                  className={`cueBtnContent ${
                    lineShort ? ' custom__lineShort' : ''
                  }`}
                >
                    <p>
                      <span
                        className='drv_btn_cue'
                        style={{cursor: 'pointer'}}
                        onClick={(e) => this.handleAssignSupplierOneBooking(data, e)}
                      >
                        {companyName}
                      </span>
                    </p>
                </div>
              )
            }
            return companyName
          }
          break
        case 'spotTime':
          col.customCell = (data) => {
            let isShowSpotTime = this.checkShowETAColumns(data) || false
            const timeZone = _.get(data, 'request.pickup.timezone')
            const spotTime = this.getSpotTime(data)
            if (isShowSpotTime && spotTime) {
              return moment
                .tz(spotTime, timeZone)
                .format(`MMM DD, YYYY ${timeFormat}`)
            }
            return '-'
          }
          break
        case 'creationTime':
          col.customCell = (data) => {
            return moment(data.time && data.time.created).format(
              `MMM DD, YYYY ${timeFormat}`
            )
          }
          col.customHeader = (columnKey) => {
            return (
              <div
                onClick={this.createTimeSortClick}
                className="cursor-pointer"
              >
                <Translate value={'cue.creationTime'} />
                {this.state.sort.createdDate ? (
                  <i
                    className={
                      this.state.sort.createdDate == -1
                        ? 'fa fa-sort-desc'
                        : 'fa fa-sort-asc'
                    }
                    aria-hidden="true"
                  />
                ) : (
                  <i className="fa fa-sort" aria-hidden="true" />
                )}
              </div>
            )
          }
          break
        case 'bookingType':
          col.customCell = (data) => {
            return (
              <Translate value={`cue.${getBookingType(data)}`} />
            )
          }
          break
        case 'groupId':
          col.customCell = (data) => {
            if (getBookingType(data) === BOOK_TYPE.intercity) {
              return ""
            }
            return data.groupId
          }
          break
        case 'networkType':
          col.customCell = (data) => {
            const currentFleetId = _.get(
              this.props.auth,
              'selectedFleet.fleetId',
              ''
            )
            const psgFleetId = _.get(data.request, 'psgFleetId', '')
            if (data.pricingType === 1) {
              if (currentFleetId === psgFleetId) {
                if (data.isFarmOut) {
                  return (
                    <span style={{ color: '#FAC940' }}>
                      {I18n.t('cue.farmOut')}
                    </span>
                  )
                } else {
                  return (
                    <span style={{ color: '#FAC940' }}>
                      {I18n.t('cue.roaming')}
                    </span>
                  )
                }
              } else {
                return (
                  <span style={{ color: '#FAC940' }}>
                    {I18n.t('cue.farmIn')}
                  </span>
                )
              }
            } else {
              return 'In-house'
            }
          }
          break
        case 'expectedTime':
          col.customCell = (data) => {
            let expectedTime = _.get(data, 'time.expectedPickupTime', '')
            if (state.selectedTab == 1 || !expectedTime) return ''
            return (
              <Moment
                tz={data.request.pickup.timezone}
                format={`MMM DD, YYYY ${timeFormat}`}
                date={expectedTime}
                locale={data.language}
              />
            )
          }
          col.customHeader = (columnKey) => (
            <div
              onClick={this.expectedTimeSortClick}
              className="cursor-pointer"
            >
              <Translate value="cue.expectedTime" />
              {this.state.sort.expectedTime ? (
                <i
                  className={
                    this.state.sort.expectedTime == -1
                      ? 'fa fa-sort-desc'
                      : 'fa fa-sort-asc'
                  }
                  aria-hidden="true"
                />
              ) : (
                <i className="fa fa-sort" aria-hidden="true" />
              )}
            </div>
          )
          break
        case 'eta':
          col.customCell = (data) => {
            const status = _.get(data, 'status')
            if (status === 'arrived') return '-'
            const eta = _.get(data, 'offeredInfo.estimateValue', 0)
            const isShow = this.checkShowETAColumns(data)
            if (isShow) return eta === 0 ? '-' : `${parseInt(eta / 60)} min(s)`
            return '-'
          }
          break
        case 'spareTime':
          col.customCell = (data = {}) => {
            const status = _.get(data, 'status')
            let {
              pickupTime = 'Now',
              hasShowColor = '',
              colorCell = '',
              spareTimeText = '',
            } = this.getSpaceTime(data)
            if (status === 'arrived') spareTimeText = '-'
            return (
              <div
                className={`
                  ${
                    pickupTime != 'Now' && hasShowColor ? colorCell : ''
                  } spareTime
                `}
              >
                {spareTimeText}
              </div>
            )
          }
          col.cellClass = 'cell_spareTime'
          break
        case 'pickupDropOff':
          col.customCell = (data) => {
            return (
              <PickupDropOffTime 
                booking={data}
                lineShort={lineShort}
                auth={this.props.auth}
              />
            )
          }
          col.customHeader = (columnKey) => {
            return (
              <div
                onClick={this.pickUpTimeSortClick}
                className="cursor-pointer"
              >
                <Translate
                  value={`cue.Pickup_Drop_Off${
                    isAirlineBusiness ? '_Crew' : ''
                  }`}
                />
                {this.state.sort.time ? (
                  <i
                    className={
                      this.state.sort.time == -1
                        ? 'fa fa-sort-desc'
                        : 'fa fa-sort-asc'
                    }
                    aria-hidden="true"
                  />
                ) : (
                  <i className="fa fa-sort" aria-hidden="true" />
                )}
              </div>
            )
          }
          break

        case 'pickupDropOffLocalTime':
          col.customCell = (data = {}) => {
            // data.language = language
            if (typeof data === 'object' && data !== null) {
              data.language = language;
            } else {
              data = {};
            }
            return (
              <PickupDropOffLocalTime 
                booking={data}
                lineShort={lineShort}
                auth={this.props.auth}
              />
            )
          }
          break

        case 'flightStatus':
          col.customHeader = (columnKey) => (
            <div>
              <i className={'fa fa-solid fa-plane mr-l-5 '} />
              <Translate value={'cue.Flight_Status'} />
            </div>
          )
          break

        case 'actual':
          col.customCell = (doc) => {
            if (typeof doc === 'object' && doc !== null) {
              doc.language = language;
            } else {
              doc = {};
            }
            let { actual = '', timezone = '' } = this.getActualTime(doc) || {}
            if (actual) {
              return (
                <p>
                  <Moment
                    format={`MMM DD, YYYY ${timeFormat}`}
                    date={actual * 1000}
                    locale={doc.language}
                    tz={timezone}
                  />
                </p>
              )
            }
            return ''
          }
          col.customHeader = (columnKey) => (
            <OverlayTrigger
              overlay={
                <Tooltip id="driver-tooltip">
                  {'Flight Departure/Arrival time'}
                </Tooltip>
              }
              placement="top"
              delayShow={300}
              delayHide={150}
            >
              <div>
                <i className={'fa fa-solid fa-plane mr-l-5 '} />
                <Translate value={'cue.Actual'} />
              </div>
            </OverlayTrigger>
          )
          break

        case 'scheduled':
          col.customCell = (doc) => {
            const flightInfo =
              (doc.request &&
                doc.request.moreInfo &&
                doc.request.moreInfo.flightInfo) ||
              {}
            const type = (doc.request && doc.request.type) || 3
            let timezone = '',
              scheduled = ''
            const { estimatedarrivaltime, filed_departuretime, departuretime } =
              flightInfo
            // From Airport
            if (type === 1) {
              if (estimatedarrivaltime === -1) return ''
              scheduled = estimatedarrivaltime
              timezone = _.get(doc, 'request.pickup.timezone', '')
            }
            // To Airport
            if (type === 2) {
              if (filed_departuretime > 0) {
                scheduled = filed_departuretime
              } else if (departuretime > 0) {
                scheduled = departuretime
              }
              timezone = _.get(doc, 'request.destination.timezone', '')
            }
            if (scheduled) {
              return (
                <Moment
                  format={`MMM DD, YYYY ${timeFormat}`}
                  date={scheduled * 1000}
                  locale={doc.language}
                  tz={timezone}
                />
              )
            }
            return ''
          }
          col.customHeader = (columnKey) => (
            <OverlayTrigger
              overlay={
                <Tooltip id="driver-tooltip">
                  {'Flight Departure/Arrival time'}
                </Tooltip>
              }
              placement="top"
              delayShow={300}
              delayHide={150}
            >
              <div>
                <i className={'fa fa-solid fa-plane mr-l-5 '} />
                <Translate value={'cue.Scheduled'} />
              </div>
            </OverlayTrigger>
          )
          break

        case 'location':
          col.customCell = (data) => (
            <LocationColumn booking={data} lineShort={lineShort} />
          )
          break
        case 'intercityRoute':
          col.customCell = (data) => {
            return (
              <div>
                <p>
                  {data.intercityInfo &&
                  data.intercityInfo.routeFromZone &&
                  data.intercityInfo.routeToZone
                    ? data.intercityInfo.routeFromZone +
                      ' - ' +
                      data.intercityInfo.routeToZone
                    : null}
                </p>
                {!lineShort && <p>{data.tripId ? data.tripId : null}</p>}
              </div>
            )
          }
          break
        case 'airportPickup':
          col.customCell = (data) => {
            const flightInfo = _.get(data.request, 'moreInfo.flightInfo', {})
            const flightawareHyperlink = _.get(
              this.props.auth,
              'selectedFleet.additionalService.flightawareHyperlink',
              true
            )
            const ident = flightInfo.ident
            return (
              <AirportPickup 
                booking={data}
                lineShort={lineShort}
                auth={this.props.auth}
              />
            )
          }
          col.customHeader = (columnKey) => (
            <div>
              <Translate
                value={`cue.Airport_Pickup${isAirlineBusiness ? '_Crew' : ''}`}
              />
            </div>
          )
          break
        case 'vehicle':
          col.customHeader = () => {
            if (this.props.auth?.user?.roles?.isSupplier) {
              return (
                <div>
                  <Translate value="supplier.serviceClass" />
                </div>)
            }
            return (
              <div>
                <Translate value="cue.Vehicle" />
              </div>
            )
          }
          col.customCell = (data) => (
            <VehicleColumn
              booking={data}
              lineShort={lineShort}
              isCueFinished={selectedTab == 1}
              handleAssignDriverOneBooking={this.handleAssignDriverOneBooking}
            />
          )
          break
        case 'driver':
          col.customCell = (data) => (
            <DriverColumn 
              booking={data}
              lineShort={lineShort}
              handleAssignDriverOneBooking={this.handleAssignDriverOneBooking}
              isCueFinished={selectedTab == 1}
            />
          )
          break
        case 'rideType':
          col.customCell = (data) => (
            <div>
              <p>
                {data.travelerType === 1 || data.travelerType === 2 ? (
                  _.get(data, 'corporateInfo.name', '')
                ) : (
                  <Translate value="cue.Individual" />
                )}
              </p>
            </div>
          )
          col.customHeader = (columnKey) => (
            <div onClick={this.rideTypeSortClick} className="cursor-pointer">
              <Translate value="cue.Ride_type" />
              {this.state.sort.rideType ? (
                <i
                  className={
                    this.state.sort.rideType == 1
                      ? 'fa fa-sort-desc'
                      : 'fa fa-sort-asc'
                  }
                  aria-hidden="true"
                />
              ) : (
                <i className="fa fa-sort" aria-hidden="true" />
              )}
            </div>
          )
          break
        case 'passenger':
          col.customCell = (data) => (
            <PassengerColum booking={data} lineShort={lineShort} isHidePhone={this.props?.auth?.user?.roles?.isSupplier && state.selectedTab != 0 ? true : false} />
          )
          break
        case 'rideSharing':
          col.customCell = (data) => (
            <RideSharingColumn booking={data} lineShort={lineShort} />
          )
          break
        case 'estimatedFare':
          col.customCell = (data) => (
            <EstimatedFareColumn
              booking={data}
              auth={this.props.auth}
              lineShort={lineShort}
            />
          )
          break
        case 'payment':
          col.customCell = (data) => (
            <PaymentInfoColumn
              booking={data}
              auth={this.props.auth}
              permissions={this.props.permissions}
              handleEditTotal={this.handleEditTotal}
              lineShort={lineShort}
            />
          )
          break
        case 'paidStatus':
          col.customCell = (data) => {
            return (
              <span className={`bk-${data.paidStatus || PAID_STATUS.pending} btnStatus`}>
                {I18n.t(`bookingdetail.${data.paidStatus || PAID_STATUS.pending}`)}
              </span>
            ) 
          }
          break
        case 'type':
          col.customCell = (data) => (
            <div className={'type'}>
              <div
                className={`platform ${lineShort ? ' custom__lineShort' : ''}`}
              >
                <p className={'text-ellipsis'}>
                  {data.bookFrom == 'dmc' || data.bookFrom == 'corp' ? (
                    <Translate
                      value={data.bookFrom === 'dmc' ? 'cue.dmc' : 'cue.corp'}
                    />
                  ) : data.bookFrom == 'mDispatcher' ? (
                    <Translate value="cue.mDispatcher" />
                  ) : data.bookFrom == 'streetSharing' ? (
                    'Street sharing'
                  ) : (
                    data.bookFrom
                  )}
                </p>
                {data.platform && <div className="straight-line"></div>}
                <p className={'text-ellipsis'}>{data.platform}</p>
              </div>
              {!lineShort && (
                <p className={'text-ellipsis not_mrb'}>
                  {this.renderBookType(data)}
                </p>
              )}
            </div>
          )
          break
      }
    })
    return tableColumns
  }

  getTableHeight = () => {
    let filterNode =
      (this.state.selectedTab == 0 ? this.activeFilter : (this.state.selectedTab == 3 ? this.offerFilter : this.finishedFilter ))
    let parentHeight = this ? ReactDOM.findDOMNode(this)?.clientHeight : 0,
      verticalPadding = 10,
      tabListHeight = 35,
      filterMarginBottom = 10,
      filterHeight =
        filterNode && filterNode.clientHeight ? filterNode.clientHeight : 0
    let outerHeight =
      verticalPadding + tabListHeight + filterHeight + filterMarginBottom
    let tableHeight = parentHeight ? parentHeight - outerHeight : 0
    if (window.innerHeight < 850 && tableHeight < 600) tableHeight = 650
    if(this.state?.checkedGroup?.length > 0) tableHeight =  tableHeight - 50
    return tableHeight
  }


  rowClickHandle = (event, index) => {
    let currentTarget = event.currentTarget
    if (currentTarget) {
      let statusColumn = currentTarget.querySelector('.status_cell_wrapper')
      if (statusColumn) {
        let bookId = statusColumn.getAttribute('booking-id')
        if (_.get(this.props, 'auth.user.roles.isSupplier', false)) {
          return this.props.router.push({pathname:`/supplier-booking-detail/${bookId}`, state: {selectedTab: this.state.selectedTab}})
        }
        if (bookId) {
          this.props.bookingDetailActions
            .getBookingDetail(bookId, this.state.selectedTab == 1)
            .then((data) => {
              if (!data.res || data.error) {
                this.context.notification(
                  'error',
                  I18n.t('cue.Error_load_booking')
                )
              }
            })
        }
        this.clearDataWhenUncheckboxAll()
      }
    }
  }

  rowClickHandleMobile = (bookId) => {
    if (bookId) {
      if (_.get(this.props, 'auth.user.roles.isSupplier', false)) {
        return this.props.router.push({pathname: `/supplier-booking-detail/${bookId}`, state: {selectedTab: this.state.selectedTab}})
      }
      this.props.bookingDetailActions
        .getBookingDetail(bookId, this.state.selectedTab == 1)
        .then((data) => {
          if (!data.res || data.error) {
            this.context.notification(
              'error',
              I18n.t('cue.Error_load_booking')
            )
          }
        })
      this.clearDataWhenUncheckboxAll()
    }
  }

  onChangeReorder = (event) => {
    let { rearangeColumn = [] } = this.state
    const columnDrag = rearangeColumn.find(ob => ob.key === event.reorderColumn)
    let columnOrderNew = rearangeColumn.filter((column) => {
      return column.key !== event.reorderColumn;
    });
    if (event.columnAfter && columnDrag) {
      let index = columnOrderNew.findIndex(ob => ob.key === event.columnAfter);
      columnOrderNew.splice(index, 0, columnDrag);
      this.setState({
        rearangeColumn: columnOrderNew,
      });
      const localStorageName =
        (this.state.selectedTab == 0 || this.state.selectedTab == 3)
          ? 'rearrangeColumnActive'
          : 'rearrangeColumnFinished'
      localStorage.setItem(localStorageName, JSON.stringify(columnOrderNew))
    }
  }

  handleSaveRearrangeColumn = (newColumnRearrange = []) => {
    this.setState({
      rearangeColumn: newColumnRearrange,
      isShowRearrangeModal: false,
    }, () => {
      this.renderTable()
    })
    const localStorageName =
    (this.state.selectedTab == 0 || this.state.selectedTab == 3)
        ? 'rearrangeColumnActive'
        : 'rearrangeColumnFinished'
    localStorage.setItem(localStorageName, JSON.stringify(newColumnRearrange))
  }

  renderTable = () => {
    let data = this.getBookingList()
    if (this.state.selectedTab == 0 || this.state.selectedTab == 3) {
      //active tab
      //limit cues to render is 30 for 1 page
      let rowNumber = ItemPerPage * this.state.showDataPage
      data = data.slice(0, rowNumber)
    }
    // rearrange column by rearrange popup
    let tableColumnsRearrange = [...this.getTableColumns()]
    const rearangeColumn = this.state.rearangeColumn
    if (rearangeColumn.length > 0) {
      tableColumnsRearrange = tableColumnsRearrange.sort(
        (a, b) => {
          const keyAIndex = rearangeColumn.findIndex(item => item && item.key === a.key);
          const keyBIndex = rearangeColumn.findIndex(item => item && item.key === b.key);

          if (keyAIndex === -1) {
            return 1;
          }
          return keyAIndex - keyBIndex;
        }
      )
    }
    const {
      auth: {
        selectedFleet: { 
          groupBooking = false, // setting group for transport
          batchDelivery = false // setting group for delivery
        }
      },
    } = this.props
    const { lineShort, selectedTab, checkedGroup } = this.state

    const isEnableGroupSetting = groupBooking || batchDelivery
    return (
      <>
        {isMobile ? (
            <ListBookingMobile 
              bookingList={data}
              className={'visible-mb-lg'}
              lineShort={lineShort}
              selectedTab={selectedTab}
              checkedGroup={checkedGroup}
              permissions={this.props.permissions}
              isEnableGroupSetting={isEnableGroupSetting}
              hancleViewDetailBooking={this.rowClickHandleMobile}
              checkEnableSelectMultiBook={this.checkEnableSelectMultiBook}
              handleClickGroup={this.handleClickGroup}
              auth={this.props.auth}
              paramProps={this.props}
              handleAssignDriverOneBooking={this.handleAssignDriverOneBooking}
              handleAcceptBooking={this.handleAcceptBooking}
              scrollBottomCallback={this.handleScrollBottom}
            />
          // }
        ) : (
          <StickyTable
            columns={tableColumnsRearrange}
            customeWidthColumn={{
              'bookId': 200,
            }}
            bodyData={data}
            rowHeight={this.state.rowHeight}
            noPagination={true}
            hasReorder
            onChangeReorder={this.onChangeReorder}
            className={`${this.state?.checkedGroup?.length > 0 ? 'hasShift' : ''} cue-table hidden-mb-lg`}
            contentTop={true}
            checkedGroup={this.state.checkedGroup}
            notCheckShouldComponentUpdate={true}
            typeBookingGroup={this.state.typeBookingGroup}
            scrollBottomCallback={_.debounce(this.handleScrollBottom, 500)}
            handleRowClick={this.rowClickHandle}
            getTableHeight={this.getTableHeight}
            dynamicColumns={true}
            lineShort={this.state.lineShort}
          />
        )}
      </>
    )
  }

  handleUploadClick = () => {
    this.props.router.push({
      pathname: '/import_bookings',
    })
  }

  handleToggleActiveFilter = () => {
    let currentFilter =
    (this.state.selectedTab == 0 || this.state.selectedTab == 3) ? 'showActiveFilter' : 'showFinishedFilter'
    this.setState(
      { [currentFilter]: !this.state[currentFilter] },
      dispatchOuterTableChange()
    )
  }

  checkHasShowImportBooking = () => {
    let {
      isAdmin = false,
      roles = {},
      userType: userTypeProp,
      isImportBooking = false,
      isImportDeliveryBooking = false,
    } = (this.props.auth && this.props.auth.user) || {}
    // show/hide for corporate
    if (
      userTypeProp === userType.CorporateAdmin ||
      userTypeProp == userType.CorporateUser
    ) {
      return isImportBooking || isImportDeliveryBooking
    }

    // show/hide for normal user
    let importChauffeurBooking = _.get(
      this.props.auth,
      'selectedFleet.generalSetting.importChauffeurBooking',
      false
    )
    let importDeliveryBooking = _.get(
      this.props.auth,
      'selectedFleet.generalSetting.importDeliveryBooking',
      false
    )
    if (!importChauffeurBooking && !importDeliveryBooking) {
      return false
    }
    if (isAdmin) {
      return true
    }
    let modules = roles.modules || []
    let permissionCue = modules.find((module) => module.key === 'Cue')
    const actions = (permissionCue && permissionCue.actions) || []
    return actions.some((action) => {
      return (
        (importChauffeurBooking &&
          action.name === 'ImportBookings' &&
          action.isActive) ||
        (importDeliveryBooking &&
          action.name === 'ImportDeliveryBookings' &&
          action.isActive)
      )
    })
  }

  handleAssignDriverOneBooking = (booking, e) => {
    e?.stopPropagation();
    if(this.state.selectedTab !== 0) return
    this.setState({
      openAssignDriverModal: true,
      checkedGroup: [booking]
    })
  }

  handleAcceptBooking = async (book) => {
    try {
      this.props.loadingActions.showLoadingSpiner();
      const res = await this.props.bookingDetailActions.acceptOfferBooking({
        bookId: book.bookId,
        fleetId: this.props.auth.selectedFleet.fleetId,
      });

      if (res.ok) {
        this.context.notification('success', I18n.t('supplier.acceptOfferSuccess'));
        this.props.loadingActions.hideLoadingSpiner();
        return true
        // this.props.router.push({ pathname: '/cue', state: { selectedTab: 3 } });
      } else {
        this.context.notification('error', I18n.t('supplier.acceptOfferFail'));
        this.props.loadingActions.hideLoadingSpiner();
        return false
      }
    } catch (error) {
      this.context.notification('error', I18n.t('supplier.acceptOfferFail'));
      this.props.loadingActions.hideLoadingSpiner();
      return false
    }
  };

  handleAssignSupplierOneBooking = (booking, e) => {
    e?.stopPropagation();
    this.setState({
      openAssignSuplierModal: true,
      checkedGroup: [booking]
    })
  }

  renderFilter = (tab) => {
    return (
      <CueFilter
        tab={tab}
        selectedTab={this.state.selectedTab}
        bookingType={this.state.bookingType}
        permissions={this.props.permissions}
        handleSaveRearrangeColumn={this.handleSaveRearrangeColumn}
        showFilter={
          (this.state.selectedTab == 0 || this.state.selectedTab == 3)
            ? this.state.showActiveFilter
            : this.state.showFinishedFilter
        }
        // cue={this.props.cue}
        commonData={this.props.commonData}
        companyList={this.state.companyList}
        auth={this.props.auth}
        txtSearchPressHandle={this.txtSearchPressHandle}
        updateFillterDateRange={this.updateFillterDateRange}
        updateDatefrom={this.updateDatefrom}
        updateDateTo={this.updateDateTo}
        handleToggleFilter={this.handleToggleActiveFilter}
        handleUploadClick={this.handleUploadClick}
        hasShowUploadClick={this.checkHasShowImportBooking()}
        bookingServiceHandle={this.bookingServiceHandle}
        bookingTypeHandle={this.bookingTypeHandle}
        statusItemClicked={this.statusItemClicked}
        updateFillterOperactor={this.updateFillterOperactor}
        updateFillterCarTypes={this.updateFillterCarTypes}
        updateFillterAirline={this.updateFillterAirline}
        updateFillterIntercityRoute={this.updateFillterIntercityRoute}
        passengerTypeChangeHandle={this.passengerTypeChangeHandle}
        paidStatusTypeChangeHandle={this.paidStatusTypeChangeHandle}
        updateFillterBookingFrom={this.updateFillterBookingFrom}
        rideServiceHandle={this.rideServiceHandle}
        rideSharingHandle={this.rideSharingHandle}
        supportServiceChangeHandle={this.supportServiceChangeHandle}
        rideTypeChangeHandle={this.rideTypeChangeHandle}
        corporateChangeHandle={this.corporateChangeHandle}
        networkTypeChange={this.networkTypeChange}
        companyChangeHandle={this.companyChangeHandle}
        commonActions={this.props.commonActions}
        corporateCompany={this.props.corporateCompany}
        cueDataRequest={this.cueDataRequest}
        updateFillterPaymentMethod={this.updateFillterPaymentMethod}
        onZoneChange={this.onZoneChange}
        selectedColumns={
          (this.state.selectedTab == 0 || this.state.selectedTab == 3)
            ? this.state.activeTableColumns
            : this.state.finishedTableColumns
        }
        handleSelectColumn={this.handleSelectColumn}
        showRideSharingFilter={this.state.showRideSharingFilter}
        handleSearchByChange={this.handleSearchByChange}
        txtSearch={this.state.txtSearch || ''}
        searchBy={this.state.searchBy || CUESearchBy[0].value}
        searchByOptions={this.state.selectedTab == 3 ? CUEOfferSearchBy : CUESearchBy}
        handleApplyFinishFilter={this.handleApplyFinishFilter}
        handleClearFinishFilter={this.handleClearFinishFilter}
        handleClearOfferFilter={this.handleClearOfferFilter}
        handleClickFliterTemplate={this.handleClickFliterTemplate}
        handleClearActiveFilter={this.handleClearActiveFilter}
        showRearrangeModal={() => this.showHideRearrangeModal(true)}
        rearangeColumn={this.state.rearangeColumn}
      />
    )
  }

  handleSelectColumn = (list) => {
    if (this.state.selectedTab == 0 || this.state.selectedTab == 3) {
      if (list.length === 0) {
        list = this.getLocalColumns('cue_active_table', ['payment'])
      }
      this.setState(
        {
          activeTableColumns: list,
        },
        () => setTableDynamicColumn('cue_active_table', list)
      )
    } else {
      if (list.length === 0) {
        list = this.getLocalColumns('cue_finished_table', ['estimatedFare'])
      }
      this.setState(
        {
          finishedTableColumns: list,
        },
        () => {
          setTableDynamicColumn('cue_finished_table', list)
        }
      )
    }
  }

  handleApplyFinishFilter = () => {
    this.setState(
      {
        completedBookingLoaded: false,
      },
      () => {
        this.cueDataRequest({}, this.state.selectedTab)
      }
    )
  }

  handleClearFinishFilter = () => {
    const { selectedFleet } = this.props.auth
    let paymentMethods = filterPaymentMethods(paymentMethod, selectedFleet)
    let columnsDefault = this.getLocalColumns('cue_finished_table', [
      'estimatedFare',
    ])
    let filter = {
      dateFrom: null,
      dateTo: null,
      dateRange: 'allTime',
      bookingService: 'all',
      bookingType: 'all',
      networkType: 'all',
      operator: '',
      bookFrom: [],
      carType: [],
      requestCompanyIds: [],
      finishedStatus: {
        all: true,
        completed: true,
        noShow: true,
        incident: true,
        canceled: true,
        completedWithoutService: true,
        partialCompleted: true,
        failed: true,
      },
      paymentMethod: paymentMethods,
      vip: null,
      rideService: 'all',
      rideSharing: 'all',
      supportService: 'all',
      corporateId: null,
      rideType: 'all',
      zone: '',
      intercityRoutes: [],
      routeId: null,
      routeNumber: null,
      airline: null,
    }
    this.setState(
      {
        completedBookingLoaded: false,
      },
      () => {
        this.props.cueActions.updateCueFillter(filter, true)
        this.cueDataRequest(filter, 1)
        this.handleSelectColumn(columnsDefault)
      }
    )
  }

   handleClearOfferFilter = () => {
    const { selectedFleet } = this.props.auth
    let paymentMethods = filterPaymentMethods(paymentMethod, selectedFleet)
    let columnsDefault = this.getLocalColumns('cue_offer_table', [
      'estimatedFare',
    ])
    let filter = {
      dateFrom: null,
      dateTo: null,
      dateRange: 'allTime',
      bookingService: 'all',
      bookingType: 'all',
      networkType: 'all',
      operator: '',
      bookFrom: [],
      carType: [],
      requestCompanyIds: [],
      offerStatus: {
        all: true,
        pending: true,
        dispatching: true,
        action: true,
        confirmed: true,
        allocated: true
      },
      paymentMethod: paymentMethods,
      vip: null,
      rideService: 'all',
      rideSharing: 'all',
      supportService: 'all',
      corporateId: null,
      rideType: 'all',
      zone: '',
      intercityRoutes: [],
      routeId: null,
      routeNumber: null,
      airline: null,
    }
    this.setState(
      {
        completedBookingLoaded: false,
      },
      () => {
        this.props.cueActions.updateCueFillter(filter, false)
        this.cueDataRequest(filter, 3)
        this.handleSelectColumn(columnsDefault)
      }
    )
  }

  handleClickFliterTemplate = (data) => {
    let filter = data || {}
    if (filter.dateFrom || filter.dateTo) {
      filter.isDateRangeInputChanged = true
    }
    this.setState(
      {
        txtSearch: filter.txtSearch,
        searchBy: filter.searchBy,
      },
      () => {
        if (filter.selectedTab == 0) {
          this.props.cueActions.updateCueFillter(filter, false)
          this.cueDataRequest(filter, 0)
        } else if (filter.selectedTab == 3) {
          this.props.cueActions.updateCueFillter(filter, false)
          this.cueDataRequest(filter, 3)
        } else {
          this.props.cueActions.updateCueFillter(filter, true)
          this.cueDataRequest(filter, 1)
        }
      }
    )
  }

  handleClearActiveFilter = () => {
    const { selectedFleet, user } = this.props.auth
    let paymentMethods = filterPaymentMethods(paymentMethod, selectedFleet)
    let columnsDefault = this.getLocalColumns('cue_active_table', ['payment'])
    let filter = {
      fleetId: null,
      txtSearch: this.props.location.query.txtSearch
        ? this.props.location.query.txtSearch
        : '',
      searchBy: this.props.location.query.searchBy
        ? this.props.location.query.searchBy
        : CUESearchBy[0].value,
      bookingService: 'all',
      networkType: 'all',
      supportService: 'all',
      corporateId: null,
      rideType: 'all',
      paidStatus: 'all',
      bookingType: 'all',
      dateFrom: null,
      dateTo: null,
      dateRange: 'allTime',
      operator: '',
      airline: null,
      bookFrom: [],
      carType: [],
      requestCompanyIds: [],
      intercityRoutes: [],
      routeId: '',
      routeNumber: null,
      status:
        (selectedFleet.delivery && selectedFleet.delivery.enable) ||
        (selectedFleet.food && selectedFleet.food.enable) ||
        (selectedFleet.mart && selectedFleet.mart.enable)
          ? allStatusCUE
          : statusNotDeliveryCUE,
      finishedStatus: {
        all: true,
        completed: true,
        noShow: true,
        incident: true,
        canceled: true,
        failed: true,
        partialCompleted: true
      },
      corporate:
        user.userType === userType.CorporateAdmin ||
        user.userType === userType.CorporateUser
          ? user.corporateId
          : null,
      driverCorporate: user.roles.isSupplier && user.roles.companyId,
      currentAgent: !this.props.auth.user.roles.isSupplier && this.props.auth.user.roles.companyId,
      currentSupplierCompaniesList: [...this.props.commonData.suppliers, ...this.props.commonData.companies] || [],
      process: this.props.auth?.selectedFleet?.process,
      companyOfOperator: getSupplierInfoFromID(this.props.commonData.companies, this.props.auth?.user?.roles?.companyId),
      fleetEnableBroadcastBooking: this.state.fleetEnableBroadcastBooking,
      paymentMethod: paymentMethods,
      vip: null,
      zone: null,
    }
    this.setState(
      {
        txtSearch: '',
        searchBy: CUESearchBy[0].value,
      },
      () => {
        this.props.cueActions.updateCueFillter(filter, false)
        this.cueDataRequest(filter, 1)
        this.handleSelectColumn(columnsDefault)
      }
    )
  }

  renderBookType = (book) => {
    if (book.intercity) {
      return I18n.t('cue.intercity')
    }
    if (book.delivery) {
      return I18n.t('cue.delivery')
    }
    if (book.reservation && book.jobType == 'shuttle') {
      return 'Reservation(Shuttle)'
    }
    if (book.reservation) {
      return I18n.t('cue.Reservation')
    }
    if (book.shuttle) {
      return I18n.t('cue.Shuttle')
    }
    return I18n.t('cue.now')
  }

  handleNewGroup = (e) => {
    this.setState({
      newGroup: e.target.value,
    })
  }

  handleGroupActionClick = (value) => {
    const { checkedGroup = [], newGroup = 'new' } = this.state
    if(value === GROUP_ACTIONS.groupManifest) {
      this.setState({
        openGroupModal: true,
        newGroup: checkedGroup?.length === 1 ? 'existing' : newGroup,
      })
      return
    }
    if(value === GROUP_ACTIONS.assignSuppliers) {
      this.setState({
        openAssignSuplierModal: true,
      })
      return
    }
    if(value === GROUP_ACTIONS.assignDriver) {
      this.setState({
        openAssignDriverModal: true,
      })
      return
    }

    if(value === GROUP_ACTIONS.unassignDriver) {
      this.setState({
        confirm: {
          id: GROUP_ACTIONS.unassignDriver,
          title: I18n.t('cue.unassignDriver'),
          body: I18n.t('cue.confirmUnassign'),
          buttonTitle: 'Yes',
          closeButtonText: 'No'
        }
      });
      return
    }

    if(value === GROUP_ACTIONS.unassignVehicle) {
      this.setState({
        confirm: {
          id: GROUP_ACTIONS.unassignVehicle,
          title: I18n.t('cue.unassignVehicle'),
          body: I18n.t('cue.confirmUnassign'),
          buttonTitle: 'Yes',
          closeButtonText: 'No'
        }
      });
      return
    }

    if(value === GROUP_ACTIONS.unassignAll) {
      this.setState({
        confirm: {
          id: GROUP_ACTIONS.unassignAll,
          title: I18n.t('cue.unassignAll'),
          body: I18n.t('cue.confirmUnassign'),
          buttonTitle: 'Yes',
          closeButtonText: 'No'
        }
      });
      return
    }
  }

  handleGroupCloseClick = (e) => {
    this.setState({
      openGroupModal: false,
      newGroup: 'new',
      groupList: null,
    })
  }

  handleAssignSuplierModalClose = (e) => {
    this.setState({
      openAssignSuplierModal: false,
      supplierSelected: [],
      resultChangeSupplier: null,
      checkedGroup: this.state.resultChangeSupplier ? [] : this.state.checkedGroup
    })
    this.clearDataWhenUncheckboxAll()
  }

  clearDataWhenUncheckboxAll = () => {
    this.setState({
      checkedGroup:  [],
      typeBookingGroup: '',
    })
  }

  handleAssignDriverClose = (e) => {
    this.clearDataWhenUncheckboxAll()
    this.setState({
      openAssignDriverModal: false,
      supplierSelected: [],
      resultDetachDriver: null,
      resultAssignDriver: null,
    }, () => {
      this.renderTable()
    })
  }

  handleCustomizedChange = (e) => {
    this.setState({
      groupList: e,
    })
  }

  handleLoadOption = (input, callback) => {
    if (!input) return callback()

    const { auth = {} } = this.props;
    const { groupList, typeBookingGroup } = this.state;
    this.searchGroupDebounce(
      auth.selectedFleet.fleetId,
      groupList,
      input,
      typeBookingGroup,
      callback
    )
  }

  handleChangeAssignSupplier = (selectedOption, selectingOption) => {
    // corp operator: Không cho remove company cuối cùng của corp có custome supplier
    if(
      !this.state.fleetEnableBroadcastBooking ||
      this.state.typeBookingGroup === TYPE_BOOKING_GROUP.delivery
    ) {
      selectedOption = [selectedOption]
    }
    if (
      !checkCanRemoveLastOptionCorporate(
        selectedOption,
        selectingOption,
        this.props.auth.user,
        this.props.auth.user.corporateAccount
      )
    ) {
      return;
    }
    if (selectedOption?.includes('all') || !selectedOption) {
      selectedOption = [];
    } else {
      selectedOption = selectedOption?.filter((op) => op.value !== 'all');
    }
    this.setState({
      supplierSelected: selectedOption,
    });
  }

  searchGroup = (
    fleetId,
    groupList,
    input,
    typeBookingGroup,
    callback
  ) => {
    let ids = [];
    if (groupList) ids = groupList?.map(item => item.id) || [];

    let params = {
      fleetId,
      textSearch: input,
      jobType: typeBookingGroup
    }

    this.props.cueActions.searchGroup(params).then((data) => {
      if (data && data.length > 0) {
        let result = { complete: true }
        result.options = data.map((item) => {
          return {
            value: item._id,
            id: item._id,
          }
        })
        callback(null, result)
      } else {
        callback(null, null)
      }
    })
  }

  resetAfterGroup = () => {
    this.handleGroupCloseClick()
    this.setState({
      groupIdChecking: '',
      checkedGroup: [],
      typeBookingGroup: '',
      bookLast: null
    })
  }

  groupBookingSubmit = () => {
    const { newGroup = 'new', checkedGroup = [], groupList = {} } = this.state
    const { auth } = this.props
    let params = {
      fleetId: auth.selectedFleet.fleetId,
      bookIds: checkedGroup && checkedGroup.map(bk => bk.bookId),
    }

    if (newGroup === 'existing') {
      if (groupList.id) {
        params.groupId = groupList.id
      } else {
        this.context.notification(
          'error',
          I18n.t(`messages.booking.please_select_groupId`)
        )
        return
      }
    }
    this.props.loadingActions.showLoadingSpiner();
    this.props.cueActions.groupBooking(params)
    .then((data) => {
      this.props.loadingActions.hideLoadingSpiner();
      if(data && data._id) {
        this.context.notification('success', I18n.t('messages.booking.Group_success'));
        this.resetAfterGroup()
      } else {
        if(data.code === 'BOOKINGS_LIMIT_EXCEEDED') {
          this.context.notification('error', I18n.t(`messages.booking.${data.code}`));
          return
        }
        if(data.code === 'LIMIT_EXCEEDED_ON_SEATS_OR_LUGGAGE') {
          this.context.notification('error', I18n.t(`messages.booking.${data.code}`));
          return
        }
        if(data?.data?.message) {
          this.context.notification('error', data?.data?.message);
          return
        }
        this.context.notification('error', I18n.t('General.ServerError'));
      }
    })
  }

  changeSuppliersSubmit = (hasDispatch) => {
    const { supplierSelected, checkedGroup = [] } = this.state
    const { auth } = this.props

    let checkCompanies = supplierSelected && supplierSelected.map(sup => sup.value) || [];
    if (this.props?.auth?.user?.roles?.companyId && supplierSelected?.length == 0 && this.props?.commonData?.suppliers?.length) {
      let listCompanies = [...this.props?.commonData.companies, ...this.props?.commonData?.suppliers]
      checkCompanies = listCompanies.map((company) => company._id)
    }

    let params = {
      fleetId: auth.selectedFleet.fleetId,
      bookIds: checkedGroup && checkedGroup.map(bk => bk.bookId),
      supplierCompanies: checkCompanies,
      dispatch: hasDispatch
    }

    this.props.loadingActions.showLoadingSpiner();
    this.props.cueActions.changeSuppliers(params)
    .then((data) => {
      this.props.loadingActions.hideLoadingSpiner();
      if(data.returnCode) {
        this.handleAssignSuplierModalClose()
        this.getErrorAssginSupplier(data)
      } else {
        this.context.notification('error', 'Cannot change suppliers, Please try again');
      }
    })
  }

  renderGroupManifestModal = () => {
    const { checkedGroup = [], newGroup = 'new', groupList } = this.state
    return (
      <Modal
        show={this.state.openGroupModal}
        backdrop="static"
        dialogClassName="confirm-dialog modalAssign"
        className="confirm groupOrderModal"
        onHide={this.handleCloseGroupModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate
              value="bookingdetail.groupOder"
              className="transform-none"
            />
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.handleGroupCloseClick}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="group-body">
            <Form.Label>
              <Translate value="bookingdetail.Select" />
            </Form.Label>
            <FormGroup className="radio-button-group">
              {checkedGroup?.length > 1 && (
                <RadioButton
                  text={I18n.t('bookingdetail.NewGroup')}
                  value={'new'}
                  onChange={this.handleNewGroup}
                  checked={newGroup === 'new'}
                  name="Auto_dispatch"
                />
              )}
              <RadioButton
                text={I18n.t('bookingdetail.ExistingGroup')}
                value={'existing'}
                name="Auto_dispatch"
                onChange={this.handleNewGroup}
                checked={newGroup === 'existing'}
              />
            </FormGroup>
          </div>
          {newGroup === 'existing' && (
            <FormGroup className="radio-button-group">
              <Select.Async
                multi={false}
                valueKey="id"
                labelKey="value"
                cache={false}
                placeholder={I18n.t('bookingdetail.Search_trip')}
                searchPromptText={''}
                loadingPlaceholder={I18n.t('message.Searching')}
                noResultsText={I18n.t('message.No_results')}
                className="receiver-list form-custom custom-select-control none-bg-arrow search_bookingGroup"
                value={groupList}
                loadOptions={this.handleLoadOption}
                onChange={this.handleCustomizedChange}
              />
            </FormGroup>
          )}
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button
            className="btn-save mr-md"
            onClick={this.groupBookingSubmit}
            disabled={newGroup === 'existing' && !groupList ? true : false}
          >
            {I18n.t('bookingdetail.Apply')}
          </Button>
          <Button
            className="btn-cancel"
            onClick={this.handleGroupCloseClick}
          >
            {I18n.t('bookingdetail.Cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  convertValueForMultiCompany = (supplierByZone) => {
    if (this.state.supplierSelected?.length > 0)
      return this.state.supplierSelected;
    if (
      this.state.iscorporateUser ||
      !supplierByZone.some((s) => s.value === 'all')
    )
      return [supplierByZone[0]];
    return [{ value: 'all', label: 'All Company' }];
  };
  
  renderGroupAssignSuplierModal = () => {
    const { resultChangeSupplier } = this.state;
    const showResultAfterSubmit = !!resultChangeSupplier?.message;
    const supplierByZone = filterSupplierByZone(this.state.suplierOption, this.state.checkedGroup, this.props.commonData)
    return (
      <Modal
        show={this.state.openAssignSuplierModal}
        dialogClassName="confirm-dialog modalAssign"
        backdrop="static"
        className="confirm groupOrderModal"
        onHide={this.handleAssignSuplierModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value="cue.AssignSupplier" className="transform-none" />
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.handleAssignSuplierModalClose}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {
            showWarningAssignSupplier(
              this.state.checkedGroup, 
              this.state.supplierSelected, 
              [...this.props.commonData?.companies, ...this.props.commonData?.suppliers]
            ) && (
              <p>
                <span style={{color: '#ffc70c'}}>Notice:</span>
                {` Not all suppliers will see every booking due to zone restrictions. Suppliers receive only the bookings relevant to their zones. Please review your selections.`}
              </p> 
            ) 
          }
          <FormGroup className="radio-button-group">
            <p className='searchTitle'>{I18n.t('General.search')}</p>
            <SelectMulti
              isMulti={this.state.fleetEnableBroadcastBooking && this.state.typeBookingGroup !== TYPE_BOOKING_GROUP.delivery}
              value={this.convertValueForMultiCompany(supplierByZone)}
              onChange={this.handleChangeAssignSupplier}
              options={supplierByZone}
              classNamePrefix="selectMulti"
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button
            className="btn-cancel"
            onClick={this.handleAssignSuplierModalClose}
          >
            {I18n.t('bookingdetail.Close')}
          </Button>
          {!showResultAfterSubmit && (
            <Button
              className="btn-save mr-md"
              onClick={() => this.changeSuppliersSubmit(false)}
            >
              {I18n.t('bookingdetail.Update')}
            </Button>
          )}
          {!showResultAfterSubmit &&
            !isBookingReservation(this.state.checkedGroup[0]) &&
          (
            <Button
              className="btn-save mr-md"
              onClick={() => this.changeSuppliersSubmit(true)}
            >
              {I18n.t('messages.booking.update_dispatch')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }

  getErrorAssginOrDetachDriver = (result, actionName) => {
    let msg = '',
      isSuccess =  false
    if (actionName === ACTIONS_DRIVER_NAME.unassignVehicle) {
      if(result.failBooks?.length === 0 && result.success) {
        msg = I18n.t('messages.booking.detach_vehicle_success')
        isSuccess = true;
      } else if (result?.failBooks?.length > 0) {
        msg = I18n.t('messages.booking.detach_driver_partial').format(
          result?.failBooks?.join(', ')
        )
      } else {
        msg = <p>{I18n.t('cue.notUnassignDriver')}</p>
      }
    }

    if (actionName === ACTIONS_DRIVER_NAME.unassignAll) {
      if(result.failBooks?.length === 0 && result.success) {
        msg = I18n.t('cue.unassignAllSuccess')
        isSuccess = true;
      } else if (result?.failBooks?.length > 0) {
        msg = I18n.t('cue.unassign_all_partial').format(
          result?.failBooks?.join(', ')
        )
      } else {
        msg = <p>{I18n.t('cue.notUnassignDriver')}</p>
      }
    }

    if (actionName === ACTIONS_DRIVER_NAME.unassign) {
      if(result.failBooks?.length === 0 && result.success) {
        msg = I18n.t('messages.booking.detach_driver_success')
        isSuccess = true;
      } else if (result?.failBooks?.length > 0) {
        msg = I18n.t('messages.booking.detach_driver_partial').format(
          result?.failBooks?.join(', ')
        )
      } else {
        msg = <p>{I18n.t('cue.notUnassignDriver')}</p>
      }
    }

    if (actionName === ACTIONS_DRIVER_NAME.assign) {
      if(result.failBooks?.length === 0 && result.success) {
        msg = I18n.t('messages.booking.assign_driver_vehicle_success')
        isSuccess = true;
      } else if (result?.failBooks?.length > 0) {
        msg = I18n.t('messages.booking.assign_driver_partial').format(
          result?.failBooks?.join(', ')
        )
      } else {
        msg = <p>{I18n.t('cue.notAssignDriver')}</p>
      }
    }

    if (actionName === ACTIONS_DRIVER_NAME.offer) {
      if(result.failBooks?.length === 0 && result.success) {
        msg = I18n.t('messages.booking.assign_driver_vehicle_success')
        isSuccess = true;
      } else if (result?.failBooks?.length > 0) {
        msg = I18n.t('messages.booking.assign_driver_partial').format(
          result?.failBooks?.join(', ')
        )
      } else {
        msg = <p>{I18n.t('cue.notAssignDriver')}</p>
      }
    }
    this.context.notification( isSuccess ? 'success' : 'error', msg)
  };

  getErrorAssginSupplier = (result) => {
    let msg = '',
      isSuccess =  false
    if(!result.failed || result.failed?.length === 0 && result.returnCode === 200) {
      msg = I18n.t('messages.booking.change_supplier_success')
      isSuccess = true;
    } else if (result?.failed?.length > 0) {
      msg = I18n.t('messages.booking.change_supplier_partial').format(
        result?.failBooks?.join(', ')
      )
    } else {
      msg = 'Cannot change suppliers, Please try again'
    }
    this.setState({
      checkedGroup: []
    })
    this.context.notification( isSuccess ? 'success' : 'error', msg)
  }

  renderGroupAssignDriverModal = () => {
    if(this.state.openAssignDriverModal) {
      return (
        <AutosuggestAssignDriver 
          books={this.state.checkedGroup}
          disabled={false}
          auth={this.props.auth}
          detachDriver={this.handleDetachDriver}
          assignDriver={this.handleAssignDriver}
          offerDriver={this.handleOfferDriver}
          detachVehicle={this.handleDetachVehicle}
          handleGroupActionClick={this.handleGroupActionClick}
          openAssignDriverModal={this.state.openAssignDriverModal}
          handleAssignDriverClose={this.handleAssignDriverClose}
          user={this.props.auth.user}
          notification={this.context.notification}
        />
      );
    }
    return null
  }

  handleDetachDriver = async () => {
    if(this.state?.checkedGroup?.length > 0) {
      try {
        const result = await this.props.cueActions.detachDriver({
          bookIds: this.state.checkedGroup?.map(bk => bk.bookId),
          fleetId: this.props.auth?.selectedFleet?.fleetId
        })
        if(result.success) {
          this.handleAssignDriverClose()
          this.handleConfirmCloseClick()
          this.getErrorAssginOrDetachDriver(result, ACTIONS_DRIVER_NAME.unassign)
        } else {
          this.context.notification('error', I18n.t('cue.notUnassignDriver'));
        }
      } catch (error) {
        console.log('handleDetachDriver')
      }
    }
  }
  
  handleDetachVehicle = async () => {
    if(this.state?.checkedGroup?.length > 0) {
      try {
        const result = await this.props.cueActions.detachVehicle({
          bookIds: this.state.checkedGroup?.map(bk => bk.bookId),
          fleetId: this.props.auth?.selectedFleet?.fleetId
        })
        if(result.success) {
          this.handleAssignDriverClose()
          this.handleConfirmCloseClick()
          this.getErrorAssginOrDetachDriver(result, ACTIONS_DRIVER_NAME.unassignVehicle)
        } else {
          this.context.notification('error', I18n.t('cue.notUnassignVehicle'));
        }
      } catch (error) {
        console.log('handleDetachVehicle')
      }
    }
  }

  handleUnassignAll = async () => {
    if(this.state?.checkedGroup?.length > 0) {
      try {
        const result = await this.props.cueActions.unassignAll({
          bookIds: this.state.checkedGroup?.map(bk => bk.bookId),
          fleetId: this.props.auth?.selectedFleet?.fleetId
        })
        if(result.success) {
          this.handleAssignDriverClose()
          this.handleConfirmCloseClick()
          this.getErrorAssginOrDetachDriver(result, ACTIONS_DRIVER_NAME.unassignAll)
        } else {
          this.context.notification('error', I18n.t('cue.notUnassignAll'));
        }
      } catch (error) {
        console.log('handleUnassignAll')
      }
    }
  }

  handleAssignDriver = async (driverId, vehicleId) => {
    if(this.state?.checkedGroup?.length > 0 && (driverId || vehicleId)) {
      try {
        const result = await this.props.cueActions.assignDriver({
          bookIds: this.state.checkedGroup?.map(bk => bk.bookId),
          fleetId: this.props.auth?.selectedFleet?.fleetId,
          driverId,
          vehicleId
        })
        if(result.success) {
          this.handleAssignDriverClose()
          this.getErrorAssginOrDetachDriver(result, ACTIONS_DRIVER_NAME.assign)
        } else {
          this.context.notification('error', I18n.t('cue.notAssignDriver'));
        }
      } catch (error) {
        console.log('handleDetachDriver')
      }
    }
  }

  handleOfferDriver = async (driverId, vehicleId) => {
    if(this.state?.checkedGroup?.length > 0 && (driverId || vehicleId)) {
      try {
        const result = await this.props.cueActions.offerDriver({
          bookIds: this.state.checkedGroup?.map(bk => bk.bookId),
          fleetId: this.props.auth?.selectedFleet?.fleetId,
          driverId,
          vehicleId
        })
        if(result.success) {
          this.handleAssignDriverClose()
          this.getErrorAssginOrDetachDriver(result, ACTIONS_DRIVER_NAME.offer)
        } else {
          this.context.notification('error', I18n.t('cue.notAssignDriver'));
        }
      } catch (error) {
        console.log('handleDetachDriver')
      }
    }
  }

  renderGroupModal = () => {
    const { checkedGroup = [] } = this.state
    if (checkedGroup?.length > 0) {
      return (
        <>
          {/* bottom sidebar to group oder */}
          {checkedGroup.length > 0 && (
            <div id="group_btn">
              <span>{`${checkedGroup.length} booking selected`}</span>
              <div className='group_btn_actionsDiv'>
                <Button
                  className="btn-header btnClear"
                  onClick={this.clearDataWhenUncheckboxAll}
                >
                  {I18n.t('customer.Clear')}
                </Button>
                <CCDropDown
                  id="group_btn_actions"
                  items={this.state.optionsGroupAction}
                  title={I18n.t('cue.viewActions')}
                  dropup={true}
                  selectedItems={[]}
                  valueKey="value"
                  labelKey="title"
                  onSelect={this.handleGroupActionClick}
                  noTooltip
                />
              </div>
            </div>
          )}
          {this.renderGroupManifestModal()}
          {this.renderGroupAssignSuplierModal()}
          {this.renderGroupAssignDriverModal()}
        </>
      )
    }
    return null
  }
  showHideRearrangeModal = (isShow = false) => {
    this.setState({ isShowRearrangeModal: isShow })
  }

  handleKeyDownTable = (event) => {
    if (event.key === 'Shift') {
      if(this.state.checkedGroup.length > 0) {
        this.setState({
          isShiftKey: true,
        })
      }
    }
  };

  handleKeyUpTable = (event) => {
    if (event.key === 'Shift') {
      this.setState({
        isShiftKey: false,
      })
    }
  };

  handleConfirmButtonClick = (id) => {
    if (id === GROUP_ACTIONS.unassignDriver) {
      this.handleDetachDriver()
    }

    if (id === GROUP_ACTIONS.unassignVehicle) {
      this.handleDetachVehicle()
    }

    if (id === GROUP_ACTIONS.unassignAll) {
      this.handleUnassignAll()
    }
  }

  handleConfirmCloseClick = () => {
    this.setState({ confirm: null });
  }


  render() {
    const { cue, auth } = this.props
    const showTripCount = _.get(
      auth,
      'selectedFleet.generalSetting.showTripCount',
      false
    )

    return (
      <div className="content pt0 cue-container">
        {
          /* // Show total Booking for active tab */
          (this.state.selectedTab == 0 || this.state.selectedTab == 3) && showTripCount && (
            <div className="totalBooking">{`${cue.total || 0} booking(s)`}</div>
          )
        }
        {this.state.editTotal && this.renderEditTotalDialog()}
        <Tabs
          id="tab"
          defaultActiveKey={this.state.selectedTab || 0}
          animation={false}
          className="cue-tabs"
          onSelect={this.tabSelectHandle}
        >
          {this.props.commonData?.companies[0]?.supplierJobRequired
            && this.props.auth.user?.roles?.supplierPermission?.offers
          && <Tab
            eventKey={3}
            title={<Translate value="cue.Offer" />}
            className="cue_active"
          >
            {/* Cue Filter */}
            <div ref={(node) => (this.offerFilter = node)}>
              {this.renderFilter('offer')}
            </div>
            <div 
              className="gridViewTable"
              onKeyDown={this.handleKeyDownTable}
              onKeyUp={this.handleKeyUpTable}
            >
              {this.renderTable()}
            </div>
          </Tab>}
          {(!this.props.auth.user?.roles?.isSupplier || (this.props.auth.user?.roles?.isSupplier 
            // && this.props.commonData?.companies[0]?.supplierJobRequired
            && this.props.auth.user?.roles?.supplierPermission?.booking))
          && <Tab
            eventKey={0}
            title={<Translate value="cue.Active" />}
            className="cue_active"
          >
            {/* Cue Filter */}
            <div ref={(node) => (this.activeFilter = node)}>
              {this.renderFilter('active')}
            </div>
            <div 
              className="gridViewTable"
              onKeyDown={this.handleKeyDownTable}
              onKeyUp={this.handleKeyUpTable}
            >
              {this.renderTable()}
            </div>
          </Tab>}
          {(!this.props.auth.user?.roles?.isSupplier || (this.props.auth.user?.roles?.isSupplier 
            // && this.props.commonData?.companies[0]?.supplierJobRequired
            && this.props.auth.user?.roles?.supplierPermission?.bookingCompleted))
          &&<Tab eventKey={1} title={<Translate value="cue.Finished" />}>
            <div ref={(node) => (this.finishedFilter = node)}>
              {this.renderFilter('finished')}
            </div>
            <div className="gridViewTable">{this.renderTable()}</div>
          </Tab>}
        </Tabs>
        {this.renderGroupModal()}
        <div ref="scroll_bottom" />
        <Confirm
          confirm={this.state.confirm}
          handleConfirmButtonClick={this.handleConfirmButtonClick}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
      </div>
    )
  }
}
Cue.contextTypes = {
  notification: PropTypes.func,
  reloadCueAnhMap: PropTypes.func,
}
Cue.propTypes = {
  selectedFleet: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    cue: state.cue,
    commonData: state.commonData,
    selectedFleet: state.auth.selectedFleet,
    bookingDetail: state.bookingDetail,
    auth: state.auth,
    language: state.i18n,
    permissions: state.menuHandle.modulePermission,
    corporateCompany: state.corporateCompany,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    cueActions: bindActionCreators(cueActions, dispatch),
    bookingDetailActions: bindActionCreators(bookingDetailActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingActions: bindActionCreators(loadingActions, dispatch),
    corporateActions: bindActionCreators(corporateActions, dispatch),
    corporateCompanyActions: bindActionCreators(
      corporateCompanyActions,
      dispatch
    ),
    resetCueFilter: () => {
      const storageCueSort = parseFloat(localStorage.getItem('cue_sort') || 1)
      return dispatch({
        type: cueActions.CUE_UPDATE_FILLTER,
        data: {
          sort: { time: storageCueSort },
        },
      })
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Cue)