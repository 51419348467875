import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import currencyFormatter from 'currency-formatter';
import { Translate, I18n } from 'react-redux-i18n';
import {
  FormGroup,
  FormControl,
  Form,
  Button,
  Modal,
  ListGroupItem,
  Tab,
  Tabs,
  Col,
  Row,
} from 'react-bootstrap';
import { CountryDropdown } from 'react-country-region-selector';
import _ from 'lodash';
import {
  editCustomer,
  addCustomer,
  checkExistCustomerPhone,
  getEmergencyContactCustomer
} from '../../actions/customerAction';

import IntlTelInputApp from '../../components/intlTelInputCustome/IntlTelInputApp';
import CreditCard from '../../components/creditCard/CreditCard';
import FormGroupTitle from '../../components/formGroupTitile/FormGroupTitle';
import AddCard from './AddCard';
import { deleteCreditCard, addNewCreditCard } from '../../actions/creditCardActions';
import { getSecureFieldSessionTSYS, getCheckoutPage } from '../../actions/newbookingAction';
import { paymentGetwayDisabledAddNew, NAME_GATEWAY, NAME_GATEWAY_FORCE_NEW_TAB } from '../../constants/commondata';
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox';
import { Validator, ValidCase } from '../../components/validator';
import DateTime from '../../components/dateTime/DateTime';
import { CCLiteCommonFunc, Validation, trimPhoneNumber, removeEmojis, MultiSelectCommonFunc, getUrlLoadAddCardTSYS, handleResultAddCard, checkGateWayAddCardFromURL } from '../../utils/commonFunctions';
import calendarIcon from '../../assets/images/calendar.png';
import { getGateWays } from '../../actions/settingActions';
import RadioButton from '../../components/radioButton/RadioButton';
import IframeAddCard from '../../components/IframeAddCard';
import CustomerEmergencyContacts from './CustomerEmergencyContacts';
import CreditWallet from './components/CreditWallet';
import Point from './components/Point';
import WriteOffDebt from './components/WriteOffDebt';
import {
  socketDispatchApi
} from '../../utils/socketUtils';
import { socketConfigs } from '../../constants/socketConfigs';

const moment = require('moment');
require('react-intl-tel-input/dist/libphonenumber.js');
require('react-intl-tel-input/dist/main.css');

let credit;
let phoneNumber;

class AddEdit extends Component {
  constructor() {
    super();
    this.state = {
      isShow: false,
      tab: 1,
      isCrossZone: false,
      showConfirmDelete: false,
      passengerInfo: null,
      isSubmitted: false,
      valid: {},
      phoneExistValidation: true,
      phoneValidation: true,
      gateWay: {},
      isShowTSYSModal: false,
      urlIframeTSYS: '',
      profile: {
        address: {}
      },
      editable: false
    };
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleNoteChange = this.handleNoteChange.bind(this);
    this.handleVIPChange = this.handleVIPChange.bind(this);
    this.handleSaveCustomerClick = this.handleSaveCustomerClick.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleAddCardSuccess = this.handleAddCardSuccess.bind(this);
    this.handleCardTypeChange = this.handleCardTypeChange.bind(this);
    this.handleDeleteCardClick = this.handleDeleteCardClick.bind(this);
    this.deleteCreditCard = this.deleteCreditCard.bind(this);
    this.handleCancelDeleteCardClick = this.handleCancelDeleteCardClick.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleStreetAddressChange = this.handleStreetAddressChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleZipCodeChange = this.handleZipCodeChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleSelectCompanies = this.handleSelectCompanies.bind(this);
    this.handleDateOfBirthChanged = this.handleDateOfBirthChanged.bind(this);
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.isBirthDayValid = this.isBirthDayValid.bind(this);
    this.handleMapZoneChange = this.handleMapZoneChange.bind(this);
  }

  handleAddCardSuccess(credit) {
    const { credits } = this.state;
    credits.push(credit);
    this.setState({ credits });
  }

  handleCardTypeChange(e) {
    this.setState({
      isCrossZone: e.target.value == 'true'
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      isSubmitted: false,
      phoneExistValidation: true,
      phoneValidation: true,
      passengerInfo: {}
    });
    if (nextProps.detailItem) {
      if(nextProps.detailItem.isTSYS === false) this.state.tab = 1;
      this.state.phoneNumber = trimPhoneNumber(nextProps.detailItem.phone);
      this.state.selectedZone = !_.isEmpty(this.props.gatewayZones)
        ? this.props.gatewayZones[0].zoneId
        : null;
      if (this.props.auth.selectedFleet.creditConfig.multiGateway) {
        this.state.gateWay = !_.isEmpty(this.props.gatewayZones) ? this.props.gatewayZones[0] : {};
      } else {
        this.state.gateWay = this.props.auth.selectedFleet.creditConfig.configGateway;
      }
      if (
        nextProps.detailItem.profile
        && nextProps.detailItem.profile.pob
        && moment(nextProps.detailItem.profile.pob).isValid()
      ) {
        this.currentBod = 'validDate';
      }
      phoneNumber = trimPhoneNumber(nextProps.detailItem.phone);
      this.setState(nextProps.detailItem);
    }
  }

  handleChangeStatus(e) {
    // this.props.router.push({
    //   pathname: `/customer/edit/${this.state.userId}?action=edit`
    // });
    this.setState({ editable: true });
  }

  handleFirstNameChange(e) {
    const { capitalLetter } = this.props.auth.selectedFleet;
    let firstName = removeEmojis(e.target.value);
    if (capitalLetter && capitalLetter.name.cc) {
      firstName = firstName.toUpperCase()
    };
    this.setState({
      firstNameValidation: !!firstName,
      firstName
    });
  }

  handleLastNameChange(e) {
    const { capitalLetter } = this.props.auth.selectedFleet;
    let lastName = removeEmojis(e.target.value);
    if (capitalLetter && capitalLetter.name.cc) {
      lastName = lastName.toUpperCase()
    };
    this.setState({ lastName });
  }

  handleNationalIDChange = e => {
    this.setState({
      profile: { ...this.state.profile, idNumber: removeEmojis(e.target.value) }
    });
  };

  handlePhoneChange(status, value, countryData, number, id, isBlur) {
    if (isBlur) {
      if (!status) return;
      let newPhone;
      if (countryData && `+${countryData.dialCode}` === number) {
        newPhone = '';
      } else {
        newPhone = trimPhoneNumber(number);
      }
      if (newPhone !== phoneNumber) {
        this.props
          .checkExistCustomerPhone({
            fleetId: this.props.auth.selectedFleet.fleetId,
            phone: newPhone
          })
          .then(data => {
            if (data.ok) {
              this.setState({ phoneExistValidation: !data.res.existed });
            }
          });
      }
    } else {
      this.setState({
        phoneExistValidation: true,
        phoneValidation: status,
        phoneNumber: number
      });
    }
  }

  handleEmailChange(e) {
    const email = e.target.value;
    this.setState({
      emailValidation: Validation.validateEmail(email),
      email
    });
  }

  handleNoteChange(e) {
    this.setState({ passengerInfo: { notes: e.target.value } });
  }

  handleVIPChange(e) {
    this.setState({ rank: e.target.checked ? 1 : 0 });
  }

  handleTabChange(e) {
    this.setState({ tab: e });
  }

  handleGenderChange(e) {
    this.setState({
      profile: { ...this.state.profile, gender: e }
    });
  }

  isBirthDayValid(current) {
    return !current.isAfter(DateTime.moment());
  }

  handleDateOfBirthChanged(e) {
    if (e._d) {
      this.currentBod = 'validDate';
      this.setState({
        profile: {
          ...this.state.profile,
          dob: moment(e._d).format('MM/DD/YYYY')
        }
      });
    } else if (_.isString(e)) {
      this.currentBod = e;
    }
  }

  handleStreetAddressChange(e) {
    this.setState({
      profile: {
        ...this.state.profile,
        address: {
          ...this.state.profile.address,
          street: e.target.value
        }
      }
    });
  }

  handleCityChange(e) {
    this.setState({
      profile: {
        ...this.state.profile,
        address: {
          ...this.state.profile.address,
          city: e.target.value
        }
      }
    });
  }

  handleStateChange(e) {
    this.setState({
      profile: {
        ...this.state.profile,
        address: {
          ...this.state.profile.address,
          state: e.target.value
        }
      }
    });
  }

  handleZipCodeChange(e) {
    this.setState({
      profile: {
        ...this.state.profile,
        address: {
          ...this.state.profile.address,
          zipcode: e.target.value
        }
      }
    });
  }

  handleCountryChange(e) {
    this.setState({
      profile: {
        ...this.state.profile,
        address: {
          ...this.state.profile.address,
          country: e
        }
      }
    });
  }

  handleSelectCompanies(e) {
    const { companies } = this.props;
    let value = MultiSelectCommonFunc.getSelectValues(e);
    let passengerInfo = this.state.passengerInfo || {};
    let companiesMap = [];
    value.forEach(e => {
      [...companies, ...this.props.commonData.suppliers].forEach(c => {
        if (e === c._id) {
          companiesMap.push({
            "companyId": c._id,
            "name": c.name
          });
        }
      });
    })
    passengerInfo.companies = companiesMap;
    this.setState({
      passengerInfo
    });
  };


  handleSaveCustomerClick() {
    const { companies, roles } = this.props;
    if (this.state.registerFrom !== 'App') {
      this.setState({ isSubmitted: true });
      if (!CCLiteCommonFunc.isFormValid(this.state.valid)) return;
    }
    let dob = this.state.profile
      && this.state.profile.dob
      && moment(this.state.profile.dob).format('MM/DD/YYYY');
    if (this.currentBod != 'validDate') {
      dob = '';
    }
    const param = {
      firstName: this.state.firstName,
      lastName: this.state.lastName || '',
      phone: trimPhoneNumber(this.state.phoneNumber),
      note: this.state.passengerInfo.notes || '',
      gender:
        this.state.profile && this.state.profile.gender && parseInt(this.state.profile.gender),
      idNumber:
        !this.state.profile || !this.state.profile.idNumber ? '' : this.state.profile.idNumber,
      dob,
      street: this.state.profile && this.state.profile.address && this.state.profile.address.street,
      city: this.state.profile && this.state.profile.address && this.state.profile.address.city,
      state: this.state.profile && this.state.profile.address && this.state.profile.address.state,
      zipcode:
        this.state.profile && this.state.profile.address && this.state.profile.address.zipcode,
      country:
        this.state.profile && this.state.profile.address && this.state.profile.address.country,
      rank: this.state.rank || 0,
      fleetId: this.props.auth.selectedFleet.fleetId,
      companies: this.state.passengerInfo.companies || []
    };

    // IF User Operator Company, THEN Set default company via User Operator Company Login
    // if (roles.companyId && roles.companyId !== '') {
    //   let company = [...companies].find(c => c._id === roles.companyId);
    //   if (company && company._id) {
    //     param.companies.push({
    //       "companyId": company._id,
    //       "name": company.name
    //     });
    //   };
    // };

    if (this.state.email) {
      param.email = this.state.email;
    }
    if (this.state.isAdd) {
      this.props.addCustomer(param).then(data => {
        if (data.ok) {
          this.context.notification('success', I18n.t('customer.Add_customer_success'));
          this.props.closeDialog();
          this.props.onSuccess(true, data?.res);
          this.setState({ isSubmitted: false });
        } else if (data.error) {
          this.context.notification('error', I18n.t(`errors.${data.error.errorCode}`));
        } else {
          this.context.notification('error', I18n.t('mDispatcher.Create_customer_fail'));
        }
      });
    } else {
      param.userId = this.state.userId;
      param.registerFrom = this.state.registerFrom;
      this.props.editCustomer(param).then(data => {
        if (data.ok) {
          this.context.notification('success', I18n.t('customer.Update_customer_success'));
          this.props.closeDialog();
          this.props.onSuccess();
          this.setState({ isSubmitted: false });
        } else if (data.error) {
          this.context.notification('error', I18n.t(`errors.${data.error.errorCode}`));
        } else {
          this.context.notification('error', I18n.t('mDispatcher.Update_customer_fail'));
        }
      });
    }
  }

  deleteCreditCard() {
    const deleteCredit = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      userId: this.state.userId,
      gateway: credit.gateway,
      localToken: credit.localToken,
      crossToken: credit.crossToken
    };
    this.props.deleteCreditCard(deleteCredit).then(data => {
      if (data.res.returnCode == 200) {
        const remainCredits = this.state.credits;
        const index = remainCredits.indexOf(credit);
        remainCredits.splice(index, 1);
        this.setState({ showConfirmDelete: false, credits: remainCredits });
      } else {
        this.context.notification('error', I18n.t(`errors.${data.error.errorCode}`));
      }
    });
  }

  handleDeleteCardClick(cre) {
    credit = cre;
    this.setState({ showConfirmDelete: true });
  }

  handleCancelDeleteCardClick() {
    this.setState({ showConfirmDelete: false });
  }

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  handleMapZoneChange(e) {
    const gateWay = _.find(this.props.gatewayZones, gatewayZone => gatewayZone.zoneId === e.target.value);
    this.setState({
      selectedZone: e.target.value,
      gateWay
    });
  }

  handleWriteOffDebtSuccess = () => {
    this.setState({ outStanding: [] });
    this.props.onSuccess();
  };

  closeModalTSYS = () => {
    this.props.processViewCustomer(this.state.userId, true)
    if(this.state.isShowTSYSModal) this.setState({isShowTSYSModal: false})
    // reloadCustomerTSYSGateway(currentUser.phone)
  }

  
  handleLoadSessionTSYS = () => {
    const {
      auth
    } = this.props;
    this.props.getSecureFieldSessionTSYS({
      fleetId: auth && auth.selectedFleet && auth.selectedFleet.fleetId || '',
      userId: this.state.userId
    }).then((data) => {
      let { res = {} } = data
      if (res.returnCode === 200) {
        let response = res.response || {}
        let { secureID = '', sessionId = '', showAddress = false } = response
        this.setState({
          urlIframe: getUrlLoadAddCardTSYS(secureID, sessionId, showAddress),
          isShowIframeAddCardModal: true
        })
      } else if (res.returnCode) {
        this.context.notification('error', I18n.t(`messages.credits.${res.returnCode}`));
      }
    }) 
  }
  
  closeModalIframeAddCard = () => {
    this.props.processViewCustomer(this.state.userId, true)
    if(this.state.isShowIframeAddCardModal) this.setState({isShowIframeAddCardModal: false})
  }

  handleLoadURLAddCardPayWay = () => {
    const request_params = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      userId: this.state.userId
    };
    this.props.addNewCreditCard(request_params)
    .then((result) => {
      const { res = {} } = result
      if (res.returnCode === 200 && res.response?.['3ds_url']) {
        const nameGateway = _.get(this.state.gateWay, 'gateway', '');
        if(NAME_GATEWAY_FORCE_NEW_TAB.includes(nameGateway)) {
          handleResultAddCard(res.response?.['3ds_url'], this.handleAddCardSuccess)
        } else {
          this.setState({
            urlIframe: res.response?.['3ds_url'],
            isShowIframeAddCardModal: true
          })
        }
      } else if (res.returnCode) {
        this.context.notification('error', I18n.t(`messages.credits.${res.returnCode}`));
      }
    })
    .catch(err => {
      this.context.notification('error', 'Catch Error');
    })
  }

  renderAccount = () => {
    const { profile, phoneNumber, passengerInfo } = this.state;
    const { detailItem, auth, companies, roles } = this.props;

    let outStanding = (this.state.outStanding || []).filter(o => o.amount > 0);
    let hasDebt = true;
    if (!outStanding.length) {
      hasDebt = false;
      const currencyDefault = _.get(auth, 'selectedFleet.currencies[0]');
      outStanding = [
        {
          amount: 0,
          currencyISO: currencyDefault.iso
        }
      ];
    }
    const textOutStanding = outStanding
      .map(o => currencyFormatter.format(o.amount, {
        code: o.currencyISO
      }))
      .join(', ');

    const phoneNumberDisplay = phoneNumber === detailItem.userId ? "" : phoneNumber;
    let companyIds = [];
    if (passengerInfo.companies) {
      passengerInfo.companies.forEach(c => {
        companyIds.push(c.companyId);
      });
    };

    return (
      <div className="form-detail-container">
        <Modal show={this.state.showConfirmDelete} backdrop className="confirm" onHide={this.handleCancelDeleteCardClick}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="customer.Card_holder" />
              <Translate value="customer.Delete" />
            </Modal.Title>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.handleCancelDeleteCardClick}
            >
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Translate value="customer.Card_holder" />
            <Translate value="customer.CONFIRM_DELETE_CARD" />
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-save mr-md" onClick={this.deleteCreditCard}>
              <Translate value="customer.Yes" />
            </Button>
            <Button className="btn-cancel" onClick={this.handleCancelDeleteCardClick}>
              <Translate value="customer.No" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col xs={12} sm={6}>
            <FormGroup
              className={
                !this.state.isSubmitted
                  ? null
                  : this.state.valid.firstName === false
                    ? 'error'
                    : null
              }
            >
              <Form.Label>
                <Translate value="customer.First_name" />
                <span className="require"> *</span>
              </Form.Label>
              <FormControl
                className="form-custom"
                type="text"
                value={this.state.firstName ? this.state.firstName : ''}
                onChange={this.handleFirstNameChange}
                disabled={this.state.registerFrom === 'App' || !this.state.editable}
                autoFocus
              />
              <Validator id="firstName" callback={this.ValidatorCallback}>
                <ValidCase
                  hide={!this.state.isSubmitted}
                  valid={!Validation.isStringEmpty(this.state.firstName)}
                  message={I18n.t('messages.commonMessages.Required_field')}
                />
              </Validator>
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup
              className={
                !this.state.isSubmitted
                  ? null
                  : this.state.valid.lastName === false
                    ? 'error'
                    : null
              }
            >
              <Form.Label>
                <Translate value="customer.Last_name" />
              </Form.Label>
              <FormControl
                className="form-custom"
                type="text"
                value={this.state.lastName}
                onChange={this.handleLastNameChange}
                disabled={this.state.registerFrom === 'App' || !this.state.editable}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup
              className={
                !this.state.isSubmitted ? null : this.state.valid.phoneNumber ? null : 'error'
              }
            >
              <Form.Label>
                <Translate value="customer.Phone_number" />
                <span className="require"> *</span>
              </Form.Label>
              <IntlTelInputApp
                css={['intl-tel-input', 'form-control']}
                utilsScript="libphonenumber.js"
                value={phoneNumberDisplay}
                onPhoneNumberBlur={(status, value, countryData, number, id) => {
                  this.handlePhoneChange(status, value, countryData, number, id, true);
                }}
                onPhoneNumberChange={(status, value, countryData, number, id) => {
                  this.handlePhoneChange(status, value, countryData, number, id, false);
                }}
                disabled={this.state.registerFrom === 'App' || !this.state.editable}
              />
              <Validator id="phoneNumber" callback={this.ValidatorCallback}>
                <ValidCase
                  hide={!this.state.isSubmitted}
                  valid={!Validation.isStringEmpty(this.state.phoneNumber)}
                  message={I18n.t('messages.commonMessages.Required_field')}
                />
                <ValidCase
                  hide={!this.state.isSubmitted}
                  valid={this.state.phoneValidation}
                  message={I18n.t('customer.ERROR_INPUT_VALIDPHONE')}
                />
                <ValidCase
                  hide={!this.state.isSubmitted}
                  valid={this.state.phoneExistValidation}
                  message={I18n.t('customer.ERROR_EXISTED_PHONE')}
                />
              </Validator>
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup
              className={
                !this.state.isSubmitted ? null : this.state.valid.email ? null : 'error'
              }
            >
              <Form.Label>
                <Translate value="customer.Email_address" />
              </Form.Label>
              <FormControl
                className="form-custom"
                type="email"
                value={this.state.email}
                onChange={this.handleEmailChange}
                disabled={this.state.registerFrom === 'App' || !this.state.editable}
              />
              <Validator id="email" callback={this.ValidatorCallback}>
                <ValidCase
                  hide={!this.state.isSubmitted}
                  valid={Validation.validateEmail(this.state.email)}
                  message={I18n.t('messages.commonMessages.invalid_email_format')}
                />
              </Validator>
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup>
              <Form.Label>
                <Translate value="customer.Customer_ID" />
              </Form.Label>
              <FormControl
                className="form-custom"
                type="text"
                value={profile && profile.idNumber}
                onChange={this.handleNationalIDChange}
                disabled={!this.state.editable}
              />
              {profile && profile.idNumber && detailItem.nationalIcImg ? (
                <div>
                  <a target="_blank" href={detailItem.nationalIcImg} className="text-active">
                    <Translate value="driver.Click_here_to_download" />
                  </a>
                </div>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup>
              <Form.Label>
                <Translate value="customer.Gender" />
              </Form.Label>
              <FormControl
                className="form-custom"
                as="select"
                value={
                  this.state.profile && this.state.profile.gender >= 0
                    ? this.state.profile.gender
                    : -1
                }
                disabled={!this.state.editable}
                onChange={e => {
                  this.handleGenderChange(e.target.value);
                }}
              >
                <option key={-1} value={-1}>
                  {I18n.t('customer.Select_Gender')}
                </option>
                <option key={0} value={0}>
                  {I18n.t('customer.Male')}
                </option>
                <option key={1} value={1}>
                  {I18n.t('customer.Female')}
                </option>
              </FormControl>
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup>
              <Form.Label>
                <Translate value="customer.Date_of_birth" />
              </Form.Label>
              <DateTime
                isValidDate={this.isBirthDayValid}
                inputProps={{
                  disabled: !this.state.editable,
                  className: this.state.editable
                    ? 'form-control date'
                    : 'date-readonly form-control date'
                }}
                value={
                  this.state.profile && this.state.profile.dob && moment(this.state.profile.dob)
                }
                timeFormat={false}
                onChange={this.handleDateOfBirthChanged}
                closeOnSelect
              />
            </FormGroup>
          </Col>
          <Col xs={12} />
          <Col xs={12} sm={6}>
            <FormGroup>
              <Form.Label>
                <Translate value="customer.Address" />
              </Form.Label>
              <FormControl
                className="form-custom"
                type="text"
                value={
                  this.state.profile
                  && this.state.profile.address
                  && this.state.profile.address.street
                }
                onChange={this.handleStreetAddressChange}
                placeholder={I18n.t('customer.Street_address')}
                disabled={!this.state.editable}
                maxLength={200}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup>
              <Form.Label className="invisible-control-label">
                <Translate value="customer.City" />
              </Form.Label>
              <FormControl
                className="form-custom"
                type="text"
                value={
                  this.state.profile
                  && this.state.profile.address
                  && this.state.profile.address.city
                }
                onChange={this.handleCityChange}
                placeholder={I18n.t('customer.City')}
                disabled={!this.state.editable}
                maxLength={50}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup>
              <FormControl
                className="form-custom"
                type="text"
                value={
                  this.state.profile
                  && this.state.profile.address
                  && this.state.profile.address.state
                }
                onChange={this.handleStateChange}
                placeholder={I18n.t('customer.State')}
                disabled={!this.state.editable}
                maxLength={50}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup>
              <FormControl
                className="form-custom"
                type="text"
                value={
                  this.state.profile
                  && this.state.profile.address
                  && this.state.profile.address.zipcode
                }
                onChange={this.handleZipCodeChange}
                placeholder={I18n.t('customer.Zip_code')}
                disabled={!this.state.editable}
                maxLength={20}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup>
              <CountryDropdown
                value={
                  this.state.profile
                  && this.state.profile.address
                  && this.state.profile.address.country
                }
                onChange={this.handleCountryChange}
                disabled={!this.state.editable}
                classes="form-control form-custom"
                defaultOptionLabel={I18n.t('customer.Select_Country')}
              />
            </FormGroup>
          </Col>

          <Col xs={12}>
            <Row className="align-items-center">
              <Col xs={12} sm={6}>
                <FormGroup>
                  <div className="display-flex justify-content-between mb">
                    <Translate value="customer.Outstanding_amount" />
                    {hasDebt ? (
                      <WriteOffDebt
                        customer={detailItem}
                        onWriteOffDebtSuccess={this.handleWriteOffDebtSuccess}
                      />
                    ) : null}
                    {/* <a onClick={this.writeOffDebt} href="javascript:void(0);" className="text-active">
                      <Translate className="cursor-pointer" value="customer.WriteOffDebt" />
                    </a> */}
                  </div>
                  <FormControl
                    className="form-custom"
                    type="text"
                    value={textOutStanding}
                    onChange={this.handleNoteChange}
                    placeholder=""
                    disabled
                  />
                </FormGroup>
                {auth.selectedFleet.referral.paxReferPax ? (
                  <FormGroup>
                    <div className="display-flex justify-content-between mb">
                      <Translate value="customer.referral_code" />
                    </div>
                    <FormControl
                      className="form-custom"
                      type="text"
                      value={this.state.referralCode}
                      placeholder=""
                      disabled
                    />
                  </FormGroup>
                ) : null}
              </Col>

              {/* {!roles.companyId && */}
                <Col xs={12} sm={6}>
                  <FormGroup
                    controlId="formControlsSelectMultiple"
                  >
                    <Form.Label>
                      <Translate value="customer.companies" />{' '}
                    </Form.Label>
                    <FormControl
                      className="form-custom select-companies"
                      as="select"
                      multiple
                      onChange={this.handleSelectCompanies}
                      value={companyIds}
                      disabled={!this.state.editable}
                    >
                      {[...companies, ...this.props.commonData.suppliers].map(c => {
                        return (
                          <option key={c._id} value={c._id}>
                            {c.name}
                          </option>
                        );
                      })}
                    </FormControl>
                  </FormGroup>
                </Col>
                {/* } */}

            </Row>
          </Col>

          <Col xs={12}>
            <Row className="align-items-center">
              <Col xs={12} sm={6}>
                <FormGroup>
                  <Form.Label>
                    <Translate value="customer.Notes" />
                  </Form.Label>
                  <FormControl
                    className="form-custom"
                    type="text"
                    value={this.state.passengerInfo.notes}
                    onChange={this.handleNoteChange}
                    placeholder=""
                    disabled={!this.state.editable}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <CcCheckbox
                  checked={this.state.rank == 1}
                  onChange={this.handleVIPChange}
                  disabled={!this.state.editable}
                  text={I18n.t('customer.Vip')}
                  className="mb0"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  loadFormAddCardPaymaya = () => {
    const { auth } = this.props
    this.props.getCheckoutPage({
      fleetId: auth && auth.selectedFleet && auth.selectedFleet.fleetId || '',
      userId: this.state.userId,
      corporateId: "",
      type: 'customer'
    }).then((data) => {
      let { res = {} } = data
      if (res.returnCode === 200) {
        let response = res.response || {}
        let url = response['3ds_url'] || ''
        if(url) {
          window.open(url, '_blank').focus();
          socketDispatchApi.remove(socketConfigs.receive.registeredCard);
          socketDispatchApi.on(socketConfigs.receive.registeredCard, data => {
            if(data && data.returnCode === 200) {
              this.handleAddCardSuccess(data.credit);
            } else {
              this.context.notification('error', I18n.t(`messages.credits.${res.returnCode}`));
            }
          });
        }
      } else if (res.returnCode) {
        this.context.notification('error', I18n.t(`messages.credits.${res.returnCode}`));
      }
    })
  }

  render() {
    const { gatewayZones, auth, detailItem } = this.props;
    const {
      selectedZone, gateWay, credits, isCrossZone
    } = this.state;
    const supportWeb = _.get(gateWay, 'supportWeb', false);
    const nameGateway = _.get(gateWay, 'gateway', '');
    const canUpdate = !this.props.permissions || this.props.permissions.actions;
    const canViewWallet = !this.props.permissions || this.props.permissions.totalbalance;
    const canTopupWallet = canViewWallet && this.props.permissions.topuppaxwallet;
    const currentPoint = _.get(detailItem, 'loyalty.points.available', 0) 
    if (this.state.isShow) {
      return (
        <Modal
          show
          onHide={this.props.closeDialog}
          backdrop="static"
          aria-labelledby="contained-modal-title-sm"
          dialogClassName={this.state.isAdd ? 'card-dialog' : 'modal-width-tabs card-dialog'}
        >
          <Modal.Header className="text-center pd-t-30 pd-b-30" closeButton>
            <Modal.Title>
              {this.state.editable ? (
                this.state.isAdd ? (
                  <Translate value="customer.ADD_CUSTOMER" />
                ) : (
                  <Translate value="customer.EDIT_CUSTOMER" />
                )
              ) : (
                <Translate value="customer.DETAIL_CUSTOMER" />
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="clearfix">
            {this.state.isAdd ? (
              this.renderAccount()
            ) : (
              <Tabs
                defaultActiveKey={1}
                animation
                onSelect={this.handleTabChange}
                id="controlled-tab"
              >
                <Tab eventKey={1} title={I18n.t('customer.Account')}>
                  {this.renderAccount()}
                </Tab>
                {auth.selectedFleet.creditConfig.enable ? (
                  <Tab eventKey={2} title={I18n.t('customer.Card_Management')}>
                    <Row className="customer-card">
                      <Col xs={12} sm={6}>
                        <FormGroupTitle className="pt0">
                          {I18n.t('customer.SHOW_PERSONAL_CARD')}
                        </FormGroupTitle>
                        <div>
                          {this.props.auth.selectedFleet.affiliate
                            && this.props.auth.selectedFleet.affiliate.dispatching && (
                              <FormGroup>
                                <FormControl
                                  as="select"
                                  className="card-type form-custom"
                                  placeholder="select"
                                  value={this.state.isCrossZone}
                                  onChange={this.handleCardTypeChange}
                                >
                                  <option key={1} value={false}>
                                    {I18n.t('customer.Home')}
                                  </option>
                                  <option key={0} value>
                                    {I18n.t('customer.Affiliate')}
                                  </option>
                                </FormControl>
                              </FormGroup>
                            )}
                          {auth.selectedFleet.creditConfig.multiGateway && (
                            <FormGroup>
                              <FormControl
                                as="select"
                                className="card-type form-custom"
                                placeholder="select"
                                value={selectedZone}
                                onChange={this.handleMapZoneChange}
                              >
                                {_.map(gatewayZones, zone => (
                                  <option key={zone.zoneId} value={zone.zoneId}>
                                    {zone.zoneName}
                                  </option>
                                ))}
                              </FormControl>
                            </FormGroup>
                          )}
                        </div>
                        {_.filter(credits, credit => {
                          if (auth.selectedFleet.creditConfig.multiGateway) {
                            return (
                              isCrossZone == !!credit.crossToken
                              && (!_.isEmpty(gateWay) && credit.gateway === gateWay.gateway)
                            );
                          }
                          return isCrossZone == !!credit.crossToken;
                        }).length > 0 ? (
                          _.map(
                            _.filter(credits, credit => {
                              if (auth.selectedFleet.creditConfig.multiGateway) {
                                return (
                                  isCrossZone == !!credit.crossToken
                                  && (!_.isEmpty(gateWay) && credit.gateway === gateWay.gateway)
                                );
                              }
                              return isCrossZone == !!credit.crossToken && (credit.gateway === gateWay.gateway);
                            }),
                            data => (
                              <CreditCard
                                handleDeleteCardClick={this.handleDeleteCardClick}
                                data={data}
                                cardName={I18n.t('customer.Personal_card')}
                                onlyView={!canUpdate}
                              />
                            )
                          )
                        ) : (
                          <ListGroupItem variant="warning">
                            <Translate value="customer.No_card" />
                          </ListGroupItem>
                        )}
                      </Col>
                      <Col className="2323" xs={12} sm={6}>
                        {canUpdate ? (
                          ((auth.selectedFleet.creditConfig.multiGateway
                            && !_.isEmpty(gateWay)
                            && !paymentGetwayDisabledAddNew.includes(gateWay.gateway))
                            || (!auth.selectedFleet.creditConfig.multiGateway
                              && !paymentGetwayDisabledAddNew.includes(
                                auth.selectedFleet.creditConfig.configGateway.gateway
                              )))
                            && supportWeb ? (
                              nameGateway === 'TSYS' ? (
                                <>
                                  <Button className="btn-save mr-md" onClick={this.handleLoadSessionTSYS}>
                                    Add New
                                  </Button>
                                  <IframeAddCard 
                                    isShowModal={this.state.isShowIframeAddCardModal}
                                    urlIframe={this.state.urlIframe}
                                    closeModal={this.closeModalIframeAddCard}
                                  />
                                </>
                              ) : checkGateWayAddCardFromURL(nameGateway) ? (
                                <>
                                  <Button className="btn-save mr-md" onClick={this.handleLoadURLAddCardPayWay}>
                                    Add New
                                  </Button>
                                  <IframeAddCard 
                                    isShowModal={this.state.isShowIframeAddCardModal}
                                    urlIframe={this.state.urlIframe}
                                    closeModal={this.closeModalIframeAddCard}
                                  />
                                </>
                              ) : nameGateway === NAME_GATEWAY.Paymaya ? (
                                  <Button className="btn-save mr-md" onClick={this.loadFormAddCardPaymaya}>
                                    Add New
                                  </Button>
                                ) : (
                                  <AddCard
                                    userId={this.state.userId}
                                    isCrossZone={this.state.isCrossZone}
                                    handleAddCardSuccess={this.handleAddCardSuccess}
                                    title={I18n.t('customer.ADD_PERSONAL_CARD')}
                                    addCardWebservice={this.props.addNewCreditCard}
                                    currentUser={this.state}
                                    gateWay={this.state.gateWay}
                                    zoneId={
                                      auth.selectedFleet.creditConfig.multiGateway
                                        ? _.isEmpty(gateWay)
                                          ? null
                                          : gateWay.zoneId
                                        : null
                                    }
                                    isCustomer
                                  />
                                )
                          ) : (
                            <ListGroupItem variant="warning">
                              <Translate value="customer.No_Support" />
                            </ListGroupItem>
                          )
                        ) : null}
                      </Col>
                    </Row>
                  </Tab>
                ) : null}
                {this.props.auth.selectedFleet.SOS
                  && this.props.auth.selectedFleet.SOS.passenger ? (
                  <Tab eventKey={3} title={I18n.t('customer.Emergency_contacts')}>
                    <CustomerEmergencyContacts
                      userId={this.state.userId}
                      getEmergencyContactCustomer={this.props.getEmergencyContactCustomer}
                      closeDialog={this.props.closeDialog}
                      selectedFleet={this.props.auth.selectedFleet}
                    />
                  </Tab>
                ) : null}
                {this.props.auth.selectedFleet.paxCreditWallet
                  && this.props.detailItem.paxWallet
                  // && this.props.detailItem.paxWallet.length
                  && canViewWallet
                  ? (
                    <Tab eventKey={4} title={I18n.t('driver.total_balance')}>
                      <CreditWallet
                        userId={this.state.userId}
                        paxWallet={this.props.detailItem.paxWallet}
                        closeDialog={this.props.closeDialog}
                        canTopupWallet={canTopupWallet}
                        handleChangeBalance={this.props.handleChangeBalance}
                        currencies={auth.selectedFleet.currencies}
                      />
                    </Tab>
                  ) : null}
                  {this.props.auth.selectedFleet.moduleSettings.point
                  ? (
                    <Tab eventKey={5} title={"Points"}>
                      <Point
                        userId={this.state.userId}
                        currentPoint={currentPoint}
                        closeDialog={this.props.closeDialog}
                        canUpdatePoint={canTopupWallet}
                        handleChangePoint={this.props.handleChangePoint}
                      />
                    </Tab>
                  ) : null}
              </Tabs>
            )}
          </Modal.Body>
          {this.state.tab == 1 || this.state.isAdd ? (
            <Modal.Footer className="pd-b-30">
              <div className="fill text-center">
                {canUpdate && this.state.registerFrom !== 'App' ? (
                  this.state.editable && this.state.registerFrom !== 'App' ? (
                    <Button className="btn-save mr-md" onClick={this.handleSaveCustomerClick}>
                      <Translate value="customer.Save" />
                    </Button>
                  ) : (
                    <Button
                      className="btn-edit mr-md"
                      onClick={e => {
                        this.handleChangeStatus(e);
                      }}
                    >
                      <Translate value="customer.Edit" />
                    </Button>
                  )
                ) : null}
                {canUpdate && this.state.registerFrom === 'App' ? (
                  (this.state.lastLogin.androidVersion !== 'N/A'
                    || this.state.lastLogin.iOSVersion !== 'N/A')
                    && !this.state.editable ? (
                    <Button
                      className="btn-edit mr-md"
                      onClick={e => {
                        this.handleChangeStatus(e);
                      }}
                    >
                      <Translate value="customer.Edit" />
                    </Button>
                  ) : (
                    <Button className="btn-save mr-md" onClick={this.handleSaveCustomerClick}>
                      <Translate value="customer.Save" />
                    </Button>
                  )
                ) : null}
                <Button className="btn-cancel ml0" onClick={this.props.closeDialog}>
                  <Translate value="customer.Cancel" />
                </Button>
              </div>
            </Modal.Footer>
          ) : null}
        </Modal>
      );
    }
    return null;
  }
}

AddEdit.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    roles: state.auth.user.roles,
    permissions: state.menuHandle.modulePermission,
    gatewayZones: state.commonData.gatewayZones,
    companies: state.commonData.companies,
    commonData: state.commonData,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    editCustomer: options => dispatch(editCustomer(options)),
    addCustomer: options => dispatch(addCustomer(options)),
    addNewCreditCard: options => dispatch(addNewCreditCard(options)),
    deleteCreditCard: options => dispatch(deleteCreditCard(options)),
    checkExistCustomerPhone: options => dispatch(checkExistCustomerPhone(options)),
    getSecureFieldSessionTSYS: options => dispatch(getSecureFieldSessionTSYS(options)),
    getCheckoutPage: options => dispatch(getCheckoutPage(options)),
    getGateWays: options => dispatch(getGateWays(options)),
    getEmergencyContactCustomer: options => dispatch(getEmergencyContactCustomer(options))
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEdit);
