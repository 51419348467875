import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
// import '../../containers/settings.scss';
import {
  FormGroup,
  FormControl,
  Form,
  Button,
  Modal,
} from 'react-bootstrap';

import { bindActionCreators } from 'redux';
import { Validator, ValidCase } from '../validator';
import { CCLiteCommonFunc, Validation } from '../../utils/commonFunctions';
import { MdAutoFixHigh } from 'react-icons/md';
import {
  ContentInputLabelContainer,
  ContentInput,
  MirrorArea,
  ContentInputFormGroup,
} from './style';
import Suggestion from './Suggestion';

const SuggestContent = (props) => {
  const {
    //state
    isSubmitted,
    editable,
    value,
    //function
    validatorCallback,
    handleSetStateContentChange,

    //another
    label,
    placeholder,
    TRIGGER_CHARACTER,
    AUTOFILL_ITEMS,
    SUGGESTION_WIDTH,
    require
  } = props;

  const suggestionTriggerRef = React.createRef();
  const textareaRef = React.createRef();
  const mirrorRef = React.createRef();
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [suggestionItems, setSuggestionItems] = useState(...AUTOFILL_ITEMS || [])
  const [suggestionAnchor, setSuggestionAnchor] = useState({})
  const [suggestionAutoDetect, setSuggestionAutoDetect] = useState(false)
  const  newCursorPositionRef = React.createRef();

  const getSuggestionAnchor = (trigger) => {
    const rect = trigger.getBoundingClientRect();
    const { x, y, height } = rect;
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const anchor = {
      right: windowWidth - x + 5,
      bottom: windowHeight - y - height + 5,
      maxHeight: y - 20,
      maxWidth: x - 20,
    };

    return anchor;
  };

  const handleSetStateClickSuggest = (anchor) => {
    setShowSuggestions((prev) => !prev.showSuggestions)
    setSuggestionAnchor(anchor)
    setSuggestionAutoDetect(false)
    setSuggestionItems((prev) => !prev.showSuggestions
    ? [...AUTOFILL_ITEMS]
    : prev.suggestionItems)
  }

  const handleSetStateCloseSuggest = () => {
    setShowSuggestions(false)
  }

  const handleSetStateSelectSuggest = () => {
    setShowSuggestions(false)
  }

  const handleClickSuggestionIcon = (event) => {
    event.stopPropagation();
    const anchor = getSuggestionAnchor(suggestionTriggerRef.current);
    handleSetStateClickSuggest(anchor);
  };

  const handleCloseSuggestion = (event) => {
    if (suggestionTriggerRef.current.contains(event && event.target)) {
      return;
    }
    handleSetStateCloseSuggest();
  };

  const handleSelectSuggestionItem = (data) => {
    const value = data.value;
    const newText = value;
    const textarea = textareaRef.current;

    // Get the current selection/cursor position
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    let newValue, newCursorPosition;

    if (suggestionAutoDetect) {
      const leftPart = textarea.value.substring(0, start);
      const replacedText =
        TRIGGER_CHARACTER + leftPart.split(TRIGGER_CHARACTER).pop();
      const newStartPosition = start - replacedText.length;
      newValue =
        textarea.value.substring(0, newStartPosition) +
        newText +
        textarea.value.substring(end);
      newCursorPosition = newStartPosition + newText.length;
    } else {
      newValue =
        textarea.value.substring(0, start) +
        newText +
        textarea.value.substring(end);
      newCursorPosition = start + newText.length;
    }
    newCursorPositionRef.current = newCursorPosition;
    handleSetStateSelectSuggest();
    handleSetStateContentChange(newValue);
  };

  const handleContentChange = (event) => {
    const { value } = event.target;
    const cursorPosition = event.target.selectionStart;
    const leftPart = value.substring(0, cursorPosition);
    const strs = leftPart.split(TRIGGER_CHARACTER);
    const newStates = {
      showSuggestions: false,
      suggestionItems: [],
      suggestionAnchor: {},
      suggestionAutoDetect: false,
    };

    if (strs.length > 1) {
      const typingCharacters = strs.pop();
      const filteredItems = typingCharacters.length
        ? AUTOFILL_ITEMS.filter((item) =>
            item.value.includes(typingCharacters.toUpperCase())
          )
        : AUTOFILL_ITEMS;

      if (filteredItems.length) {
        const textareaRect = event.target.getBoundingClientRect();
        const mirrorDiv = mirrorRef.current.querySelector('div');
        mirrorDiv.innerHTML = leftPart;
        const mirrorRect = mirrorDiv.getBoundingClientRect();
        const textHeight = Math.min(
          mirrorRect.height - event.target.scrollTop,
          textareaRect.height
        );
        const lineHeight = 20;
        const cursorY = textareaRect.top + textHeight - lineHeight;
        const cursorX = textareaRect.left;
        newStates.suggestionItems = [...filteredItems];
        newStates.showSuggestions = true;
        newStates.suggestionAutoDetect = true;
        newStates.suggestionAnchor = {
          bottom: window.innerHeight - cursorY + 5,
          left:
            cursorX + SUGGESTION_WIDTH > window.innerWidth
              ? window.innerWidth - SUGGESTION_WIDTH
              : cursorX,
          maxHeight: cursorY - 40,
        };
      }
    }
    setShowSuggestions(newStates.showSuggestions)
    setSuggestionAnchor(newStates.suggestionAnchor)
    setSuggestionAutoDetect(newStates.suggestionAutoDetect)
    setSuggestionItems(newStates.suggestionItems)
    handleSetStateContentChange(value);
  };
  
  useEffect(() => {
    if(newCursorPositionRef && newCursorPositionRef.current) {
        textareaRef.current.focus();
        textareaRef.current.setSelectionRange(newCursorPositionRef.current.value, newCursorPositionRef.current.value);
    }
  },[newCursorPositionRef])

  return (
    <>
      <ContentInputFormGroup
        className={require === true && (!isSubmitted ? null : !Validation.isStringEmpty(value) ? null : 'error')}
      >
        <MirrorArea
          ref={mirrorRef}
          dangerouslySetInnerHTML={{ __html: '<div></div>' }}
        />
        <ContentInputLabelContainer>
          <Form.Label>
            <Translate value={label} />
            {require === true && <span className="require">*</span>}
          </Form.Label>
          <div ref={suggestionTriggerRef}>
            {editable && (
              <MdAutoFixHigh size={18} onClick={handleClickSuggestionIcon} />
            )}
          </div>
        </ContentInputLabelContainer>
        <ContentInputLabelContainer>
          <Form.Label>
            <spam style={{fontStyle: 'italic', marginLeft: '10px'}}>Select the magic wand or type {`[`} to instantly access booking keywords suggestions.</spam>
          </Form.Label>
        </ContentInputLabelContainer>
        
        <ContentInput
          type="text"
          className={'form-custom'}
          onChange={handleContentChange}
          as="textarea"
          value={value}
          placeholder={I18n.t(placeholder)}
          disabled={!editable}
          ref={textareaRef}
        />
       {require === true && <Validator id="content" callback={validatorCallback}>
          <ValidCase
            hide={!isSubmitted}
            valid={!Validation.isStringEmpty(value)}
            message={I18n.t('messages.commonMessages.Required_field')}
          />
        </Validator>}
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'end',
          }}
        >
          <span style={{ fontSize: '12px', opacity: 0.8 }}>
            {value.length}
          </span>
        </div>
      </ContentInputFormGroup>

      {showSuggestions && (
        <Suggestion
          items={suggestionItems}
          onSelect={handleSelectSuggestionItem}
          anchor={suggestionAnchor}
          onClose={handleCloseSuggestion}
        />
      )}
    </>
  );
};

export default SuggestContent;
