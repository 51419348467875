import React, { PureComponent } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import _ from 'lodash';
import { STATUS_BOOKING } from '../../../constants/commondata';
import CcCheckbox from '../../ccCheckbox/CcCheckbox';

class UpdateConfirmationModal extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      showConfirmUpdate,
      showWarningResetEditFare,
      updateBookingButtonClick,
      updateInfoBookingClick,
      updateInfoAndDispatchBookingClick,
      hideUpdateInfoAndDispatch,
      handleNotifyCustomerViaEmailChange,
      notifyCustomerViaEmail,
      data,
      propsData,
      user,
      userType,
      listGroup = [],
      isHydraBooking,
      farmOut,
      selectedFleet,
      isDelivery
    } = this.props;
    const propsDriver = _.get(propsData, 'drvInfo.phone');
    const stateDriver = _.get(data, 'drvInfo.phone');
    const bookStatus = _.get(data, 'status'),
      isHideUpdateAndDispatch =
        data.request.pickUpTime !== 'Now' &&
        (data.dispatchType !== 0 ||
          (data.dispatchType === 0 &&
            ![
              STATUS_BOOKING.pending,
              STATUS_BOOKING.pre,
              STATUS_BOOKING.action,
              STATUS_BOOKING.booked,
              STATUS_BOOKING.confirmed,
              STATUS_BOOKING.arrived,
            ].includes(bookStatus)));

    const disableUpdateAndDispatch =
      propsDriver === stateDriver &&
      ['booked', 'arrived'].includes(bookStatus) &&
      data.dispatchType === 1
        ? true
        : false;

    let isDisableUpdateInfo =
      data.reservation === true &&
      bookStatus !== 'confirmed' &&
      bookStatus !== 'booked' &&
      bookStatus !== 'arrived' &&
      bookStatus !== 'action' &&
      bookStatus !== 'pre' &&
      bookStatus !== 'pending';

    if(data.request.pickUpTime === 'Now') {
      if (data.dispatchType === 1) {
        isDisableUpdateInfo = propsDriver !== stateDriver;
      }

      if (
        data.dispatchType === 0 && 
        [STATUS_BOOKING.booked, STATUS_BOOKING.arrived].includes(bookStatus) &&
        !stateDriver
      ) {
        isDisableUpdateInfo = true;
      }
    }

    return (
      <Modal
        show={showConfirmUpdate}
        backdrop
        dialogClassName="confirm-dialog update-book-detail-modal"
        onHide={updateBookingButtonClick}
        className={'confirm'}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value="bookingdetail.CONFIRM_UPDATE" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Translate value="bookingdetail.CONFIRM_UPDATE_MESSAGE" />
          {showWarningResetEditFare && (
            <div>
              <Translate value="bookingdetail.CONFIRM_RESET_EDIT_FARE_MESSAGE" />
            </div>
          )}
          {
            (isDelivery 
            ? selectedFleet?.settingSendEmail?.enableDelivery
            : selectedFleet?.settingSendEmail?.enableTransport) &&
            data?.request?.pickUpTime !== 'Now' &&
            <div style={{ display: 'block', marginTop: '1rem'}}>
              <CcCheckbox
                checked={notifyCustomerViaEmail}
                onChange={handleNotifyCustomerViaEmailChange}
                text={I18n.t('newbooking.notify_via_email')}
                disabled={isDisableUpdateInfo}
                style={{ marginBottom: '0px' }}
                className='mr-b-0 mr-t-20'
              />
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={updateBookingButtonClick} className="btn-reset">
            <Translate value="bookingdetail.No" />
          </Button>
          <Button
            onClick={() => updateInfoBookingClick()}
            className="btn-save"
            disabled={isDisableUpdateInfo}
          >
            {isDisableUpdateInfo ? (
              <OverlayTrigger
                overlay={
                  <Tooltip id="Update_info">
                    <Translate value="bookingdetail.Update_info_tooltip" />
                  </Tooltip>
                }
                placement="top"
                delayShow={300}
                delayHide={150}
              >
                <span className="dropdown-title">
                  <Translate
                    value={
                      isHideUpdateAndDispatch
                        ? 'newbooking.Yes'
                        : 'bookingdetail.Update_info'
                    }
                  />
                </span>
              </OverlayTrigger>
            ) : (
              <Translate
                value={
                  isHideUpdateAndDispatch
                    ? 'newbooking.Yes'
                    : 'bookingdetail.Update_info'
                }
              />
            )}
          </Button>
          {![
            'completed',
            'noShow',
            'incident',
            'canceled',
            'completedWithoutService',
            'droppedOff',
            'engaged',
            'delivering',
          ].includes(data.status) &&
            !hideUpdateInfoAndDispatch &&
            !farmOut &&
            !data.finishedAt &&
            user.userType !== userType.CorporateAdmin &&
            user.userType !== userType.CorporateUser &&
            !(listGroup && listGroup.length > 0) && // hide when is booking group SL-28263
            !isHideUpdateAndDispatch && ( // hide: later book and auto dispatch
              <Button
                variant="success"
                className="btn-save"
                onClick={updateInfoAndDispatchBookingClick}
                disabled={disableUpdateAndDispatch}
              >
                <Translate value="bookingdetail.Update_info_dispatch" />
              </Button>
            )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default UpdateConfirmationModal;
